import React, { FC } from 'react';
import { CellClickedEvent, ICellRendererParams } from 'ag-grid-community';
import { FactCheckOutlined } from '@mui/icons-material';
import { IDailyReport } from '../../../main/types/jobDetailTypes';

interface EditQualityControlRendererProps extends ICellRendererParams {
    handleQCClick(data: IDailyReport): void;
}

const EditQualityControlRenderer: FC<EditQualityControlRendererProps> = ({handleQCClick, ...props}) => {
    
    return <span className="icon-wrapper align-right">
        {props.value ? <FactCheckOutlined className="cursor-pointer" onClick={() => {handleQCClick(props.data)}} /> : ""}
    </span>
};

export default EditQualityControlRenderer;
