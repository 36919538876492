import { FC, useMemo } from "react";
import { Document, Page, View, Image, Text } from '@react-pdf/renderer';
import styles from './PDFInvoiceGeneratorStyles';
import PDFTable from "../PDFRendererComponents/PDFTable/PDFTable";
import PDFTableHeader from "../PDFRendererComponents/PDFTableHeader/PDFTableHeader";
import PDFTableCell from "../PDFRendererComponents/PDFTableCell/PDFTableCell";
import PDFTableRow from "../PDFRendererComponents/PDFTableRow/PDFTableRow";
import PDFTableDataCell from "../PDFRendererComponents/PDFTableDataCell/PDFTableDataCell";
import { IDailyJobCode, IInvoiceSummary, IJobDetailCombined } from "../../../main/types/jobDetailTypes";
import PDFTableNumberCell from "../PDFRendererComponents/PDFTableDataCell/PDFTableNumberCell";
import { reduce } from "lodash";
import { logoBlack } from "../../constants/imageConstants";
import { ILookupItem } from "../../../main/types/genericTypes";
import moment from "moment";
import { dateFormat } from "../../constants/dateFormat";

interface InvoiceGeneratorProps {
    fileName: string,
    data: IInvoiceSummary[],
    dailyData: IDailyJobCode[],
    customerList: ILookupItem[],
    bighamList: ILookupItem[],
    categoryList: ILookupItem[],
    invoiceDate: string,
    invoiceNumber: string,
    jobDetails: IJobDetailCombined
}

const InvoiceGenerator: FC<InvoiceGeneratorProps> = ({fileName, data, dailyData, invoiceDate, invoiceNumber, jobDetails, categoryList, bighamList, customerList}) => {

    const total = useMemo(() => {
        return reduce(data, (prev, curr) => {
            if (invoiceDate) {
                return prev + curr.invoicedTotal;
            } else {
                return prev + curr.newInvoiceTotal;
            }
        }, 0);
    }, [data]);

    const getName = (list: ILookupItem[], id: number) => {
        return list.find((listItem) => listItem.id === id )?.name;
    }

    const {
        bighamCoord,
        customerCoord,
        category
    } = useMemo(() => {
        const bighamCoord = getName(bighamList, jobDetails.details.bCoord);
        const customerCoord = getName(customerList, jobDetails.details.cCoords[0]);
        const category = getName(categoryList, jobDetails.details.category);
        return {
            bighamCoord,
            customerCoord,
            category
        }

    }, [bighamList, customerList, categoryList, jobDetails.details])

    const invoiceDateMemo = useMemo(() => {
        return moment(invoiceDate).format(dateFormat);
    }, [invoiceDate]);

    return <Document title={fileName}>
        <Page style={styles.page} orientation={'portrait'}>
            <View style={styles.header}>
                <Image style={styles.headerContent} src={logoBlack} />
                <Text style={styles.contentText}>Invoice #: {invoiceNumber}</Text>
            </View>
            <View style={styles.content}>
                <View style={styles.contentRow}>
                    <Text style={styles.contentText}>Bigham: {bighamCoord}</Text>
                    <Text style={styles.contentText}>PO#: {jobDetails.purchaseOrder.purchaseOrderNumber}</Text>
                </View>
                <View style={styles.contentRow}>
                    <Text style={styles.contentText}>Customer Rep: {customerCoord}</Text>
                    <Text style={styles.contentText}>{jobDetails.details.job}</Text>
                </View>
                <View style={styles.contentRow}>
                    <Text style={styles.contentText}>Invoice Date: {invoiceNumber ? invoiceDateMemo : ''}</Text>
                    <Text style={styles.contentText}>Job Type: {category}</Text>
                </View>
                <View style={styles.contentRow}>
                    <Text style={styles.contentText}>LOCATION: {jobDetails.details.address1}</Text>
                </View>
            </View>
            <PDFTable>
                <>
                    <PDFTableHeader>
                        <PDFTableCell label={'Code'} />
                        <PDFTableCell label={'Description'} flexGrow={3} />
                        <PDFTableCell label={'Quantity'} />
                        <PDFTableCell label={'Unit Price'} />
                        <PDFTableCell label={'Total'} />
                    </PDFTableHeader>
                    {!!data?.length && data.map((rowData, index) => {
                        return <PDFTableRow key={index} rowData={rowData}>
                            <PDFTableDataCell fieldName={'jobCode'} />
                            <PDFTableDataCell fieldName={'description'} flexGrow={3} />
                            <PDFTableNumberCell fieldName={invoiceDate ? 'invoicedToDate' : 'newInvoiceQuantity'} textAlign={'center'} />
                            <PDFTableNumberCell fieldName={'unitPrice'} textAlign={'right'} isMoney={true} />
                            <PDFTableNumberCell fieldName={invoiceDate ? 'invoicedTotal' : 'newInvoiceTotal'} textAlign={'right'} isMoney={true} shaded={true} />
                        </PDFTableRow>
                    })}
                    <PDFTableRow rowData={{ text: 'Total Amount', total }}>
                        <PDFTableDataCell fieldName={'text'} flexGrow={6.65} textAlign={'right'} fontSize={'10px'} />
                        <PDFTableNumberCell fieldName={'total'} textAlign={'right'} isMoney={true} shaded={true} />
                    </PDFTableRow>
                </>
            </PDFTable>
        </Page>
        <Page style={styles.page} orientation={'portrait'}>
            <View style={styles.header}>
                <View style={styles.headerContent}>
                    <Text style={styles.headerText}>Invoice Daily Job Codes</Text>
                </View>
            </View>
            <PDFTable>
                <>
                    <PDFTableHeader>
                        <PDFTableCell label={'Date'} />
                        <PDFTableCell label={'Job Number'} />
                        <PDFTableCell label={'Business Name'} />
                        <PDFTableCell label={'Address'} />
                        <PDFTableCell label={'Bigham Coordinator'} />
                        <PDFTableCell label={'Crew Members'} />
                        <PDFTableCell label={'Job Code'} />
                        <PDFTableCell label={'Job Code Description'} />
                        <PDFTableCell label={'Quantity'} />
                        <PDFTableCell label={'Unit Price'} />
                        <PDFTableCell label={'Total Price'} />
                    </PDFTableHeader>
                    {!!dailyData?.length && dailyData.map((rowData, index) => {
                        return <PDFTableRow key={index} rowData={rowData}>
                            <PDFTableDataCell fieldName={'date'} />
                            <PDFTableDataCell fieldName={'jobNumber'} />
                            <PDFTableDataCell fieldName={'businessName'} />
                            <PDFTableDataCell fieldName={'address'} />
                            <PDFTableDataCell fieldName={'bighamCoordinator'} />
                            <PDFTableDataCell fieldName={'crewMembers'} />
                            <PDFTableDataCell fieldName={'jobCode'} />
                            <PDFTableDataCell fieldName={'jobCodeDescription'} />
                            <PDFTableNumberCell fieldName={'quantity'} textAlign={'right'}/>
                            <PDFTableNumberCell fieldName={'unitPrice'} textAlign={'right'} />
                            <PDFTableNumberCell fieldName={'totalPrice'} textAlign={'right'} />
                        </PDFTableRow>
                    })}
                </>
            </PDFTable>
        </Page>
    </Document>;
}

export default InvoiceGenerator;