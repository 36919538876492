import bighamApi from '../axios/bighamApi';
import { IUserPermissions } from '../types/permissionTypes';

export const getUserPermissions = async (): Promise<{
  data: IUserPermissions;
}> => {
  const response = bighamApi({
    method: 'GET',
    url: 'Users/Permissions'
  });
  return response;
};
