import { AgGridReact } from 'ag-grid-react';
import React, { ColDef, GridReadyEvent, GridSizeChangedEvent, RowNode, SelectionChangedEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { FC, ForwardedRef } from 'react';
import MultiSelectFilter from '../../../../library/components/GridComponents/GridMultiSelectFilter';
import { forwardRef } from 'react';
import ValueRenderer from '../../../../library/components/GridComponents/ValueRenderer';
import { DateColumnDef, DefaultColumnValues, MoneyColumnDef, moneyFormatter, NumberColumnDef, StringColumnDef } from '../../../JobTracker/trackerColumnFactory';


interface RequestedInvoiceSummaryGridProps {
    ref: ForwardedRef<AgGridReact>;
    listItems: any[]
}

const RequestedInvoiceSummaryGrid: FC<RequestedInvoiceSummaryGridProps> = forwardRef<AgGridReact, RequestedInvoiceSummaryGridProps>(({listItems}, ref) => {
    
    const presetColumnWidth = 160;

    const columnDefs: ColDef[] = [
        {
            ...StringColumnDef('jobCode', 'Job Code', false),
            ...DefaultColumnValues(presetColumnWidth),
        },
        {
            ...StringColumnDef('description', 'Description', false),
            suppressSizeToFit: false
        },
        {
            ...MoneyColumnDef('newInvoiceTotal', 'Total', false),
            ...DefaultColumnValues(presetColumnWidth)
        }
    ];

    return <div className="grid-wrapper ag-theme-material">
       {!!listItems?.length && <AgGridReact
            ref={ref}
            components={{
                multiSelect: MultiSelectFilter
            }}
            domLayout="autoHeight"
            defaultColDef={
                {
                    resizable: true,
                    cellRenderer: ValueRenderer,
                    sortable: true,
                    suppressSizeToFit: true,
                    menuTabs: ['filterMenuTab'],
                }
            }
            columnDefs={columnDefs}
            rowData={listItems}
            suppressRowClickSelection={true}
            onGridReady={(event: GridReadyEvent) => {
                event.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(event: GridSizeChangedEvent) => {
                event.api.sizeColumnsToFit();
            }}
        />}
    </div>
})

export default RequestedInvoiceSummaryGrid;