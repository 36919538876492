import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColDef } from 'ag-grid-community';
import {
  transformJobTrackerResponsePayload
} from '../../library/transforms/jobTrackerTransform';
import { generateGridConfig } from '../../pages/JobTracker/trackerColumnFactory';
import {
  getCategoryList,
} from '../api/lookupApi';
import { getTrackerData, getTrackerDataPaged } from '../api/trackerApi';
import type { AppDispatch, RootState } from '../store/store';
import { IJobTrackerDashboardSample, IJobTrackerSummary } from '../types/trackerTypes';
import {
  callGetUserLookupsByArea,
  selectAreaList,
  selectCategoryList,
  setCategoryList
} from './lookupSlice';
import { callGetUserPreferences, selectColumnOrder, selectFilterModel } from './preferencesSlice';

interface TrackerState {
    trackerData: IJobTrackerDashboardSample[];
    mapData: IJobTrackerDashboardSample[];
  trackerGridColDef: ColDef[];
}

// Define the initial state using that type
const initialState: TrackerState = {
  trackerData: [],
  mapData: [],
  trackerGridColDef: []
};

const getCommonTrackerData = async (
  areaId: number,
  dispatch: AppDispatch,
  state: RootState,
  requestParams: any
) => {
  await dispatch(callGetUserLookupsByArea(areaId));
  let categoryList = selectCategoryList(state);
  if (!categoryList?.length) {
    const categoryListResponse = await getCategoryList();
    dispatch(setCategoryList(categoryListResponse.data));
    categoryList = categoryListResponse.data;
  }
  const response = await getTrackerDataPaged(areaId, requestParams);
  const transformedResponse = transformJobTrackerResponsePayload(response.rowData);
  return transformedResponse;
};

export const callGetMapData = createAsyncThunk<
  any,
  number,
  { state: RootState; dispatch: AppDispatch }
>('tracker/getMapData', async (areaId, { dispatch, getState }) => {
  const state = getState();
  let filterModel = selectFilterModel(state);
  if (!Object.keys(filterModel)?.length) {
    const response = await dispatch(callGetUserPreferences());
    filterModel = (response.payload as any).filterModel;
  }
  const transformedResponseWithForemenMapped: {
      trackerData: IJobTrackerDashboardSample[];
  } = await getCommonTrackerData(areaId, dispatch, state, {
    startRow: 0,
    endRow: 0,
    filterModel,
    sortModel: [],
  });
  dispatch(setMapData(transformedResponseWithForemenMapped.trackerData));
});

export const trackerSlice = createSlice({
  name: 'tracker',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setMapData: (state, action: PayloadAction<IJobTrackerDashboardSample[]>) => {
      state.mapData = action.payload;
    }
  }
});

export const {
  setMapData
} = trackerSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMapData = (state: RootState) => state.tracker.mapData;
export default trackerSlice.reducer;
