import { FC, useMemo } from "react";
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import variables from '../../../../assets/styles/variables.module.scss';

interface PDFTableDataCellProps {
    fieldName: any,
    rowData?: any,
    flexGrow?: number,
    fontSize?: string,
    textAlign?: 'left' | 'center' | 'right'
}

const styles = StyleSheet.create({
    cell: {
        flexBasis: 0,
        flexGrow: 1,
        flexShrink: 1,
        borderRight: '1px solid black',
        paddingTop: variables.spacing_xxs,
        paddingBottom: variables.spacing_xxs,
        paddingLeft: variables.spacing_xs,
        paddingRight: variables.spacing_xs
    },
    cellValue: {
        textAlign: 'center'
    }
})

const PDFTableDataCell: FC<PDFTableDataCellProps> = ({fieldName, rowData, textAlign = 'left', flexGrow = 1, fontSize = '8px' }) => {
    
    const value = useMemo(() => {
        if (rowData[fieldName].indexOf('+') > -1) {
            return rowData[fieldName].split('+').join(' + ');
        }
        if (rowData[fieldName].indexOf(',') > -1) {
            return rowData[fieldName].split(',').join(', ');
        }
        return rowData[fieldName];
    }, [rowData[fieldName]]);

    return <View style={{ ...styles.cell, flexGrow }}>
        <Text style={{ ...styles.cellValue, textAlign, fontSize }}>{value}</Text>
    </View>
}

export default PDFTableDataCell;