import React, { ChangeEvent, FC } from 'react';
import BighamSelect from '../../../../../library/components/FormControls/BighamSelect/BighamSelect';
import BighamDatePicker from '../../../../../library/components/FormControls/BighamDatePicker/BighamDatePicker';
import { ILookupItem } from '../../../../../main/types/genericTypes';
import BighamCheckbox from '../../../../../library/components/FormControls/BighamCheckbox/BighamCheckbox';
import { CloseOutlined } from '@mui/icons-material';
import BighamTextInput from '../../../../../library/components/FormControls/BighamTextInput/BighamTextInput';
import { ISplicerPermissions } from '../../../../../main/types/permissionTypes';
import { IJobTeamErrors } from '../../../JobDetails';

interface SplicerCardProps {
    data: any,
    list: any[],
    index: number,
    foremanList: ILookupItem[],
    installerList: ILookupItem[],
    splicerOptions: ILookupItem[],
    handleFormChange(value: any, fieldName?: string): void;
    permissions: ISplicerPermissions;
    errors: IJobTeamErrors;
    setErrors(errors: IJobTeamErrors): void;
}


const SplicerCard: FC<SplicerCardProps> = ({data, list, index, foremanList, installerList, splicerOptions, handleFormChange, permissions, errors, setErrors}) => {

    const handleLocalChange = (value: any, fieldName: string) => {
        const updatedValue = {...data, [fieldName]: value};
        const updatedList = [...list];
        updatedList.splice(index, 1, updatedValue);
        handleFormChange(updatedList, 'splicer.teams');
        const temp = {...errors};
        delete temp?.splicer?.[index]?.[fieldName];
        setErrors(temp);
    }

    const handleCheckboxChange = (e: ChangeEvent<{ value: any, checked: boolean}>, fieldName: string) => {
        const updatedValue = {...data, [fieldName]: e.target.checked};
        const updatedList = [...list];
        updatedList.splice(index, 1, updatedValue);
        handleFormChange(updatedList, 'splicer.teams');
    }

    const handleRemoveTeam = () => {
        const updatedList = [...list];
        updatedList.splice(index, 1);
        handleFormChange(updatedList, `splicer.teams`);
        const temp = {...errors};
        delete temp.splicer?.[index];
        setErrors(temp);
    }
    
    return <div className={`footage-card-wrapper form`}>
        {!data.id && <div className="delete-team-wrapper">
            <CloseOutlined onClick={handleRemoveTeam} />
        </div>}
        <div className="form-row">
            {permissions.canViewSplicer && <BighamSelect
                fieldName={`foremanId`}
                listItems={foremanList}
                value={data[`foremanId`]}
                label={`Splicer 1`}
                onChange={handleLocalChange}
                readOnly={!permissions.canEditSplicer}
                error={errors.splicer?.[index]?.foremanId}
            />}
            {permissions.canViewSplicer && <BighamSelect
                fieldName={`splicer2Id`}
                listItems={foremanList}
                value={data[`splicer2Id`]}
                label={`Splicer 2`}
                onChange={handleLocalChange}
                readOnly={!permissions.canEditSplicer}
            />}

        </div>
        <div className="form-row">
            <BighamDatePicker
                fieldName={`splicerIssueDate`}
                value={data[`splicerIssueDate`]}
                label="Splicer Issue Date"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditSplicerScheduledDate}
                error={errors.splicer?.[index]?.splicerIssueDate}
            />
            <BighamSelect
                fieldName={`splicerOptionId`}
                listItems={splicerOptions}
                value={data[`splicerOptionId`]}
                label={`Splicer Option`}
                onChange={handleLocalChange}
                readOnly={!permissions.canEditSplicerOptions}
            />
        </div>
        <div className="form-row">
            <BighamDatePicker
                fieldName={`scheduledSplicerDate`}
                value={data[`scheduledSplicerDate`]}
                label="Scheduled Date"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditSplicerScheduledDate}
                error={errors.splicer?.[index]?.scheduledSplicerDate}
            />
            {permissions.canViewInstaller && <BighamSelect
                fieldName={`installerId`}
                listItems={installerList}
                value={data[`installerId`]}
                label={`Installer`}
                onChange={handleLocalChange}
                readOnly={!permissions.canEditInstaller}
            />}
        </div>
        <div className="form-row">
            <BighamDatePicker
                fieldName={`installerIssueDate`}
                value={data[`installerIssueDate`]}
                label="Installer Issue Date"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditSplicerScheduledDate}
                error={errors.splicer?.[index]?.installerIssueDate}
            />
            <BighamDatePicker 
                fieldName={`installDate`}
                value={data[`installDate`]}
                label="Install Date"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditInstallDate}
                error={errors.splicer?.[index]?.installDate}
            />
        </div>
        <div className="form-row">
            <BighamDatePicker 
                fieldName={`completedDate`}
                value={data[`completedDate`]}
                label="Complete Date"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditCrewCompleteDate}
                error={errors.splicer?.[index]?.completedDate}
            />
        </div>
        <div className="form-row">
            <BighamTextInput
                fieldName="comments"
                value={data['comments']}
                label="Comments"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditComments}
            />
        </div>
        <div className="form-row">
            <div className="form-control">
                <BighamCheckbox
                    fieldName="isCritical"
                    checked={data['isCritical']}
                    value={data['isCritical']}
                    onChange={handleCheckboxChange}
                    label="Critical"
                    disabled={!permissions.canEditIsCritical}
                />
            </div>
        </div>
    </div>
}
export default SplicerCard;
