import React, { useEffect, useRef, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { selectDownloadPacket } from '../../../main/slices/jobDetailSlice';

const FileDownload: FC = () => {
    const downloadLinkRef = useRef<HTMLAnchorElement>(null);
    const [downloadUrl, setDownloadUrl] = useState('');

    const downloadPacket = useSelector(selectDownloadPacket);

    useEffect(() => {
        if (downloadPacket.data && downloadPacket?.name && downloadPacket.name.length > 0) {
            setDownloadUrl(window.URL.createObjectURL(downloadPacket.data));
        }
    }, [downloadPacket.data, downloadPacket.name]);

    useEffect(() => {
        if (downloadUrl.length) {
            downloadLinkRef?.current?.click();
        }
    }, [downloadUrl]);

    return <>
        {downloadUrl?.length > 0 && <a download={downloadPacket.name} ref={downloadLinkRef} type="file" style={{display: 'none'}} href={downloadUrl} />}
    </>
}

export default FileDownload;