import { ExpandMoreOutlined, FileDownloadOutlined, InsertDriveFileOutlined, Delete, EditLocationAltOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FC, ChangeEvent, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../main/hooks/hooks";
import { callAnnotateFile, callDownloadFile, callDeleteFile, resetDownloadPacket, selectAnnotationData, setAnnotationData, callGetJobDetailData } from "../../../../main/slices/jobDetailSlice";
import { IDocument } from "../../../../main/types/jobDetailTypes";
import { IJobDocumentsPermissions } from "../../../../main/types/permissionTypes";
import DocumentUploadModal from "../../../../library/components/DocumentUploadModal/DocumentUploadModal";
import FileDownload from "../../../../library/components/FileDownload/FileDownload";
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import { formatDate } from "../../../../library/transforms/utilTransforms";
import './DocumentSection.scss'
import DocumentViewer from "./DocumentViewer/DocumentViewer";
interface DocumentSectionProps {
    jobId: string,
    jobDetailModel: any;
    setJobDetailModel: any;
    openSection: string,
    handleAccordionAction(panel: string): (event: ChangeEvent<any>, isExpanded: boolean) => void,
    permissions: IJobDocumentsPermissions
}


const DocumentSection: FC<DocumentSectionProps> = ({
    jobId,
    jobDetailModel,
    setJobDetailModel,
    openSection,
    handleAccordionAction,
    permissions
}) => {
    const sectionId = 'documents';
    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState<boolean>(false);
    const annotationData = useAppSelector(selectAnnotationData);

    const handleFileDownload = (file: IDocument) => () => {
        dispatch(callDownloadFile({ fileName: file.fileName, fileId: file.id }));
    }

    const handleFileDelete = (file: IDocument) => () => {
        dispatch(callDeleteFile({ fileName: file.fileName, fileId: file.id}));
        dispatch(callGetJobDetailData(Number(jobId)))
    }

    const handleAnnotateDocument = (file: IDocument) => () => {
        dispatch(callAnnotateFile({ fileName: file.fileName, fileId: file.id }));
    }

    useEffect(() => {
        return () => {
            dispatch(resetDownloadPacket());
        }
    }, []);

    const canAnnotateDocument = (key: string, fileName: string): boolean => 
        key.toLowerCase() === 'maps' && permissions.canAddDocument && permissions.canDownloadDocument && fileName.endsWith('.pdf');

    return <>
        {showModal && <DocumentUploadModal onCancel={() => setShowModal(false)} jobId={jobId} jobDetailModel={jobDetailModel} setJobDetailModel={setJobDetailModel} />}
        <FileDownload />
        {!!annotationData?.url?.length && <DocumentViewer annotationData={annotationData} jobId={jobId} jobDetailModel={jobDetailModel} setJobDetailModel={setJobDetailModel} />}
        <Accordion className="styled-accordion" expanded={openSection === sectionId} id={sectionId} onChange={handleAccordionAction(sectionId)}>
            <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
            >
                <div className="flex-header">
                    <div className="component-title">Documents</div>
                    
                </div>
            </AccordionSummary>
            <AccordionDetails
                style={{flexFlow: 'column'}}
            >
                <div className="document-container">
                    {Object.keys(jobDetailModel?.documents)?.length > 0 && Object.keys(jobDetailModel.documents).map((key: string) => {
                        return <div className='document-section' key={key}>
                            <div className="section-title">{key}</div>
                            {jobDetailModel.documents[key]?.length > 0 && jobDetailModel.documents[key].map((file: IDocument, index: number) => {
                                return <div className="file-row" key={index}>
                                    <span className="icon-name-wrapper"><InsertDriveFileOutlined /><span>{file.fileName}</span></span>
                                    {file.uploadedOn && <span className="upload-date">{formatDate(file.uploadedOn, 'MM/DD/YYYY hh:mm A')}</span>}
                                    <span className="download-wrapper">
                                        {permissions.canDeleteDocument && <Delete className="download-icon" onClick={handleFileDelete(file)} />}
                                        {canAnnotateDocument(key, file.fileName) && <EditLocationAltOutlined className="download-icon" onClick={handleAnnotateDocument(file)} />}
                                        {permissions.canDownloadDocument && <FileDownloadOutlined className="download-icon" onClick={handleFileDownload(file)} />}
                                    </span>
                                </div>
                            })}
                        </div>
                    })}
                </div>
                {permissions.canAddDocument && <div className="flex-row center">
                    <BighamButton
                        variant="contained"
                        type="primary"
                        label="Add New Documents"
                        onClick={() => setShowModal(true)}
                    />
                </div>}
            </AccordionDetails>
        </Accordion>
    </>
}

export default DocumentSection;