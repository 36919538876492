import React, { useEffect } from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { LoadScript } from '@react-google-maps/api';
import './App.scss';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import store from './main/store/store';
import { BrowserRouter as Router } from 'react-router-dom';
import RouteDefinitionComponent from './main/routes/RouteDefinitions';
import bighamApi, { attachAuthInterceptor } from './main/axios/bighamApi';
import { addFetch, removeFetch, setActiveUser, setToastMessage } from './main/slices/utilSlice';
import LoadingIcon from './library/components/LoadingIcon/LoadingIcon';
import ToastMessage from './library/components/ToastMessage/ToastMessage';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './main/keycloak/keycloak';
import jwt_decode from "jwt-decode";
import { theme } from './main/layout/theme';
import { LicenseManager } from  'ag-grid-enterprise'
import 'ag-grid-enterprise';

function App() {
  let google;
  // Logic to show the loading icon on any http request.
  // placed here so that we can dispatch redux actions without circular dependency import issues.
  bighamApi.interceptors.request.use(function (config) {
    store.dispatch(addFetch());
    return config
  }, function (error) {
    return Promise.reject(error);
  });
  // setup general error handler for all API requests.
  bighamApi.interceptors.response.use(function (response) {
    store.dispatch(removeFetch());
    return response;
  }, function (error) {
    store.dispatch(removeFetch());
    store.dispatch(setToastMessage({
      type: 'error',
      message: error.response.data.message
    }));
    return Promise.reject(error);
  });

  const handleKeycloakToken = (tokens: any) => {
    const token = jwt_decode(tokens.token);
    store.dispatch(setActiveUser(token));
    attachAuthInterceptor(tokens.token);
  }

  useEffect(() => {
    LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-043050 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Bigham Cable Construction )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Bigham Cable Job Tracker )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Bigham Cable Job Tracker )_need_to_be_licensed___( Bigham Cable Job Tracker )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 30 May 2024 )____[v2]_MTcxNzAyMzYwMDAwMA==b3a135d844f927d2ef39ec96c03959fe');
  }, []);

  return (
    <ReactKeycloakProvider
      initOptions={{ onload: 'login-required' }}
      authClient={keycloak}
      onTokens={handleKeycloakToken}
    >
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={['places']}>
            <LoadingIcon />
            <ToastMessage />
            <Router>
              <RouteDefinitionComponent />
            </Router>
          </LoadScript>
        </Provider>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
