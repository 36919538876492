import { configureStore } from '@reduxjs/toolkit';
import codeSlice from '../slices/codeSlice';
import jobDetailSlice from '../slices/jobDetailSlice';
import lookupSlice from '../slices/lookupSlice';
import preferencesSlice from '../slices/preferencesSlice';
import reportsSlice from '../slices/reportsSlice';
import trackerSlice from '../slices/trackerSlice';
import userSlice from '../slices/userSlice';
import utilSlice from '../slices/utilSlice';

const store = configureStore({
  reducer: {
    jobDetail: jobDetailSlice,
    preferences: preferencesSlice,
    lookup: lookupSlice,
    tracker: trackerSlice,
    user: userSlice,
    util: utilSlice,
    code: codeSlice,
    reports: reportsSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: true
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
