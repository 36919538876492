import React, { ChangeEvent, ChangeEventHandler, FC } from 'react';
import { TextField } from '@material-ui/core';
import { useEffect } from 'react';
import { InputAdornment } from '@mui/material';
import { formatNumberWithCommas } from '../../../util/numberFormatter';

interface TextProps {
    label?: string,
    placeholder?: string,
    value: string | number | undefined,
    className?: string,
    error?: string,
    decimalPlaces?: number
}

const BighamMoneyDisplayInput: FC<TextProps> = ({label, placeholder, value = '', className = '', error = "", decimalPlaces = 2}) => {

    const InputProps: any = {
      inputProps: {
        readOnly: true
      },
      startAdornment: <InputAdornment position="start">$</InputAdornment>
    }

    return <TextField
        className={`form-control ${className}`}
        label={label}
        variant={'outlined'}
        placeholder={placeholder}
        value={formatNumberWithCommas(parseFloat(""+value).toFixed(decimalPlaces))}
        size="small"
        error={!!error.length}
        helperText={error}
        InputProps={InputProps}
    >
    </TextField>;
}

export default BighamMoneyDisplayInput;