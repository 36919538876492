import generateQueryString from '../../library/util/generateQueryString';
import bighamApi from '../axios/bighamApi';
import { IUserInterface, IUserServiceGet } from '../types/userTypes';

export const getUserList = async (): Promise<{ data: IUserServiceGet[] }> => {
  const response = bighamApi({
    url: `Users`,
    method: 'GET'
  });
  return response;
};

export const getUserDetails = async (
  userId: number
): Promise<{ data: IUserServiceGet }> => {
  const response = bighamApi({
    url: `Users/${userId}`,
    method: 'GET'
  });
  return response;
};

export const updateUserDetails = async (
  user: IUserInterface
): Promise<{ data: number }> => {
  const response = bighamApi({
    url: `Users/${user.id}`,
    method: 'PUT',
    data: user
  });
  return response;
};

export const createNewUser = async (
  user: IUserInterface
): Promise<{ data: number }> => {
  const response = bighamApi({
    url: `Users`,
    method: 'POST',
    data: user
  });
  return response;
};

export const validateUser = async (payload: {
  [key: string]: string;
}): Promise<{ data: boolean }> => {
  const response = bighamApi({
    url: `Users/Validate${generateQueryString(payload)}`,
    method: 'GET'
  });
  return response;
};
