import ReactDOM from 'react-dom/client';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

declare global {
  interface Window {
    _env_: {
      API_BASE_URL: string,
      KEYCLOAK_URL: string,
      KEYCLOAK_REALM: string,
      KEYCLOAK_CLIENT_ID: string,
      JOB_TRACKER_VERSION: string
    };
    map: google.maps.Map
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_GOOGLE_API_KEY: string,
      REACT_APP_PDF_TRON_KEY: string,
    }
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
