import { FC } from 'react';
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../library/constants/routeConstants';

interface PrivateRouteTypes {
    children: JSX.Element,
    canAccessRoute(): boolean
};

const PrivateRoute: FC<PrivateRouteTypes> = ({children, canAccessRoute}) => {
    const { keycloak, initialized } = useKeycloak();
    const navigate = useNavigate();
    const isLoggedIn = keycloak.authenticated;
    if (isLoggedIn && canAccessRoute()) {
        return children;
    } else if (initialized && keycloak.authenticated && !canAccessRoute()) {
        navigate(ROUTES.TRACKER);
        return <span></span>
    } else {
        return <span></span>
    }
};

export default PrivateRoute;
