import { ILookupItem } from "../../main/types/genericTypes"
import { IUserInterface, IUserServiceGet } from "../../main/types/userTypes"


export const transformUserDetailsResponse = (user: IUserServiceGet): IUserInterface => {
    const returnValue: IUserInterface = {
        ...user,
        ...user.address,
        address: user.address.addressLine1,
        zip: user.address.zipCode,
        areaIds: user.areas.map((area: ILookupItem) => area.id)
    };
    delete returnValue.addressId;
    delete returnValue.addressLine1;
    delete returnValue.addressLine2;
    delete returnValue.areas;
    delete returnValue.zipCode;
    return returnValue;
}

export const transformUserDetailsForUpdate = (user: IUserInterface): IUserInterface => {
    const returnValue: IUserInterface = {
        ...user
    };
    delete returnValue.username;
    delete returnValue.password;
    delete returnValue.confirmPassword;
    return returnValue;
}

export const transformUserDetailsForCreate = (user: IUserInterface): IUserInterface => {
    const returnValue: IUserInterface = {
        ...user
    };
    delete returnValue.id;
    delete returnValue.fullName;
    return returnValue;
}