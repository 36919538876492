import { FC, useEffect, useMemo, useState } from 'react';
import './MyProductionReports.scss';
import { AgGridReact } from 'ag-grid-react';
import { useRef } from 'react';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import { useAppDispatch, useAppSelector, useReferredState } from '../../main/hooks/hooks';
import { selectSelectedArea, setComponentName, setSelectedArea } from '../../main/slices/utilSlice';
import BighamSelect from '../../library/components/FormControls/BighamSelect/BighamSelect';
import { callGetAreaList, selectAreaList } from '../../main/slices/lookupSlice';
import MyProductionReportsGrid from './MyProductionReportsGrid';
import { selectMyProductionReports, setMyProductionReports } from '../../main/slices/reportsSlice';
import { formatNumberWithCommas } from '../../library/util/numberFormatter';
import { IServerSideDatasource } from 'ag-grid-community';
import { getMyProductionReports } from '../../main/api/reportsApi';
import { SESSION_KEYS } from '../../library/constants/sessionStorageKeys';
import { FilterListOffOutlined } from '@mui/icons-material';

const MyProductionReports: FC = () => {
    const dispatch = useAppDispatch();
    const gridRef = useRef<AgGridReact>(null);
    const {
        selectedArea,
        areaList,
        productionReports
    } = useAppSelector((state) => ({
        selectedArea: selectSelectedArea(state),
        areaList: selectAreaList(state),
        productionReports: selectMyProductionReports(state)
    }));

    const [avoidServerCall, avoidServerCallRef, setAvoidServerCall] = useReferredState(true);

    const [trackerSummary, setTrackerSummary] = useState({
        totalAmount: 0,
    });

    useEffect(() => {
        dispatch(setComponentName('My Production Report'));
        !areaList?.length && dispatch(callGetAreaList());
        return () => {
            dispatch(setMyProductionReports([]));
        }
    },[]);


    useEffect(() => {
        if (areaList?.length) {
            if (selectedArea) {
                handleAreaChange(selectedArea);
            } else {
                handleAreaChange(areaList[0]?.id);
            }
        }
    }, [areaList])

    const filterModel = useMemo(() => {
        return JSON.parse(sessionStorage.getItem(SESSION_KEYS.MY_REPORT_FILTERS) || '{}');
    }, [sessionStorage.getItem(SESSION_KEYS.MY_REPORT_FILTERS)])

    const handleAreaChange = (value: any): void => {
        dispatch(setSelectedArea(value));
        dispatch(setMyProductionReports(value));
        if (gridRef.current?.api) {
            gridRef.current.api.setServerSideDatasource(dataSource(value));
        }
    }

    const dataSource = (areaId: number): IServerSideDatasource => ({
        getRows: async (params) => {
            if (avoidServerCallRef.current) {
                const filterModel = JSON.parse(sessionStorage.getItem(SESSION_KEYS.WEEKLY_REPORT_FILTERS) || '{}');
                if (filterModel && Object.keys(filterModel)?.length) {
                    params.api.setFilterModel(filterModel);
                }
                params.success({
                    rowData: [{}],
                    rowCount: 10000
                })
                return;
            }
            const response = await getMyProductionReports(areaId, params.request);
            // TODO set tracker summary here
            params.success({
                rowData: response.data.rowData as any[],
                rowCount: response.data.rowCount,
            })
            setTrackerSummary(response.data.summary);
            setTimeout(() => {
                params.columnApi.autoSizeAllColumns();
            });
        }
    })

        const handleClearFilters = () => {
        sessionStorage.setItem(SESSION_KEYS.MY_REPORT_FILTERS, '{}');
        gridRef.current?.api.setFilterModel({});
    }

    return <>
        <div className="production-report-wrapper">
            <div className="flex-row margin-bottom">
                <div className="select-wrapper">
                    <BighamSelect
                        value={selectedArea}
                        onChange={handleAreaChange}
                        fieldName={'area'}
                        label="Area"
                        listItems={areaList}
                    />
                </div>
            </div>
            <div className="grid-header">
                <div className="header-left">
                    {!!Object.keys(filterModel)?.length && <div className="clickable-link" onClick={handleClearFilters}>
                        <span>
                            Clear Filters
                        </span>
                        <FilterListOffOutlined />
                    </div>}
                    <div className="clickable-link" onClick={() => gridRef.current?.api.exportDataAsCsv({
                        fileName: 'MyProductionReport',
                        allColumns: false
                    })}>
                        <span>
                            Export to .csv
                        </span>
                        <InputOutlinedIcon />
                    </div>
                </div>
                <div className="header-right">
                        <span>
                            Total: ${formatNumberWithCommas(trackerSummary.totalAmount?.toFixed(2))} 
                        </span>
                </div>
            </div>
            <MyProductionReportsGrid ref={gridRef} avoidServerCall={avoidServerCallRef.current} setAvoidServerCall={setAvoidServerCall} areaId={selectedArea} dataSource={dataSource} />
        </div>
    </>
}

export default MyProductionReports;