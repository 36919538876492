import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { some, filter, orderBy } from 'lodash';
import React, { FC, useImperativeHandle, useState, forwardRef, useEffect, ChangeEvent } from 'react';
import { useMemo } from 'react';
import { ILookupItem } from '../../../main/types/genericTypes';
import BighamCheckbox from '../FormControls/BighamCheckbox/BighamCheckbox';
import BighamTextInput from '../FormControls/BighamTextInput/BighamTextInput';
import './filterStyles.scss';

const MultiSelectFilter: FC<IFilterParams> = forwardRef((filterParams: IFilterParams, ref) => {
    const fieldName: string = filterParams.colDef.field || '';
    const filterOptions = useMemo(() => {
        if (fieldName === 'locateDigDates' || fieldName === 'locateUpdateDates') {
            return orderBy(filterParams.context[fieldName + 'FilterOptions'], ['id'], ['asc'])
        }
        return orderBy(filterParams.context[fieldName + 'FilterOptions'], ['name'], ['asc']);
    }, [fieldName]);
    const initialValue: { [index: number]: boolean } = { [-1]: false };
    filterOptions.forEach((option: ILookupItem) => {
        initialValue[option.id] = false;
    })
    const isArray = filterParams.context.isArray;
    const [filterTerm, setFilterTerm] = useState<string>('');
    const [selectedIds, setSelectedIds] = useState<{ [index: number]: boolean }>(initialValue);
    const isAllSelected = filterOptions?.length > 0 && ((selectedIds[-1] && (filterOptions.length + 1 === filter(selectedIds).length)) || (!selectedIds[-1] && filterOptions.length === filter(selectedIds).length));

    useEffect(() => {
        filterParams.filterChangedCallback();
    }, [selectedIds])

    const handleFilterChange = (value: string) => {
        setFilterTerm(value);
    }

    const handleFormChange = (e: ChangeEvent<{ value: string, checked: boolean }>) => {
        const value: number = e.target.value ? +e.target.value : 0;
        // select All has a value of 0
        if (value === 0) {
            if (isAllSelected){
                const temp:{ [index: number]: boolean } = { [-1]: selectedIds[-1] };
                filterOptions.forEach((option: ILookupItem) => {
                    temp[option.id] = false;
                })
                setSelectedIds(temp);
            } else {
                const temp:{ [index: number]: boolean } = { [-1]: selectedIds[-1] };
                filterOptions.forEach((option: ILookupItem) => {
                    temp[option.id] = true;
                })
                setSelectedIds(temp);
            }
            return;
        }

        const isChecked: boolean = e.target.checked ? e.target.checked : false;
        const temp = {...selectedIds};
        temp[value] = isChecked;
        setSelectedIds(temp);
    }

    useImperativeHandle(ref, () => {
        return {
            isFilterActive(): boolean {
                return some(selectedIds);
            },
            doesFilterPass(filterPassParams: IDoesFilterPassParams): boolean{
                // implement custom logic to make sure that the data meets the criteria.
                if (isArray) {
                    if (filterPassParams.data[fieldName]?.length) {
                        return some(filterPassParams.data[fieldName], (value: ILookupItem) => {
                            return selectedIds[value.id];
                        })
                    } else if (selectedIds[-1] && !filterPassParams.data[fieldName]?.length) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (!filterPassParams.data[fieldName] && selectedIds[-1]) {
                        return true;
                    } else if (!filterPassParams.data[fieldName]) {
                        return false;
                    } else {
                        return selectedIds[filterPassParams.data[fieldName].id]
                    }
                }
            },
            getModel() {
                if (!this.isFilterActive()) {
                    return null;
                }
                return selectedIds;
            },
            setModel(model: any) {
                if (model) {
                    setSelectedIds(model);
                } else {
                    const temp:{ [index: number]: boolean } = {};
                    filterOptions.forEach((option: ILookupItem) => {
                        temp[option.id] = false;
                    })
                    temp[-1] = false;
                    setSelectedIds(temp);
                }
            }
        }
    });

    return <div className="flex-column flex-start select-filter">
        <div className="form">
            <div className="form-row">
                <BighamTextInput
                    className="no-margin"
                    placeholder="Search..."
                    value={filterTerm}
                    fieldName="searchTerm"
                    onChange={handleFilterChange}
                />
            </div>
        </div>
        {selectedIds[-1] !== undefined && <BighamCheckbox value={-1} fieldName={'none'} label={'(None)'} checked={selectedIds[-1]} onChange={handleFormChange} />}
        {!filterTerm?.length && <BighamCheckbox value={0} label="Select All" fieldName="all" checked={isAllSelected} onChange={handleFormChange} />}
        {filterOptions?.length > 0 && orderBy(filterOptions, ['name'], ['asc']).map((option: ILookupItem) => {
            if (selectedIds[+option.id] !== undefined && option.name.toLowerCase().includes(filterTerm.toLowerCase())){
                return <BighamCheckbox key={option.id} value={option.id} fieldName={''+option.id} label={option.name} checked={selectedIds[+option.id]} onChange={handleFormChange} />
            }
        })}
    </div>
});

export default MultiSelectFilter;