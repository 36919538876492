import { FC, useState, useRef } from "react"
import './DailyProductionReportModal.scss';
import ModalBase from "../ModalBase/ModalBase"
import BighamTextInput from "../FormControls/BighamTextInput/BighamTextInput";
import BighamButton from "../FormControls/BighamButton/BighamButton";
import { useAppDispatch, useAppSelector } from "../../../main/hooks/hooks";

import { updateNewDailyFootage } from "../../../main/api/jobDetailApi";

import JobCodeGrid from "../JobCodeGrid/JobCodeGrid";

import { selectDailyStatusList } from "../../../main/slices/lookupSlice";
import { IDailyProductionReport } from "../../../main/types/reportTypes";
import { IDailyReport, ITeamJobCode } from "../../../main/types/jobDetailTypes";

import { JOB_CODE_STATUS } from "../../constants/jobCodeStatus";

import { IProductionReportsPermissions } from "../../../main/types/permissionTypes";


interface NewFootageModal {
    onCancel(): void,
    reportDetails?: IDailyProductionReport | IDailyReport,
    permissions: IProductionReportsPermissions | undefined,
    handleSaveNewFootage: any,
    setReportDetailsModel: any,
    reportDetailsModel: any,
}


const DailyProductionReportModal: FC<NewFootageModal> = ({onCancel, reportDetails, permissions, handleSaveNewFootage, setReportDetailsModel, reportDetailsModel}) => {
    const dailyId = reportDetails?.id;
    // const [reportDetailsModel, setReportDetailsModel] = useState<any>(reportDetails);
    const dailyStatusList = useAppSelector(selectDailyStatusList);

    
    const handleFormChange = (value: any, fieldName: string) => {
        setReportDetailsModel({ ...reportDetailsModel, [fieldName]: value });
    }

    const canReviewReport = (): boolean => {
        // status cannot be approved, invoiceRequested, or invoiced.
        return (
            !!permissions?.canApproveOrRejectDaily && !!permissions?.canEditDailyApproverComments
        );
    }

    return <ModalBase onCancel={onCancel} size="small">
        <div className="flex-row">
            <span className="modal-title">
                Edit Footage
            </span>
        </div>
        <div className="form">
            <div className="form-row">
                <BighamTextInput
                fieldName="newDailyFootage"
                value={reportDetailsModel.newDailyFootage}
                label="New Daily Footage"
                onChange={handleFormChange}
                readOnly={!canReviewReport()}
            />
            </div>
        </div>
        <div className="modal-button-container">
                <BighamButton
                    type="primary"
                    variant="contained"
                    onClick={handleSaveNewFootage}
                    label={'Save Change'}
                />
                </div>

    </ModalBase>
}

export default DailyProductionReportModal;