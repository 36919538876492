import { AgGridReact } from 'ag-grid-react';
import React, { ColDef, GridReadyEvent, GridSizeChangedEvent, RowDoubleClickedEvent, ValueGetterParams} from 'ag-grid-community';
import { FC, ForwardedRef, useEffect } from 'react';
import MultiSelectFilter from '../../library/components/GridComponents/GridMultiSelectFilter';
import { forwardRef } from 'react';
import ROUTES from '../../library/constants/routeConstants';
import { useNavigate } from 'react-router-dom';
import ValueRenderer from '../../library/components/GridComponents/ValueRenderer';
import { ArrayColumnDef, DateColumnDef, generateFilterOptions, moneyFormatter } from '../JobTracker/trackerColumnFactory';
import { dateFormat } from '../../library/constants/dateFormat';
import BooleanRenderer from '../../library/components/GridComponents/BooleanRenderer';
import { ILookupItem } from '../../main/types/genericTypes';
import { useState } from 'react';
import variables from '../../assets/styles/variables.module.scss';
import { useRef } from 'react';


interface CodeListGridProps {
    listItems: any[],
    ref: ForwardedRef<AgGridReact>
}

const CodeListGrid: FC<CodeListGridProps> = forwardRef<AgGridReact, CodeListGridProps>(({listItems}, ref) => {
    const navigate = useNavigate();
    const gridWrapperRef = useRef<HTMLDivElement | null>(null);
    const [gridHeight, setGridHeight] = useState('auto');
    const handleDoubleClick = (params: RowDoubleClickedEvent) => {
        navigate(`${ROUTES.CODES}/${params.data.id}`);
    }

    const columnDefs: ColDef[] = [
        {
            field: 'code',
            headerName: 'Code',
            filter: 'agTextColumnFilter'
        },{
            field: 'description',
            headerName: 'Description',
            filter: 'agTextColumnFilter'
        },{
            field: 'jobCodeCategories',
            headerName: 'Categories',
            valueGetter: (params: ValueGetterParams): string => {
                return params.data['jobCodeCategories'].map((codeCategory: ILookupItem) => codeCategory.name);
            },
            filter: 'multiSelect',
            filterParams: {
                context: {
                    jobCodeCategoriesFilterOptions: generateFilterOptions(listItems, 'jobCodeCategories', true),
                    isArray: true
                }
            }
        },{
            ...DateColumnDef('effectiveOn', 'Effective On', dateFormat, true)
        },{
            ...DateColumnDef('expiresOn', 'Expiration Date', dateFormat, true)
        },{
            field: 'unitCt',
            headerName: 'Unit/Count',
            valueGetter: (params: ValueGetterParams): string => {
                if (params.data['unitCt']) {
                    return params.data['unitCt'].name;
                } else {
                    return '';
                }
            },
            filter: 'multiSelect',
            filterParams: {
                context: {
                    unitCtFilterOptions: generateFilterOptions(listItems, 'unitCt', false)
                }
            }
        },{
            field: 'contractPrice',
            headerName: 'Contract Price',
            valueGetter: moneyFormatter('contractPrice'),
            filter: 'agNumberColumnFilter'
        },{
            field: 'inHouseRate',
            headerName: 'In-House Rate',
            valueGetter: moneyFormatter('inHouseRate'),
            filter: 'agNumberColumnFilter'
        },{
            field: 'subContractorRate',
            headerName: 'Sub Contractor Rate',
            valueGetter: moneyFormatter('subContractorRate')
        },{
            field: 'isCrewSubCode',
            headerName: 'Crew Sub Code',
            cellRenderer: BooleanRenderer
        },{
            field: 'isPrimaryCustomerCode',
            headerName: 'Primary Customer Code',
            cellRenderer: BooleanRenderer
        },{
            field: 'isPrimaryMeasure',
            headerName: 'Primary Measure',
            cellRenderer: BooleanRenderer
        },{
            ...ArrayColumnDef('areas', 'Areas', false)
        },{
            field: 'comments',
            headerName: 'Comments',
            filter: 'agTextColumnFilter'
        }
    ];

    const handleSetGridHeight = () => {
        if (gridWrapperRef?.current) {
            const offset = gridWrapperRef.current.getBoundingClientRect().top;
            setGridHeight(`calc(100vh - (${offset}px + ${variables.spacing_standard}))`);
        } else {
            setGridHeight('auto');
        }
    }

    useEffect(() => {
        if (gridWrapperRef.current) {
            handleSetGridHeight();
            window.addEventListener('resize', handleSetGridHeight);
        }
        return () => {
            window.removeEventListener('resize', handleSetGridHeight);
        }
    }, [gridWrapperRef.current])

    return <div ref={gridWrapperRef} className="grid-wrapper ag-theme-material" style={{ height: gridHeight }}>
       {!!listItems?.length && <AgGridReact
            ref={ref}
            components={{
                multiSelect: MultiSelectFilter
            }}
            onRowDoubleClicked={handleDoubleClick}
            defaultColDef={
                {
                    resizable: true,
                    cellRenderer: ValueRenderer,
                    sortable: true,
                    menuTabs: ['filterMenuTab'],
                }
            }
            columnDefs={columnDefs}
            rowData={listItems}
            onGridReady={(event: GridReadyEvent) => {
                event.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(event: GridSizeChangedEvent) => {
                event.api.sizeColumnsToFit();
            }}
        />}
    </div>
})

export default CodeListGrid;