import React, { FC, useState } from "react"
import ModalBase from "../../../../library/components/ModalBase/ModalBase"
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import BighamDatePicker from "../../../../library/components/FormControls/BighamDatePicker/BighamDatePicker";
import { IJobLocatesPermissions } from "../../../../main/types/permissionTypes";

interface LocateDatePickerModalProps {
    onCancel(): void,
    handleUpdateDate(value: string, fieldName: string): () => void,
    dateType: 'locateDigDate' | 'locateUpdate' | ''
}

const LocateDatePickerModal: FC<LocateDatePickerModalProps> = ({onCancel, handleUpdateDate, dateType}) => {

    const [tempDate, setTempDate] = useState('');

    const displayMap = {
        'locateDigDate': 'Dig Date',
        'locateUpdate': 'Update Date',
        '': ''
    }

    const handleFormChange = (value: any, fieldName: string) => {
        setTempDate(value);
    }

    return <ModalBase onCancel={onCancel}>
        <h2 className="modal-title">{`Change Locate ${displayMap[dateType]}`}</h2>
        <div className={`form`}>
            <div className="form-row">
                <BighamDatePicker 
                    fieldName={dateType}
                    value={tempDate}
                    label={displayMap[dateType]}
                    onChange={handleFormChange}
                />
            </div>
        </div>
        <div className="modal-button-container">
            <BighamButton
                type="default"
                variant="contained"
                onClick={onCancel}
                label={'Cancel'}
            />
            <BighamButton
                type="primary" 
                variant="contained"
                label={`Update`}
                onClick={handleUpdateDate(tempDate, dateType)}
                disabled={!tempDate?.length}
            />
        </div>
    </ModalBase>
}

export default LocateDatePickerModal;