import React, { FC } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { WorkOutlined } from '@mui/icons-material';

const GigRenderer: FC<ICellRendererParams> = (props: ICellRendererParams) => {
    return <span className="icon-wrapper align-left">
        {props.value && <span className="gig-renderer">G</span>}
    </span>
};

export default GigRenderer;
