import React, { FC, useMemo } from 'react';
import './BighamMultiSelect.scss';
import { FormControl, MenuItem, Select, InputLabel, Box, Chip } from '@material-ui/core';
import { ILookupItem } from '../../../../main/types/genericTypes';
import { FormHelperText } from '@mui/material';
import { sortBy } from 'lodash';

interface MultiSelectProps {
    label?: string,
    fieldName: string,
    selectedList: Array<number>,
    listItems: Array<ILookupItem>,
    onChange: (value: unknown, fieldName: string) => void,
    error?: string,
    sortFilterOptions?: boolean,
    readOnly?: boolean
}

const BighamMultiSelect: FC<MultiSelectProps> = ({label, listItems, selectedList, fieldName, onChange, error = "", sortFilterOptions = true, readOnly = false}) => {
    const randInt = Math.random();

    const sortedListOptions = useMemo(() => {
        if (sortFilterOptions && listItems?.length) {
            return sortBy(listItems, ['name']);
        }
        return listItems;
    }, [listItems])

    return <FormControl
        variant="outlined"
        size="small"
        className="form-control bigham-multi-select"
        error={!!error.length}
    >
        {label && <InputLabel id={`${randInt}-multi-select-id`}>{label}</InputLabel>}
        <Select
            labelId={`${randInt}-multi-select-id`}
            MenuProps={{variant:'menu'}}
            multiple
            label={label}
            onChange={(e) => onChange(e.target.value, fieldName)}
            value={selectedList}
            style={{ whiteSpace: 'pre-wrap' }}
            renderValue={(selected: any) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                    {selected.map((id: number, index: number) => {
                        const value: ILookupItem = sortedListOptions.find((value: ILookupItem) => value.id === id) || {id: 0, name: ''};
                        if (value.id && value.name) {
                            return <Chip key={index} label={value.name} style={{ margin: '2px 5px 2px 0', height: 'auto' }} />;
                        } else {
                            return <span key={index}></span>;
                        }
                    })}
                </Box>
            )}
            readOnly={readOnly}
        >
            {sortedListOptions?.length > 0 && sortedListOptions.map((item) => {
                return <MenuItem key={item.id} value={item.id} disabled={item.archived}>{item.name}</MenuItem>
            })}
        </Select>
        {error?.length > 0 && <FormHelperText error={true}>{error}</FormHelperText>}
    </FormControl>;
}

export default BighamMultiSelect;