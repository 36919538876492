import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useKeycloak } from "@react-keycloak/web";
import PrivateRoute from './PrivateRoute';
import ROUTES from '../../library/constants/routeConstants';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import Layout from '../layout/Layout';
import JobTracker from '../../pages/JobTracker/JobTracker';
import JobDetails from '../../pages/JobDetails/JobDetails';
import UserList from '../../pages/UserList/UserList';
import UserDetails from '../../pages/UserDetails/UserDetails';
import { callGetUserPermissions, selectIsUserRegistered, selectUserPermissions } from '../slices/utilSlice';
import { IUserPermissions } from '../types/permissionTypes';
import CodeList from '../../pages/CodeList/CodeList';
import { canAccessDailyProductionReports, canAccessDailyQualityControlReports, canAccessJobCodeManagement, canAccessJobDetails, canAccessRequestedInvoicesReport, canAccessUserManagement, canAccessWeeklyProductionReports, canAccessMyProductionReports } from './accessRules';
import CodeDetails from '../../pages/CodeDetails/CodeDetails';
import JobMap from '../../pages/JobMap/JobMap';
import DailyProductionReports from '../../pages/DailyProductionReports/DailyProductionReports';
import QualityControlReports from '../../pages/QualityControlReports/QualityControlReports';
import InvoiceReports from '../../pages/InvoiceReports/InvoiceReports';
import WeeklyProductionReports from '../../pages/WeeklyProductionReports/WeeklyProductionReports';
import MyProductionReports from '../../pages/MyProductionReports/MyProductionReports';
import RegistrationSplashPage from '../../pages/RegistrationSplashPage/RegistrationSplashPage';

const RouteDefinitionComponent = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { keycloak, initialized } = useKeycloak();
    const permissions: IUserPermissions = useAppSelector(selectUserPermissions);
    const isUserRegistered: boolean = useAppSelector(selectIsUserRegistered) 

    const [renderApp, setRenderApp] = useState<boolean>(false);

    useEffect(() => {
        if (initialized && !keycloak.authenticated && location.pathname !== ROUTES.PING) {
            keycloak.login();
        }
    }, [initialized]);

    useEffect(() => {
        if (keycloak && initialized) {
            keycloak.onTokenExpired = () =>  {
                keycloak.updateToken(60).catch(() => {
                    // if it fails to update the token, call login.
                    keycloak.login();
                });
            }
        }
        return () => {
            // no empty arrow functions
            if (keycloak) keycloak.onTokenExpired = () => console.log('Do not refresh.');
        };
    }, [initialized, keycloak]);

    useEffect(() => {
        // attach authentication token to axios headers once resolved.
        if (keycloak.token) {
            setRenderApp(true);
            dispatch(callGetUserPermissions());
        }
        return () => {
            setRenderApp(false);
        }
    }, [keycloak.token])

    return (<>
        <Routes>
            <Route path={ROUTES.PING} element={<div>
                Health Check
            </div>} />
        </Routes>
        {keycloak.authenticated && renderApp && <>
            {!isUserRegistered && <Layout>
                <Routes>
                    <Route path={`/*`} element={<RegistrationSplashPage />} />
                </Routes>
            </Layout>}
            {isUserRegistered && <Layout>
                <Routes>
                    <Route path={ROUTES.TRACKER} element={
                        <PrivateRoute canAccessRoute={() => true}>
                            <JobTracker />
                        </PrivateRoute>
                    } />
                    <Route path={ROUTES.MAP} element={
                        <PrivateRoute canAccessRoute={() => true}>
                            <JobMap />
                        </PrivateRoute>
                    } />
                    <Route path={`${ROUTES.JOB_DETAILS}/:jobId`} element={
                        <PrivateRoute canAccessRoute={canAccessJobDetails(permissions)}>
                            <JobDetails />
                        </PrivateRoute>
                    } />
                    <Route path={`${ROUTES.USERS}/:userId`} element={
                        <PrivateRoute canAccessRoute={canAccessUserManagement(permissions)}>
                            <UserDetails />
                        </PrivateRoute>
                    } />
                    <Route path={ROUTES.USERS} element={
                        <PrivateRoute canAccessRoute={canAccessUserManagement(permissions)}>
                            <UserList />
                        </PrivateRoute>
                    } />
                    <Route path={`${ROUTES.CODES}/:codeId`} element={
                        <PrivateRoute canAccessRoute={canAccessJobCodeManagement(permissions)}>
                            <CodeDetails />
                        </PrivateRoute>
                    } />
                    <Route path={ROUTES.CODES} element={
                        <PrivateRoute canAccessRoute={canAccessJobCodeManagement(permissions)}>
                            <CodeList />
                        </PrivateRoute>
                    } />
                    <Route path={ROUTES.DAILY_PROD_REPORTS} element={
                        <PrivateRoute canAccessRoute={canAccessDailyProductionReports(permissions)}>
                            <DailyProductionReports />
                        </PrivateRoute>
                    } />
                    <Route path={ROUTES.QC_REPORTS} element={
                        <PrivateRoute canAccessRoute={canAccessDailyQualityControlReports(permissions)}>
                            <QualityControlReports />
                        </PrivateRoute>
                    } />
                    <Route path={ROUTES.REQUESTED_INVOICES} element={
                        <PrivateRoute canAccessRoute={canAccessRequestedInvoicesReport(permissions)}>
                            <InvoiceReports />
                        </PrivateRoute>
                    } />
                    <Route path={ROUTES.WEEKLY_PROD_REPORTS} element={
                        <PrivateRoute canAccessRoute={canAccessWeeklyProductionReports(permissions)}>
                            <WeeklyProductionReports />
                        </PrivateRoute>
                    } />
                    <Route path={ROUTES.MY_PROD_REPORTS} element={
                        <PrivateRoute canAccessRoute={canAccessMyProductionReports(permissions)}>
                            <MyProductionReports />
                        </PrivateRoute>
                    } />
                </Routes>
            </Layout>}
        </>
        }
    </>);
};

export default RouteDefinitionComponent;
