import React, { FC } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { CloseOutlined } from '@mui/icons-material';

interface DeleteRendererProps extends ICellRendererParams {
    handleDelete(data: any, index: number): void;
}

const DeleteRenderer: FC<DeleteRendererProps> = ({handleDelete, ...props}) => {
    return <span className="icon-wrapper align-right">
        {props.value ? '' : <CloseOutlined className="cursor-pointer" onClick={() => handleDelete(props.data, props.rowIndex)} />}
    </span>
};

export default DeleteRenderer;
