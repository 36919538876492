import { FC, useMemo } from 'react';
import { InfoWindow, InfoWindowProps, Marker, MarkerProps } from '@react-google-maps/api';
import { IJobTrackerDashboardSample } from '../../main/types/trackerTypes';
import { getLabelByCategory, getColorByCategoryId } from './mapUtil';
import { useState } from 'react';
import { createRoot } from 'react-dom/client';
import JobMapInfoCard from '../../library/components/JobMapInfoCard/JobMapInfoCard';

interface LocationMarkerProps extends MarkerProps {
  addressId: number;
  jobDetail: IJobTrackerDashboardSample;
  infoWindowOpen: boolean;
  map?: google.maps.Map;
  panorama?: google.maps.StreetViewPanorama;
  setDestinationLocation(coords: google.maps.LatLngLiteral | null): void,
  setZoom(zoomlevel: number): void;
  setCenter(coords: google.maps.LatLngLiteral): void;
  handleNavigateToJob(jobId: number): () => void;
  setAddressId(addressId: number): void;
  clearDirections(): void;
}

const LocationMarker: FC<LocationMarkerProps> = ({
    jobDetail,
    map,
    panorama,
    handleNavigateToJob,
    setCenter,
    setZoom,
    setDestinationLocation,
    addressId,
    infoWindowOpen,
    setAddressId,
    ...options
}) => {
    const [marker, setMarker] = useState<google.maps.Marker>();
    const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();

    const handleMarkerClick = () => {
        if (!infoWindowOpen) {
            infoWindow?.open();
            infoWindow?.setPosition(jobDetail.coordinates as google.maps.LatLngLiteral);
            setAddressId(jobDetail.id);
            setZoom(16);
            setCenter(jobDetail.coordinates as google.maps.LatLngLiteral)
        }
    }

    const handleCloseClick = () => {
        infoWindow?.close();
        setAddressId(0);
    }

    const handleStreetView = () => {
        panorama?.setPosition(jobDetail.coordinates as google.maps.LatLngLiteral);
        panorama?.setVisible(true);
    }

    const handleGetDirections = () => {
        setDestinationLocation(jobDetail.coordinates as google.maps.LatLngLiteral);
    }

    return <Marker
        onLoad={(marker) => setMarker(marker)}
        onClick={handleMarkerClick}
        label={{
            text: getLabelByCategory(jobDetail.category),
            className: `marker-label ${jobDetail.id === addressId ? 'big-label': ''}`
        }}
        icon={{
            path: "M384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384z",
            fillColor: getColorByCategoryId(jobDetail.category.id),
            fillOpacity: 1,
            scale: jobDetail.id === addressId ? 0.09 : 0.06,
            anchor: new google.maps.Point(
                200, // width
                500 // height
            ),
            strokeColor: '#000000',
            strokeWeight: 1.3,
            strokeOpacity: 1
        }}
        {...options}
    >
        {infoWindowOpen && <InfoWindow onCloseClick={handleCloseClick} options={{ position: jobDetail.coordinates }} onLoad={(infoWindow) => setInfoWindow(infoWindow)}>
            <JobMapInfoCard
                handleNavigateToJob={handleNavigateToJob(jobDetail.id)}
                jobDetail={jobDetail}
                handleStreetView={handleStreetView}
                handleGetDirections={handleGetDirections}
                setZoom={setZoom}
                setCenter={setCenter}
                handleClick={() => infoWindow?.close()}
            />
        </InfoWindow>}
    </Marker>
    
}

export default LocationMarker;