import { FC, useEffect } from 'react';
import './WeeklyProductionReports.scss';
import { AgGridReact } from 'ag-grid-react';
import { useRef } from 'react';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import { useAppDispatch, useAppSelector, useReferredState } from '../../main/hooks/hooks';
import { selectSelectedArea, setComponentName, setDownloadRowData, setSelectedArea } from '../../main/slices/utilSlice';
import BighamSelect from '../../library/components/FormControls/BighamSelect/BighamSelect';
import { callGetAreaList, selectAreaList } from '../../main/slices/lookupSlice';
import WeeklyProductionReportsGrid from './WeeklyProductionReportsGrid';
import { setWeeklyProductionReports } from '../../main/slices/reportsSlice';
import { IServerSideDatasource } from 'ag-grid-community';
import { getWeeklyProductionReports } from '../../main/api/reportsApi';
import { SESSION_KEYS } from '../../library/constants/sessionStorageKeys';
import { FilterListOffOutlined } from '@mui/icons-material';
import { useMemo } from 'react';

const WeeklyProductionReports: FC = () => {
    const dispatch = useAppDispatch();
    const gridRef = useRef<AgGridReact>(null);
    const {
        selectedArea,
        areaList
    } = useAppSelector((state) => ({
        selectedArea: selectSelectedArea(state),
        areaList: selectAreaList(state),
    }));

    const [avoidServerCall, avoidServerCallRef, setAvoidServerCall] = useReferredState(true);

    useEffect(() => {
        dispatch(setComponentName('Weekly Production Report'));
        !areaList?.length && dispatch(callGetAreaList());
        return () => {
            dispatch(setWeeklyProductionReports([]));
        }
    },[]);


    useEffect(() => {
        if (areaList?.length) {
            if (selectedArea) {
                handleAreaChange(selectedArea);
            } else {
                handleAreaChange(areaList[0]?.id);
            }
        }
    }, [areaList])

    const filterModel = useMemo(() => {
        return JSON.parse(sessionStorage.getItem(SESSION_KEYS.WEEKLY_REPORT_FILTERS) || '{}');
    }, [sessionStorage.getItem(SESSION_KEYS.WEEKLY_REPORT_FILTERS)])

    const handleAreaChange = (value: any): void => {
        dispatch(setSelectedArea(value));
        dispatch(setWeeklyProductionReports([]));
        if (gridRef.current?.api) {
            gridRef.current?.api?.setServerSideDatasource(dataSource(value))
        }
    }

    const dataSource = (areaId: number): IServerSideDatasource => ({
        getRows: async (params) => {
            if (avoidServerCallRef.current) {
                const filterModel = JSON.parse(sessionStorage.getItem(SESSION_KEYS.WEEKLY_REPORT_FILTERS) || '{}');
                if (filterModel && Object.keys(filterModel)?.length) {
                    params.api.setFilterModel(filterModel);
                }
                params.success({
                    rowData: [{}],
                    rowCount: 10000
                })
                return;
            }
            const response = await getWeeklyProductionReports(areaId, params.request);
            params.success({
                rowData: response.data.rowData as any[],
                rowCount: response.data.rowCount,
            })
            setTimeout(() => {
                params.columnApi.autoSizeAllColumns();
            });
        }
    })

    const handleExportCsv = async () => {
        const filterModel = JSON.parse(sessionStorage.getItem(SESSION_KEYS.WEEKLY_REPORT_FILTERS) || '{}');
        const reqParams = {
            startRow: 0,
            endRow: 0,
            filterModel,
            sortModel: [],
        }
        const { data: { rowData, rowCount } } = await getWeeklyProductionReports(selectedArea, reqParams);
        dispatch(setDownloadRowData(rowData));
    }

    const handleClearFilters = () => {
        sessionStorage.setItem(SESSION_KEYS.WEEKLY_REPORT_FILTERS, '{}');
        gridRef.current?.api.setFilterModel({});
    }

    return <>
        <div className="production-report-wrapper">
            <div className="flex-row margin-bottom">
                <div className="select-wrapper">
                    <BighamSelect
                        value={selectedArea}
                        onChange={handleAreaChange}
                        fieldName={'area'}
                        label="Area"
                        listItems={areaList}
                    />
                </div>
            </div>
            <div className="grid-header">
                <div className="header-left">
                    {!!Object.keys(filterModel)?.length && <div className="clickable-link" onClick={handleClearFilters}>
                        <span>
                            Clear Filters
                        </span>
                        <FilterListOffOutlined />
                    </div>}
                    <div className="clickable-link" onClick={handleExportCsv}>
                        <span>
                            Export to .csv
                        </span>
                        <InputOutlinedIcon />
                    </div>
                </div>
            </div>
            <WeeklyProductionReportsGrid ref={gridRef} dataSource={dataSource} avoidServerCall={avoidServerCallRef.current} setAvoidServerCall={setAvoidServerCall} areaId={selectedArea} />
        </div>
    </>
}

export default WeeklyProductionReports;