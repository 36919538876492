export default [
    {
        "type": "spacer"
    },
    {
        "type": "toolGroupButton",
        "toolGroup": "polyLineTools",
        "dataElement": "polyLineToolGroupButton",
        "title": "annotation.polyline"
    },
    {
        "type": "toolGroupButton",
        "toolGroup": "freeHandHighlightTools",
        "dataElement": "freeHandHighlightToolGroupButton",
        "title": "annotation.freeHandHighlight"
    },
    {
        "type": "toolGroupButton",
        "toolGroup": "lineTools",
        "dataElement": "lineToolGroupButton",
        "title": "annotation.line"
    },
    {
        "type": "toolGroupButton",
        "toolGroup": "calloutTools",
        "dataElement": "calloutToolGroupButton",
        "title": "annotation.callout"
    },
    {
        "type": "toolGroupButton",
        "toolGroup": "freeTextTools",
        "dataElement": "freeTextToolGroupButton",
        "title": "annotation.freetext"
    },
    {
        "type": "toolGroupButton",
        "toolGroup": "ellipseTools",
        "dataElement": "ellipseToolGroupButton",
        "title": "annotation.ellipse"
    },
    {
        "type": "toolGroupButton",
        "toolGroup": "freeHandTools",
        "dataElement": "freeHandToolGroupButton",
        "title": "annotation.freehand"
    },
    {
        "type": "toolGroupButton",
        "toolGroup": "arrowTools",
        "dataElement": "arrowToolGroupButton",
        "title": "annotation.arrow"
    },
    {
        "type": "divider"
    },
    {
        "type": "actionButton",
        "style": {
            "marginLeft": "0px"
        },
        "dataElement": "undoButton",
        "title": "action.undo",
        "img": "icon-operation-undo"
    },
    {
        "type": "actionButton",
        "dataElement": "redoButton",
        "title": "action.redo",
        "img": "icon-operation-redo"
    },
    {
        "type": "toolButton",
        "toolName": "AnnotationEraserTool"
    },
    {
        "type": "spacer"
    }
]