import React, { useState, useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";
import { selectToastMessage, setToastMessage } from "../../../main/slices/utilSlice";
import './ToastMessage.scss';
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { useAppDispatch, useAppSelector } from "../../../main/hooks/hooks";

const ToastMessage = () => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const {
        toastMessage
    } = useAppSelector((state) => ({
        toastMessage: selectToastMessage(state)
    }));

    useEffect(() => {
        if (Object.keys(toastMessage)?.length) {
            setOpen(true);
        }
    }, [toastMessage]);

    const handleClose = (e: any, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        dispatch(setToastMessage({}));
    }

    if (Object.keys(toastMessage).length > 0) {
        return <>
            {toastMessage.type !== 'success' && <Snackbar
                open={open}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                onClose={handleClose}
            >
                <Alert onClick={(e) => handleClose(e)} variant="filled" severity={toastMessage.type} color={toastMessage.type}>
                    {toastMessage.message}
                    <span className="close-icon">
                        <CloseOutlined onClick={handleClose} />
                    </span>
                </Alert>
            </Snackbar>}
            {toastMessage.type === 'success' && <Snackbar
                open={open}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClick={handleClose} variant="filled" severity={toastMessage.type} color={toastMessage.type}>
                    {toastMessage.message}
                    <span className="close-icon">
                        <CloseOutlined onClick={handleClose} />
                    </span>
                </Alert>
            </Snackbar>}
        </>
        
    } else {
        return <></>
    }
};

export default ToastMessage;