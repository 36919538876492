import React, { FC } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

const ValueRenderer: FC<ICellRendererParams> = (props: ICellRendererParams) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  return (
    <span className="value-wrapper">
      {cellValue}
    </span>
  );
};

export default ValueRenderer;
