import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { CellClickedEvent, RowDoubleClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { isNil, reduce, sumBy } from "lodash";
import { ChangeEvent, FC, useState, useRef } from "react";
import { IJobCode } from "../../../../main/types/codeTypes";
import { ILookupItem } from "../../../../main/types/genericTypes";
import { IJobCodeLineItem } from "../../../../main/types/jobDetailTypes";
import { IPurchaseOrderPermissions } from "../../../../main/types/permissionTypes";
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import BighamNumberInput from "../../../../library/components/FormControls/BighamNumberInput/BighamNumberInput";
import BighamTextInput from "../../../../library/components/FormControls/BighamTextInput/BighamTextInput";
import PurchaseOrderGrid from "./PurchaseOrderGrid";
import PurchaseOrderModal from "./PurchaseOrderModal";
import BighamMoneyDisplayInput from "../../../../library/components/FormControls/BighamMoneyDisplayInput/BighamMoneyDisplayInput";

interface PurchaseOrderSectionProps {
    openSection: string,
    handleAccordionAction(panel: string): (event: ChangeEvent<any>, isExpanded: boolean) => void,
    handleFormChange(value: any, fieldName: string): void,
    setJobDetailModel(value: any): void,
    jobDetailModel: any,
    codeLookup: ILookupItem[],
    codeList: IJobCode[],
    permissions: IPurchaseOrderPermissions
}

const PurchaseOrderSection: FC<PurchaseOrderSectionProps> = ({openSection, handleAccordionAction, handleFormChange, setJobDetailModel, jobDetailModel, codeLookup, codeList, permissions}) => {
    const sectionId = 'ntp/po';
    const gridRef = useRef<AgGridReact>(null);
    const [showModal, setShowModal] = useState(false);
    const [tempCodeDetails, setTempCodeDetails] = useState<IJobCodeLineItem | undefined>(undefined);

    const handleCloseModal = () => {
        setShowModal(false);
        setTempCodeDetails(undefined);
    }

    const handleShowModal = () => {
        setShowModal(true);
    }

    const handleAddCode = (lineItem: IJobCodeLineItem) => {
        const jobCodes = [...jobDetailModel.purchaseOrder.jobCodes];
        if (!isNil(lineItem.index)) {
            jobCodes.splice(lineItem.index, 1, lineItem);
        } else {
            jobCodes.push(lineItem);
        }
        calculatePoTotal(jobCodes);
    }

    const calculatePoTotal = (jobCodes: IJobCodeLineItem[]) => {
        const totalSum = sumBy(jobCodes, (jobCode): number => jobCode?.total && +jobCode.total || 0).toFixed(2)
        setJobDetailModel({...jobDetailModel, purchaseOrder: {...jobDetailModel.purchaseOrder, purchaseOrderAmount: totalSum, jobCodes}})
        resizeGrid();
    }

    const resizeGrid = () => {
        setTimeout(() => {
            if (gridRef && gridRef?.current){
                gridRef.current?.api.sizeColumnsToFit();
            }
        });
    }

    const canAddLineItems = (): boolean => {
        return permissions.canEditJobCode && permissions.canEditUnitCt;
    }

    const canManageLineItems = (): boolean => {
        return permissions.canEditJobCode || permissions.canEditUnitCt;
    }

    const handleDoubleClick = (params: RowDoubleClickedEvent) => {
        if (canManageLineItems()){
            const temp = {...params.data};
            temp.index = jobDetailModel.purchaseOrder.jobCodes?.findIndex((jobCode: IJobCodeLineItem) => {
                return jobCode.jobCodeId === params.data.jobCodeId;
            });
            setTempCodeDetails(temp);
            setShowModal(true);
        }
    }

    const handleDelete = (data: IJobCodeLineItem, index: number) => {
        if (!data.id) {
            const temp = [...jobDetailModel.purchaseOrder.jobCodes];
            if (index && temp.length) {
                temp.splice(index, 1);
                const transformed = {...jobDetailModel, purchaseOrder: {...jobDetailModel.purchaseOrder, jobCodes: temp }};
                setJobDetailModel(transformed);
                calculatePoTotal(transformed.purchaseOrder.jobCodes);
            }
        }
        resizeGrid();
    }

    return <Accordion className="styled-accordion" expanded={openSection === sectionId} id={sectionId} onChange={handleAccordionAction(sectionId)}>
        <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
        >
            <div className="flex-header">
                <div className="component-title">NTP/PO</div>
            </div>
        </AccordionSummary>
        <AccordionDetails
            style={{flexFlow: 'column'}}
        >
            {showModal && <PurchaseOrderModal onCancel={handleCloseModal} handleAddCode={handleAddCode} codeLookup={codeLookup} codeList={codeList} codeDetails={tempCodeDetails} permissions={permissions} />}
            <div className="form">
                <div className="form-row align-center">
                    {permissions.canViewPoNumber && <BighamTextInput
                        fieldName="purchaseOrder.purchaseOrderNumber"
                        value={jobDetailModel.purchaseOrder.purchaseOrderNumber}
                        label="PO #"
                        onChange={handleFormChange}
                        readOnly={!permissions.canEditPoNumber}
                    />}
                    {permissions.canViewPoAmount && <BighamMoneyDisplayInput
                        value={jobDetailModel.purchaseOrder.purchaseOrderAmount}
                        label="PO Amount"
                        decimalPlaces={2}
                    />}
                    {canAddLineItems() && <BighamButton 
                        label={'Add Line Item'}
                        variant="contained"
                        type="primary"
                        onClick={handleShowModal}
                    />}
                </div>
                {jobDetailModel.purchaseOrder.jobCodes?.length > 0 && <PurchaseOrderGrid
                    listItems={jobDetailModel.purchaseOrder.jobCodes}
                    ref={gridRef}
                    handleDoubleClick={handleDoubleClick}
                    handleDelete={handleDelete}
                />}
            </div>
        </AccordionDetails>
    </Accordion>
}

export default PurchaseOrderSection;