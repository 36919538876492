import React, { FC, useEffect } from 'react';
import './CodeList.scss';
import { AgGridReact } from 'ag-grid-react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import BighamButton from '../../library/components/FormControls/BighamButton/BighamButton';
import ROUTES from '../../library/constants/routeConstants';
import { useAppDispatch, useAppSelector } from '../../main/hooks/hooks';
import { selectSelectedArea, setComponentName, setSelectedArea } from '../../main/slices/utilSlice';
import CodeListGrid from './CodeListGrid';
import BighamSelect from '../../library/components/FormControls/BighamSelect/BighamSelect';
import { callGetAreaList, selectAreaList } from '../../main/slices/lookupSlice';
import { callGetCodeList, selectCodeList, setCodeList } from '../../main/slices/codeSlice';

const CodeList: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const gridRef = useRef<AgGridReact>(null);
    const {
        selectedArea,
        areaList,
        codeList
    } = useAppSelector((state) => ({
        selectedArea: selectSelectedArea(state),
        areaList: selectAreaList(state),
        codeList: selectCodeList(state)
    }));

    useEffect(() => {
        dispatch(setComponentName('Job Codes'));
        !areaList?.length && dispatch(callGetAreaList());
    },[]);

    useEffect(() => {
        if (areaList?.length) {
            dispatch(setCodeList([]))
            if (selectedArea) {
                handleAreaChange(selectedArea);
            } else {
                handleAreaChange(areaList[0]?.id);
            }
        }
    }, [areaList])

    const handleAreaChange = (value: any): void => {
        dispatch(setSelectedArea(value));
        dispatch(setCodeList([]));
        dispatch(callGetCodeList(value));
    }

    return <>
    <div className="code-list-wrapper">
            <div className="flex-row space-between">
                <div className="select-wrapper">
                    <BighamSelect
                        value={selectedArea}
                        onChange={handleAreaChange}
                        fieldName={'area'}
                        label="Area"
                        listItems={areaList}
                    />
                </div>
                <div className="button-wrapper">
                    <BighamButton
                        label="Create Job Code"
                        type="primary"
                        variant="contained"
                        onClick={() => navigate(`${ROUTES.CODES}/new`)}
                    />
                </div>
            </div>
            <CodeListGrid ref={gridRef} listItems={codeList} />
        </div>
        
    </>
}

export default CodeList;