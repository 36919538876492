import { IUserPermissions } from '../types/permissionTypes';

export const canAccessJobDetails =
  (permissions: IUserPermissions) => (): boolean => {
    return !!(
      permissions.jobPermissions?.jobDetailsPermissions?.isViewable ||
      permissions.jobPermissions?.jobAerialPermissions?.isViewable ||
      permissions.jobPermissions?.jobDocumentsPermissions?.isViewable ||
      permissions.jobPermissions?.jobUndergroundPermissions?.isViewable ||
      permissions.jobPermissions?.jobLocatesPermissions?.isViewable ||
      permissions.jobPermissions?.jobPermitsPermissions?.isViewable ||
      permissions.jobPermissions?.jobSplicerPermissions?.isViewable
    );
  };

export const canAccessUserManagement =
  (permissions: IUserPermissions) => (): boolean =>
    !!permissions.managementPermissions?.canManageUsers;

export const canAccessJobCodeManagement =
  (permissions: IUserPermissions) => (): boolean =>
    !!permissions.managementPermissions?.canManageJobCodes;

export const canAccessDailyProductionReports =
  (permissions: IUserPermissions) => (): boolean => {
    return (
      permissions.areaDailyProductionReportPermissions
        ?.canViewAreaDailyProductionReport || false
    );
  };
export const canAccessWeeklyProductionReports =
  (permissions: IUserPermissions) => (): boolean => {
    return (
      permissions.areaWeeklyProductionReportPermissionsDto
        ?.canViewAreaWeeklyProductionReport || false
    );
  };
export const canAccessMyProductionReports =
  (permissions: IUserPermissions) => (): boolean => {
    return (
      permissions.areaMyProductionReportPermissionsDto
        ?.canViewAreaMyProductionReport || false
    );
  };
export const canAccessDailyQualityControlReports =
  (permissions: IUserPermissions) => (): boolean => {
    return (
      permissions.areaDailyQualityControlReportPermissions
        ?.canViewAreaDailyProductionReport || false
    );
  };
export const canAccessRequestedInvoicesReport =
  (permissions: IUserPermissions) => (): boolean => {
    return (
      permissions.areaRequestedInvoicesReportPermissionsDto
        ?.canViewAreaRequestedInvoicesReport || false
    );
  };
