import { AgGridReact } from 'ag-grid-react';
import React, { FC, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../main/hooks/hooks';
import './UserList.scss';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import { useNavigate } from 'react-router-dom';
import { setComponentName } from '../../main/slices/utilSlice';
import BighamButton from '../../library/components/FormControls/BighamButton/BighamButton';
import ROUTES from '../../library/constants/routeConstants';
import UserListGrid from './UserListGrid';
import { useRef } from 'react';
import { callGetUserList, selectUserList } from '../../main/slices/userSlice';

const UserList: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const gridRef = useRef<AgGridReact>(null);

    useEffect(() => {
        dispatch(setComponentName('Users'));
        dispatch(callGetUserList());
    },[]);

    const {
        userData
    } = useAppSelector((state) => ({
        userData: selectUserList(state)
    }));

    return <>
        <div className="user-list-wrapper">
            <div className="flex-row space-between">
                <div className="button-wrapper">
                    <BighamButton
                        label="Export to CSV"
                        type="primary"
                        variant="text"
                        endIcon={<InputOutlinedIcon />}
                        onClick={() => gridRef.current?.api.exportDataAsCsv({ fileName: `bigham-user-list-${new Date().toDateString()}.csv`})}
                    />
                </div>
                <div className="button-wrapper">
                    <BighamButton
                        label="Create User"
                        type="primary"
                        variant="contained"
                        onClick={() => navigate(`${ROUTES.USERS}/new`)}
                    />
                </div>
            </div>
            <UserListGrid userData={userData} ref={gridRef} />
        </div>
    </>;
}

export default UserList;