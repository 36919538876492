export const CATEGORIES = {
  CABLE_REPLACEMENT: 1,
  RESI_SERVICE: 2,
  COMM_BUSINESS: 3,
  FORCED_RELOC: 4,
  NEW_BUILD_LEGACY: 5,
  GREENFIELD: 6,
  DROP_REDUCT: 7,
  POLE_TRANSFER: 8,
  POLE_TRANSFER_2: 9,
  FIBER_CUSTOMER: 10,
  STORM_DAMAGE: 11,
  EMERGENCY_CALL_OUT: 12,
  MDU: 13,
  DSW_WALKOUT: 14,
  NODE_REDUCTTION: 15
};
