import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IJobTrackerSample, IJobTrackerDashboardSample } from '../../../main/types/trackerTypes';
import ROUTES from '../../constants/routeConstants';
import './JobMapInfoCard.scss';

interface JobMapInfoCardProps {
    jobDetail: IJobTrackerDashboardSample,
    isListView?: boolean,
    handleClick?(): void,
    active?: boolean,
    handleNavigateToJob(): void,
    handleStreetView?(): void,
    handleGetDirections?(): void,
    setZoom?(zoomlevel: number): void,
    setCenter?(coords: google.maps.LatLngLiteral): void,
}

const JobMapInfoCard: FC<JobMapInfoCardProps> = ({
    jobDetail,
    isListView = false,
    handleClick,
    active = false,
    handleNavigateToJob,
    handleStreetView,
    handleGetDirections,
    setZoom,
    setCenter
}) => {
    const headerString = `${jobDetail.category.name}${jobDetail.job ? ` - Job#${jobDetail.job}`: ''}${jobDetail.node ? `, Node: ${jobDetail.node}` : ''}`
    const handleSetZoom = () => {
        setZoom && setZoom(17);
        if (jobDetail.coordinates) {
            setCenter && setCenter(jobDetail.coordinates);
        }
    }

    return <div className={`job-map-info-card ${isListView ? 'list-view' : 'map-view'} ${active ? 'active' : ''}`} onClick={handleClick}>
        <div>
            <span className="map-info-header" onClick={handleNavigateToJob}>{headerString}</span>
        </div>
        <div className="flex-row">
            {jobDetail.bighamCoordinator && <span><b>BCoord:</b> {jobDetail.bighamCoordinator.name}</span>}
            {jobDetail.customerCoordinators && jobDetail.customerCoordinators.length > 0 && <span><b>CCoord:</b> {jobDetail.customerCoordinators[0].name}</span>}
        </div>
        <div className="flex-row">
            <span>{jobDetail?.address}</span>
        </div>
        {!isListView && <div className="flex-row">
            <span className="map-action" onClick={handleGetDirections}>Directions</span>
            <span className="map-action" onClick={handleSetZoom}>Zoom Here</span>
            <span className="map-action" onClick={handleStreetView}>Street View</span>
        </div>}
    </div>
}

export default JobMapInfoCard;