import { FC, useEffect, useRef, useState } from "react";
import WebViewer, { Core, UI } from '@pdftron/webviewer';
import { useAppDispatch, useAppSelector } from "../../../../../main/hooks/hooks";
import { callUploadFiles, IAnnotationData, selectAnnotationData, setAnnotationData } from "../../../../../main/slices/jobDetailSlice";
import BighamButton from "../../../../../library/components/FormControls/BighamButton/BighamButton";
import { addFetch, removeFetch } from "../../../../../main/slices/utilSlice";
import { setuid } from "node:process";
import DocumentSection from "../DocumentSection";
import StampsCollection from "./StampsCollection";
import { useCallback } from "react";
import toolsDefinition from "./toolsDefinition";
import {Buffer} from 'buffer';

interface DocumentViewerProps {
    annotationData: IAnnotationData,
    jobId: string,
    jobDetailModel: any,
    setJobDetailModel: any
}

const IMAGE_PIXELS = 96;
const INITIAL_SCALE = 7.2 / IMAGE_PIXELS;

const DocumentViewer: FC<DocumentViewerProps> = ({annotationData, jobId, jobDetailModel, setJobDetailModel}) => {
    const dispatch = useAppDispatch();
    const documentViewerRef = useRef<HTMLDivElement>(null);
    const core = useRef<typeof Core>()
    const ui = useRef<typeof UI>()
    const [selectedStamp, setSelectedStamp] = useState<string>();
    const stampScale = useRef(INITIAL_SCALE);
    const selectedStampRef = useRef('');
    const selectedAnnotationRef = useRef<any | undefined>();

    const getImageUrl = async (stamp: string): Promise<string> => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const path = require(`/src/assets/images/stamps/${selectedStampRef.current}`)
        const response = await fetch(path);
        const imageData = await response.arrayBuffer();
        const base64Image = Buffer.from(imageData).toString('base64');
        return `data:image/png;base64,${base64Image}`;
    }

    const resetStamp = useCallback(() => {
        setSelectedStamp('');
        selectedStampRef.current = '';
    }, []);

    useEffect(() => {
        document.body.className = 'disable-scroll';
        return () => {
            document.body.className = "";
        }
    }, []);

    useEffect(() => {
        if (annotationData.url && documentViewerRef.current) {
            dispatch(addFetch());
            WebViewer({
                path: '/lib',
                licenseKey: process.env.REACT_APP_PDF_TRON_KEY,
                initialDoc: annotationData.url,
                extension: 'pdf',
                disabledElements: [
                    "viewControlsButton",
                    "searchButton",
                    "toggleNotesButton",
                    "menuButton",
                    "moreButton",
                    "leftPanel",
                    "leftPanelButton"
                ]
            }, documentViewerRef.current as HTMLDivElement).then((instance) => {
                core.current = instance.Core;
                ui.current = instance.UI;
                instance.UI.setToolbarGroup('toolbarGroup-Shapes');
                instance.UI.setHeaderItems((header) => {
                    const items = header.getItems();
                    header.update(items.filter((item: any) => {
                        return item.dataElement === 'zoomOverlayButton' || item.type === 'toolButton'
                    }));
                    header.getHeader('toolbarGroup-Shapes').update(toolsDefinition)
                })
                const { documentViewer, Annotations, annotationManager } = instance.Core;
                instance.UI.addEventListener('click', (e: MouseEvent) => {
                    const toolMode = documentViewer.getToolMode();
                    if(toolMode.name !== instance.Core.Tools.ToolNames.STAMP.toString()) {
                        resetStamp();
                    }
                    e.preventDefault();
                });
                documentViewer.addEventListener('click', (e: MouseEvent) => {
                    e.preventDefault();
                    if (selectedStampRef.current) {
                        const windowCoordinates = getMouseLocation(e, documentViewer);
                        const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
                        const page = displayMode.getSelectedPages(windowCoordinates, windowCoordinates);
                        const clickedPage = (page.first !== null) ? page.first : documentViewer.getCurrentPage();
                        const coords = displayMode.windowToPage(windowCoordinates, clickedPage);
                        if (coords) {
                            getImageUrl(selectedStampRef.current).then((imageUrl: string) => {
                                const stampImage = new Image();
                                stampImage.addEventListener('load', () => {
                                    const width = stampImage.width * stampScale.current;
                                    const height = stampImage.height * stampScale.current;
                                    const annotation = new Annotations.StampAnnotation({
                                        PageNumber: coords.pageNumber,
                                        X: coords.x - (width/2),
                                        Y: coords.y - (height/2),
                                        Width: width,
                                        Height: height,
                                    });
                                    annotation.setImageData(imageUrl);
                                    annotationManager.addAnnotation(annotation);
                                    annotationManager.redrawAnnotation(annotation);
                                })
                                stampImage.src = imageUrl;
                            });
                        }
                    }
                });
                annotationManager.addEventListener('annotationSelected', (annotations: any) => {
                    if (annotations?.length === 1) {
                        const annotation = annotations[0];
                        // Can't find constant for the tool name
                        if (annotation.ToolName === 'AnnotationCreateRubberStamp') {
                            selectedAnnotationRef.current = {
                                id: annotation.Mr,
                                width: annotation.getWidth(),
                                height: annotation.getHeight(),
                            };
                        }
                    }
                })
                annotationManager.addEventListener('annotationChanged', (annotations: any, action: string) => {
                    if(action === 'modify' && selectedAnnotationRef.current) {
                        const selectedAnnotation = selectedAnnotationRef.current;
                        for (let a = 0; a < annotations.length; a += 1) {
                            const annotation = annotations[a];
                            if (annotation.ToolName === 'AnnotationCreateRubberStamp' && selectedAnnotation.id === annotation.Mr) {
                                const width = annotation.getWidth();
                                const height = annotation.getHeight();
                                if(width > height) {
                                    stampScale.current = height / IMAGE_PIXELS;
                                } else {
                                    stampScale.current = width / IMAGE_PIXELS;
                                }
                            }
                        }
                    }
                })
                documentViewer.addEventListener('documentLoaded', () => {
                    // perform document operations
                    dispatch(removeFetch());
                });
            });
        }
    }, [annotationData, documentViewerRef]);


    const getMouseLocation = (e: MouseEvent, documentViewer: Core.DocumentViewer) => {
        const scrollElement = documentViewer.getScrollViewElement();
        const scrollLeft = scrollElement.scrollLeft || 0;
        const scrollTop = scrollElement.scrollTop || 0;
        const x = e.pageX + scrollLeft;
        const y = e.pageY + scrollTop;
        if (x || y) {
            return {
                x,
                y
            };
        }
    };

    const handleSaveAnnotations = async () => {
        const doc = core.current?.documentViewer?.getDocument();
        const xfdfString = await core.current?.annotationManager.exportAnnotations();
        const data = await doc?.getFileData({
            xfdfString,
            finishedWithDocument: true,
        });
        const arr = new Uint8Array(data as ArrayBuffer);
        const blob = new Blob([arr], { type: 'application/pdf' });
        const payload = new FormData();
        payload.append('documentCategoryId', '1');
        payload.append('files', blob, annotationData.name);
        dispatch(callUploadFiles({payload, jobId, jobDetailModel, setJobDetailModel}));
        dispatch(setAnnotationData({}));
    }

    const handleStampClick = (stamp: string, sameStamp = false) => {
    const { Tools } = core.current as typeof Core;
    const { setToolMode } = ui.current as typeof UI;
      if (selectedStampRef.current !== stamp || sameStamp) {
        setSelectedStamp(stamp);
        selectedStampRef.current = stamp;
        setToolMode(Tools.ToolNames.STAMP);
      } else {
        setToolMode(Tools.ToolNames.PAN);
        resetStamp();
      }
    }


    if (!annotationData.url?.length) {
        return <></>
    }
    return <div className="pdftron-wrapper">
        <div className="flex-row space-between">
            <BighamButton
                label="Back"
                type="default"
                variant="contained"
                onClick={handleSaveAnnotations}
            />
            <BighamButton
                label="Save"
                type="primary"
                variant="contained"
                onClick={handleSaveAnnotations}
            />
        </div>
        <StampsCollection handleStampClick={handleStampClick} selectedStamp={selectedStamp} />
        <div ref={documentViewerRef} className="document-viewer-container"></div>
    </div>
}

export default DocumentViewer;
