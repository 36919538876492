import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  mapArrayUserInfo,
  mapJobCodes
} from '../../library/transforms/utilTransforms';
import {
  getAccountTypeList,
  getAerialForemenList,
  getAreaList,
  getBighamCoordinatorList,
  getCategoryList,
  getCustomerCoordinatorList,
  getFileTypeList,
  getInstallersList,
  getJobCodeCategories,
  getJobCodeLookup,
  getOfficeList,
  getPermitEntityList,
  getPermitTypeList,
  getSplicerOptions,
  getSplicersList,
  getStateList,
  getStatusList,
  getTechnicalCoordinatorList,
  getUndergroundForemenList,
  getUnitsOfMeasurement,
  getUserAccessLevels,
  getUserEmployeeTitles,
  getUserStatusList,
  getDailyStatusList,
  getDailyJobCodeStatusList,
  getPermittingAgentList,
  getContractNames
} from '../api/lookupApi';
import type { AppDispatch, RootState } from '../store/store';
import { IJobCode } from '../types/codeTypes';
import { ILookupItem } from '../types/genericTypes';
import { selectSelectedArea } from './utilSlice';

interface lookupState {
  areaList: ILookupItem[];
  categoryList: ILookupItem[];
  statusList: ILookupItem[];
  bighamCoordinators: ILookupItem[];
  customerCoordinators: ILookupItem[];
  technicalCoordinators: ILookupItem[];
  aerialForemen: ILookupItem[];
  undergroundForemen: ILookupItem[];
  splicers: ILookupItem[];
  splicerOptions: ILookupItem[];
  installers: ILookupItem[];
  stateList: ILookupItem[];
  fileTypeList: ILookupItem[];
  permitEntityList: ILookupItem[];
  permitTypeList: ILookupItem[];
  permittingAgentList: ILookupItem[];
  userStatusList: ILookupItem[];
  accountTypeList: ILookupItem[];
  userAccessLevels: ILookupItem[];
  userEmployeeTitles: ILookupItem[];
  unitsOfMeasurement: ILookupItem[];
  codeList: ILookupItem[];
  codeCategories: ILookupItem[];
  officeList: ILookupItem[];
  dailyStatusList: ILookupItem[];
  dailyJobCodeStatusList: ILookupItem[];
  contractNames: string[];
}

// Define the initial state using that type
const initialState: lookupState = {
  areaList: [],
  categoryList: [],
  statusList: [],
  bighamCoordinators: [],
  customerCoordinators: [],
  technicalCoordinators: [],
  aerialForemen: [],
  undergroundForemen: [],
  splicers: [],
  splicerOptions: [],
  installers: [],
  stateList: [],
  fileTypeList: [],
  permitEntityList: [],
  permitTypeList: [],
  permittingAgentList: [],
  userStatusList: [],
  accountTypeList: [],
  userAccessLevels: [],
  userEmployeeTitles: [],
  unitsOfMeasurement: [],
  codeList: [],
  codeCategories: [],
  officeList: [],
  dailyStatusList: [],
  dailyJobCodeStatusList: [],
  contractNames: []
};

export const callGetAreaList = createAsyncThunk(
  'lookup/getAreaList',
  async (args, { dispatch }) => {
    const response = await getAreaList();
    dispatch(setAreaList(response.data));
  }
);

export const callGetStateList = createAsyncThunk(
  'lookup/getStateList',
  async (args, { dispatch }) => {
    const response = await getStateList();
    dispatch(setStateList(response.data));
  }
);

export const callGetCategoryList = createAsyncThunk(
  'lookup/getCategoryList',
  async (args, { dispatch }) => {
    const response = await getCategoryList();
    dispatch(setCategoryList(response.data));
  }
);

export const callGetStatusList = createAsyncThunk(
  'lookup/getStatusList',
  async (args, { dispatch }) => {
    const response = await getStatusList();
    dispatch(setStatusList(response.data));
  }
);

export const callGetUserLookupsByArea = createAsyncThunk<
  any,
  number,
  { dispatch: AppDispatch; state: RootState }
>(
  'lookup/getUserLookupsByArea',
  async (areaId: number, { dispatch, getState }) => {
    const state = getState();
    const selectedAreaId = selectSelectedArea(state);
    const userLists: { [key: string]: ILookupItem[] } = {};
    const didAreaChange =
      !areaId || !selectedAreaId || areaId !== selectedAreaId;
    const areaToRequest = areaId ? areaId : selectedAreaId;
    const callsToMake: any[] = [];
    const listKeys: string[] = [];
    if (didAreaChange || !selectBighamCoordinatorList(state)?.length) {
      // const { payload } = await dispatch(
      //   callGetBighamCoordinatorList(areaToRequest)
      // );
      // userLists['bighamCoordinators'] = payload;
      callsToMake.push(dispatch(callGetBighamCoordinatorList(areaToRequest)));
      listKeys.push('bighamCoordinators');
    } else {
      userLists['bighamCoordinators'] = selectBighamCoordinatorList(state);
    }
    if (didAreaChange || !selectCustomerCoordinatorList(state)?.length) {
      // const { payload } = await dispatch(
      //   callGetCustomerCoordinatorList(areaToRequest)
      // );
      // userLists['customerCoordinators'] = payload;
      callsToMake.push(dispatch(callGetCustomerCoordinatorList(areaToRequest)));
      listKeys.push('customerCoordinators');
    } else {
      userLists['customerCoordinators'] = selectCustomerCoordinatorList(state);
    }
    if (didAreaChange || !selectTechnicalCoordinatorList(state)?.length) {
      // const { payload } = await dispatch(
      //   callGetTechnicalCoordinatorList(areaToRequest)
      // );
      // userLists['techOpsCoordinators'] = payload;
      callsToMake.push(
        dispatch(callGetTechnicalCoordinatorList(areaToRequest))
      );
      listKeys.push('techOpsCoordinators');
    } else {
      userLists['techOpsCoordinators'] = selectTechnicalCoordinatorList(state);
    }
    if (didAreaChange || !selectAerialForemenList(state)?.length) {
      // const { payload } = await dispatch(callGetAerialForemen(areaToRequest));
      // userLists['aerialForemen'] = payload;
      callsToMake.push(dispatch(callGetAerialForemen(areaToRequest)));
      listKeys.push('aerialForemen');
    } else {
      userLists['aerialForemen'] = selectAerialForemenList(state);
    }
    if (didAreaChange || !selectUndergroundForemenList(state)?.length) {
      // const { payload } = await dispatch(
      //   callGetUndergroundForemen(areaToRequest)
      // );
      // userLists['undergroundForemen'] = payload;
      callsToMake.push(dispatch(callGetUndergroundForemen(areaToRequest)));
      listKeys.push('undergroundForemen');
    } else {
      userLists['undergroundForemen'] = selectUndergroundForemenList(state);
    }
    if (didAreaChange || !selectSplicerList(state)?.length) {
      // const { payload } = await dispatch(callGetSplicers(areaToRequest));
      // userLists['splicingForemen'] = payload;
      callsToMake.push(dispatch(callGetSplicers(areaToRequest)));
      listKeys.push('splicingForemen');
    } else {
      userLists['splicingForemen'] = selectSplicerList(state);
    }
    if (didAreaChange || !selectInstallerList(state)?.length) {
      // const { payload } = await dispatch(callGetInstallers(areaToRequest));
      // userLists['installers'] = payload;
      callsToMake.push(dispatch(callGetInstallers(areaToRequest)));
      listKeys.push('installers');
    } else {
      userLists['installers'] = selectInstallerList(state);
    }
    if (didAreaChange || !selectPermittingAgentList(state)?.length) {
      callsToMake.push(dispatch(callGetPermittingAgentList(areaToRequest)));
      listKeys.push('permittingAgents');
    } else {
      userLists['permittingAgents'] = selectPermittingAgentList(state);
    }

    const response = await Promise.all(callsToMake);
    response.forEach((userList: { payload: ILookupItem[] }, index: number) => {
      userLists[listKeys[index]] = userList.payload;
    });
    return userLists;
  }
);

export const callGetBighamCoordinatorList = createAsyncThunk(
  'lookup/getBighamCoordinators',
  async (areaId: number, { dispatch }) => {
    const response = await getBighamCoordinatorList(areaId);
    const userData = mapArrayUserInfo(response.data);
    dispatch(setBighamCoordinatorList(userData));
    return userData;
  }
);

export const callGetCustomerCoordinatorList = createAsyncThunk(
  'lookup/getCustomerCoordinators',
  async (areaId: number, { dispatch }) => {
    const response = await getCustomerCoordinatorList(areaId);
    const userData = mapArrayUserInfo(response.data);
    dispatch(setCustomerCoordinatorList(userData));
    return userData;
  }
);

export const callGetTechnicalCoordinatorList = createAsyncThunk(
  'lookup/getTechnicalCoordinators',
  async (areaId: number, { dispatch }) => {
    const response = await getTechnicalCoordinatorList(areaId);
    const userData = mapArrayUserInfo(response.data);
    dispatch(setTechnicalCoordinatorList(userData));
    return userData;
  }
);

export const callGetAerialForemen = createAsyncThunk(
  'lookup/getAerialForemen',
  async (areaId: number, { dispatch }) => {
    const response = await getAerialForemenList(areaId);
    const userData = mapArrayUserInfo(response.data);
    dispatch(setAerialForemenList(userData));
    return userData;
  }
);

export const callGetUndergroundForemen = createAsyncThunk(
  'lookup/getUndergroundForemen',
  async (areaId: number, { dispatch }) => {
    const response = await getUndergroundForemenList(areaId);
    const userData = mapArrayUserInfo(response.data);
    dispatch(setUndergroundForemenList(userData));
    return userData;
  }
);

export const callGetSplicers = createAsyncThunk(
  'lookup/getSplicers',
  async (areaId: number, { dispatch }) => {
    const response = await getSplicersList(areaId);
    const userData = mapArrayUserInfo(response.data);
    dispatch(setSplicerList(userData));
    return userData;
  }
);

export const callGetSplicerOptions = createAsyncThunk(
  'lookup/getSplicerOptions',
  async (args, { dispatch }) => {
    const response = await getSplicerOptions();
    dispatch(setSplicerOptions(response.data));
  }
);

export const callGetInstallers = createAsyncThunk(
  'lookup/getInstallers',
  async (areaId: number, { dispatch }) => {
    const response = await getInstallersList(areaId);
    const userData = mapArrayUserInfo(response.data);
    dispatch(setInstallerList(userData));
    return userData;
  }
);

export const callGetFileTypeList = createAsyncThunk(
  'lookup/getFileTypes',
  async (args, { dispatch }) => {
    const response = await getFileTypeList();
    dispatch(setFileTypeList(response.data));
  }
);

export const callGetPermitTypeList = createAsyncThunk(
  'lookup/getPermitType',
  async (args, { dispatch }) => {
    const response = await getPermitTypeList();
    dispatch(setPermitTypeList(response.data));
  }
);
export const callGetPermitEntityList = createAsyncThunk(
  'lookup/getPermitEntity',
  async (args, { dispatch }) => {
    const response = await getPermitEntityList();
    dispatch(setPermitEntityList(response.data));
  }
);

export const callGetUserStatusList = createAsyncThunk(
  'lookup/getUserStatusList',
  async (args, { dispatch }) => {
    const response = await getUserStatusList();
    dispatch(setUserStatusList(response.data));
  }
);

export const callGetAccountTypeList = createAsyncThunk(
  'lookup/getAccountTypes',
  async (args, { dispatch }) => {
    const response = await getAccountTypeList();
    dispatch(setAccountTypeList(response.data));
  }
);

export const callGetUserAccessLevels = createAsyncThunk(
  'lookup/getUserAccessLevels',
  async (args, { dispatch }) => {
    const response = await getUserAccessLevels();
    dispatch(setUserAccessLevels(response.data));
    return response.data;
  }
);
export const callGetUserEmployeeTitles = createAsyncThunk(
  'lookup/getUserEmployeeTitles',
  async (args, { dispatch }) => {
    const response = await getUserEmployeeTitles();
    dispatch(setUserEmployeeTitles(response.data));
  }
);

export const callGetUnitsOfMeasurement = createAsyncThunk(
  'lookup/getUnitsOfMeasurement',
  async (args, { dispatch }) => {
    const response = await getUnitsOfMeasurement();
    dispatch(setUnitsOfMeasurement(response.data));
  }
);

export const callGetJobCodeCategoryList = createAsyncThunk(
  'lookup/getJobCodeCategories',
  async (args, { dispatch }) => {
    const response = await getJobCodeCategories();
    dispatch(setJobCodeCategories(response.data));
  }
);

export const callGetOfficeList = createAsyncThunk(
  'lookup/getOfficeList',
  async (args, { dispatch }) => {
    const response = await getOfficeList();
    dispatch(setOfficeList(response.data));
  }
);

export const callGetDailyStatusList = createAsyncThunk(
  'lookup/getDailyStatusList',
  async (args, { dispatch }) => {
    const response = await getDailyStatusList();
    dispatch(setDailyStatusList(response.data));
    return response.data;
  }
);
export const callGetDailyJobCodeStatusList = createAsyncThunk(
  'lookup/getDailyJobCodeStatusList',
  async (args, { dispatch }) => {
    const response = await getDailyJobCodeStatusList();
    dispatch(setDailyJobCodeStatusList(response.data));
  }
);

export const callGetPermittingAgentList = createAsyncThunk(
  'lookup/getPermittingAgentsList',
  async (areaId: number, { dispatch }) => {
    const response = await getPermittingAgentList(areaId);
    const userData = mapArrayUserInfo(response.data);
    dispatch(setPermittingAgentList(userData));
  }
)

export const callGetContractNames = createAsyncThunk(
  'lookup/getContractNames',
  async (args, { dispatch }) => {
      const response = await getContractNames();
      dispatch(setContractNames(response.data))
  }
)

export const lookupSlice = createSlice({
  name: 'lookup',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAreaList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.areaList = action.payload;
    },
    setStateList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.stateList = action.payload;
    },
    setCategoryList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.categoryList = action.payload;
    },
    setStatusList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.statusList = action.payload;
    },
    setBighamCoordinatorList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.bighamCoordinators = action.payload;
    },
    setCustomerCoordinatorList: (
      state,
      action: PayloadAction<ILookupItem[]>
    ) => {
      state.customerCoordinators = action.payload;
    },
    setTechnicalCoordinatorList: (
      state,
      action: PayloadAction<ILookupItem[]>
    ) => {
      state.technicalCoordinators = action.payload;
    },
    setAerialForemenList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.aerialForemen = action.payload;
    },
    setUndergroundForemenList: (
      state,
      action: PayloadAction<ILookupItem[]>
    ) => {
      state.undergroundForemen = action.payload;
    },
    setSplicerList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.splicers = action.payload;
    },
    setSplicerOptions: (state, action: PayloadAction<ILookupItem[]>) => {
      state.splicerOptions = action.payload;
    },
    setInstallerList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.installers = action.payload;
    },
    setFileTypeList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.fileTypeList = action.payload;
    },
    setPermitTypeList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.permitTypeList = action.payload;
    },
    setPermitEntityList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.permitEntityList = action.payload;
    },
    setUserStatusList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.userStatusList = action.payload;
    },
    setAccountTypeList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.accountTypeList = action.payload;
    },
    setUserAccessLevels: (state, action: PayloadAction<ILookupItem[]>) => {
      state.userAccessLevels = action.payload;
    },
    setUserEmployeeTitles: (state, action: PayloadAction<ILookupItem[]>) => {
      state.userEmployeeTitles = action.payload;
    },
    setUnitsOfMeasurement: (state, action: PayloadAction<ILookupItem[]>) => {
      state.unitsOfMeasurement = action.payload;
    },
    setJobCodeLookup: (state, action: PayloadAction<ILookupItem[]>) => {
      state.codeList = action.payload;
    },
    setJobCodeCategories: (state, action: PayloadAction<ILookupItem[]>) => {
      state.codeCategories = action.payload;
    },
    setOfficeList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.officeList = action.payload;
    },
    setDailyStatusList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.dailyStatusList = action.payload;
    },
    setDailyJobCodeStatusList: (
      state,
      action: PayloadAction<ILookupItem[]>
    ) => {
      state.dailyJobCodeStatusList = action.payload;
    },
    setPermittingAgentList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.permittingAgentList = action.payload;
    },
    setContractNames: (state, action: PayloadAction<string[]>) => {
      state.contractNames = action.payload;
    }
  }
});

export const {
  setAreaList,
  setStateList,
  setCategoryList,
  setStatusList,
  setBighamCoordinatorList,
  setCustomerCoordinatorList,
  setTechnicalCoordinatorList,
  setAerialForemenList,
  setUndergroundForemenList,
  setSplicerList,
  setSplicerOptions,
  setInstallerList,
  setFileTypeList,
  setPermitTypeList,
  setPermitEntityList,
  setPermittingAgentList,
  setUserStatusList,
  setAccountTypeList,
  setUserAccessLevels,
  setUserEmployeeTitles,
  setUnitsOfMeasurement,
  setJobCodeLookup,
  setJobCodeCategories,
  setOfficeList,
  setDailyStatusList,
  setDailyJobCodeStatusList,
  setContractNames
} = lookupSlice.actions;

export const selectAreaList = (state: RootState): ILookupItem[] =>
  state.lookup.areaList;
export const selectStateList = (state: RootState): ILookupItem[] =>
  state.lookup.stateList;
export const selectCategoryList = (state: RootState): ILookupItem[] =>
  state.lookup.categoryList;
export const selectFileTypeList = (state: RootState): ILookupItem[] =>
  state.lookup.fileTypeList;
export const selectStatusList = (state: RootState): ILookupItem[] =>
  state.lookup.statusList;
export const selectBighamCoordinatorList = (state: RootState): ILookupItem[] =>
  state.lookup.bighamCoordinators;
export const selectCustomerCoordinatorList = (
  state: RootState
): ILookupItem[] => state.lookup.customerCoordinators;
export const selectTechnicalCoordinatorList = (
  state: RootState
): ILookupItem[] => state.lookup.technicalCoordinators;
export const selectAerialForemenList = (state: RootState) =>
  state.lookup.aerialForemen;
export const selectUndergroundForemenList = (state: RootState) =>
  state.lookup.undergroundForemen;
export const selectSplicerList = (state: RootState) => state.lookup.splicers;
export const selectSplicerOptionList = (state: RootState) =>
  state.lookup.splicerOptions;
export const selectInstallerList = (state: RootState) =>
  state.lookup.installers;
export const selectPermitEntityList = (state: RootState) =>
  state.lookup.permitEntityList;
export const selectPermitTypeList = (state: RootState) =>
  state.lookup.permitTypeList;
export const selectPermittingAgentList = (state: RootState) => 
  state.lookup.permittingAgentList;
export const selectUserStatusList = (state: RootState) =>
  state.lookup.userStatusList;
export const selectAccountTypeList = (state: RootState) =>
  state.lookup.accountTypeList;
export const selectUserAccessLevels = (state: RootState) =>
  state.lookup.userAccessLevels;
export const selectUserEmployeeTitles = (state: RootState) =>
  state.lookup.userEmployeeTitles;
export const selectUnitsOfMeasurement = (state: RootState) =>
  state.lookup.unitsOfMeasurement;
export const selectJobCodeLookup = (state: RootState) => state.lookup.codeList;
export const selectJobCodeCategories = (state: RootState) =>
  state.lookup.codeCategories;
export const selectOfficeList = (state: RootState) => state.lookup.officeList;
export const selectDailyStatusList = (state: RootState) =>
  state.lookup.dailyStatusList;
export const selectDailyJobCodeStatusList = (state: RootState) =>
  state.lookup.dailyJobCodeStatusList;
export const selectContractNamesList = (state: RootState) => 
  state.lookup.contractNames;


export default lookupSlice.reducer;
