import { isNil } from 'lodash';

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const formatNumberWithCommas = (x: number | string) => {
  let string = '';
  if (!isNil(x)) {
    if (typeof x === 'number') {
      string = x.toString();
    } else {
      string = x;
    }
  }
  return string.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatCurrency = (x: number | string) => {
  let number: number;
  if (!isNil(x)) {
    if (typeof x === 'string') {
      number = parseInt(x);
    } else {
      number = x;
    }
  } else {
    number = 0;
  }
  return USDollar.format(number);
}
