import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { RowNode, ValueSetterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { sortBy, map, find, filter, some } from "lodash";
import { ChangeEvent, FC, useState, useRef, useEffect } from "react";
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import BighamMoneyDisplayInput from "../../../../library/components/FormControls/BighamMoneyDisplayInput/BighamMoneyDisplayInput";
import { INVOICE_STATUS } from "../../../../library/constants/jobCodeStatus";
import { buildInvoiceSummary, getDailyJobCodes, getJobCodeOverrides } from "../../../../library/transforms/invoiceTransform";
import { useAppDispatch } from "../../../../main/hooks/hooks";
import { callCreateInvoiceRequest } from "../../../../main/slices/jobDetailSlice";
import { IInvoiceRequestPayload, IInvoiceSummaryBlock, IJobCodeLineItem, ITeamJobCode } from "../../../../main/types/jobDetailTypes";
import { IProductionReportsForInvoicingPermissions } from "../../../../main/types/permissionTypes";
import CommentsModal from "../../../../library/components/CommentsModal/CommentsModal";
import InvoicingJobCodesModal from "./InvoicingJobCodesModal";
import InvoicingSummaryGrid from "./InvoicingSummaryGrid";

interface InvoicingSectionProps {
    jobDetailModel: any,
    setJobDetailModel(details: any): void,
    openSection: string,
    handleAccordionAction(panel: string): (event: ChangeEvent<any>, isExpanded: boolean) => void,
    permissions: IProductionReportsForInvoicingPermissions | undefined
}

const InvoicingSection: FC<InvoicingSectionProps> = ({openSection, handleAccordionAction, setJobDetailModel, jobDetailModel, permissions}) => {
    const dispatch = useAppDispatch();
    const summaryGrid = useRef<AgGridReact | null>(null);
    const [isInvoiceRequested, setIsInvoiceRequested] = useState(false);
    const [showJobCodeModal, setShowJobCodeModal] = useState(false);
    const [showCommentsModal, setShowCommentsModal] = useState(false);
    const [newInvoiceTotal, setNewInvoiceTotal] = useState(0.00);
    const [invoiceSummary, setInvoiceSummary] = useState<IInvoiceSummaryBlock>({});
    const [jobCodeModalState, setJobCodeModalState] = useState<ITeamJobCode[]>([])

    useEffect(() => {
        setJobCodeModalState(jobDetailModel.invoicing.jobCodes);
    }, [jobDetailModel.invoicing.jobCodes]);
    
    useEffect(() => {
        setInvoiceSummary(jobDetailModel.invoicing.invoiceSummary);
        handleSetNewInvoiceTotal(jobDetailModel.invoicing.invoiceSummary);
    }, [jobDetailModel.invoicing.invoiceSummary]);

    const handleAddToInvoice = (selectedJobCodes: ITeamJobCode[]) => {
        const parentModel:ITeamJobCode[] = [...jobDetailModel.invoicing.jobCodes];
        const temp: ITeamJobCode[] = map(parentModel, (jobCode: ITeamJobCode):ITeamJobCode => {
            const updatedValue = find(selectedJobCodes, (updatedJobCodes) => jobCode.id === updatedJobCodes.id);
            if (updatedValue) {
                return updatedValue;
            } else {
                return jobCode;
            }
        });
        setJobCodeModalState(temp);
        handleUpdateSummary(temp);
        hideModal();
    }

    const handleUpdateSummary = (dailyJobCodes: ITeamJobCode[]) => {
        const ntpJobCodes: IJobCodeLineItem[] = jobDetailModel.purchaseOrder.ntpJobCodes;
        const tempSummary = buildInvoiceSummary(ntpJobCodes, dailyJobCodes);
        handleSetNewInvoiceTotal(tempSummary);
        setInvoiceSummary(tempSummary);
    }

    const handleShowCommentsModal = () => {
        setShowCommentsModal(true);
    }

    const handleShowModal = () => {
        setShowJobCodeModal(true);
    }

    const hideModal = () => {
        setShowJobCodeModal(false);
        setShowCommentsModal(false);
    }

    const handleCellEdit = (params: ValueSetterParams) => {
        const temp = {...invoiceSummary, [params.data.jobCodeId]: { ...invoiceSummary[params.data.jobCodeId], manualOverride: params.newValue, newInvoiceTotal: params.data.unitPrice * params.newValue }};
        handleSetNewInvoiceTotal(temp);
        setInvoiceSummary(temp)
        return true;
    }

    const handleFinalizeInvoiceRequest = (comments: string) => () => {
        const jobCodeOverrides = getJobCodeOverrides(invoiceSummary);
        const dailyJobCodes = getDailyJobCodes(invoiceSummary, jobCodeModalState);
        const payload: IInvoiceRequestPayload = {
            comments,
            dailyJobCodes,
            jobCodeOverrides,
            jobId: jobDetailModel.details.id
        }
        dispatch(callCreateInvoiceRequest(payload));
        hideModal();
    }

    const handleSetNewInvoiceTotal = (summary: IInvoiceSummaryBlock) => {
        let invoiceValue = 0.00;
        const keys = Object.keys(summary);
        keys?.length && keys.forEach((key: string) => {
            invoiceValue += summary[+key].newInvoiceTotal;
        })
        setNewInvoiceTotal(invoiceValue ? invoiceValue : 0.00);
    }

    useEffect(() => {
        if (jobDetailModel.invoices?.length) {
            setIsInvoiceRequested(some(jobDetailModel.invoices, (invoice) => {
                return invoice.invoiceStatusId === INVOICE_STATUS.REQUESTED;
            }));
        }
    }, [jobDetailModel.invoices])

    return <Accordion className="styled-accordion" expanded={openSection === 'invoicing'} onChange={handleAccordionAction('invoicing')}>
        <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
        >
            <div className="flex-header">
                <div className="component-title">Invoicing</div>
            </div>
        </AccordionSummary>
        <AccordionDetails
            style={{flexFlow: 'column'}}
        >
            {showCommentsModal && <CommentsModal onCancel={hideModal} handleSubmit={handleFinalizeInvoiceRequest} actionText={`Finalize Requested Invoice`} />}
            {showJobCodeModal && <InvoicingJobCodesModal onCancel={hideModal} listItems={jobCodeModalState} handleAddToInvoice={handleAddToInvoice} permissions={permissions} />}
            {!isInvoiceRequested && <div className="flex-row space-between standard-padding">
                {!!permissions?.canSelectDailyJobCodes && <BighamButton
                    label={'Update Requested Invoice'}
                    variant="contained"
                    type="default"
                    onClick={handleShowModal}
                />}
                {!!permissions?.canSendJobCodeToInvoice && <BighamButton
                    label={'Finalize Requested Invoice'}
                    variant="contained"
                    type="primary"
                    onClick={handleShowCommentsModal}
                    disabled={newInvoiceTotal == 0}
                />}
            </div>}
            <div className="form">
                <div className="form-row align-center">
                    <BighamMoneyDisplayInput
                        label="PO Amount"
                        value={jobDetailModel.purchaseOrder.purchaseOrderAmount}
                        decimalPlaces={2}
                    />
                    <BighamMoneyDisplayInput
                        label="New Invoice"
                        value={newInvoiceTotal}
                        decimalPlaces={2}
                    />
                </div>
            </div>
            {!!Object.keys(invoiceSummary)?.length && <>
                <div className="sub-header">Summary</div>
                <InvoicingSummaryGrid ref={summaryGrid} handleCellEdit={handleCellEdit} listItems={sortBy(Object.values(invoiceSummary), ['jobCode'])} permissions={permissions} />
            </>}
        </AccordionDetails>
    </Accordion>
}

export default InvoicingSection;