import { FC } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

const FootageDisplayRenderer: FC<ICellRendererParams> = ({value}) => {
    // const {
    //     estimated = 0,
    //     complete = 0
    // } = value || {};
    const [estimated, complete] = (value || '0 / 0').split('/');
    return <div className="footage-display-renderer">
        <span className="red">{estimated}</span>/<span className="">{complete}</span>
    </div>
};

export default FootageDisplayRenderer;
