import { FC, useMemo } from "react";
import { Document, Page, View, Image, Text } from '@react-pdf/renderer';
import styles from './PDFDailyGeneratorStyles';
import PDFTable from "../PDFRendererComponents/PDFTable/PDFTable";
import PDFTableHeader from "../PDFRendererComponents/PDFTableHeader/PDFTableHeader";
import PDFTableCell from "../PDFRendererComponents/PDFTableCell/PDFTableCell";
import PDFTableRow from "../PDFRendererComponents/PDFTableRow/PDFTableRow";
import PDFTableDataCell from "../PDFRendererComponents/PDFTableDataCell/PDFTableDataCell";
import PDFTableNumberCell from "../PDFRendererComponents/PDFTableDataCell/PDFTableNumberCell";
import moment from 'moment';
import { logoBlack } from "../../constants/imageConstants";
import { IDailyReport, ITeamJobCode } from "../../../main/types/jobDetailTypes";
import { IDailyProductionReport } from "../../../main/types/reportTypes";
import { formatDate } from "../../transforms/utilTransforms";
import { dateFormat } from "../../constants/dateFormat";

interface DailyGeneratorProps {
    fileName: string,
    reportDetails?: IDailyProductionReport | IDailyReport;
}

const DailyGenerator: FC<DailyGeneratorProps> = ({ fileName, reportDetails }) => {

    const reportData = useMemo(() => {
        return (reportDetails?.jobCodes as ITeamJobCode[] || []);
    }, [reportDetails]);

    const address = useMemo(() => {
        if (reportDetails?.address) {
            const address = reportDetails.address;
            return `${address.addressLine1}, ${address.city}, ${address.state} ${address.zipCode}`;
        }
        return ``;
    }, [reportDetails?.address]);

    const crew = useMemo(() => {
        if (reportDetails?.crew && reportDetails.crew.length) {
            let crew = ``;
            reportDetails.crew.forEach((crewMember) => {
                crew += `${crewMember.firstName} ${crewMember.lastName} \n`;
            });
            return crew;
        }
        return ``;
    }, [reportDetails?.crew])

    return <Document title={fileName}>
        <Page style={styles.page} orientation={'portrait'}>
            <View style={styles.header}>
                <Image style={styles.headerContent} src={logoBlack} />
            </View>
            <View style={styles.header}>
                <Text style={styles.headerText}>Daily Production - {reportDetails?.foreman || ''}</Text>
            </View>
            <View style={styles.content}>
                <View style={styles.contentRow}>
                    <Text style={{...styles.contentText, marginLeft: 0 }}>{formatDate(reportDetails?.dailyDate || '', dateFormat)}</Text>
                </View>
                <View style={styles.contentRow}>
                    <Text style={styles.contentLabel}>Job ID:</Text>
                    <Text style={styles.contentText}>
                        {reportDetails?.jobNumber}
                    </Text>
                </View>
                <View style={styles.contentRow}>
                    <Text style={styles.contentLabel}>Business:</Text>
                    <Text style={styles.contentText}>
                        {reportDetails?.businessName}
                    </Text>
                </View>
                <View style={styles.contentRow}>
                    <Text style={styles.contentLabel}>Address:</Text>
                    <Text style={styles.contentText}>
                        {address}
                    </Text>
                </View>
                <View style={styles.contentRow}>
                    <Text style={styles.contentLabel}>New Daily Footage:</Text>
                    <Text style={styles.contentText}>
                        <Text style={styles.blueText}>{reportDetails?.newDailyFootage}</Text>
                    </Text>
                </View>
                <View style={styles.contentColumn}>
                    <Text style={styles.contentLabel}>Crew:</Text>
                    <Text style={styles.contentText}>
                        {crew}
                    </Text>
                </View>

            </View>
            <PDFTable>
                <>
                    <PDFTableHeader>
                        <PDFTableCell label={'Code'} />
                        <PDFTableCell label={'Description'} flexGrow={2} />
                        <PDFTableCell label={'Footage'} />
                        <PDFTableCell label={'Footage Comments'} flexGrow={2} />
                        <PDFTableCell label={'Comments'} flexGrow={2} />
                    </PDFTableHeader>
                    {!!reportData?.length && reportData.map((rowData, index) => {
                        return <PDFTableRow key={index} rowData={rowData}>
                            <PDFTableDataCell fieldName={'code'} />
                            <PDFTableDataCell fieldName={'description'} flexGrow={2} />
                            <PDFTableNumberCell fieldName={'productionQuantity'} textAlign={'right'} />
                            <PDFTableDataCell fieldName={'productionFootageComments'} flexGrow={2} />
                            <PDFTableDataCell fieldName={'productionComments'} flexGrow={2} />
                        </PDFTableRow>
                    })}
                </>
            </PDFTable>
        </Page>
    </Document>;
}

export default DailyGenerator;