import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  transformCodeDetailsForUpdateOrCreate,
  transformCodeDetailsResponse
} from '../../library/transforms/codeDetailsTransform';
import { transformParentCodeListResponse } from '../../library/transforms/codeListTransform';
import { mapJobCodes } from '../../library/transforms/utilTransforms';
import {
  createCodeDetails,
  getCodeDetails,
  getCodeList,
  updateCodeDetails
} from '../api/codeApi';
import { getJobCodeLookup } from '../api/lookupApi';
import type { RootState } from '../store/store';
import { IJobCode } from '../types/codeTypes';
import { ILookupItem } from '../types/genericTypes';
import { setJobCodeLookup } from './lookupSlice';

interface codeState {
  parentCodeList: ILookupItem[];
  codeList: IJobCode[];
  codeDetails: IJobCode;
  newCodeId: number;
}

export const initialCodeState: IJobCode = {
  id: 0,
  parentJobCodeId: 0,
  code: '',
  description: '',
  unitCtId: 0,
  contractName: '',
  areaId: 0,
  areaIds: [],
  contractPrice: 0,
  inHouseRate: 0,
  subContractorRate: 0,
  effectiveOn: '',
  expiresOn: '',
  comments: '',
  jobCodeCategoryIds: [],
  isPrimaryCustomerCode: false,
  isCrewSubCode: false,
  isPrimaryMeasure: false
};

// Define the initial state using that type
const initialState: codeState = {
  parentCodeList: [],
  codeList: [],
  codeDetails: initialCodeState,
  newCodeId: 0
};

export const callGetCodeList = createAsyncThunk(
  'code/getCodeList',
  async (areaId: number, { dispatch }) => {
    const response = await getCodeList(areaId, true, true);
    dispatch(setCodeList(response.data));
  }
);

export const callGetJobCodeLookup = createAsyncThunk<
  any,
  { areaId: number; receivedDate?: string }
>('lookup/getJobCodes', async ({ areaId, receivedDate = '' }, { dispatch }) => {
  const response = await getJobCodeLookup(areaId, receivedDate);
  dispatch(setJobCodeLookup(mapJobCodes(response.data)));
  dispatch(setCodeList(response.data));
});

export const callGetCodeDetails = createAsyncThunk(
  'code/getCodeDetails',
  async (codeId: number, { dispatch }) => {
    const response = await getCodeDetails(codeId);
    dispatch(setCodeDetails(transformCodeDetailsResponse(response.data)));
  }
);

export const callUpdateCodeDetails = createAsyncThunk(
  'code/updateCodeDetails',
  async (codeDetails: IJobCode) => {
    const response = await updateCodeDetails(transformCodeDetailsForUpdateOrCreate(codeDetails));
    return response.data
  }
);

export const callCreateCodeDetails = createAsyncThunk(
  'code/updateCodeDetails',
  async (codeDetails: IJobCode, { dispatch }) => {
    const response = await createCodeDetails(
      transformCodeDetailsForUpdateOrCreate(codeDetails)
    );
    dispatch(setNewCodeId(response.data));
    return response.data
  }
);

export const codeSlice = createSlice({
  name: 'code',
  initialState,
  reducers: {
    setParentCodeList: (state, action: PayloadAction<ILookupItem[]>) => {
      state.parentCodeList = action.payload;
    },
    setCodeList: (state, action: PayloadAction<IJobCode[]>) => {
      state.codeList = action.payload;
    },
    setCodeDetails: (state, action: PayloadAction<IJobCode>) => {
      state.codeDetails = action.payload;
    },
    setNewCodeId: (state, action: PayloadAction<number>) => {
      state.newCodeId = action.payload;
    }
  }
});

export const { setParentCodeList, setCodeList, setCodeDetails, setNewCodeId } =
  codeSlice.actions;

export const selectParentCodeList = (state: RootState) =>
  state.code.parentCodeList;
export const selectCodeList = (state: RootState) => state.code.codeList;
export const selectCodeDetails = (state: RootState) => state.code.codeDetails;
export const selectNewCodeId = (state: RootState) => state.code.newCodeId;
export default codeSlice.reducer;
