import React, { FC, useEffect, useState } from 'react';
import './QualityControlReports.scss';
import { AgGridReact } from 'ag-grid-react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import BighamButton from '../../library/components/FormControls/BighamButton/BighamButton';
import ROUTES from '../../library/constants/routeConstants';
import { useAppDispatch, useAppSelector } from '../../main/hooks/hooks';
import { selectSelectedArea, selectUserPermissions, setComponentName, setSelectedArea } from '../../main/slices/utilSlice';
import BighamSelect from '../../library/components/FormControls/BighamSelect/BighamSelect';
import { callGetAreaList, selectAreaList } from '../../main/slices/lookupSlice';
import QualityControlReportsGrid from './QualityControlReportsGrid';
import { callGetQCReports, selectQCReports, setQCReports } from '../../main/slices/reportsSlice';
import { IDailyReport } from '../../main/types/jobDetailTypes';
import QualityControlModal from '../../library/components/QualityControlModal/QualityControlModal';
import { RowDoubleClickedEvent } from 'ag-grid-community';
import { IQualityControlReport } from '../../main/types/reportTypes';

const QualityControlReports: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const gridRef = useRef<AgGridReact>(null);
    const [modalData, setModalData] = useState<IDailyReport | null>(null);
    const [filteredData, setFilteredData] = useState<IQualityControlReport[]>([]);
    const {
        selectedArea,
        areaList,
        qcReports,
        permissions
    } = useAppSelector((state) => ({
        selectedArea: selectSelectedArea(state),
        areaList: selectAreaList(state),
        qcReports: selectQCReports(state),
        permissions: selectUserPermissions(state)
    }));

    useEffect(() => {
        dispatch(setComponentName('Quality Control'));
        !areaList?.length && dispatch(callGetAreaList());
        !qcReports?.length && dispatch(callGetQCReports());

        return () => {
            dispatch(setQCReports([]));
        }
    },[]);

    useEffect(() => {
        if (areaList?.length) {
            // TODO: set the current qc list to empty array.
            if (selectedArea) {
                handleAreaChange(selectedArea);
            } else {
                handleAreaChange(areaList[0]?.id);
            }
        }
    }, [areaList])

    useEffect(() => {
        if (selectedArea && qcReports) {
            setFilteredData(filterReportData(qcReports));
        }
    }, [selectedArea, qcReports])
    
    const filterReportData = (reports: IQualityControlReport[]) => {
        return reports.filter((report) => report.areaId === selectedArea)
    }

    const handleAreaChange = (value: any): void => {
        dispatch(setSelectedArea(value));
        setFilteredData([]);
        filterReportData(qcReports);
    }

    const handleCloseModal = () => {
        setModalData(null);
    }

    const handleDoubleClick = (e: RowDoubleClickedEvent) => {
        setModalData(e.data);
    }

    return <>
        <div className="quality-control-wrapper">
            {modalData && <QualityControlModal onCancel={handleCloseModal} reportDetails={modalData} permissions={permissions.jobPermissions.jobProductionReportsPermissions} />}
            <div className="flex-row">
                <div className="select-wrapper">
                    <BighamSelect
                        value={selectedArea}
                        onChange={handleAreaChange}
                        fieldName={'area'}
                        label="Area"
                        listItems={areaList}
                    />
                </div>
            </div>
            <QualityControlReportsGrid ref={gridRef} listItems={filteredData} handleDoubleClick={handleDoubleClick} />
        </div>
    </>
}

export default QualityControlReports;