import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../main/hooks/hooks';
import './CodeDetails.scss';
import { setComponentName } from '../../main/slices/utilSlice';
import BighamButton from '../../library/components/FormControls/BighamButton/BighamButton';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from '../../library/constants/routeConstants';
import BighamTextInput from '../../library/components/FormControls/BighamTextInput/BighamTextInput';
import BighamSelect from '../../library/components/FormControls/BighamSelect/BighamSelect';
import { callGetAreaList, callGetContractNames, callGetJobCodeCategoryList, callGetUnitsOfMeasurement, selectAreaList, selectContractNamesList, selectJobCodeCategories, selectJobCodeLookup, selectUnitsOfMeasurement } from '../../main/slices/lookupSlice';
import BighamMultiSelect from '../../library/components/FormControls/BighamMultiSelect/BighamMultiSelect';
import BighamNumberInput from '../../library/components/FormControls/BighamNumberInput/BighamNumberInput';
import { callCreateCodeDetails, callGetCodeDetails, callGetJobCodeLookup, callUpdateCodeDetails, initialCodeState, selectCodeDetails, selectCodeList, selectNewCodeId, setCodeDetails, setNewCodeId } from '../../main/slices/codeSlice';
import BighamDatePicker from '../../library/components/FormControls/BighamDatePicker/BighamDatePicker';
import BighamCheckbox from '../../library/components/FormControls/BighamCheckbox/BighamCheckbox';
import { IJobCode } from '../../main/types/codeTypes';
import { FormControl, FormHelperText, MenuItem } from '@material-ui/core';
import { find } from 'lodash';
import BighamTextAutoComplete from '../../library/components/FormControls/BighamTextAutoComplete/BighamTextAutoComplete';

const CodeDetails: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { codeId = '' } = useParams();

    const {
        parentCodeListLookup,
        parentCodeList,
        codeDetails,
        areaList,
        unitCountList,
        newCodeId,
        codeCategories,
        contractNamesList
    } = useAppSelector((state) => ({
        parentCodeListLookup: selectJobCodeLookup(state),
        parentCodeList: selectCodeList(state),
        codeDetails: selectCodeDetails(state),
        areaList: selectAreaList(state),
        unitCountList: selectUnitsOfMeasurement(state),
        newCodeId: selectNewCodeId(state),
        codeCategories: selectJobCodeCategories(state),
        contractNamesList: selectContractNamesList(state)
    }));

    useEffect(() => {
        dispatch(setComponentName('Job Code Details'));
        if (!isNewCode()) {
            dispatch(callGetCodeDetails(+codeId));
        }
        !areaList?.length && dispatch(callGetAreaList());
        !unitCountList?.length && dispatch(callGetUnitsOfMeasurement());
        !codeCategories?.length && dispatch(callGetJobCodeCategoryList());
        !contractNamesList?.length && dispatch(callGetContractNames());
        return () => {
            dispatch(setCodeDetails(initialCodeState));
        }
    },[]);

    const [localCodeDetails, setLocalCodeDetails] = useState<IJobCode>(initialCodeState);
    const [isPrimaryMeasureDisabled, setIsPrimaryMeasureDisabled] = useState(false);
    const [errors, setErrors] = useState<{ [key: string] : string }>({});

    useEffect(() => {
        if (codeDetails) {
            setLocalCodeDetails(codeDetails);
            if (codeDetails.parentJobCodeId) {
                const fieldName = 'parentJobCodeId'
                checkPrimaryMeasureFlag(codeDetails, codeDetails[fieldName], fieldName);
            }
        }
    }, [codeDetails])

    useEffect(() => {
        if (newCodeId) {
            navigate(`${ROUTES.CODES}/${newCodeId}`)
        }
        return () => {
            dispatch(setNewCodeId(0));
        }
    }, [newCodeId]);

    useEffect(() => {
        if (localCodeDetails.areaId) {
            dispatch(callGetJobCodeLookup({ areaId: localCodeDetails.areaId }));
        }
    }, [localCodeDetails.areaId]);

    const handleSaveClick = () => formValidation(localCodeDetails);

    const handleSave = async (values: IJobCode) => {
        if (isNewCode()) {
            await dispatch(callCreateCodeDetails(values));
        } else {
            await dispatch(callUpdateCodeDetails(values));
        }
        dispatch(setCodeDetails(values));
        dispatch(callGetContractNames())
    }

    const isNewCode = () => codeId === 'new';

    const handleBack = () => {
        navigate(ROUTES.CODES);
    }

    const handleParentJobCodeChange = (value: any, fieldName: string) => {
        handleFormChange(value, fieldName);
        checkPrimaryMeasureFlag(localCodeDetails, value, fieldName);
    }

    const checkPrimaryMeasureFlag = (codeDetails: IJobCode, value: any, fieldName: string) => {
        const parentCode = find(parentCodeList, (code) => code.id === value);
        if (!parentCode?.childJobCodes) {
            setIsPrimaryMeasureDisabled(false);
            return;
        }
        const primaryMeasureJobCode = find(parentCode.childJobCodes, (childCode: IJobCode) => childCode.isPrimaryMeasure);
        if (primaryMeasureJobCode && primaryMeasureJobCode.id !== codeDetails.id) {
            setIsPrimaryMeasureDisabled(true);
            setLocalCodeDetails({...codeDetails, parentJobCodeId: value, isPrimaryMeasure: false})
        } else {
            setIsPrimaryMeasureDisabled(false)
        }
    }

    const handleFormChange = (value: any, fieldName: string) => {
        setLocalCodeDetails({ ...localCodeDetails, [fieldName]: value });
        const temp = {...errors};
        temp[fieldName] && delete temp[fieldName];
        setErrors(temp);
    }

    const handleCheckboxChange = (e: ChangeEvent<{ value: any, checked: boolean }>, fieldName: string) => {
        setLocalCodeDetails({ ...localCodeDetails, [fieldName]: e.target.checked });
        const temp = {...errors};
        if (fieldName === 'isPrimaryCustomerCode' || fieldName === "isCrewSubCode" ) {
            delete temp['codeType'];
        }
        setErrors(temp);
    }

    const formValidation = (values: any) => {
        const tempErrors: { [key: string] : string } = {...errors};
        if (!values.code) {
            tempErrors.code = 'Required';
        }
        if (!values.description) {
            tempErrors.description = 'Required';
        }
        if (!values.contractName) {
            tempErrors.contractName = 'Required';
        }
        if (!values.areaIds?.length) {
            tempErrors.areaIds = 'Required';
        }
        if (!values.effectiveOn) {
            tempErrors.effectiveOn = 'Required';
        }
        if (!values.expiresOn) {
            tempErrors.expiresOn = 'Required';
        }
        if (!values.jobCodeCategoryIds.length) {
            tempErrors.jobCodeCategoryIds = 'Required';
        }
        if (!values.isPrimaryCustomerCode && !values.isCrewSubCode) {
            tempErrors.codeType = 'Pick at least one';
        }

        if (Object.keys(tempErrors)?.length) {
            setErrors(tempErrors);
        } else {
            handleSave(values);
        }
    }

    return <>
        <div className="code-details-wrapper">
            <div className="flex-row space-between">
                <div className="button-wrapper">
                    <BighamButton
                        label="Back"
                        type="default"
                        variant="contained"
                        onClick={handleBack}
                    />
                </div>
                <div className="button-wrapper">
                    <BighamButton
                        label="Save Code"
                        type="primary"
                        variant="contained"
                        onClick={handleSaveClick}
                    />
                </div>
            </div>
            <div className="form">
                <div className="form-row">
                    <BighamMultiSelect 
                        fieldName="areaIds"
                        selectedList={localCodeDetails.areaIds as number[]}
                        listItems={areaList}
                        label="Area"
                        onChange={handleFormChange}
                        error={errors.areaIds}
                    />
                    <BighamSelect
                        fieldName="parentJobCodeId"
                        value={localCodeDetails.parentJobCodeId || 0}
                        listItems={parentCodeListLookup}
                        label="Primary Customer Code"
                        onChange={handleParentJobCodeChange}
                        initialOption={<MenuItem value={0}>Select One</MenuItem>}
                        disabledId={+codeId}
                        readOnly={localCodeDetails.isPrimaryCustomerCode}
                    />
                </div>
                <div className="form-row">
                    <BighamTextInput
                        fieldName="code"
                        value={localCodeDetails.code}
                        label="Job Code"
                        onChange={handleFormChange}
                        maxLength={12}
                        error={errors.code}
                    />
                    <BighamTextInput
                        fieldName="description"
                        value={localCodeDetails.description}
                        label="Description"
                        onChange={handleFormChange}
                        error={errors.description}
                    />
                </div>
                <div className="form-row">
                    <BighamSelect 
                        fieldName="unitCtId"
                        value={localCodeDetails.unitCtId || 0}
                        listItems={unitCountList}
                        label="Unit/Count"
                        onChange={handleFormChange}
                    />
                    <BighamTextAutoComplete
                        fieldName="contractName"
                        listItems={contractNamesList}
                        value={localCodeDetails.contractName}
                        label="Contract Name"
                        onChange={handleFormChange}
                        error={errors.contractName}
                        freeSolo={true}
                    />
                </div>
                <div className="form-row">
                    <BighamNumberInput
                        fieldName="contractPrice"
                        value={localCodeDetails.contractPrice}
                        label="Contract Price"
                        decimalPlaces={2}
                        symbol={'$'}
                        step={'0.01'}
                        onChange={handleFormChange}
                    />
                    <BighamNumberInput
                        fieldName="inHouseRate"
                        value={localCodeDetails.inHouseRate}
                        label="In-House Rate"
                        decimalPlaces={2}
                        symbol={'$'}
                        step={'0.01'}
                        onChange={handleFormChange}
                    />
                    <BighamNumberInput
                        fieldName="subContractorRate"
                        value={localCodeDetails.subContractorRate}
                        label="Subcontractor Rate"
                        decimalPlaces={2}
                        symbol={'$'}
                        step={'0.01'}
                        onChange={handleFormChange}
                    />
                </div>
                <div className="form-row">
                    <BighamDatePicker
                        fieldName="effectiveOn"
                        value={localCodeDetails.effectiveOn || ''}
                        label="Effective On"
                        onChange={handleFormChange}
                        error={errors.effectiveOn}
                    />
                    <BighamDatePicker
                        fieldName="expiresOn"
                        value={localCodeDetails.expiresOn || ''}
                        label="Expires On"
                        onChange={handleFormChange}
                        error={errors.expiresOn}
                    />
                </div>
                <div className="form-row">
                    <BighamMultiSelect 
                        fieldName="jobCodeCategoryIds"
                        selectedList={localCodeDetails.jobCodeCategoryIds}
                        listItems={codeCategories}
                        label="Category"
                        onChange={handleFormChange}
                        error={errors.jobCodeCategoryIds}
                    />
                    <BighamTextInput
                        fieldName="comments"
                        value={localCodeDetails.comments}
                        label="Comments"
                        onChange={handleFormChange}
                    />
                </div>
                <div className="form-row center">
                    <FormControl
                        className="form-control"
                        component="fieldset"
                        variant="standard"
                        error={!!errors.codeType}
                    >
                        <BighamCheckbox
                            fieldName="isPrimaryCustomerCode"
                            value='isPrimaryCustomerCode'
                            checked={localCodeDetails.isPrimaryCustomerCode}
                            label="Primary Customer Code"
                            onChange={handleCheckboxChange}
                            className="checkbox-controls"
                            disabled={!!localCodeDetails.parentJobCodeId}
                        />
                        <BighamCheckbox
                            fieldName="isCrewSubCode"
                            value='isCrewSubCode'
                            checked={localCodeDetails.isCrewSubCode}
                            label="Crew Sub Code"
                            onChange={handleCheckboxChange}
                            className="checkbox-controls"
                        />
                        {errors.codeType && <FormHelperText>{errors.codeType}</FormHelperText>}
                    </FormControl>
                    <FormControl
                        className="form-control"
                        component="fieldset"
                        variant="standard"
                    >
                        <BighamCheckbox
                            fieldName="isPrimaryMeasure"
                            value='isPrimaryMeasure'
                            checked={localCodeDetails.isPrimaryMeasure}
                            label="Primary Measure"
                            onChange={handleCheckboxChange}
                            className="checkbox-controls"
                            disabled={isPrimaryMeasureDisabled}
                        />
                        {isPrimaryMeasureDisabled && <FormHelperText>Only one sibling can be the primary measure.</FormHelperText>}
                    </FormControl>
                </div>
            </div>
        </div>
    </>;
}

export default CodeDetails;