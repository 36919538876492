


import { AgGridReact } from 'ag-grid-react';
import { ColDef, FirstDataRenderedEvent } from 'ag-grid-community';
import { FC, ForwardedRef, useState } from 'react';
import { forwardRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../main/hooks/hooks';
import { selectDownloadRowData, setDownloadRowData } from '../../../main/slices/utilSlice';

interface GridCsvDownloadProps {
    colDef: ColDef[],
    fileName?: string,
    ref?: ForwardedRef<AgGridReact | null>;
}

const GridCsvDownload: FC<GridCsvDownloadProps> = forwardRef<AgGridReact, GridCsvDownloadProps>(({ colDef, fileName = '' }, ref) => {
    const dispatch = useAppDispatch();

    const {
        rowData
    } = useAppSelector((state) => ({
        rowData: selectDownloadRowData(state)
    }))

    return <div className="grid-wrapper ag-theme-material" style={{ height: 0 }}>
        {!!rowData?.length && <AgGridReact
            ref={ref}
            className={'job-tracker-grid'}
            rowData={rowData}
            columnDefs={colDef}
            rowModelType={'clientSide'}
            onFirstDataRendered={(params: FirstDataRenderedEvent) => {
                setTimeout(() => {
                    params.api.exportDataAsCsv({ fileName, allColumns: false });
                    dispatch(setDownloadRowData([]));
                })
            }}
        />}
    </div>
})

export default GridCsvDownload;