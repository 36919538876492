import { AgGridReact } from 'ag-grid-react';
import React, { ColDef, EditableCallbackParams, GridReadyEvent, GridSizeChangedEvent, RowClassParams, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from 'ag-grid-community';
import { FC, ForwardedRef, useEffect, useRef, useState } from 'react';
import MultiSelectFilter from '../../../../library/components/GridComponents/GridMultiSelectFilter';
import { forwardRef } from 'react';
import ValueRenderer from '../../../../library/components/GridComponents/ValueRenderer';
import { StringColumnDef, NumberColumnDef, moneyFormatter } from '../../../JobTracker/trackerColumnFactory';
import { formatNumberWithCommas } from '../../../../library/util/numberFormatter';
import { IInvoiceSummary } from '../../../../main/types/jobDetailTypes';
import ManualOverrideEditor from '../../../../library/components/GridComponents/ManualOverrideEditor';
import { IProductionReportsForInvoicingPermissions } from '../../../../main/types/permissionTypes';
import variables from '../../../../assets/styles/variables.module.scss';


interface InvoicingSummaryGridProps {
    listItems: IInvoiceSummary[];
    handleCellEdit(params: ValueSetterParams): boolean;
    ref: ForwardedRef<AgGridReact>;
    permissions?: IProductionReportsForInvoicingPermissions;
}

const InvoicingSummaryGrid: FC<InvoicingSummaryGridProps> = forwardRef<AgGridReact, InvoicingSummaryGridProps>(({listItems, handleCellEdit, permissions}, ref) => {

    const numberFormatterOverride = (params: ValueFormatterParams) => {
        return params.value;
    }
    const gridHeight = '400px';
    const gridWrapperRef = useRef<HTMLDivElement | null>(null);

    const columnDefs: ColDef[] = [
        StringColumnDef('jobCode', 'Job Code', true),
        StringColumnDef('description', 'Description', false),
        {
            ...NumberColumnDef('ntpQuantity', 'NTP Quantity', false),
            valueGetter: (params: ValueGetterParams) => {
                if (params.data.isPrimaryCustomerCode && params.data.ntpQuantity) {
                    return formatNumberWithCommas(params.data.ntpQuantity);
                } else {
                    return '';
                }
            },
            valueFormatter: numberFormatterOverride
        },
        {
            ...NumberColumnDef('productionQuantity', 'Production Quantity', false),
            valueGetter: (params: ValueGetterParams) => {
                if (params.data.isPrimaryCustomerCode && !params.data.isCrewSubCode) {
                    return '';
                } else {
                    return formatNumberWithCommas(params.data.productionQuantity);
                }
            },
            valueFormatter: numberFormatterOverride
        },
        {
            ...NumberColumnDef('invoicedToDate', 'Invoiced to Date', false),
            valueGetter: (params: ValueGetterParams) => {
                if (params.data.isPrimaryCustomerCode && !params.data.isCrewSubCode) {
                    return '';
                } else {
                    return formatNumberWithCommas(params.data.invoicedToDate);
                }
            },
            valueFormatter: numberFormatterOverride
        },
        {
            ...NumberColumnDef('newInvoiceQuantity', 'New Invoice Quantity', false),
            valueGetter: (params: ValueGetterParams) => {
                return formatNumberWithCommas(params.data.newInvoiceQuantity);
            },
            valueFormatter: numberFormatterOverride
        },
        {
            ...NumberColumnDef('manualOverride', 'Manual Override', false),
            editable: (params: EditableCallbackParams) => {
                return !!permissions?.canUseManualOverride && params.data.isPrimaryCustomerCode &&  params.data.newInvoiceQuantity > 0;
            },
            cellEditor: ManualOverrideEditor,
            valueSetter: handleCellEdit,
            valueGetter: (params: ValueGetterParams) => {
                if (params.data.isPrimaryCustomerCode) {
                    return formatNumberWithCommas(params.data.manualOverride);
                } else {
                    return '';
                }
            },
            valueFormatter: numberFormatterOverride
        },
        {
            ...NumberColumnDef('remainingQuantity', 'Remaining Quantity', false),
            valueGetter: (params: ValueGetterParams) => {
                if (params.data.isPrimaryCustomerCode) {
                    return formatNumberWithCommas(params.data.remainingQuantity);
                } else {
                    return '';
                }
            },
            valueFormatter: numberFormatterOverride
        },
        {
            ...NumberColumnDef('unitPrice', 'Unit Price', false),
            valueGetter: (params: ValueGetterParams) => {
                if (params.data.isPrimaryCustomerCode) {
                    return moneyFormatter('unitPrice')(params);
                } else {
                    return '';
                }
            },
            valueFormatter: numberFormatterOverride
        },
        {
            ...NumberColumnDef('ntpTotal', 'NTP Total', false),
            valueGetter: (params: ValueGetterParams) => {
                if (params.data.isPrimaryCustomerCode) {
                    return moneyFormatter('ntpTotal')(params);
                } else {
                    return '';
                }
            },
            valueFormatter: numberFormatterOverride
        },
        {
            ...NumberColumnDef('newInvoiceTotal', 'New Invoice Total', false),
            valueGetter: (params: ValueGetterParams) => {
                if (params.data.isPrimaryCustomerCode) {
                    return moneyFormatter('newInvoiceTotal')(params);
                } else {
                    return '';
                }
            },
            valueFormatter: numberFormatterOverride
        },
    ];

    return <div ref={gridWrapperRef} className="grid-wrapper ag-theme-material" style={{ height: gridHeight }}>
       {!!listItems?.length && <AgGridReact
            ref={ref}
            components={{
                multiSelect: MultiSelectFilter
            }}
            // domLayout="autoHeight"
            defaultColDef={
                {
                    resizable: true,
                    cellRenderer: ValueRenderer,
                    sortable: true,
                    menuTabs: ['filterMenuTab'],
                }
            }
            getRowClass={(params: RowClassParams): string => {
                if (params.data.isNonNtpJobCode) {
                    return 'non-ntp-job-code';
                }
                return ''
            }}
            columnDefs={columnDefs}
            rowData={listItems}
            onGridReady={(event: GridReadyEvent) => {
                event.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(event: GridSizeChangedEvent) => {
                event.api.sizeColumnsToFit();
            }}
        />}
    </div>
})

export default InvoicingSummaryGrid;
