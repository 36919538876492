import React, { FC, useState } from "react"
import ModalBase from "../../../../library/components/ModalBase/ModalBase"
import BighamTextInput from "../../../../library/components/FormControls/BighamTextInput/BighamTextInput";
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import BighamDatePicker from "../../../../library/components/FormControls/BighamDatePicker/BighamDatePicker";
import { IJobLocates } from "../../../../main/types/jobDetailTypes";
import { IJobLocatesPermissions } from "../../../../main/types/permissionTypes";
import { isNil } from "lodash";
import { useEffect } from "react";
import { isDateValid } from "../../../../library/util/validators";

interface LocatesModalProps {
    onCancel(): void,
    handleUpdateLocates(locate: IJobLocates, isDuplicated?: boolean): void,
    data: any,
    list: IJobLocates[],
    permissions: IJobLocatesPermissions
}

const LocatesModal: FC<LocatesModalProps> = ({onCancel, handleUpdateLocates, data, list, permissions}) => {

    const [tempLocate, setTempLocate] = useState({...data});
    const [errors, setErrors] = useState<{ [fieldName: string]: string }>({});

    const handleFormChange = (value: any, fieldName: string) => {
        setTempLocate({...tempLocate, [fieldName]: value});
        const temp = {...errors};
        delete temp[fieldName];
        setErrors(temp);
    }

    const validateLocates = (values: IJobLocates, isDuplicate?: boolean) => {
        const tempErrors: any = {};
        const duplicateCode = list.find((value) => value.locateNumber === values.locateNumber);
        if (duplicateCode && values.index !== duplicateCode.index) {
            tempErrors['locateNumber'] = 'Must be a unique locate number';
        }
        if (tempLocate.locateDigDate && !isDateValid(tempLocate.locateDigDate)) {
            tempErrors.locateDigDate = 'Invalid Date (use format MM/DD/YYYY)'
        }
        if (tempLocate.locateUpdate && !isDateValid(tempLocate.locateUpdate)) {
            tempErrors.locateUpdate = 'Invalid Date (use format MM/DD/YYYY)'
        }
        if (Object.keys(tempErrors)?.length) {
            setErrors(tempErrors)
        } else {
            handleUpdateLocates(tempLocate, isDuplicate);
            if (!isDuplicate) {
                onCancel();
            } else {
                setTempLocate({...tempLocate, index: undefined, id: 0})
            }
        }
    }

    const handleSaveLocate = () => {
        validateLocates(tempLocate);
    }

    const handleSaveAndDuplicate = () => {
        validateLocates(tempLocate, true);
    }

    return <ModalBase onCancel={onCancel}>
        <h2 className="modal-title">{`${isNil(data?.index) ? 'Add' : 'Update'} Locate`}</h2>
        <div className={`form`}>
            <div className="form-row">
                <BighamTextInput
                    fieldName="locateNumber"
                    value={tempLocate?.locateNumber || ''}
                    label="Locate Number"
                    onChange={handleFormChange}
                    readOnly={!permissions.canEditLocateNumbers}
                    error={errors?.locateNumber}
                />
            </div>
            <div className="form-row">
                <BighamDatePicker 
                    fieldName={`locateDigDate`}
                    value={tempLocate?.locateDigDate || ''}
                    label="Dig Date"
                    onChange={handleFormChange}
                    readOnly={!permissions.canEditLocateDigDate}
                    error={errors.locateDigDate}
                />
                <BighamDatePicker 
                    fieldName={`locateUpdate`}
                    value={tempLocate?.locateUpdate || ''}
                    label="Update Date"
                    onChange={handleFormChange}
                    readOnly={!permissions.canEditLocateUpdate}
                    error={errors.locateUpdate}
                />
            </div>
        </div>
        <div className="modal-button-container">
            <BighamButton
                type="default"
                variant="contained"
                onClick={onCancel}
                label={'Cancel'}
            />
            <BighamButton
                type="primary" 
                variant="contained"
                label={`${isNil(data?.index) ? 'Add' : 'Update'}`}
                onClick={handleSaveLocate}
                disabled={!!Object.keys(errors)?.length}
            />
            <BighamButton
                type="primary" 
                variant="contained"
                label={`${isNil(data?.index) ? 'Add' : 'Update'} and Duplicate`}
                onClick={handleSaveAndDuplicate}
                disabled={!!Object.keys(errors)?.length}
            />
        </div>
    </ModalBase>
}

export default LocatesModal;