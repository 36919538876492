const ROUTES = {
  TRACKER: '/',
  JOB_DETAILS: '/jobs',
  DAILY_PROD_REPORTS: '/production-reports',
  WEEKLY_PROD_REPORTS: '/payroll',
  MY_PROD_REPORTS: '/my-reports',
  QC_REPORTS: '/qc-reports',
  REQUESTED_INVOICES: '/invoices',
  MAP: '/map',
  USERS: '/users',
  CODES: '/codes',
  TEST: '/test',
  PING: '/ping'
};

export default ROUTES;
