import React, { FC } from 'react';
import './ModalBase.scss';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface ModalBaseProps {
    children: JSX.Element | JSX.Element[],
    size?: 'small' | 'medium' | 'large',
    onCancel(): void
}

const ModalBase: FC<ModalBaseProps> = ({children, size = "medium", onCancel}) => {

    return <>
        <div className="modal-background-noclick">
            <div className={`modal-wrapper ${size}`} onClick={(e) => e.stopPropagation()}>
                {onCancel && <span className="close-icon" onClick={onCancel}><CloseOutlinedIcon /></span>}
                {children}
            </div>
        </div>
    </>
}

export default ModalBase;