import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FC, ChangeEvent } from "react";
import { ILookupItem } from "../../../../main/types/genericTypes";
import { IAerialTeam } from "../../../../main/types/jobDetailTypes";
import { IAerialPermissions } from "../../../../main/types/permissionTypes";
import { IJobTeamErrors } from "../../JobDetails";
import FootageCard from "../../../../library/components/FootageCard/FootageCard";
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import BighamDatePicker from "../../../../library/components/FormControls/BighamDatePicker/BighamDatePicker";
import BighamNumberInput from "../../../../library/components/FormControls/BighamNumberInput/BighamNumberInput";

interface AerialSectionProps {
    openSection: string,
    handleAccordionAction(panel: string): (event: ChangeEvent<any>, isExpanded: boolean) => void,
    handleAddNewCard(addData: any, fieldName: string): () => void,
    handleFormChange(value: any, fieldName: string): void,
    jobDetailModel: any,
    foremanList: ILookupItem[],
    permissions: IAerialPermissions,
    setErrors(errors: IJobTeamErrors): void,
    errors: IJobTeamErrors
}

const AerialSection: FC<AerialSectionProps> = ({openSection, handleAccordionAction, jobDetailModel, handleFormChange, handleAddNewCard, foremanList, permissions, errors, setErrors}) => {

    const sectionId = 'aerial';

    const canAddNewCard = ():boolean => {
        return permissions.canEditForeman
            || permissions.canEditIssueDate
            || permissions.canEditCompleteDate 
            || permissions.canEditIsCritical
            || permissions.canEditComments
    }
    
    const handleLocalChange = (value: any, fieldName: string) => {
        const temp = {...errors};
        delete errors?.[sectionId]?.completeDate;
        setErrors(temp);
        handleFormChange(value, fieldName);
    }

    return <Accordion className="styled-accordion" expanded={openSection === sectionId} id={sectionId} onChange={handleAccordionAction(sectionId)}>
        <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
        >
            <div className="flex-header">
                <div className="component-title">Aerial</div>
            </div>
        </AccordionSummary>
        <AccordionDetails
            style={{flexFlow: 'column'}}
        >
            <div className="form">
                <div className="form-row">
                    <BighamNumberInput
                        label="Total Estimated Footage"
                        fieldName="aerial.totalFootage"
                        value={jobDetailModel.aerial.totalFootage}
                        onChange={handleFormChange}
                        readOnly={!permissions.canEditTotalEstimatedFootage}
                    /> 
                    <BighamNumberInput
                        label="Total Completed Footage"
                        fieldName="aerial.totalFootageCompleted"
                        value={jobDetailModel.aerial.totalFootageCompleted}
                        onChange={handleFormChange}
                        readOnly={!permissions.canEditTotalFootageCompleted}
                        
                    />
                    <BighamDatePicker 
                        label="Complete Date"
                        fieldName="aerial.completeDate"
                        value={jobDetailModel.aerial.completeDate}
                        onChange={handleLocalChange}
                        readOnly={!permissions.canEditCompleteDate}
                        error={errors?.aerial?.completeDate}
                    />
                </div>
            </div>
            <div className="card-container">
                {!!jobDetailModel?.aerial?.teams?.length && jobDetailModel.aerial?.teams?.map((aerialRecord: IAerialTeam, index: number, collection: IAerialTeam[]) => 
                    <FootageCard key={index} index={index} data={aerialRecord} list={collection} foremanList={foremanList} handleFormChange={handleFormChange} group="aerial" permissions={permissions} errors={errors} setErrors={setErrors} />
                )}
            </div>
            {canAddNewCard() && <div className="flex-row center">
                <BighamButton
                    label={'Add New'}
                    type="primary"
                    variant="contained"
                    onClick={handleAddNewCard({ footageCompleted: 0, issueDate: '', completeDate: '', foremanId: 0, isCritical: false }, 'aerial.teams')}
                />
            </div>}
        </AccordionDetails>
    </Accordion>
}

export default AerialSection;