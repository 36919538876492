import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ChangeEvent, FC, useState } from "react";
import { ILookupItem } from "../../../../main/types/genericTypes";
import { IPermitRecord } from "../../../../main/types/jobDetailTypes";
import { IJobPermitsPermissions } from "../../../../main/types/permissionTypes";
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import BighamDatePicker from "../../../../library/components/FormControls/BighamDatePicker/BighamDatePicker";
import PermitCard from "./PermitCard/PermitCard";
import PermitEntityModal from "./PermitEntityModal";
import { callCreateNewPermitEntity } from "../../../../main/slices/jobDetailSlice";
import { useAppDispatch } from "../../../../main/hooks/hooks";
import { isNull } from "lodash";
import { IJobPermitErrors } from "../../JobDetails";

interface PermitSectionProps {
    openSection: string,
    handleAccordionAction(panel: string): (event: ChangeEvent<any>, isExpanded: boolean) => void,
    handleAddNewCard(addData: any, fieldName: string): () => void,
    handleFormChange(value: any, fieldName: string): void,
    jobDetailModel: any,
    lookups: { [key: string]: ILookupItem[] },
    permissions: IJobPermitsPermissions,
    errors: IJobPermitErrors;
    setErrors(errors: IJobPermitErrors): void
}

const PermitSection: FC<PermitSectionProps> = ({openSection, handleAccordionAction, handleAddNewCard, handleFormChange, jobDetailModel, lookups, permissions, errors, setErrors}) => {
    const sectionId = 'permits';
    const dispatch = useAppDispatch();
    const [showEntityModal, setShowEntityModal] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

    const canAddNewCard = ():boolean => {
        return permissions.canEditPermitEntity && permissions.canEditPermitType;
    }

    const handleCloseModal = () => {
        setShowEntityModal(false);
        setFocusedIndex(null);
    }

    const handleShowModal = (index: number) => {
        setFocusedIndex(index);
        setShowEntityModal(true);
    }

    const handleAddEntity = (name: string, index: number | null) => {
        if (!isNull(index)) {
            const list = jobDetailModel.permits
            dispatch(callCreateNewPermitEntity({ name, dataHandler: handleLocalChange(list[index], list, index) }));
        }
    }

    const handleLocalChange = (data: any, list: any, index: number) => (value: any, fieldName: string) => {
        const updatedValue = {...data, [fieldName]: value};
        const updatedList = [...list];
        updatedList.splice(index, 1, updatedValue);
        handleFormChange(updatedList, 'permits');
        const temp = {...errors};
        delete temp?.[index]?.[fieldName];
        setErrors(temp);
    }

    const handleRemoveTeam = (list: any[], index: number) => () => {
        const updatedList = [...list];
        updatedList.splice(index, 1);
        handleFormChange(updatedList, `permits`);
    }

    const handleEntityChange = (index: number) => (key: string) => (value: any, fieldName: string) => {
        if (!isNull(index)) {
            if (value === key) {
                handleShowModal(index);
            } else {
                handleLocalChange(jobDetailModel.permits[index], jobDetailModel.permits, index)(value, fieldName);
                return;
            }
        }
    }

    return <Accordion className="styled-accordion" expanded={openSection === sectionId} id={sectionId} onChange={handleAccordionAction(sectionId)}>
        <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
        >
            <div className="flex-header">
                <div className="component-title">Permits</div>
            </div>
        </AccordionSummary>
        <AccordionDetails
            style={{flexFlow: 'column'}}
        >
            {showEntityModal && <PermitEntityModal onCancel={handleCloseModal} handleSave={handleAddEntity} index={focusedIndex} />}
            <div className="card-container">
                {!!jobDetailModel?.permits?.length && jobDetailModel.permits?.map((permitsRecord: IPermitRecord, index: number, collection: IPermitRecord[]) => 
                    <PermitCard
                        key={index}
                        index={index}
                        data={permitsRecord}
                        list={collection}
                        handleLocalChange={handleLocalChange(permitsRecord, collection, index)}
                        handleRemoveTeam={handleRemoveTeam(collection, index)}
                        handleEntityChange={handleEntityChange(index)}
                        lookups={{
                            permitTypeList: lookups.permitTypeList,
                            permitEntityList: lookups.permitEntityList,
                            permittingAgentList: lookups.permittingAgentList
                        }}
                        permissions={permissions}
                        errors={errors?.[index]}
                        setErrors={setErrors}
                    />
                )}
            </div>
            {canAddNewCard() && <div className="flex-row center">
                <BighamButton
                    label={'Add New'}
                    type="primary"
                    variant="contained"
                    onClick={handleAddNewCard({ permitTypeId: 0, engineeringCompany: '', dateSentToEngineer: '', permitEntityId: 0, permittingAgentIds: [], comments: '', dateSentToUtilityOrDot: '', readyForConstructionDate: ''}, 'permits')}
                />
            </div>}
        </AccordionDetails>
    </Accordion>
}

export default PermitSection;