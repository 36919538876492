import { AgGridReact } from 'ag-grid-react';
import { ColumnState, DragStoppedEvent, FilterChangedEvent, FirstDataRenderedEvent, GetRowIdParams, GridReadyEvent, IServerSideDatasource, PaginationChangedEvent, RowClassParams, RowDoubleClickedEvent, SortChangedEvent} from 'ag-grid-community';
import { FC, ForwardedRef, useState } from 'react';
import { forwardRef } from 'react';
import ROUTES from '../../library/constants/routeConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import ValueRenderer from '../../library/components/GridComponents/ValueRenderer';
import { useAppDispatch, useAppSelector } from '../../main/hooks/hooks';
import { callUpdateUserPreferences, selectColumnOrder, selectFilterModel } from '../../main/slices/preferencesSlice';
import { isEqual } from 'lodash';
import { useRef, useEffect } from 'react';
import variables from '../../assets/styles/variables.module.scss';
import { SESSION_KEYS } from '../../library/constants/sessionStorageKeys';


interface JobTrackerGridProps {
    ref: ForwardedRef<AgGridReact>,
    areaId: number,
    avoidServerCall: boolean;
    setAvoidServerCall(value: boolean): void;
    dataSource(areaId: number): IServerSideDatasource,
}

const JobTrackerGrid: FC<JobTrackerGridProps> = forwardRef<AgGridReact, JobTrackerGridProps>(({ dataSource, avoidServerCall, setAvoidServerCall, areaId }, ref) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [gridHeight, setGridHeight] = useState('auto');
    const [onInit, setOnInit] = useState(false);
    const {
        columnOrder,
        filterModel
    } = useAppSelector((state) => ({
        columnOrder: selectColumnOrder(state),
        filterModel: selectFilterModel(state)
    }));
    const gridWrapperRef = useRef<HTMLDivElement | null>(null);
    const handleDoubleClick = (params: RowDoubleClickedEvent) => {
        navigate(`${ROUTES.JOB_DETAILS}/${params.data.id}`, { state: { location } });
    }

    const handleUpdatePreferences = async (params: DragStoppedEvent | FilterChangedEvent | SortChangedEvent) => {
        const currentModel = params.api.getFilterModel();
        const columns = params.columnApi.getColumnState();
        const savePayload = {
            columnOrder: columns.map((columnValue: ColumnState) => {
                return { name: columnValue.colId, width: columnValue.width, sort: columnValue.sort, sortIndex: columnValue.sortIndex, pinned: columnValue.pinned };
            }),
            filterModel: currentModel
        };
        if (!isEqual(savePayload, { columnOrder, filterModel }) && !onInit) {
            await dispatch(callUpdateUserPreferences(JSON.stringify(savePayload)));
        } else {
            setOnInit(false);
        }
    }

    const handleSetGridHeight = () => {
        if (gridWrapperRef?.current) {
            const offset = gridWrapperRef.current.getBoundingClientRect().top;
            setGridHeight(`calc(100vh - (${offset}px + ${variables.spacing_standard}))`);
        } else {
            setGridHeight('auto');
        }
    }

    useEffect(() => {
        if (gridWrapperRef.current) {
            handleSetGridHeight();
            window.addEventListener('resize', handleSetGridHeight);
        }
        return () => {
            window.removeEventListener('resize', handleSetGridHeight);
        }
    }, [gridWrapperRef.current]);

    return <div ref={gridWrapperRef} className="grid-wrapper ag-theme-material" style={{ height: gridHeight }}>
        <AgGridReact
            ref={ref}
            className={'job-tracker-grid'}
            detailRowAutoHeight={true}
            onRowDoubleClicked={handleDoubleClick}
            defaultColDef={
                {
                    resizable: true,
                    cellRenderer: ValueRenderer,
                    wrapText: true,
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    menuTabs: ['filterMenuTab'],
                }
            }
            onDragStopped={handleUpdatePreferences}
            suppressDragLeaveHidesColumns={true}
            suppressContextMenu={true}
            paginationPageSize={100}
            cacheBlockSize={100}
            blockLoadDebounceMillis={100}
            serverSideFilterOnServer={true}
            serverSideSortOnServer={true}
            pagination={true}
            rowModelType={'serverSide'}
            onPaginationChanged={(params: PaginationChangedEvent) => {
                if (params.newPage && !avoidServerCall) {
                    sessionStorage.setItem(SESSION_KEYS.DASHBOARD_PAGE, (params.api.paginationGetCurrentPage() || 0).toString());
                }
            }}
            onFilterChanged={handleUpdatePreferences}
            onSortChanged={handleUpdatePreferences}
            onGridReady={(params: GridReadyEvent) => {
                params.api.setServerSideDatasource(dataSource(areaId));
            }}
            getRowId={(params: GetRowIdParams) => {
                return params.data.id;
            }}
            getRowClass={(params: RowClassParams) => {
                const rowData = params.data?.['status'] || '';
                if (rowData.length) {
                    return `status ${rowData[0].name.toLowerCase().split(' ').join('-')}`
                } else {
                    return '';
                }
            }}
            onFirstDataRendered={(params: FirstDataRenderedEvent) => {
                setTimeout(() => {
                    setAvoidServerCall(false);
                    // params.api.refreshServerSide();
                })
            }}
        />
    </div>
})

export default JobTrackerGrid;