import React, { useState, useRef, FC } from 'react';
import { map, indexOf, each } from 'lodash';
import './DocumentUploadModal.scss';
import ModalBase from '../ModalBase/ModalBase';
import BighamButton from '../FormControls/BighamButton/BighamButton';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { setToastMessage } from '../../../main/slices/utilSlice';
import { useAppDispatch, useAppSelector } from '../../../main/hooks/hooks';
import BighamSelect from '../FormControls/BighamSelect/BighamSelect';
import { useEffect } from 'react';
import { callGetFileTypeList, selectFileTypeList } from '../../../main/slices/lookupSlice';
import { callUploadFiles, callValidateFileNames } from '../../../main/slices/jobDetailSlice';
import FilenameConfirmationModal from './FilenameConfirmationModal';


interface DocumentUploadProps {
    onCancel(): void,
    jobId: string,
    jobDetailModel: any,
    setJobDetailModel: any
}

const uploadSizeMax = {
    label: '100MB',
    bytes: 100000000
};

const DocumentUploadModal: FC<DocumentUploadProps> = ({onCancel, jobId, jobDetailModel, setJobDetailModel}) => {
    const dispatch = useAppDispatch();
    const fileUpload = useRef<HTMLInputElement>(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [uploadData, setUploadData] = useState<any[]>([]);
    const [errors, setErrors] = useState<{ [fileName: string]: boolean }>({})
    const [fileType, setFileType] = useState<any>(0)
    const [uploadSize, setUploadSize] = useState(0);

    const {
        fileTypeList
    } = useAppSelector((state) => ({
        fileTypeList: selectFileTypeList(state)
    }));


    useEffect(() => {
        if (!fileTypeList?.length) {
            dispatch(callGetFileTypeList());
        }
    }, []);

    const onDragOver = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const onDragEnter = (e: any) => {
        e.stopPropagation();
    }
    
    const handleDropFileUpload = (e: any) => {
        e.preventDefault();
        if (e.dataTransfer?.files?.length > 0) {
            handleSetUploadData(e.dataTransfer.files);
        }
    }

    const handleFileUploadClick = (e: any) => {
        e.stopPropagation();
        fileUpload?.current?.click();
    }

    const handleFileUpload = (e: any) => {
        if (e?.target?.files?.length > 0) {
            handleSetUploadData(e.target.files);
        }
    }

    const handleSetUploadData = (data: any) => {
        const temp: any[] = [...uploadData];
        let tempUploadSize = uploadSize;
        const uploadArray: {size: number, name: string}[] = Array.from(data);
        if (tempUploadSize < uploadSizeMax.bytes) {
            const uploadLoop = new Promise((resolve: any, reject) => {
                uploadArray.forEach((file, index, array) => {
                    if (tempUploadSize < uploadSizeMax.bytes) {
                        tempUploadSize += file.size;
                        const nameMap = map(temp, (fileItem: any) => {
                            return fileItem.name;
                        });
                        const duplicateNameIndex = indexOf(nameMap, file.name);
                        if (tempUploadSize > uploadSizeMax.bytes) {
                            dispatch(setToastMessage({
                                type: 'warning',
                                message: 'You cannot upload more than 100MB at a time. All items over the limit have been ignored.'
                            }))
                        } else if (duplicateNameIndex >= 0) {
                            dispatch(setToastMessage({
                                type: 'warning',
                                message: "Duplicate file names are not allowed, one or more files have been ignored."
                            }));
                        } else {
                            isThisAFile(file)
                            .then((validFile: any) => {
                                temp.push(validFile);
                            })
                            .catch((message) => {
                                dispatch(setToastMessage({
                                    type: 'warning',
                                    message
                                }))
                            })
                            .finally(() => {
                                if (index === array.length - 1) {
                                    setTimeout(() => {
                                        resolve();
                                    }, 150);
                                }
                            })
                        }
                    }
                })
            });
            uploadLoop.then(() => {
                setUploadData(temp);
                setUploadSize(tempUploadSize);
            })
        } else {
            dispatch(setToastMessage({
                type: 'warning',
                message: 'You cannot upload more than 100MB at a time. All items over the limit have been ignored.'
            }))
        }
    }

    const isThisAFile = async (maybeFile: any) => {
        return new Promise(function (resolve, reject) {
          const reader = new FileReader()
          reader.onloadend = () => {
            if (reader.error && reader.error.name === 'NotFoundError') {
              return reject("Only files can be uploaded, not folders")
            }
            if (maybeFile.type !== '') {
                return resolve(maybeFile)
            }
            return resolve(maybeFile)
          }
          reader.readAsBinaryString(maybeFile);
        })
      }

    const handleSaveClick = async () => {
        const validatePayload = uploadData.map((file) => file.name);
        const response = await dispatch(callValidateFileNames(validatePayload));
        if (response.payload.documentsAreValid) {
            savePayload();
        } else if (response.payload.existingFilenames?.length) {

            const temp: { [fileName: string]: boolean } = {};
            each(response.payload.existingFilenames, (name: string) => {
                temp[name] = true;
            });
            setErrors(temp);
            setShowConfirmation(true);
        }
    }

    const savePayload = () => {
        const payload = new FormData();
        payload.append('documentCategoryId', fileType);
        uploadData.forEach((file) => {
            payload.append('Files', file);
        });
        dispatch(callUploadFiles({payload, jobId, jobDetailModel, setJobDetailModel}));
        onCancel();
    }

    const handleOverwrite = () => {
        savePayload();
        handleClose();
    }

    const removeFile = (index: any, filename: string) => {
        const temp = [...uploadData];
        const removedElem: any = temp.splice(index, 1);
        setUploadSize(uploadSize - removedElem[0].size);
        setUploadData(temp);
        const tempErrors = {...errors};
        delete tempErrors[filename];
        setErrors(tempErrors);
    }

    const handleClose = () => {
        setShowConfirmation(false);
    }

    return <ModalBase onCancel={onCancel}>
        <>
            {showConfirmation && <FilenameConfirmationModal onCancel={handleClose} handleOverwrite={handleOverwrite} filenames={Object.keys(errors)} />}
        </>
        <h2 className="modal-title">Upload Files</h2>
        <div className="modal-body file-upload">
            <div className="upload-left"
                onClick={handleFileUploadClick}
                onDragEnter={onDragEnter}
                onDragOver={onDragOver}
                onDrop={handleDropFileUpload}
            >
                <FileUploadOutlinedIcon />
                <div>
                    <div className="text"><b>Drag and Drop</b> your file(s) here</div>
                    <div className="info">(Max Upload Size: {uploadSizeMax.label})</div>
                </div>
                <BighamButton
                    variant="contained"
                    type="primary"
                    label="Select Files"
                    onClick={handleFileUploadClick}
                />
                <input ref={fileUpload} type="file" style={{ display: 'none'}} onChange={handleFileUpload} multiple />
            </div>
            <div className="upload-right">
                <div className="form">
                    <div className="form-row">
                        <BighamSelect
                            className="no-margin"
                            label="Document Type"
                            value={fileType}
                            fieldName="documentType"
                            onChange={(value: any) => setFileType(value)}
                            listItems={fileTypeList}
                        />
                    </div>
                </div>
                <div className="header">Files to Upload:</div>
                {uploadData?.length > 0 ? uploadData.map((file: any, index) => {
                    return <div key={index} className={`file-preview ${errors[file.name] && 'error'}`}>
                        <span>{file.name}</span>
                        <span onClick={() => {removeFile(index, file.name)}}><RemoveOutlinedIcon /></span>
                    </div>
                }) : <span className="no-files">No File(s) Selected</span>}
            </div>
        </div>
        <div className="modal-button-container">
            <BighamButton
                type="default"
                variant="contained"
                onClick={onCancel}
                label={'Cancel'}
            />
            <BighamButton
                type="primary" 
                variant="contained"
                label={'Upload'}
                disabled={!uploadData?.length || !fileType}
                onClick={handleSaveClick}
            />
        </div>
    </ModalBase>

}

export default DocumentUploadModal;