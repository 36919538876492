import React, { FC } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { BuildOutlined, PersonOutline, WorkOutlineOutlined } from '@mui/icons-material';

const UserTypeRenderer: FC<ICellRendererParams> = (props: ICellRendererParams) => {
    const userType = props.value;
    let typeIcon;
    switch (userType){
        case 1:
            typeIcon = <WorkOutlineOutlined />
            break;
        case 2:
            typeIcon = <BuildOutlined />
            break;
        case 3:
            typeIcon = <PersonOutline />
            break;
        default:
            typeIcon = <PersonOutline />
            break;
    }
    return <span className="icon-wrapper">
        {typeIcon}
    </span>
};

export default UserTypeRenderer;
