import { FC, useEffect, useState, ChangeEvent } from 'react';
import { SaveOutlined } from '@mui/icons-material';
import './JobDetails.scss'
import { useAppDispatch, useAppSelector } from '../../main/hooks/hooks';
import { callCreateJobDetail, callGetJobDetailData, callUpdateJobDetail, initialJobDetailState, selectJobDetailData, selectNewJobId, setJobDetailData, setNewJobId } from '../../main/slices/jobDetailSlice';
import { callGetAreaList, callGetCategoryList, callGetDailyJobCodeStatusList, callGetDailyStatusList, callGetPermitEntityList, callGetPermitTypeList, callGetSplicerOptions, callGetStateList, callGetStatusList, callGetUserLookupsByArea, selectAerialForemenList, selectAreaList, selectBighamCoordinatorList, selectCategoryList, selectCustomerCoordinatorList, selectDailyJobCodeStatusList, selectDailyStatusList, selectInstallerList, selectJobCodeLookup, selectPermitEntityList, selectPermittingAgentList, selectPermitTypeList, selectSplicerList, selectSplicerOptionList, selectStateList, selectStatusList, selectTechnicalCoordinatorList, selectUndergroundForemenList } from '../../main/slices/lookupSlice';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import JobSummaryDrawer from './JobSummaryDrawer';
import { addFetch, removeFetch, selectSelectedArea, selectUserPermissions, setComponentName, setSelectedArea, setToastMessage } from '../../main/slices/utilSlice';
import { IJobAerial, IJobAerialTeam, IJobDetailCombined, IJobSplicing, IJobUnderGround, IJobUnderGroundTeam, IPermitRecord } from '../../main/types/jobDetailTypes';
import BighamButton from '../../library/components/FormControls/BighamButton/BighamButton';
import ROUTES from '../../library/constants/routeConstants';
import JobDetailsSection from './JobDetailsSections/JobDetailsSection/JobDetailsSection';
import DocumentSection from './JobDetailsSections/DocumentSection/DocumentSection';
import AerialSection from './JobDetailsSections/AerialSection/AerialSection';
import UndergroundSection from './JobDetailsSections/UndergroundSection/UndergroundSection';
import SplicingSection from './JobDetailsSections/SplicingSection/SplicingSection';
import LocatesSection from './JobDetailsSections/LocatesSection/LocatesSection';
import PermitSection from './JobDetailsSections/PermitSection/PermitSection';
import { isEqual } from 'lodash';
import PurchaseOrderSection from './JobDetailsSections/PurchaseOrderSection/PurchaseOrderSection';
import { callGetJobCodeLookup, selectCodeList } from '../../main/slices/codeSlice';
import ProductionReportSection from './JobDetailsSections/ProductionReportSection/ProductionReportSection';
import InvoicingSection from './JobDetailsSections/InvoicingSection/InvoicingSection';
import JobFinancialsSection from './JobDetailsSections/FinancialsSection/FinancialsSection';
import { isDateValid } from '../../library/util/validators';

export interface IJobDetailsErrors { 
    [key: string]: { [key: string] : string }
}

export interface IJobTeamErrors {
    [key: string]: {
        completeDate?: string,
        [index: number]: { [key: string]: string }
    } 
}

export interface IJobPermitErrors {
    [index: number]: { [key: string] : string }
}

const JobDetails: FC = (props) => {
    const google = (window as any).google;
    const geocoder = new google.maps.Geocoder();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { from = { pathname: '/'} } = (useLocation()?.state || {}) as { from?: Location };
    const { jobId = '' } = useParams();
    const preOpenedSection = useSearchParams()[0].get('openSection') || '';

    const {
        jobDetails,
        areaList,
        categoryList,
        statusList,
        bighamCoordinators,
        customerCoordinators,
        technicalCoordinators,
        aerialForeman,
        undergroundForeman,
        splicers,
        splicerOptions,
        installers,
        stateList,
        newJobId,
        permitEntityList,
        permitTypeList,
        permittingAgentList,
        permissions,
        codeLookup,
        codeList,
        dailyStatusList,
        dailyJobCodeStatusList,
        selectedArea
    } = useAppSelector((state) => ({
        jobDetails: selectJobDetailData(state),
        areaList: selectAreaList(state),
        categoryList: selectCategoryList(state),
        statusList: selectStatusList(state),
        bighamCoordinators: selectBighamCoordinatorList(state),
        customerCoordinators: selectCustomerCoordinatorList(state),
        technicalCoordinators: selectTechnicalCoordinatorList(state),
        aerialForeman: selectAerialForemenList(state),
        undergroundForeman: selectUndergroundForemenList(state),
        splicers: selectSplicerList(state),
        splicerOptions: selectSplicerOptionList(state),
        installers: selectInstallerList(state),
        stateList: selectStateList(state),
        newJobId: selectNewJobId(state),
        permitEntityList: selectPermitEntityList(state),
        permitTypeList: selectPermitTypeList(state),
        permittingAgentList: selectPermittingAgentList(state),
        permissions: selectUserPermissions(state),
        codeLookup: selectJobCodeLookup(state),
        codeList: selectCodeList(state),
        dailyStatusList: selectDailyStatusList(state),
        dailyJobCodeStatusList: selectDailyJobCodeStatusList(state),
        selectedArea: selectSelectedArea(state)
    }));

    const [openSection, setOpenSection] = useState<string>(jobId === 'new' ? 'details' : preOpenedSection);
    const [jobDetailModel, setJobDetailModel] = useState<any>(jobDetails);
    const [errors, setErrors] = useState<IJobDetailsErrors>({});
    const [teamErrors, setTeamErrors] = useState<IJobTeamErrors>({});
    const [permitErrors, setPermitErrors] = useState<IJobPermitErrors>({});
    const [drawerOpen, setDrawerOpen] = useState<boolean>(jobId !== 'new');
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [showProductionReportsDrawer, setShowProductionReportsDrawer] = useState(false);
    const drawerWidth = 400;

    useEffect(() => {
        const tempPermissions: any = {...permissions};
        if (tempPermissions) {
            let hasEditPermission = false;
            const permissionGroupKeys = Object.keys(tempPermissions.jobPermissions);
            permissionGroupKeys.forEach((groupKey: string) => {
                if (tempPermissions.jobPermissions[groupKey]) {
                    const hasPermissionInThisGroup = Object.keys(tempPermissions.jobPermissions[groupKey]).some((childKey) => {
                        if (childKey.includes('canEdit')) {
                            return tempPermissions.jobPermissions[groupKey][childKey];
                        } else {
                            return false;
                        }
                    });
                    if (!hasEditPermission && hasPermissionInThisGroup) {
                        hasEditPermission = hasPermissionInThisGroup;
                        setShowSaveButton(hasPermissionInThisGroup)
                    }
                }
            });
            const productionReportsPermissions = Object.keys(tempPermissions.jobPermissions?.jobProductionReportsPermissions).some((permission) => {
                if (permission.includes('canViewProductionReportsData')) {
                    return tempPermissions.jobPermissions.jobProductionReportsPermissions[permission];
                }
                return false;
            });
            setShowProductionReportsDrawer(productionReportsPermissions);
        }
    }, [permissions]);


    useEffect(() => {
        !areaList?.length && dispatch(callGetAreaList());
        !stateList?.length && dispatch(callGetStateList())
        !categoryList?.length && dispatch(callGetCategoryList());
        !statusList?.length && dispatch(callGetStatusList());
        !permitEntityList?.length && dispatch(callGetPermitEntityList());
        !permitTypeList?.length && dispatch(callGetPermitTypeList());
        !splicerOptions?.length && dispatch(callGetSplicerOptions());
        !dailyStatusList?.length && dispatch(callGetDailyStatusList());
        !dailyJobCodeStatusList?.length && dispatch(callGetDailyJobCodeStatusList());
    }, []);

    useEffect(() => {
        if (newJobId) {
            navigate(`${ROUTES.JOB_DETAILS}/${newJobId}`, { state: { location }})
        }
        return () => {
            dispatch(setNewJobId(0));
        }
    }, [newJobId]);

    useEffect(() => {
        if (jobDetails) {
            setJobDetailModel(jobDetails);
        }
    }, [jobDetails])

    useEffect(() => {
        if (jobId && jobId !== 'new') {
            dispatch(callGetJobDetailData(+jobId));
        } else {
            // reset job details to clean form...
            handleAreaChange(selectedArea, 'details.area');
            const temp = { ...initialJobDetailState.jobDetailData, details: { ...initialJobDetailState.jobDetailData.details, recDate: new Date().toISOString(), area: selectedArea }};
            setJobDetailModel(temp);
        }
        return () => {
            dispatch(setJobDetailData({...initialJobDetailState.jobDetailData}))
        };
    }, [jobId]);

    useEffect(() => {
        if (jobId === 'new'){
            dispatch(setComponentName(`Job - New Job`))
        } else if (jobDetailModel.details.job) {
            dispatch(setComponentName(`Job - ${jobDetailModel.details.job}`))
        }
    }, [jobDetailModel.details.job, jobId]);

    useEffect(() => {
        if (jobDetailModel.details.recDate && jobDetailModel?.details?.area) {
            dispatch(callGetJobCodeLookup({areaId: jobDetailModel.details.area, receivedDate: jobDetailModel.details.recDate}))
        }
    },[jobDetailModel.details.recDate, jobDetailModel?.details?.area])

    const handleAccordionAction = (panel: string) => (event: ChangeEvent<any>, isExpanded: boolean) => {
        if (openSection === panel) {
            setOpenSection('');
            return;
        }
        setOpenSection(panel);
    };

    const handleFormChange = (value: any, fieldName: string) => {
        const array = fieldName.split('.');
        const temp = {...errors};
        temp[array[0]] && delete temp[array[0]][array[1]];
        setErrors(temp);
        if (fieldName === 'details.area') {
            handleAreaChange(value, fieldName);
            return;
        }
        if (array.length > 1) {
            setJobDetailModel({...jobDetailModel, [array[0]]: {...jobDetailModel[array[0]], [array[1]]: value}})
        } else {
            setJobDetailModel({...jobDetailModel, [fieldName]: value});
        }
    }

    const handleAreaChange = (value: any, fieldName: string) => {
        const array = fieldName.split('.');
        dispatch(callGetUserLookupsByArea(value));
        dispatch(setSelectedArea(value));
        setJobDetailModel({...jobDetailModel, [array[0]]: {...jobDetailModel[array[0]], [array[1]]: value, bCoord: 0, cCoords: [], tCoord: 0 }})
    }

    const handleAddNewCard = (addData: any, fieldName: string) => () => {
        const array = fieldName.split('.');
        let temp: any[] = [];
        if (array.length > 1) {
            temp = Object.assign([], jobDetailModel[array[0]][array[1]]);
            temp.push(addData);
            setJobDetailModel({...jobDetailModel, [array[0]]: {...jobDetailModel[array[0]], [array[1]]: temp}})
        } else {
            temp = Object.assign([], jobDetailModel[fieldName]);
            temp.push(addData);
            setJobDetailModel({...jobDetailModel, [fieldName]: temp });
        }
    }

    const formValidation = (values: IJobDetailCombined) => {
        const detailErrors: 
            IJobDetailsErrors 
        = {...errors};
        let sectionError = "";
        let hasDetailsError = false;
        if (values.details) {
            detailErrors.details = {};
            if (!values.details.area) {
                detailErrors.details.area = "Area is required"
            }
            if (!values.details.category) {
                detailErrors.details.category = "Category is required"
            }
            if (!values.details.job) {
                detailErrors.details.job = "Job # is required"
            }
            if (!values.details.bCoord) {
                detailErrors.details.bCoord = 'Bigham Coordinator is required'
            }
            if (!values.details.cCoords?.length) {
                detailErrors.details.cCoords = 'A Customer Coordinator is required'
            }
            if (!values.details.recDate) {
                detailErrors.details.recDate = 'Received Date is required'
            } else if (values.details.recDate && !isDateValid(values.details.recDate)) {
                detailErrors.details.recDate = 'Invalid Date (use format MM/DD/YYYY) entered';
            }
            if (values.details.tsdDate && !isDateValid(values.details.tsdDate)) {
                detailErrors.details.tsdDate = 'Invalid Date (use format MM/DD/YYYY) entered';
            }
            if (values.details.dueDate && !isDateValid(values.details.dueDate)) {
                detailErrors.details.dueDate = 'Invalid Date (use format MM/DD/YYYY) entered';
            }
            if (values.details.completeDate && !isDateValid(values.details.completeDate)) {
                detailErrors.details.completeDate = 'Invalid Date (use format MM/DD/YYYY) entered';
            }
            if (!values.details.address1) {
                detailErrors.details.address1 = 'Address is required'
            }
            if (!values.details.city) {
                detailErrors.details.city = 'City is required'
            }
            if (!values.details.state) {
                detailErrors.details.state = 'State is required'
            }
            if (!values.details.zip) {
                detailErrors.details.zip = 'Zip is required'
            }
            if (Object.keys(detailErrors.details)?.length) {
                sectionError = 'details';
                hasDetailsError = true;
            }
        }
        const {permitErrors, hasPermitErrors} = getPermitErrors(values.permits);
        const {teamErrors, hasTeamError} = getTeamErrors(values.aerial, values.underground, values.splicer);
        if (hasDetailsError) {
            setOpenSection(sectionError);
            setErrors(detailErrors);
        } else if (hasPermitErrors) {
            setOpenSection('permits');
            setPermitErrors(permitErrors);
        } else if(hasTeamError) {
            setOpenSection(Object.keys(teamErrors)[0]);
            setTeamErrors(teamErrors);
        } else {
            handleSave(values);
        }
        
    }

    const getPermitErrors = (permits: IPermitRecord[]):{ permitErrors: IJobPermitErrors, hasPermitErrors: boolean } => {
        const tempErrors: IJobPermitErrors = {};
        if (permits?.length) {
            permits?.forEach((permit, index) => {
                if (!permit.permitEntityId) {
                    tempErrors[index] = { ...tempErrors[index], permitEntityId: 'Required'};
                }
                if (!permit.permitTypeId) {
                    tempErrors[index] = { ...tempErrors[index], permitTypeId: 'Required'};
                }
                if (!permit.permittingAgentIds?.length) {
                    tempErrors[index] = { ...tempErrors[index], permittingAgentIds: 'Required' }
                }
                if (permit.dateSentToEngineer && !isDateValid(permit.dateSentToEngineer)) {
                    tempErrors[index] = { ...tempErrors[index], dateSentToEngineer: 'Invalid Date (use format MM/DD/YYYY) entered'}
                }
                if (permit.dateSentToUtilityOrDot && !isDateValid(permit.dateSentToUtilityOrDot)) {
                    tempErrors[index] = { ...tempErrors[index], dateSentToUtilityOrDot: 'Invalid Date (use format MM/DD/YYYY) entered'}
                }
                if (permit.readyForConstructionDate && !isDateValid(permit.readyForConstructionDate)) {
                    tempErrors[index] = { ...tempErrors[index], readyForConstructionDate: 'Invalid Date (use format MM/DD/YYYY) entered'}
                }
            });
        }
        return { permitErrors: tempErrors, hasPermitErrors: !!Object.keys(tempErrors)?.length };
    }

    const getTeamErrors = (
        aerial: IJobAerial,
        underground: IJobUnderGround,
        splicer: { teams: IJobSplicing[], completeDate: string }
    ):{ teamErrors: IJobTeamErrors, hasTeamError: boolean}  => {
        const tempErrors: IJobTeamErrors = {};
        // validate complete dates
        if (aerial.completeDate && !isDateValid(aerial.completeDate)) {
            tempErrors['aerial'] = { completeDate: 'Invalid Date (use format MM/DD/YYYY)' }
        }
        if (underground.completeDate && !isDateValid(underground.completeDate)) {
            tempErrors['underground'] = { completeDate: 'Invalid Date (use format MM/DD/YYYY)' }
        }
        if (splicer.completeDate && !isDateValid(splicer.completeDate)) {
            tempErrors['splicer'] = { completeDate: 'Invalid Date (use format MM/DD/YYYY)' }
        }
        (aerial?.teams || []).forEach(validateTeam(tempErrors, 'aerial'));
        (underground?.teams || []).forEach(validateTeam(tempErrors, 'underground'));
        (splicer?.teams || []).forEach(validateTeam(tempErrors, 'splicer'));
        return { teamErrors: tempErrors, hasTeamError: !!Object.keys(tempErrors)?.length };
    }

    const validateTeam = (tempErrors: any, teamType: string) => (team: IJobUnderGroundTeam | IJobAerialTeam | IJobSplicing, index: number) => {
        if (tempErrors[teamType]) {
            tempErrors[teamType] = { ...tempErrors[teamType], [index]: getTeamSpecificErrors(team, teamType) }
        } else {
            if (getTeamSpecificErrors(team, teamType)) {
                tempErrors[teamType] = { [index]: getTeamSpecificErrors(team, teamType)}
            }
        }
    }

    const getTeamSpecificErrors = (team: IJobUnderGroundTeam | IJobAerialTeam | IJobSplicing, teamType: string) => {
        const tempErrors: { [key: string] : string } = {};
        if (!team.foremanId) {
            tempErrors.foremanId = 'Required';
        }
        if (teamType === 'aerial' || teamType === 'underground') {
            if ((team as IJobAerialTeam | IJobUnderGroundTeam).issueDate && !isDateValid((team as IJobAerialTeam | IJobUnderGroundTeam).issueDate)) {
                tempErrors.issueDate = 'Invalid Date (use format MM/DD/YYYY)'
            }
            if ((team as IJobAerialTeam | IJobUnderGroundTeam).completeDate && !isDateValid((team as IJobAerialTeam | IJobUnderGroundTeam).completeDate)) {
                tempErrors.completeDate = 'Invalid Date (use format MM/DD/YYYY)'
            }
        } else if (teamType === 'splicer') {
            if ((team as IJobSplicing).completedDate && !isDateValid((team as IJobSplicing).completedDate)) {
                tempErrors.completedDate = 'Invalid Date (use format MM/DD/YYYY)'
            }
            if ((team as IJobSplicing).installDate && !isDateValid((team as IJobSplicing).installDate)) {
                tempErrors.installDate = 'Invalid Date (use format MM/DD/YYYY)'
            }
            if ((team as IJobSplicing).installerIssueDate && !isDateValid((team as IJobSplicing).installerIssueDate)) {
                tempErrors.installerIssueDate = 'Invalid Date (use format MM/DD/YYYY)'
            }
            if ((team as IJobSplicing).scheduledSplicerDate && !isDateValid((team as IJobSplicing).scheduledSplicerDate)) {
                tempErrors.scheduledSplicerDate = 'Invalid Date (use format MM/DD/YYYY)'
            }
            if ((team as IJobSplicing).splicerIssueDate && !isDateValid((team as IJobSplicing).splicerIssueDate)) {
                tempErrors.splicerIssueDate = 'Invalid Date (use format MM/DD/YYYY)'
            }
        }
        if (Object.keys(tempErrors)?.length) {
            return tempErrors;
        }
    }

    const handleBack = () => navigate(from.pathname, { state: { jobId }});

    const handleSaveClick = () => formValidation(jobDetailModel);

    const isGeocodingNecessary = (values: IJobDetailCombined, reference: IJobDetailCombined): boolean => {
        if (values.details.address1 !== reference.details.address1) {
            return true;
        }
        if (values.details.city !== reference.details.city) {
            return true;
        }
        if (values.details.state !== reference.details.state) {
            return true;
        }
        if (values.details.zip !== reference.details.zip) {
            return true;
        }
        if (!values.details.lat) {
            return true;
        }
        if (!values.details.lng) {
            return true;
        }
        return false;
    }

    const handleSave = async (values: IJobDetailCombined) => {
        if (isGeocodingNecessary(values, jobDetails)) {
             getAddressData(`${values.details.address1}, ${values.details.city}, ${values.details.state} ${values.details.zip}`).then((results) => {
                 const transformedValue = mapLatLongData(results[0], values);
                 dispatch(removeFetch());
                 saveData(transformedValue);
             }).catch((err) => {
                console.error(err);
                dispatch(setToastMessage({
                    type: 'info',
                    message: 'The address you entered was not found by the geolocation service. It will not appear on a map'
                }));
                dispatch(removeFetch());
                saveData(values);
             });
        } else {
            saveData(values);
        }
    }

    const saveData = (values: IJobDetailCombined) => {
        if (jobId === 'new') {
            dispatch(callCreateJobDetail(values));
        } else if (jobId) {
            // call update route.
            dispatch(callUpdateJobDetail({...values, details: { ...values.details, id: +jobId }}));
        }
    }

    const mapLatLongData = (results: google.maps.GeocoderResult, values: IJobDetailCombined): IJobDetailCombined => {
        
        const {lat, lng} = results.geometry.location.toJSON();
        if (lat && lng) {
            const returnValue: IJobDetailCombined = {...values, details: { ...values.details, lat: lat.toString(), lng: lng.toString()}};
            return returnValue;
        } else {
            return values;
        }
    }

    const getAddressData = async (address: string): Promise<google.maps.GeocoderResult[]> => {
        dispatch(addFetch());
        const response = await geocoder.geocode({ address });
        return response.results;
    }

    const showNavigationPrompt = (): boolean => {
        return !isEqual(jobDetailModel, jobDetails) && !newJobId;
    }
    
    
    return <div className="job-details-flex-wrapper">
        <div className="sticky-header">
            <div className="sticky-header-drawer-spacer" style={
                drawerOpen ? { width: '80vw', maxWidth: `${drawerWidth}px`, flexGrow: 1 } : { width: '0px', flexGrow: 0 }
            }></div>
            <div className="job-details-wrapper">
                <div className="flex-row space-between standard-padding">
                    <div className="button-wrapper">
                        <BighamButton
                            label="Back"
                            type="default"
                            variant="contained"
                            onClick={handleBack}
                        />
                    </div>
                    <div className="button-wrapper">
                        {showSaveButton && <BighamButton label={'Save Job'} variant="contained" type="primary" onClick={handleSaveClick} startIcon={<SaveOutlined />} />}
                    </div>
                </div>
            </div>
        </div>
        <JobSummaryDrawer
            jobId={jobId}
            jobDetails={jobDetailModel}
            areaList={areaList}
            categoryList={categoryList}
            statusList={statusList}
            bighamCoordinators={bighamCoordinators}
            customerCoordinators={customerCoordinators}
            technicalCoordinators={technicalCoordinators}
            setDrawerOpen={setDrawerOpen}
            drawerOpen={drawerOpen}
            drawerWidth={drawerWidth}
            aerialForeman={aerialForeman}
            undergroundForeman={undergroundForeman}
            splicers={splicers}
        />
        <div className="job-details-wrapper">
            {permissions.jobPermissions?.jobDetailsPermissions?.isViewable && <JobDetailsSection
                openSection={openSection}
                jobId={jobId}
                handleAccordionAction={handleAccordionAction}
                handleFormChange={handleFormChange}
                errors={errors}
                jobDetailModel={jobDetailModel}
                permissions={permissions.jobPermissions?.jobDetailsPermissions}
                lookups={{
                    areaList,
                    categoryList,
                    statusList,
                    bighamCoordinators,
                    customerCoordinators,
                    technicalCoordinators,
                    stateList
                }}
            />}
            {jobId !== 'new' && <>
                {permissions.jobPermissions?.jobDocumentsPermissions?.isViewable && <DocumentSection
                    jobId={jobId}
                    jobDetailModel={jobDetailModel}
                    setJobDetailModel={setJobDetailModel}
                    handleAccordionAction={handleAccordionAction}
                    openSection={openSection}
                    permissions={permissions.jobPermissions?.jobDocumentsPermissions}
                />}
                {permissions.jobPermissions?.jobNtpPoPermissionsDto?.isViewable && <PurchaseOrderSection
                    jobDetailModel={jobDetailModel}
                    openSection={openSection}
                    handleAccordionAction={handleAccordionAction}
                    handleFormChange={handleFormChange}
                    setJobDetailModel={setJobDetailModel}
                    codeLookup={codeLookup}
                    codeList={codeList}
                    permissions={permissions.jobPermissions?.jobNtpPoPermissionsDto}
                />}
                {permissions.jobPermissions?.jobPermitsPermissions?.isViewable && <PermitSection
                    jobDetailModel={jobDetailModel}
                    openSection={openSection}
                    handleAccordionAction={handleAccordionAction}
                    handleFormChange={handleFormChange}
                    handleAddNewCard={handleAddNewCard}
                    permissions={permissions.jobPermissions?.jobPermitsPermissions}
                    errors={permitErrors}
                    setErrors={setPermitErrors}
                    lookups={{
                        areaList,
                        categoryList,
                        statusList,
                        bighamCoordinators,
                        customerCoordinators,
                        technicalCoordinators,
                        stateList,
                        permitTypeList,
                        permitEntityList,
                        permittingAgentList
                    }}
                />}
                {permissions.jobPermissions?.jobLocatesPermissions?.isViewable && <LocatesSection
                    jobDetailModel={jobDetailModel}
                    openSection={openSection}
                    handleAccordionAction={handleAccordionAction}
                    setJobDetailModel={setJobDetailModel}
                    handleFormChange={handleFormChange}
                    permissions={permissions.jobPermissions?.jobLocatesPermissions}
                />}
                {permissions.jobPermissions?.jobAerialPermissions?.isViewable && <AerialSection
                    jobDetailModel={jobDetailModel}
                    openSection={openSection}
                    handleAccordionAction={handleAccordionAction}
                    handleAddNewCard={handleAddNewCard}
                    handleFormChange={handleFormChange}
                    foremanList={aerialForeman}
                    permissions={permissions.jobPermissions?.jobAerialPermissions}
                    errors={teamErrors}
                    setErrors={setTeamErrors}
                />}
                {permissions.jobPermissions?.jobUndergroundPermissions?.isViewable && <UndergroundSection
                    jobDetailModel={jobDetailModel}
                    openSection={openSection}
                    handleAccordionAction={handleAccordionAction}
                    handleAddNewCard={handleAddNewCard}
                    handleFormChange={handleFormChange}
                    foremanList={undergroundForeman}
                    permissions={permissions.jobPermissions?.jobUndergroundPermissions}
                    errors={teamErrors}
                    setErrors={setTeamErrors}
                />}
                {permissions.jobPermissions?.jobSplicerPermissions?.isViewable && <SplicingSection 
                    jobDetailModel={jobDetailModel}
                    openSection={openSection}
                    handleAccordionAction={handleAccordionAction}
                    handleAddNewCard={handleAddNewCard}
                    handleFormChange={handleFormChange}
                    lookups={{
                        foremanList: splicers,
                        splicerOptions,
                        installerList: installers
                    }}
                    permissions={permissions.jobPermissions?.jobSplicerPermissions}
                    errors={teamErrors}
                    setErrors={setTeamErrors}
                />}
                {showProductionReportsDrawer && <ProductionReportSection
                    jobDetailModel={jobDetailModel}
                    setJobDetailModel={setJobDetailModel}
                    openSection={openSection}
                    handleAccordionAction={handleAccordionAction}
                    permissions={permissions.jobPermissions?.jobProductionReportsPermissions}
                />}
                {!!permissions.jobPermissions?.jobProductionReportForInvoicingReportsPermissions?.canViewProductionReportForInvoicingData && <InvoicingSection
                    jobDetailModel={jobDetailModel}
                    setJobDetailModel={setJobDetailModel}
                    openSection={openSection}
                    handleAccordionAction={handleAccordionAction}
                    permissions={permissions?.jobPermissions?.jobProductionReportForInvoicingReportsPermissions}
                />}
                {!!permissions.jobPermissions?.jobFinancialsPermissionsDto?.canViewJobFinancialsData && <JobFinancialsSection 
                    jobDetailModel={jobDetailModel}
                    setJobDetailModel={setJobDetailModel}
                    openSection={openSection}
                    handleAccordionAction={handleAccordionAction}
                    permissions={permissions.jobPermissions?.jobFinancialsPermissionsDto}
                />}
            </>}
        </div>
    </div>;
}

export default JobDetails;