import { FC } from 'react';
import ModalBase from '../ModalBase/ModalBase';
import BighamButton from '../FormControls/BighamButton/BighamButton';


interface DocumentUploadProps {
    onCancel(): void,
    handleOverwrite(): void,
    filenames: string[]
}

const FilenameConfirmationModal: FC<DocumentUploadProps> = ({onCancel, handleOverwrite, filenames}) => {

    return <ModalBase onCancel={onCancel}>
        <h2 className="modal-title">Overwrite existing files?</h2>
        <div className="modal-body">
            The file(s) listed below already exist on this job. Would you like to overwrite the old file(s) with a new version?
        </div>
        <div className="modal-body">
            <ul className="file-list">
                {filenames?.length > 0 && filenames.map((filename: string, index: number) => {
                    return <li key={index}>{filename}</li>
                })}
            </ul>
        </div>
        <div className="modal-button-container">
            <BighamButton
                type="default"
                variant="contained"
                onClick={onCancel}
                label={'Cancel'}
            />
            <BighamButton
                type="primary" 
                variant="contained"
                label={'Overwrite Files'}
                onClick={handleOverwrite}
            />
        </div>
    </ModalBase>

}

export default FilenameConfirmationModal;