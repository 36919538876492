import { FC, useState, useRef } from "react"
import ModalBase from "../ModalBase/ModalBase"
import BighamTextInput from "../FormControls/BighamTextInput/BighamTextInput";
import BighamButton from "../FormControls/BighamButton/BighamButton";
import { useAppDispatch } from "../../../main/hooks/hooks";
import { ITeamJobCode } from "../../../main/types/jobDetailTypes";
import BighamNumberInput from "../FormControls/BighamNumberInput/BighamNumberInput";
import { callCreateBackCharge, callUpdateBackCharge } from "../../../main/slices/jobDetailSlice";
import { min } from "lodash";
import { setToastMessage } from "../../../main/slices/utilSlice";

interface BackChargesModalProps {
    onCancel(): void,
    jobCodeDetails: ITeamJobCode
}

const BackChargesModal: FC<BackChargesModalProps> = ({onCancel, jobCodeDetails}) => {
    const dispatch = useAppDispatch();
    const inputMax = jobCodeDetails.unbilledQuantity ? jobCodeDetails.unbilledQuantity : jobCodeDetails.productionQuantity;
    const { backCharge = {
        id: 0,
        comments: '',
        quantity: 0
    } } = jobCodeDetails;
    const [backChargeModel, setBackChargeModel] = useState<any>(backCharge);
    
    const handleFormChange = (value: any, fieldName: string) => {
        setBackChargeModel({ ...backChargeModel, [fieldName]: value });
    }

    const handleAddBackCharge = () => {
        if (backChargeModel.quantity <= inputMax) {
            if (!backCharge.id) {
                dispatch(callCreateBackCharge({
                    dailyJobCodeId: jobCodeDetails.id,
                    comments: backChargeModel.comments,
                    quantity: backChargeModel.quantity
                }));
            } else {
                dispatch(callUpdateBackCharge({
                    backChargeId: backCharge.id,
                    comments: backChargeModel.comments,
                    quantity: backChargeModel.quantity
                }));
            }
            onCancel();
        } else {
            dispatch(setToastMessage({
                type: 'error',
                message: `The backcharge quantity cannot be greater than ${inputMax}`
            }));
        }
    }


    return <ModalBase onCancel={onCancel} size="medium">
        <h2 className="modal-title">
            Back Charges - {jobCodeDetails.code}
        </h2>
        <div className="form">
            <div className="form-row">
                <BighamNumberInput
                    fieldName="quantity"
                    label="Quantity"
                    value={backChargeModel.quantity}
                    onChange={handleFormChange}
                    max={inputMax}
                />
                <span className="form-control placeholder"></span>
                <span className="form-control placeholder"></span>
            </div>
            <div className="form-row">
                <BighamTextInput
                    fieldName="comments"
                    value={backChargeModel.comments}
                    label="Comments"
                    onChange={handleFormChange}
                />
            </div>
        </div>
        <div className="modal-button-container">
            <BighamButton
                type="default" 
                variant="contained"
                label={`Cancel`}
                onClick={onCancel}
            />
            <BighamButton
                type="primary" 
                variant="contained"
                label={`Save`}
                onClick={handleAddBackCharge}
            />
        </div>
    </ModalBase>
}

export default BackChargesModal;