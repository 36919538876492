import React, { FC, useEffect, useState } from 'react';
import './DailyProductionReports.scss';
import { AgGridReact } from 'ag-grid-react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import BighamButton from '../../library/components/FormControls/BighamButton/BighamButton';
import ROUTES from '../../library/constants/routeConstants';
import { useAppDispatch, useAppSelector } from '../../main/hooks/hooks';
import { selectSelectedArea, selectUserPermissions, setComponentName, setSelectedArea } from '../../main/slices/utilSlice';
import BighamSelect from '../../library/components/FormControls/BighamSelect/BighamSelect';
import { callGetAreaList, callGetBighamCoordinatorList, callGetUserLookupsByArea, selectAreaList, selectBighamCoordinatorList } from '../../main/slices/lookupSlice';
import DailyProductionReportsGrid from './DailyProductionReportsGrid';
import { callGetDailyProductionReports, callGetJobDetailSummary, callGetQCReports, selectDailyProductionReports, setDailyProductionReports } from '../../main/slices/reportsSlice';
import { IServerSideDatasource, RowDoubleClickedEvent } from 'ag-grid-community';
import ProductionReportModal from '../../library/components/DailyProductionReportModal/DailyProductionReportModal';
import { IDailyProductionReport } from '../../main/types/reportTypes';
import { getDailyProductionReports } from '../../main/api/reportsApi';
import { IDailyModalJobDetailSummary } from '../../main/slices/jobDetailSlice';

const DailyProductionReports: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const gridRef = useRef<AgGridReact>(null);
    const [modalData, setModalData] = useState<IDailyProductionReport | null>(null);
    const [jobSummary, setJobSummary] = useState<IDailyModalJobDetailSummary | null>(null)
    const [filteredData, setFilteredData] = useState<IDailyProductionReport[]>([])
    const {
        selectedArea,
        areaList,
        productionReports,
        permissions
    } = useAppSelector((state) => ({
        selectedArea: selectSelectedArea(state),
        areaList: selectAreaList(state),
        productionReports: selectDailyProductionReports(state),
        permissions: selectUserPermissions(state)
    }));

    useEffect(() => {
        dispatch(setComponentName('Daily Production Reports'));
        !areaList?.length && dispatch(callGetAreaList());
        !productionReports?.length && dispatch(callGetDailyProductionReports(selectedArea));
        return () => {
            dispatch(setDailyProductionReports([]));
        }
    },[]);


    useEffect(() => {
        if (areaList?.length) {
            // TODO: set the current qc list to empty array.
            if (selectedArea) {
                handleAreaChange(selectedArea);
            } else {
                handleAreaChange(areaList[0]?.id);
            }
        }
    }, [areaList])

    useEffect(() => {
        if (selectedArea && productionReports) {
            setFilteredData(filterReportData(productionReports));
        }
    }, [selectedArea, productionReports])
    
    const filterReportData = (reports: IDailyProductionReport[]) => {
        return reports.filter((report) => report.areaId === selectedArea)
    }

    const handleAreaChange = (value: any): void => {
        dispatch(callGetUserLookupsByArea(value));
        dispatch(setSelectedArea(value));
        dispatch(callGetDailyProductionReports(value))
    }


    const handleCloseModal = () => {
        setModalData(null);
    }

    const handleDoubleClick = async (e: RowDoubleClickedEvent) => {
        const response = await dispatch(callGetJobDetailSummary(e.data.jobId));
        setJobSummary(response.payload as IDailyModalJobDetailSummary);
        setModalData(e.data);
    }

    return <>
        <div className="production-report-wrapper">
            {!!modalData && <ProductionReportModal onCancel={handleCloseModal} reportDetails={modalData} jobSummary={jobSummary} permissions={permissions.jobPermissions?.jobProductionReportsPermissions} />}
            <div className="flex-row margin-bottom">
                <div className="select-wrapper">
                    <BighamSelect
                        value={selectedArea}
                        onChange={handleAreaChange}
                        fieldName={'area'}
                        label="Area"
                        listItems={areaList}
                    />
                </div>
            </div>
            <DailyProductionReportsGrid ref={gridRef} listItems={filteredData} handleDoubleClick={handleDoubleClick} />
        </div>
    </>
}

export default DailyProductionReports;