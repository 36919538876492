import React, { FC } from 'react';
import './LoadingIcon.scss';
import LoopIcon from '@mui/icons-material/Loop';
import { useAppSelector } from '../../../main/hooks/hooks';
import { selectIsLoading } from '../../../main/slices/utilSlice';

const LoadingIcon: FC = () => {
    const {
        isLoading
      } = useAppSelector((state) => ({
        isLoading: selectIsLoading(state)
      }));
    return <div className={`loading-icon-wrapper ${isLoading ? 'loading-indicator' : ''}`}>
        <LoopIcon className="icon" />
    </div>
}
export default LoadingIcon;
