import { AgGridReact } from 'ag-grid-react';
import React, { CellClickedEvent, ColDef, GridReadyEvent, GridSizeChangedEvent, RowDoubleClickedEvent, ValueGetterParams} from 'ag-grid-community';
import { FC, ForwardedRef } from 'react';
import MultiSelectFilter from '../../../../library/components/GridComponents/GridMultiSelectFilter';
import { forwardRef } from 'react';
import ValueRenderer from '../../../../library/components/GridComponents/ValueRenderer';
import GigRenderer from '../../../../library/components/GridComponents/GigRenderer';
import EditQualityControlRenderer from '../../../../library/components/GridComponents/EditQualityControlRenderer';
import { DateColumnDef, dateFormatter, StringColumnDef } from '../../../JobTracker/trackerColumnFactory';
import { useAppSelector } from '../../../../main/hooks/hooks';
import { selectDailyStatusList } from '../../../../main/slices/lookupSlice';
import { find } from 'lodash';
import { dateFormat } from '../../../../library/constants/dateFormat';
import { IDailyReport } from '../../../../main/types/jobDetailTypes';
import { IProductionReportsPermissions } from '../../../../main/types/permissionTypes';


interface ProductionReportGridProps {
    listItems: any[];
    ref: ForwardedRef<AgGridReact>;
    handleDoubleClick(params: RowDoubleClickedEvent): void;
    handleQCClick(data: IDailyReport): void;
    permissions: IProductionReportsPermissions | undefined;
}

const ProductionReportGrid: FC<ProductionReportGridProps> = forwardRef<AgGridReact, ProductionReportGridProps>(({listItems, handleDoubleClick, handleQCClick, permissions}, ref) => {


    const dailyStatusList = useAppSelector(selectDailyStatusList);

    const iconColWidth = 100;
    let columnDefs: ColDef[] = [
        DateColumnDef('dailyDate', 'Date', dateFormat, true),
        {
            ...StringColumnDef('dailyStatus', 'Status', true),
            valueGetter: (params: ValueGetterParams): string => {
                const status = find(dailyStatusList, (status) => params.data['dailyStatus'] === status.id);
                return status ? status.name : '';
            }
        },
        StringColumnDef('foreman', 'Foreman', true),
        StringColumnDef('crewType', 'Crew Type', true),
        StringColumnDef('reviewer', 'Reviewer', true),
        DateColumnDef('reviewDate', 'ReviewDate', dateFormat, true)
    ];
    if (permissions?.canViewDailyQcData) {
        columnDefs = columnDefs.concat([
            DateColumnDef('qualityControlDate', 'QC Date', dateFormat, true),
            {
                field: 'gig',
                headerName: 'Gig',
                cellRenderer: GigRenderer,
                width: iconColWidth,
                minWidth: iconColWidth,
                maxWidth: iconColWidth,
            },
            DateColumnDef('gigRepairDate', 'Repair Date', dateFormat, true),
            {
                field: 'id',
                headerName: 'Edit QC',
                width: iconColWidth,
                minWidth: iconColWidth,
                maxWidth: iconColWidth,
                cellRenderer: EditQualityControlRenderer,
                cellRendererParams: {
                    handleQCClick
                },
                type: 'rightAligned',
                sortable: false,
                suppressFiltersToolPanel: true
            }
        ]);
    }

    return <div className="grid-wrapper ag-theme-material">
       {!!listItems?.length && <AgGridReact
            ref={ref}
            components={{
                multiSelect: MultiSelectFilter
            }}
            onRowDoubleClicked={handleDoubleClick}
            domLayout="autoHeight"
            defaultColDef={
                {
                    resizable: true,
                    cellRenderer: ValueRenderer,
                    sortable: true,
                    menuTabs: ['filterMenuTab'],
                }
            }
            columnDefs={columnDefs}
            rowData={listItems}
            onGridReady={(event: GridReadyEvent) => {
                event.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(event: GridSizeChangedEvent) => {
                event.api.sizeColumnsToFit();
            }}
        />}
    </div>
})

export default ProductionReportGrid;