import bighamApi from '../axios/bighamApi';
import timer from '../../library/util/timer';
import { ILookupItem } from '../types/genericTypes';
import { IUserServiceGet } from '../types/userTypes';
import { IJobCode } from '../types/codeTypes';

export const getAreaList = async (): Promise<{ data: ILookupItem[] }> => {
  const response = bighamApi({
    url: `areas`,
    method: 'GET'
  });
  return response;
};

export const getCategoryList = async (): Promise<{ data: ILookupItem[] }> => {
  const response = bighamApi({
    url: `categories`,
    method: 'GET'
  });
  return response;
};

export const getStatusList = async (): Promise<{ data: ILookupItem[] }> => {
  const response = bighamApi({
    url: `statuses`,
    method: 'GET'
  });
  return response;
};

export const getBighamCoordinatorList = async (
  areaId: number
): Promise<{ data: IUserServiceGet[] }> => {
  const response = bighamApi({
    method: 'GET',
    url: `Users/BighamCoordinators?areaId=${areaId}&allData=false`
  });
  return response;
};

export const getCustomerCoordinatorList = async (
  areaId: number
): Promise<{
  data: IUserServiceGet[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: `Users/CustomerCoordinators?areaId=${areaId}&allData=false`
  });
  return response;
};

export const getTechnicalCoordinatorList = async (
  areaId: number
): Promise<{
  data: IUserServiceGet[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: `Users/TechnicalCoordinators?areaId=${areaId}&allData=false`
  });
  return response;
};

export const getAerialForemenList = async (
  areaId: number
): Promise<{
  data: IUserServiceGet[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: `Users/AerialForemen?areaId=${areaId}&allData=false`
  });
  return response;
};

export const getUndergroundForemenList = async (
  areaId: number
): Promise<{
  data: IUserServiceGet[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: `Users/UndergroundForemen?areaId=${areaId}&allData=false`
  });
  return response;
};

export const getSplicersList = async (
  areaId: number
): Promise<{
  data: IUserServiceGet[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: `Users/Splicers?areaId=${areaId}&allData=false`
  });
  return response;
};

export const getSplicerOptions = async (): Promise<{
  data: ILookupItem[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: 'SplicerOptions'
  });
  return response;
};

export const getInstallersList = async (
  areaId: number
): Promise<{
  data: IUserServiceGet[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: `Users/Installers?areaId=${areaId}&allData=false`
  });
  return response;
};

export const getFileTypeList = async (): Promise<{ data: ILookupItem[] }> => {
  const response = bighamApi({
    method: 'GET',
    url: 'Documents/Types'
  });
  return response;
};

export const getPermitTypeList = async (): Promise<{ data: ILookupItem[] }> => {
  const response = bighamApi({
    method: 'GET',
    url: 'PermitTypes'
  });
  return response;
};

export const getPermitEntityList = async (): Promise<{
  data: ILookupItem[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: 'PermitEntities'
  });
  return response;
};

export const getPermittingAgentList = async (areaId: number): Promise<{ data: IUserServiceGet[] }> => {
  const reponse = bighamApi({
    method: 'GET',
    url: `Users/PermittingAgents?areaId=${areaId}&allData=false`
  });
  return reponse;
}

export const getAccountTypeList = async (): Promise<{
  data: ILookupItem[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: 'UserTypes'
  });
  return response;
};

export const getUserStatusList = async (): Promise<{ data: ILookupItem[] }> => {
  const response = bighamApi({
    method: 'GET',
    url: 'UserStatuses'
  });
  return response;
};

export const getUserAccessLevels = async (): Promise<{
  data: ILookupItem[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: 'UserRoles/AccessLevels'
  });
  return response;
};

export const getUserEmployeeTitles = async (): Promise<{
  data: ILookupItem[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: 'UserRoles/EmployeeTitles'
  });
  return response;
};

export const getUnitsOfMeasurement = async (): Promise<{
  data: ILookupItem[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: 'UnitsOfMeasurement'
  });
  return response;
};

export const getJobCodeLookup = async (
  areaId: number,
  receivedDate: string
): Promise<{ data: IJobCode[] }> => {
  let url = `JobCodes?areaId=${areaId}&includePrimaryCustomerCodes=true&includeCrewSubCodes=false`;
  if (receivedDate) {
    url += `&effectiveOn=${receivedDate}`;
  }
  const response = bighamApi({
    method: 'GET',
    url
  });
  return response;
};

export const getJobCodeCategories = async (): Promise<{
  data: ILookupItem[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: `JobCodes/Categories`
  });
  return response;
};

export const getOfficeList = async (): Promise<{ data: ILookupItem[] }> => {
  const response = bighamApi({
    method: 'GET',
    url: `Offices`
  });
  return response;
};

export const getDailyStatusList = async (): Promise<{
  data: ILookupItem[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: 'DailyStatuses'
  });
  return response;
};

export const getDailyJobCodeStatusList = async (): Promise<{
  data: ILookupItem[];
}> => {
  const response = bighamApi({
    method: 'GET',
    url: 'DailyJobCodeStatuses'
  });
  return response;
};

export const getGridFilterData = async (areaId: number, colId: string): Promise<{
  data: ILookupItem[]
}> => {
  const response = await bighamApi({
    method: 'GET',
    url: `GridFilterData?areaId=${areaId}&columnKey=${colId}`
  })
  return response.data;
}

export const getContractNames = async (): Promise<{
  data: string[]
}> => {
  const response = await bighamApi({
    method: 'GET',
    url: `JobCodes/ContractNames`
  });
  return response;
}

export const getStateList = async (): Promise<{ data: ILookupItem[] }> => {
  const response = await timer(
    {
      data: [
        {
          name: 'AL',
          id: 1
        },
        {
          name: 'FL',
          id: 2
        },
        {
          name: 'GA',
          id: 3
        },
        {
          name: 'MS',
          id: 4
        },
        {
          name: 'NC',
          id: 5
        },
        {
          name: 'SC',
          id: 6
        },
        {
          name: 'TN',
          id: 7
        }
      ]
    },
    100
  );
  return response;
};
