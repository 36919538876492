import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { cloneDeep } from 'lodash';

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'Access-Control-Allow-Credentials': true,
  'X-Requested-With': 'XMLHttpRequest'
};

const bighamApi: AxiosInstance = axios.create({
  baseURL: window._env_.API_BASE_URL,
  headers,
  withCredentials: true
});

let authInterceptor = bighamApi.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    return config;
  }
);

export const attachAuthInterceptor = (token: string) => {
  bighamApi.interceptors.request.eject(authInterceptor);
  authInterceptor = bighamApi.interceptors.request.use(
    (config: AxiosRequestConfig): AxiosRequestConfig => {
      const clonedConfig = cloneDeep(config);
      if (token) {
        // Apply app logic and modify the request
        clonedConfig.headers = {
          ...clonedConfig.headers,
          Authorization: `Bearer ${token}`
        };
      }
      return clonedConfig;
    }
  );
};

export default bighamApi;
