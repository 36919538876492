import { AgGridReact } from 'ag-grid-react';
import React, { CellClickedEvent, ColDef, GridReadyEvent, GridSizeChangedEvent, RowClassParams, RowClickedEvent, RowDoubleClickedEvent, RowNode, SelectionChangedEvent, ValueFormatterParams, ValueGetterParams} from 'ag-grid-community';
import { FC, ForwardedRef } from 'react';
import MultiSelectFilter from '../../../../library/components/GridComponents/GridMultiSelectFilter';
import { forwardRef } from 'react';
import ValueRenderer from '../../../../library/components/GridComponents/ValueRenderer';
import { formatNumberWithCommas } from '../../../../library/util/numberFormatter';
import { IJobLocates } from '../../../../main/types/jobDetailTypes';
import DeleteRenderer from '../../../../library/components/GridComponents/DeleteRenderer';
import { DateColumnDef, moneyFormatter, StringColumnDef } from '../../../JobTracker/trackerColumnFactory';
import { dateFormat } from '../../../../library/constants/dateFormat';
import { IJobLocatesPermissions } from '../../../../main/types/permissionTypes';


interface LocatesGridProps {
    listItems: IJobLocates[];
    ref: ForwardedRef<AgGridReact>;
    handleDoubleClick(params: RowDoubleClickedEvent): void;
    handleDelete(data: IJobLocates): void;
    handleSelectionChange(params: SelectionChangedEvent): void;
    permissions: IJobLocatesPermissions;
}

const LocatesGrid: FC<LocatesGridProps> = forwardRef<AgGridReact, LocatesGridProps>(({listItems, handleDoubleClick, handleDelete, handleSelectionChange, permissions}, ref) => {
    const hasPermissionToSelectRows = permissions.canEditLocateDigDate || permissions.canEditLocateUpdate;
    const columnDefs: ColDef[] = [
        {
            ...StringColumnDef('locateNumber', 'Locate #', true),
            checkboxSelection: hasPermissionToSelectRows,
            headerCheckboxSelection: hasPermissionToSelectRows,
            headerCheckboxSelectionFilteredOnly: true
        },
        DateColumnDef('locateDigDate', 'Dig Date', dateFormat, true),
        DateColumnDef('locateUpdate', 'Update', dateFormat, true),
        {
            field: 'id',
            headerName: '',
            width: 50,
            maxWidth: 50,
            minWidth: 50,
            cellRenderer: DeleteRenderer,
            cellRendererParams: {
                handleDelete
            },
            hide: !listItems.some((item) => !item.id)
        }
    ];

    

    return <div className="grid-wrapper ag-theme-material">
       {!!listItems?.length && <AgGridReact
            ref={ref}
            components={{
                multiSelect: MultiSelectFilter
            }}
            onRowDoubleClicked={handleDoubleClick}
            domLayout="autoHeight"
            defaultColDef={
                {
                    resizable: true,
                    cellRenderer: ValueRenderer,
                    sortable: true,
                    menuTabs: ['filterMenuTab'],
                }
            }
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            onSelectionChanged={handleSelectionChange}
            columnDefs={columnDefs}
            rowData={listItems}
            onGridReady={(event: GridReadyEvent) => {
                event.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(event: GridSizeChangedEvent) => {
                event.api.sizeColumnsToFit();
            }}
        />}
    </div>
})

export default LocatesGrid;