import React, { FC, useEffect } from 'react';
import './RegistrationSplashPage.scss';

const RegistrationSplashPage: FC = () => {
    return <div className="splash-page-wrapper">
        <div className="splash-page-card">
            Thank you for requesting access to the Bigham Cable Job Tracker. You will receive a follow up email with further instructions.
        </div>
    </div>
}

export default RegistrationSplashPage;