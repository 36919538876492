import { FC, ReactElement } from "react";
import { View, StyleSheet } from '@react-pdf/renderer';
import variables from '../../../../assets/styles/variables.module.scss';

interface PDFTableProps {
    children: ReactElement
}

const styles = StyleSheet.create({
    table: {
        margin: variables.spacing_large,
        fontSize: variables.font_size_small,
        borderBottom: '1px solid black'
    }
})

const PDFTable: FC<PDFTableProps> = ({ children }) => {

    return <View style={styles.table}>
        {children}
    </View>
}

export default PDFTable;