import { min, minBy, orderBy } from 'lodash';
import {
  IJobFinancials,
  IInvoiceJobCodeDetails,
  IInvoiceSummary,
  IInvoiceSummaryBlock,
  IJobCodeLineItem,
  IJobCodeOverride,
  IJobInvoice,
  ITeamJobCode
} from '../../main/types/jobDetailTypes';
import { INVOICE_STATUS, JOB_CODE_STATUS } from '../constants/jobCodeStatus';

export const buildInvoicedSummary = (ntpJobCodes: IJobCodeLineItem[], dailyJobCodes: ITeamJobCode[], invoiceJobCodes: number[]) => {
    const filteredDailyJobCodes = dailyJobCodes.filter((code) => {
      return invoiceJobCodes.indexOf(code.id) > -1;
    });
    return buildInvoiceSummary(ntpJobCodes, filteredDailyJobCodes);
}

export const buildInvoiceSummary = (
  ntpJobCodes: IJobCodeLineItem[],
  dailyJobCodes: ITeamJobCode[]
): IInvoiceSummaryBlock => {
  // sort daily job codes so that the primary customer codes are always iterated through first.
  const sortedDailyJobCodes = orderBy(dailyJobCodes, ['isPrimaryCustomerCode'], ['desc'])
  let jobCodeAggregator: IInvoiceSummaryBlock = {};
  if (ntpJobCodes?.length) {
    ntpJobCodes.forEach((ntpJobCode) => {
      if (ntpJobCode.jobCode) {
        jobCodeAggregator[ntpJobCode.jobCodeId] = {
          id: ntpJobCode.id || 0,
          jobCode: ntpJobCode.jobCode,
          jobCodeId: ntpJobCode.jobCodeId,
          description: ntpJobCode.description || '',
          ntpQuantity: ntpJobCode.quantity,
          ntpTotal: ntpJobCode.total || 0,
          remainingQuantity: ntpJobCode.quantity,
          unitPrice: ntpJobCode.pricePerUnit,
          manualOverride: 0,
          productionQuantity: 0,
          invoicedToDate: 0,
          invoicedTotal: 0,
          newInvoiceQuantity: 0,
          newInvoiceTotal: 0,
          potentialInvoiceQuantity: 0,
          potentialInvoiceTotal: 0,
          isPrimaryCustomerCode: ntpJobCode.isPrimaryCustomerCode || false,
          isCrewSubCode: ntpJobCode.isCrewSubCode || false,
          isNonNtpJobCode: false,
          parentJobCodeId: ntpJobCode.parentJobCodeId
            ? ntpJobCode.parentJobCodeId
            : 0,
          childJobCodes: {}
        };
        if (ntpJobCode.parentJobCodeId) {
          jobCodeAggregator[ntpJobCode.parentJobCodeId].childJobCodes[
            ntpJobCode.jobCodeId
          ] = jobCodeAggregator[ntpJobCode.jobCodeId];
        }
      }
    });
  }
  if (sortedDailyJobCodes?.length) {
    sortedDailyJobCodes.forEach((dailyJobCode) => {
      // if job code has been invoiced
      if (!jobCodeAggregator[dailyJobCode.jobCodeId]) {
        jobCodeAggregator[dailyJobCode.jobCodeId] = {
          id: dailyJobCode.id,
          jobCode: dailyJobCode.code,
          jobCodeId: dailyJobCode.jobCodeId,
          description: dailyJobCode.description,
          ntpQuantity: 0,
          ntpTotal: 0,
          remainingQuantity: 0,
          manualOverride: 0,
          unitPrice: dailyJobCode.pricePerUnit || 0,
          productionQuantity: 0,
          invoicedToDate: 0,
          invoicedTotal: 0,
          newInvoiceQuantity: 0,
          newInvoiceTotal: 0,
          potentialInvoiceQuantity: 0,
          potentialInvoiceTotal: 0,
          isPrimaryCustomerCode: dailyJobCode.isPrimaryCustomerCode,
          isCrewSubCode: dailyJobCode.isCrewSubCode,
          isNonNtpJobCode: dailyJobCode.isNonNtpJobCode,
          parentJobCodeId: dailyJobCode.parentJobCodeId
            ? dailyJobCode.parentJobCodeId
            : 0,
          childJobCodes: {}
        };
      }
      let parentCode: number | undefined;
      if (!dailyJobCode.isPrimaryCustomerCode) {
        jobCodeAggregator[dailyJobCode.jobCodeId].parentJobCodeId =
          dailyJobCode?.parentJobCodeId || 0;
        parentCode = dailyJobCode?.parentJobCodeId;
      }
      // sum up the production quantity
      if (dailyJobCode.productionQuantityAdjusted) {
        jobCodeAggregator[dailyJobCode.jobCodeId].productionQuantity +=
          dailyJobCode.productionQuantityAdjusted;
      }
      // sum up the remaining quantity from all the invoiced production quantities
      jobCodeAggregator = getRemainingQuantityValues(
        jobCodeAggregator,
        dailyJobCode,
        parentCode
      );
      // this is for requested invoice job codes (or temporary line items that have been added from the InvoicingJobCodesModal)
      jobCodeAggregator = getNewInvoiceQuantityAmounts(
        jobCodeAggregator,
        dailyJobCode
      );
      // this is to calculate the potential number of quantity that could be billed
      jobCodeAggregator = getPotentialInvoiceQuantity(
        jobCodeAggregator,
        dailyJobCode
      );
      // if there is a parent code, add the latest of the daily job code to the child reference object.
      if (parentCode) {
        if (
          jobCodeAggregator[parentCode]
        ) {
          jobCodeAggregator[parentCode].childJobCodes[dailyJobCode.jobCodeId] =
            jobCodeAggregator[dailyJobCode.jobCodeId];
        }
      } else {
        jobCodeAggregator[dailyJobCode.jobCodeId].manualOverride =
          jobCodeAggregator[dailyJobCode.jobCodeId].newInvoiceQuantity;
        jobCodeAggregator[dailyJobCode.jobCodeId].newInvoiceTotal =
          jobCodeAggregator[dailyJobCode.jobCodeId].newInvoiceQuantity *
          jobCodeAggregator[dailyJobCode.jobCodeId].unitPrice;
        jobCodeAggregator[dailyJobCode.jobCodeId].potentialInvoiceTotal +=
          jobCodeAggregator[dailyJobCode.jobCodeId].potentialInvoiceQuantity *
          jobCodeAggregator[dailyJobCode.jobCodeId].unitPrice;
      }
    });
  }

  jobCodeAggregator = updateParentSummations(jobCodeAggregator);
  return jobCodeAggregator;
};

const getPotentialInvoiceQuantity = (
  jobCodeAggregator: IInvoiceSummaryBlock,
  dailyJobCode: ITeamJobCode
): IInvoiceSummaryBlock => {
  if (
    dailyJobCode.statusId === JOB_CODE_STATUS.INVOICE_REQUESTED ||
    dailyJobCode.statusId === JOB_CODE_STATUS.APPROVED
  ) {
    jobCodeAggregator[dailyJobCode.jobCodeId].potentialInvoiceQuantity +=
      dailyJobCode.productionQuantityAdjusted;
  }
  return jobCodeAggregator;
};

const getNewInvoiceQuantityAmounts = (
  jobCodeAggregator: IInvoiceSummaryBlock,
  dailyJobCode: ITeamJobCode
): IInvoiceSummaryBlock => {
  if (
    dailyJobCode.statusId === JOB_CODE_STATUS.INVOICE_REQUESTED ||
    dailyJobCode.isSelected ||
    (dailyJobCode.statusId === JOB_CODE_STATUS.INVOICED_PARTIAL &&
      dailyJobCode.isSelected)
  ) {
    if (dailyJobCode.unbilledQuantity) {
      if (dailyJobCode.statusId === JOB_CODE_STATUS.INVOICE_REQUESTED) {
        jobCodeAggregator[dailyJobCode.jobCodeId].newInvoiceQuantity +=
          dailyJobCode.productionQuantityAdjusted - dailyJobCode.unbilledQuantity;
      } else if (dailyJobCode.statusId === JOB_CODE_STATUS.INVOICED_PARTIAL) {
        jobCodeAggregator[dailyJobCode.jobCodeId].newInvoiceQuantity +=
          dailyJobCode.unbilledQuantity;
      }
    } else {
      jobCodeAggregator[dailyJobCode.jobCodeId].newInvoiceQuantity +=
        dailyJobCode.productionQuantityAdjusted;
    }
  }
  return jobCodeAggregator;
};

const getRemainingQuantityValues = (
  jobCodeAggregator: IInvoiceSummaryBlock,
  dailyJobCode: ITeamJobCode,
  parentCode?: number
): IInvoiceSummaryBlock => {
  if (
    dailyJobCode.statusId === JOB_CODE_STATUS.INVOICED ||
    (dailyJobCode.statusId === JOB_CODE_STATUS.INVOICED_PARTIAL &&
      dailyJobCode.unbilledQuantity)
  ) {
    const temp =
      (jobCodeAggregator[parentCode ? parentCode : dailyJobCode.jobCodeId]
        ?.remainingQuantity || 0) -
      (dailyJobCode.productionQuantityAdjusted - (dailyJobCode.unbilledQuantity || 0));
    if (
      temp >
      jobCodeAggregator[parentCode ? parentCode : dailyJobCode.jobCodeId]
        .remainingQuantity
    ) {
      jobCodeAggregator[
        parentCode ? parentCode : dailyJobCode.jobCodeId
      ].remainingQuantity = temp;
    }
    jobCodeAggregator[dailyJobCode.jobCodeId].invoicedToDate +=
      dailyJobCode.productionQuantityAdjusted - (dailyJobCode.unbilledQuantity || 0);
    jobCodeAggregator[dailyJobCode.jobCodeId].invoicedTotal += (dailyJobCode.productionQuantityAdjusted - (dailyJobCode.unbilledQuantity || 0)) * (dailyJobCode?.pricePerUnit || 0);
    jobCodeAggregator[dailyJobCode.jobCodeId].remainingQuantity -=
      dailyJobCode.productionQuantityAdjusted - (dailyJobCode.unbilledQuantity || 0);
    if (dailyJobCode.invoicedAmount) {
      jobCodeAggregator[dailyJobCode.jobCodeId].invoicedToDate +=
        dailyJobCode.invoicedAmount;
      jobCodeAggregator[dailyJobCode.jobCodeId].remainingQuantity -=
        dailyJobCode.invoicedAmount;
      jobCodeAggregator[dailyJobCode.jobCodeId].invoicedTotal += dailyJobCode.invoicedAmount * (dailyJobCode?.pricePerUnit || 0);
    }
  }
  return jobCodeAggregator;
};

const updateParentSummations = (
  jobCodeAggregator: IInvoiceSummaryBlock
): IInvoiceSummaryBlock => {
  const tempJobCodeAggregator: IInvoiceSummaryBlock = { ...jobCodeAggregator };
  const keys = Object.keys(tempJobCodeAggregator);
  if (keys.length) {
    keys.forEach((key: string) => {
      const currentSummary = tempJobCodeAggregator[+key];
      const childKeys = Object.keys(currentSummary.childJobCodes);
      if (childKeys?.length && currentSummary.isPrimaryCustomerCode) {
        const lowestInvoiceQuantity = minBy(
          Object.values(currentSummary.childJobCodes) || {},
          'newInvoiceQuantity'
        );
        const potentialInvoicedAmount = minBy(
          Object.values(currentSummary.childJobCodes) || {},
          'potentialInvoiceQuantity'
        );
        const lowestInvoicedAmount = minBy(
          Object.values(currentSummary.childJobCodes) || {},
          'invoicedToDate'
        );
        if (lowestInvoiceQuantity.newInvoiceQuantity) {
          tempJobCodeAggregator[+key].newInvoiceQuantity =
            lowestInvoiceQuantity.newInvoiceQuantity;
          tempJobCodeAggregator[+key].manualOverride =
            lowestInvoiceQuantity.newInvoiceQuantity;
          tempJobCodeAggregator[+key].newInvoiceTotal =
            lowestInvoiceQuantity.newInvoiceQuantity * currentSummary.unitPrice;
        }
        if (lowestInvoicedAmount.invoicedToDate >= 0) {
          tempJobCodeAggregator[+key].remainingQuantity =
            currentSummary.ntpQuantity - lowestInvoicedAmount.invoicedToDate;
          tempJobCodeAggregator[+key].invoicedToDate += lowestInvoicedAmount.invoicedToDate;
          tempJobCodeAggregator[+key].invoicedTotal =
           tempJobCodeAggregator[+key].invoicedToDate * currentSummary.unitPrice;
        }
        if (potentialInvoicedAmount) {
          tempJobCodeAggregator[+key].potentialInvoiceTotal =
            potentialInvoicedAmount.potentialInvoiceQuantity *
            currentSummary.unitPrice;
        }
      }
    });
  }
  return tempJobCodeAggregator;
};

// for creating payload to create an invoice request.
export const getJobCodeOverrides = (
  invoiceSummary: IInvoiceSummaryBlock
): IJobCodeOverride[] => {
  const keys = Object.keys(invoiceSummary);
  const overrideArray: IJobCodeOverride[] = [];
  keys.forEach((key: string) => {
    const currentInvoice = invoiceSummary[+key];
    if (currentInvoice.isPrimaryCustomerCode && currentInvoice.manualOverride) {
      overrideArray.push({
        jobCodeId: currentInvoice.jobCodeId,
        manualOverride: +currentInvoice.manualOverride
      });
    }
  });
  return overrideArray;
};

// for creating payload to create an invoice request.
export const getDailyJobCodes = (
  invoiceSummary: IInvoiceSummaryBlock,
  selectedJobCodes: ITeamJobCode[]
): IInvoiceJobCodeDetails[] => {
  const remainingQuantityMap = getRemainingQuantityMap(invoiceSummary);
  const dailyJobCodeMap: IInvoiceJobCodeDetails[] = [];
  selectedJobCodes.forEach((dailyJobCode) => {
    if (dailyJobCode.isSelected) {
      const remainingQuantity = remainingQuantityMap[dailyJobCode.jobCodeId];
      const productionAmt = dailyJobCode.unbilledQuantity || dailyJobCode.productionQuantityAdjusted;
      if (remainingQuantity === 0) {
        // do not add anymore job codes to the update map...
        return;
      }
      if (productionAmt > remainingQuantity) {
        dailyJobCodeMap.push({
          unbilledQuantity: productionAmt - remainingQuantity,
          dailyJobCodeId: dailyJobCode.id
        });
        remainingQuantityMap[dailyJobCode.jobCodeId] = 0;
      } else {
        dailyJobCodeMap.push({
          unbilledQuantity: 0,
          dailyJobCodeId: dailyJobCode.id
        });
        remainingQuantityMap[dailyJobCode.jobCodeId] -= productionAmt;
      }
    }
  });

  return dailyJobCodeMap;
};

const getRemainingQuantityMap = (
  invoiceSummary: IInvoiceSummaryBlock
): { [id: number]: number } => {
  const remainingQuantityMap: { [id: number]: number } = {};
  const invoiceKeys = Object.keys(invoiceSummary);
  if (invoiceKeys) {
    invoiceKeys.forEach((key: string) => {
      const currentSummary = invoiceSummary[+key];
      const childKeys = Object.keys(currentSummary.childJobCodes);
      if (childKeys?.length) {
        childKeys.forEach((key: string) => {
          if (currentSummary.manualOverride) {
            remainingQuantityMap[+key] = +currentSummary.manualOverride;
          } else {
            remainingQuantityMap[+key] = 0;
          }
        });
      } else if (currentSummary.isPrimaryCustomerCode) {
        if (currentSummary.manualOverride) {
          remainingQuantityMap[+key] = +currentSummary.manualOverride
        } else {
          remainingQuantityMap[+key] = 0;
        }
      }
    });
  }
  return remainingQuantityMap;
};

// for display in the job financials drawer.
export const getFinancialSummary = (
  invoices: IJobInvoice[] = [],
  purchaseOrderAmount = 0,
  summaryBlock: IInvoiceSummaryBlock
): IJobFinancials => {
  let invoicedToDate = 0;
  let readyToInvoice = 0;
  let remainingOnPurchaseOrder = purchaseOrderAmount;
  const paidOut = 0;
  const profitLoss = 0;

  if (invoices?.length) {
    invoices.forEach((invoice) => {
      if (invoice.invoiceStatusId === INVOICE_STATUS.INVOICED) {
        invoicedToDate += invoice.amount;
        remainingOnPurchaseOrder -= invoice.amount;
      }
    });
  }
  if (Object.keys(summaryBlock)?.length) {
    Object.values(summaryBlock).forEach((summary: IInvoiceSummary) => {
      readyToInvoice += summary.potentialInvoiceTotal;
    });
  }

  const returnValue: IJobFinancials = {
    purchaseOrderAmount,
    paidOut,
    profitLoss,
    remainingOnPurchaseOrder: remainingOnPurchaseOrder - readyToInvoice - invoicedToDate,
    invoicedToDate,
    readyToInvoice
  };
  return returnValue;
};
