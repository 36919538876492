import React, { FC, ChangeEvent } from 'react';
import './BighamDatePicker.scss';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@material-ui/core';
import { Moment } from 'moment';
import { isNull } from 'lodash';

interface DateProps {
    label?: string,
    fieldName: string,
    value: string,
    onChange: (value: string, fieldName: string) => void,
    error?: string,
    readOnly?: boolean
}

const BighamDatePicker: FC<DateProps> = ({label, value, fieldName, onChange, error, readOnly = false}) => {
    const handleChange = (value: any, fieldName: string) => {
        onChange(value, fieldName);
    }
    return <LocalizationProvider dateAdapter={AdapterMoment} >
        <DatePicker
            label={label}
            inputFormat="MM/DD/yyyy"
            value={value}
            onChange={(date: Moment | null) =>  {handleChange(!isNull(date) ? date?.toISOString() : '', fieldName)}}
            readOnly={readOnly}
            renderInput={(params: any) => <TextField variant="outlined" className="form-control date-picker" size="small" {...params} error={!!error?.length} helperText={error} />}
        />
    </LocalizationProvider>
}

export default BighamDatePicker;