import { FC, useState } from "react"
import ModalBase from "../../../../library/components/ModalBase/ModalBase"
import BighamNumberInput from "../../../../library/components/FormControls/BighamNumberInput/BighamNumberInput";
import BighamSelect from "../../../../library/components/FormControls/BighamSelect/BighamSelect";
import BighamTextInput from "../../../../library/components/FormControls/BighamTextInput/BighamTextInput";
import { ILookupItem } from "../../../../main/types/genericTypes";
import { IJobCode } from "../../../../main/types/codeTypes";
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import { isNil } from "lodash";
import BighamAutoComplete from "../../../../library/components/FormControls/BighamAutoComplete/BighamAutoComplete";
import { IJobCodeLineItem } from "../../../../main/types/jobDetailTypes";
import { IPurchaseOrderPermissions } from "../../../../main/types/permissionTypes";
import BighamMoneyDisplayInput from "../../../../library/components/FormControls/BighamMoneyDisplayInput/BighamMoneyDisplayInput";

interface PurchaseOrderModalProps {
    onCancel(): void,
    handleAddCode(code: IJobCodeLineItem): void,
    codeLookup: ILookupItem[],
    codeList: IJobCode[],
    codeDetails?: IJobCodeLineItem,
    permissions: IPurchaseOrderPermissions
}

const initialTempValue: IJobCodeLineItem = {
    index: undefined,
    jobCodeObj: { id: 0, name: ''},
    jobCodeId: 0,
    quantity: 0,
    description: '',
    pricePerUnit: 0,
    total: 0,
    isCrewSubCode: false,
    isPrimaryCustomerCode: false,
    parentJobCodeId: 0
}

const PurchaseOrderModal: FC<PurchaseOrderModalProps> = ({onCancel, handleAddCode, codeLookup, codeList, codeDetails = initialTempValue, permissions}) => {

    const [tempJobCode, setTempJobCode] = useState(codeDetails);
    const [errors, setErrors] = useState<{ [fieldName: string]: string }>({});

    const handleQuantityChange = (value: any, fieldName: string) => {
        const newValue = { ...tempJobCode, [fieldName]: value, total: formatNumber(tempJobCode.pricePerUnit || 0, value) };
        setTempJobCode(newValue);
        delete errors[fieldName];
    }

    const validate = (values: IJobCodeLineItem) => {
        const tempErrors: { [fieldName: string]: string } = {};

        if (!values.jobCodeObj?.id) {
            tempErrors.jobCodeObj = 'Required';
        }
        if (!+values.quantity) {
            tempErrors.quantity = 'Required';
        }

        if (!Object.keys(tempErrors)?.length) {
            handleAddCode(values);
            onCancel();
        } else {
            setErrors(tempErrors);
        }
    }

    const handleJobCodeChange = (value: any, fieldName: string) => {
        const foundCodeDetails = codeList.find((code) => code.id === value.id);
        if (foundCodeDetails) {
            setTempJobCode({
                ...tempJobCode,
                [fieldName]: value,
                description: foundCodeDetails.description,
                pricePerUnit: foundCodeDetails.contractPrice,
                total: formatNumber(foundCodeDetails.contractPrice, tempJobCode.quantity),
                isCrewSubCode: foundCodeDetails.isCrewSubCode,
                isPrimaryCustomerCode: foundCodeDetails.isPrimaryCustomerCode

            })
            delete errors[fieldName];
        }
    }

    const formatNumber = (price: number, quantity: number): string => {
        const returnValue = parseFloat(""+(+price * +parseFloat(""+quantity))).toFixed(2);
        return returnValue;
    }

    const handleReadOnlyChange = () => {
        // to ensure that values are not editable, and doesnt call the other form handler method.
        console.log('No Change')
    }

    const handleAddCodeClick = () => {
        const code = {
            ...tempJobCode,
            jobCode: tempJobCode?.jobCodeObj?.name || '',
            jobCodeId: tempJobCode?.jobCodeObj?.id || 0
        }
        validate(code);
    }

    return <ModalBase onCancel={onCancel}>
        <h2 className="modal-title">{`${isNil(codeDetails?.index) ? 'Add' : 'Update'} Job Code`}</h2>
        {/* <div className="modal-body"> */}
            <div className="form">
                <div className="form-row">
                    <BighamAutoComplete
                        fieldName="jobCodeObj"
                        value={tempJobCode.jobCodeObj || {id: 0, name: ''}}
                        label={'Job Code'}
                        listItems={codeLookup}
                        onChange={handleJobCodeChange}
                        readOnly={!permissions.canEditJobCode}
                        error={errors.jobCodeObj}
                    />
                    <BighamTextInput
                        fieldName="description"
                        value={tempJobCode.description}
                        label="Description"
                        onChange={handleReadOnlyChange}
                        readOnly={true}
                    />
                </div>
                <div className="form-row">
                    <BighamNumberInput
                        fieldName="quantity"
                        value={tempJobCode.quantity}
                        label="Quantity"
                        onChange={handleQuantityChange}
                        readOnly={!permissions.canEditUnitCt}
                        error={errors.quantity}
                    />
                    <BighamMoneyDisplayInput
                        value={tempJobCode.pricePerUnit}
                        label="Unit Price"
                        decimalPlaces={2}
                    />
                    <BighamMoneyDisplayInput
                        value={tempJobCode.total}
                        label="Total"
                        decimalPlaces={2}
                    />
                </div>
            </div>
            <div className="modal-button-container">
                <BighamButton
                    type="default"
                    variant="contained"
                    onClick={onCancel}
                    label={'Cancel'}
                />
                <BighamButton
                    type="primary" 
                    variant="contained"
                    label={`${isNil(codeDetails?.index) ? 'Add' : 'Update'} Job Code`}
                    onClick={handleAddCodeClick}
                    disabled={!!Object.keys(errors)?.length}
                />
            </div>
        {/* </div> */}
    </ModalBase>
}

export default PurchaseOrderModal;