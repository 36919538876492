import React, { FC, useMemo } from "react";
import { View, StyleSheet } from '@react-pdf/renderer'
import PDFTableDataCell from "../PDFTableDataCell/PDFTableDataCell";

interface PDFTableRowProps {
    rowData: any,
    children: React.ReactElement[]
}

const styles = StyleSheet.create({
    dataRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        borderTop: '1px solid black',
        borderLeft: '1px solid black'
    }
})

const PDFTableRow: FC<PDFTableRowProps> = ({rowData, children}) => {
    return <View style={styles.dataRow}>
        {React.Children.map(children, (el) => {
            return React.cloneElement(el, { rowData })
        })}
    </View>
}

export default PDFTableRow