import React, { FC } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { CheckOutlined, CloseOutlined } from '@mui/icons-material';

const UserStatusRenderer: FC<ICellRendererParams> = (props: ICellRendererParams) => {
    const userType = props.value;
    let typeIcon;
    switch (userType){
        case 1:
            typeIcon = <CheckOutlined />
            break;
        case 2:
            typeIcon = <CloseOutlined />
            break;
        default:
            typeIcon = <CloseOutlined />
            break;
    }
    return <span className="icon-wrapper align-left">
        {typeIcon}
    </span>
};

export default UserStatusRenderer;
