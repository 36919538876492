import { CATEGORIES } from '../../library/constants/categoryConstants';
import { ILookupItem } from '../../main/types/genericTypes';

export const getLabelByCategory = (category: ILookupItem) => {
  const categoryLabelMap = {
    [CATEGORIES.CABLE_REPLACEMENT]: 'C',
    [CATEGORIES.RESI_SERVICE]: 'R',
    [CATEGORIES.COMM_BUSINESS]: 'C',
    [CATEGORIES.FORCED_RELOC]: 'P',
    [CATEGORIES.NEW_BUILD_LEGACY]: 'N',
    [CATEGORIES.GREENFIELD]: 'G',
    [CATEGORIES.DROP_REDUCT]: 'P',
    [CATEGORIES.POLE_TRANSFER]: 'D',
    [CATEGORIES.POLE_TRANSFER_2]: 'P',
    [CATEGORIES.FIBER_CUSTOMER]: 'F',
    [CATEGORIES.STORM_DAMAGE]: 'P',
    [CATEGORIES.EMERGENCY_CALL_OUT]: '!',
    [CATEGORIES.MDU]: 'M',
    [CATEGORIES.DSW_WALKOUT]: 'P',
    [CATEGORIES.NODE_REDUCTTION]: 'N'
  };
  return categoryLabelMap[category.id] ? categoryLabelMap[category.id] : '';
};

export const getColorByCategory = (category: ILookupItem): string => {
  if (category.id === CATEGORIES.CABLE_REPLACEMENT) {
    return `rgb(152, 251, 152)`;
  }
  if (category.id === CATEGORIES.RESI_SERVICE) {
    return `rgb(255, 255, 224)`;
  }
  if (category.id === CATEGORIES.COMM_BUSINESS) {
    return `rgb(255, 179, 84)`;
  }
  if (category.id === CATEGORIES.FORCED_RELOC) {
    return `rgb(142, 239, 244)`;
  }
  if (category.id === CATEGORIES.NEW_BUILD_LEGACY) {
    return `rgb(230, 230, 250)`;
  }
  if (category.id === CATEGORIES.GREENFIELD) {
    return `rgb(173, 255, 47)`;
  }
  if (category.id === CATEGORIES.DROP_REDUCT) {
    return `rgb(255, 228, 225)`;
  }
  if (category.id === CATEGORIES.POLE_TRANSFER) {
    return `rgb(255, 255, 0)`;
  }
  if (category.id === CATEGORIES.POLE_TRANSFER_2) {
    return `rgb(255, 215, 0)`;
  }
  if (category.id === CATEGORIES.FIBER_CUSTOMER) {
    return `rgb(0, 255, 255)`;
  }
  if (category.id === CATEGORIES.STORM_DAMAGE) {
    return `rgb(223, 192, 159)`;
  }
  if (category.id === CATEGORIES.EMERGENCY_CALL_OUT) {
    return `rgb(255, 192, 203)`;
  }
  if (category.id === CATEGORIES.MDU) {
    return `rgb(162, 217, 206)`;
  }
  if (category.id === CATEGORIES.DSW_WALKOUT) {
    return `rgb(239, 153, 239)`;
  }
  if (category.id === CATEGORIES.NODE_REDUCTTION) {
    return `rgb(219, 197, 153)`;
  }
  return '';
};

export const getLabelByCategoryId = (categoryId: number) => {
    const categoryLabelMap = {
        [CATEGORIES.CABLE_REPLACEMENT]: 'C',
        [CATEGORIES.RESI_SERVICE]: 'R',
        [CATEGORIES.COMM_BUSINESS]: 'C',
        [CATEGORIES.FORCED_RELOC]: 'P',
        [CATEGORIES.NEW_BUILD_LEGACY]: 'N',
        [CATEGORIES.GREENFIELD]: 'G',
        [CATEGORIES.DROP_REDUCT]: 'P',
        [CATEGORIES.POLE_TRANSFER]: 'D',
        [CATEGORIES.POLE_TRANSFER_2]: 'P',
        [CATEGORIES.FIBER_CUSTOMER]: 'F',
        [CATEGORIES.STORM_DAMAGE]: 'P',
        [CATEGORIES.EMERGENCY_CALL_OUT]: '!',
        [CATEGORIES.MDU]: 'M',
        [CATEGORIES.DSW_WALKOUT]: 'P',
        [CATEGORIES.NODE_REDUCTTION]: 'N'
    };
    return categoryLabelMap[categoryId] ? categoryLabelMap[categoryId] : '';
};

export const getColorByCategoryId = (categoryId: number): string => {
    if (categoryId === CATEGORIES.CABLE_REPLACEMENT) {
        return `rgb(152, 251, 152)`;
    }
    if (categoryId === CATEGORIES.RESI_SERVICE) {
        return `rgb(255, 255, 224)`;
    }
    if (categoryId === CATEGORIES.COMM_BUSINESS) {
        return `rgb(255, 179, 84)`;
    }
    if (categoryId === CATEGORIES.FORCED_RELOC) {
        return `rgb(142, 239, 244)`;
    }
    if (categoryId === CATEGORIES.NEW_BUILD_LEGACY) {
        return `rgb(230, 230, 250)`;
    }
    if (categoryId === CATEGORIES.GREENFIELD) {
        return `rgb(173, 255, 47)`;
    }
    if (categoryId === CATEGORIES.DROP_REDUCT) {
        return `rgb(255, 228, 225)`;
    }
    if (categoryId === CATEGORIES.POLE_TRANSFER) {
        return `rgb(255, 255, 0)`;
    }
    if (categoryId === CATEGORIES.POLE_TRANSFER_2) {
        return `rgb(255, 215, 0)`;
    }
    if (categoryId === CATEGORIES.FIBER_CUSTOMER) {
        return `rgb(0, 255, 255)`;
    }
    if (categoryId === CATEGORIES.STORM_DAMAGE) {
        return `rgb(223, 192, 159)`;
    }
    if (categoryId === CATEGORIES.EMERGENCY_CALL_OUT) {
        return `rgb(255, 192, 203)`;
    }
    if (categoryId === CATEGORIES.MDU) {
        return `rgb(162, 217, 206)`;
    }
    if (categoryId === CATEGORIES.DSW_WALKOUT) {
        return `rgb(239, 153, 239)`;
    }
    if (categoryId === CATEGORIES.NODE_REDUCTTION) {
        return `rgb(219, 197, 153)`;
    }
    return '';
};