import React, { FC } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

const EmailRenderer: FC<ICellRendererParams> = (props: ICellRendererParams) => {
  return (
    <span className="value-wrapper clickable-link">
      <a className="clickable-link" href={`mailto:${props.value}`}>{props.value}</a>
    </span>
  );
};

export default EmailRenderer;
