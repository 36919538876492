import { ExpandMoreOutlined } from '@mui/icons-material';
import moment from 'moment';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, {FC, ChangeEvent} from 'react';
import { ILookupItem } from '../../../../main/types/genericTypes';
import { IJobDetailsPermissions } from '../../../../main/types/permissionTypes';
import { IJobDetailsErrors } from '../../JobDetails';
import BighamDatePicker from '../../../../library/components/FormControls/BighamDatePicker/BighamDatePicker';
import BighamMultiSelect from '../../../../library/components/FormControls/BighamMultiSelect/BighamMultiSelect';
import BighamSelect from '../../../../library/components/FormControls/BighamSelect/BighamSelect';
import BighamTextInput from '../../../../library/components/FormControls/BighamTextInput/BighamTextInput';
import CompleteDateNotificationModal from './CompleteDateNotificationModal';
import { dateTimeFormat } from '../../../../library/constants/dateFormat';
import { useState } from 'react';
import { isDateValid } from '../../../../library/util/validators';

interface JobSectionProps {
    errors: IJobDetailsErrors,
    openSection: string,
    jobId: string,
    handleAccordionAction(panel: string): (event: ChangeEvent<any>, isExpanded: boolean) => void,
    handleFormChange(value: any, fieldName: string): void,
    jobDetailModel: any,
    lookups: { [key: string]: ILookupItem[] },
    permissions: IJobDetailsPermissions
}


const JobDetailsSection: FC<JobSectionProps> = ({
    openSection,
    handleAccordionAction,
    handleFormChange,
    jobId,
    errors,
    jobDetailModel,
    lookups,
    permissions
}) => {
    const sectionId = 'details';
    const [showNotificationModal, setShowNotificationModal] = useState(false);

    const handleCompleteDateChange = (value: any, fieldName: string) => {
        handleFormChange(value, fieldName);
        if (value && value !== jobDetailModel.details.completeDate && isDateValid(value)) {
            setShowNotificationModal(true);
        }
    }

    const hideModal = () => {
        setShowNotificationModal(false);
    }

    const handleSetNotificationFlag = (flagValue: boolean) => () => {
        handleFormChange(flagValue, 'details.sendConfirmationEmail');
        hideModal();
    }

    const formatDate = (dateString: string): string => {
        return moment(dateString).format(dateTimeFormat);
    }

    const isAreaReadOnly = !permissions.canEditArea || jobId !== 'new';
    return <Accordion className="styled-accordion" expanded={openSection === sectionId} id={sectionId} onChange={handleAccordionAction(sectionId)}>
        {showNotificationModal && <CompleteDateNotificationModal handleSetNotificationFlag={handleSetNotificationFlag} />}
        <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
        >
            <div className="flex-header">
                <div className="component-title">Details</div>
            </div>
        </AccordionSummary>
        <AccordionDetails>
            <div className="form">
                <div className="form-row">
                    <BighamSelect
                        label="Area* (required)"
                        fieldName="details.area"
                        value={jobDetailModel.details.area}
                        listItems={lookups.areaList}
                        onChange={handleFormChange}
                        error={errors.details?.area}
                        readOnly={isAreaReadOnly}
                    />
                    <BighamSelect
                        label="Category* (required)"
                        fieldName="details.category"
                        value={jobDetailModel.details.category}
                        listItems={lookups.categoryList}
                        onChange={handleFormChange}
                        error={errors.details?.category}
                        readOnly={!permissions.canEditCategory}
                    />
                    <BighamMultiSelect
                        label="Status"
                        fieldName="details.status"
                        selectedList={jobDetailModel.details.status}
                        listItems={lookups.statusList}
                        onChange={handleFormChange}
                        readOnly={!permissions.canEditJobStatus}
                    />
                </div>
                <div className="form-row">
                    <BighamTextInput
                        label="Job #* (required)"
                        fieldName="details.job"
                        value={jobDetailModel.details.job}
                        onChange={handleFormChange}
                        error={errors.details?.job}
                        readOnly={!permissions.canEditJobNumber}
                    />
                    <BighamTextInput
                        label="Node"
                        fieldName="details.node"
                        value={jobDetailModel.details.node}
                        onChange={handleFormChange}
                        readOnly={!permissions.canEditNode}
                    />
                    <BighamSelect
                        label="Bigham Coordinator* (required)"
                        fieldName="details.bCoord"
                        value={jobDetailModel.details.bCoord}
                        listItems={lookups.bighamCoordinators}
                        onChange={handleFormChange}
                        error={errors.details?.bCoord}
                        readOnly={!permissions.canEditBighamCoordinator}
                    />
                </div>
                <div className="form-row">
                    <BighamMultiSelect
                        label="Customer Coordinator* (required)"
                        fieldName="details.cCoords"
                        selectedList={jobDetailModel.details.cCoords}
                        listItems={lookups.customerCoordinators}
                        onChange={handleFormChange}
                        error={errors.details?.cCoords}
                        readOnly={!permissions.canEditCustomerCoordinator}
                    />
                    <BighamSelect
                        label="Tech Ops Coordinator"
                        fieldName="details.tCoord"
                        value={jobDetailModel.details.tCoord}
                        listItems={lookups.technicalCoordinators}
                        onChange={handleFormChange}
                        readOnly={!permissions.canEditTechOpsCoordinator}
                    />
                    <BighamDatePicker
                        label="Received Date* (required)"
                        fieldName="details.recDate"
                        value={jobDetailModel.details.recDate}
                        onChange={handleFormChange}
                        error={errors.details?.recDate}
                        readOnly={!permissions.canEditReceivedDate}
                    />
                </div>
                <div className="form-row">
                    <BighamDatePicker
                        label="Due Date"
                        fieldName="details.dueDate"
                        value={jobDetailModel.details.dueDate}
                        onChange={handleFormChange}
                        error={errors.details?.dueDate}
                        readOnly={!permissions.canEditDueDate}
                    />
                    <BighamDatePicker
                        label="TSD Date"
                        fieldName="details.tsdDate"
                        value={jobDetailModel.details.tsdDate}
                        onChange={handleFormChange}
                        error={errors.details?.tsdDate}
                        readOnly={!permissions.canEditTsdDate}
                    />
                    <BighamDatePicker
                        label="Completion Date"
                        fieldName="details.completeDate"
                        value={jobDetailModel.details.completeDate}
                        onChange={handleCompleteDateChange}
                        error={errors.details?.completeDate}
                        readOnly={!permissions.canEditCompleteDate}
                    />
                </div>
                <div className="form-row">
                    <BighamTextInput
                        label="Business Name"
                        fieldName="details.business"
                        value={jobDetailModel.details.business}
                        onChange={handleFormChange}
                        readOnly={!permissions.canEditBusiness}
                    />
                    <BighamTextInput
                        label="Address* (required)"
                        fieldName="details.address1"
                        value={jobDetailModel.details.address1}
                        onChange={handleFormChange}
                        error={errors.details?.address1}
                        readOnly={!permissions.canEditAddress}
                    />
                </div>
                <div className="form-row">
                    <BighamTextInput
                        label="City* (required)"
                        fieldName="details.city"
                        value={jobDetailModel.details.city}
                        onChange={handleFormChange}
                        error={errors.details?.city}
                        readOnly={!permissions.canEditCity}
                    />
                    <BighamSelect
                        label="State* (required)"
                        fieldName="details.state"
                        value={jobDetailModel.details.state}
                        listItems={lookups.stateList}
                        useNameAsValue={true}
                        onChange={handleFormChange}
                        error={errors.details?.state}
                        readOnly={!permissions.canEditState}
                    />
                    <BighamTextInput
                        label="Zipcode* (required)"
                        fieldName="details.zip"
                        value={jobDetailModel.details.zip}
                        onChange={handleFormChange}
                        readOnly={!permissions.canEditZip}
                        error={errors.details?.zip}
                    />
                </div>
                <div className="form-row">
                    <BighamTextInput
                        label="Comments"
                        fieldName="details.comments"
                        value={jobDetailModel.details.comments}
                        onChange={handleFormChange}
                        readOnly={!permissions.canEditComments}
                        multiline={true}
                        minRows={5}
                    />
                </div>
                <div className="form-row">
                <span className="form-control MuiFormLabel-root">{!!jobDetailModel.details.lastUpdateDateTime && `Last Updated: ${formatDate(jobDetailModel.details.lastUpdateDateTime)} by ${jobDetailModel.details.lastUpdateUser.fullName}`}</span>
                </div>
            </div>
        </AccordionDetails>
    </Accordion>
}

export default JobDetailsSection;