import { IJobCode } from '../../main/types/codeTypes';
import { ILookupItem } from '../../main/types/genericTypes';

export const transformCodeDetailsResponse = (jobCode: IJobCode): IJobCode => {
  const returnValue: IJobCode = {
    ...jobCode,
    areaIds: (jobCode.areas as ILookupItem[])?.map((area) => area.id ),
    jobCodeCategoryIds:
      jobCode.jobCodeCategories?.map((codeCategory) => codeCategory.id) || []
  };
  return returnValue;
};

export const transformCodeDetailsForUpdateOrCreate = (
  jobCode: IJobCode
): IJobCode => {
  const returnValue: IJobCode = {
    ...jobCode,
    contractPrice: parseFloat('' + jobCode.contractPrice),
    inHouseRate: parseFloat('' + jobCode.inHouseRate),
    subContractorRate: parseFloat('' + jobCode.subContractorRate),
    effectiveOn: jobCode.effectiveOn || null,
    expiresOn: jobCode.expiresOn || null
  };
  returnValue.parentJobCodeId = returnValue.parentJobCodeId || null;
  !returnValue.unitCtId && delete returnValue.unitCtId;
  delete returnValue.jobCodeCategories;
  delete returnValue.childJobCodes;
  delete returnValue.area;
  return returnValue;
};
