import { isNull } from "lodash";
import { useState } from "react";
import { FC } from "react";
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import BighamTextInput from "../../../../library/components/FormControls/BighamTextInput/BighamTextInput";
import ModalBase from "../../../../library/components/ModalBase/ModalBase";

interface PermitEntityModalProps {
    onCancel(): void;
    handleSave(name: string, index: number): void;
    index: number | null;
}

const PermitEntityModal: FC<PermitEntityModalProps> = ({onCancel, handleSave, index}) => {
    const [entityName, setEntityName] = useState('');

    const handleFormChange = (value: any) => {
        setEntityName(value);
    }

    const handleSaveClick = () => {
        if (!isNull(index)) {
            handleSave(entityName, index)
        }
        onCancel();
    }

    return <ModalBase onCancel={onCancel}>
        <h2 className="modal-title">Add Permitting Entity</h2>
        <div className="form">
            <div className="form-row">
                <BighamTextInput
                    fieldName="entityName"
                    label="New Entity Name"
                    value={entityName}
                    onChange={handleFormChange}
                />
            </div>
        </div>
        <div className="modal-button-container">
            <BighamButton
                type="default"
                variant="contained"
                onClick={onCancel}
                label={'Cancel'}
            />
            <BighamButton
                type="primary" 
                variant="contained"
                label={'Add'}
                disabled={!entityName?.length}
                onClick={handleSaveClick}
            />
        </div>
    </ModalBase>
}

export default PermitEntityModal;