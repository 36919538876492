import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { CellClickedEvent, RowDoubleClickedEvent } from "ag-grid-community";
import { findIndex, isNil, keyBy, reduce, sumBy } from "lodash";
import { ChangeEvent, FC, useState, useRef, useEffect } from "react";
import { IDailyReport, IJobCodeLineItem, IJobDetailCombined } from "../../../../main/types/jobDetailTypes";
import ProductionReportGrid from "./ProductionReportGrid";
import DailyProductionReportModal from "../../../../library/components/DailyProductionReportModal/DailyProductionReportModal";
import QualityControlModal from "../../../../library/components/QualityControlModal/QualityControlModal";
import { IProductionReportsPermissions } from "../../../../main/types/permissionTypes";
import { useMemo } from "react";
import { transformJobDetailsForDailyModal } from "../../../../library/transforms/jobDetailsTransform";

interface ProductionReportSectionProps {
    jobDetailModel: any,
    setJobDetailModel(value: any): void,
    openSection: string,
    handleAccordionAction(panel: string): (event: ChangeEvent<any>, isExpanded: boolean) => void,
    permissions: IProductionReportsPermissions | undefined
}

const ProductionReportSection: FC<ProductionReportSectionProps> = ({openSection, handleAccordionAction, setJobDetailModel, jobDetailModel, permissions}) => {
    const sectionId = 'production-reports';
    const gridRef = useRef(null);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [modalData, setModalData] = useState<IDailyReport | undefined>();
    const [showQCModal, setShowQCModal] = useState(false);

    const jobSummary = useMemo(() => {
        return transformJobDetailsForDailyModal(jobDetailModel as IJobDetailCombined);
    }, [jobDetailModel])

    const handleCloseModal = () => {
        setShowApprovalModal(false);
        setShowQCModal(false);
        setModalData(undefined);
    }

    const handleDoubleClick = (params: RowDoubleClickedEvent) => {
        
        setModalData(params.data);
        setShowApprovalModal(true);
    }

    const handleQCClick = (data: IDailyReport) => {
        setModalData(data);
        setShowQCModal(true);
    }

    useEffect(() => {
        if (modalData?.id) {
            const dataRefObj = keyBy(jobDetailModel.dailies, 'id');
            setModalData({ 
                ...dataRefObj[modalData.id],
                jobId: jobDetailModel.details.id,
                jobNumber: jobDetailModel.details.job,
                address: jobDetailModel.details.address,
                businessName: jobDetailModel.details.business
            });
        }
    }, [jobDetailModel.dailies, modalData?.id])

    return <Accordion className="styled-accordion" expanded={openSection === sectionId} id={sectionId} onChange={handleAccordionAction(sectionId)}>
        <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
        >
            <div className="flex-header">
                <div className="component-title">Production Reports</div>
            </div>
        </AccordionSummary>
        <AccordionDetails
            style={{flexFlow: 'column'}}
        >
            {showApprovalModal && <DailyProductionReportModal onCancel={handleCloseModal} reportDetails={modalData} jobSummary={jobSummary} permissions={permissions} />}
            {showQCModal && <QualityControlModal onCancel={handleCloseModal} reportDetails={modalData} permissions={permissions} />}
            <div className="form">
                <ProductionReportGrid
                    listItems={jobDetailModel.dailies}
                    ref={gridRef}
                    handleDoubleClick={handleDoubleClick}
                    handleQCClick={handleQCClick}
                    permissions={permissions}
                />
            </div>
        </AccordionDetails>
    </Accordion>
}

export default ProductionReportSection;