import { LocationOnOutlined } from "@mui/icons-material";
import './JobMap.scss';
import { DirectionsRenderer, GoogleMap, MarkerClusterer, StreetViewPanorama } from "@react-google-maps/api";
import { Clusterer } from '@react-google-maps/marker-clusterer';
import { isNil, debounce, isEqual } from "lodash";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BighamButton from "../../library/components/FormControls/BighamButton/BighamButton";
import BighamTextInput from "../../library/components/FormControls/BighamTextInput/BighamTextInput";
import JobMapInfoCard from "../../library/components/JobMapInfoCard/JobMapInfoCard";
import ROUTES from "../../library/constants/routeConstants";
import './JobMap.scss';
import { useAppDispatch, useAppSelector, useReferredState } from "../../main/hooks/hooks";
import { selectAreaList, callGetAreaList } from "../../main/slices/lookupSlice";
import { selectMapData, callGetMapData, setMapData } from "../../main/slices/trackerSlice";
import { selectSelectedArea, setComponentName, addFetch, removeFetch } from "../../main/slices/utilSlice";
import { IJobTrackerDashboardSample } from "../../main/types/trackerTypes";
import LocationMarker from "./LocationMarker";


const JobMap: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const panelRef = useRef<HTMLDivElement | null>(null);
    const geocoder = new google.maps.Geocoder();
    const directionsService = new google.maps.DirectionsService();
    const {
      mapData,
      areaId,
      areaList
    } = useAppSelector((state) => ({
      mapData: selectMapData(state),
      areaId: selectSelectedArea(state),
      areaList: selectAreaList(state)
    }));

    const [zoom, setZoom] = useState(8);
    const [map, setMap] = useState<google.maps.Map>();
    const [directionResponse, setDirectionsResponse] = useState<google.maps.DirectionsResult>();
    const [directionRenderer, setDirectionRenderer] = useState<google.maps.DirectionsRenderer>();
    const [center, setCenter] = useState<google.maps.LatLngLiteral>();
    const [currentLocation, setCurrentLocation] = useState<google.maps.LatLngLiteral | null>(null);
    const [addressId, addressIdRef, setAddressId] = useReferredState<number>(0)
    const [referenceAddress, setReferenceAddress] = useState('')
    const [referenceAddressLocation, setReferenceAddressLocation] = useState<google.maps.LatLngLiteral | null>(null);
    const [autoComplete, setAutoComplete] = useState<google.maps.places.Autocomplete | null>(null);
    const [destinationLocation, setDestinationLocation] = useState<google.maps.LatLngLiteral | null>(null);
    const [panorama, setPanorama] = useState<google.maps.StreetViewPanorama>();
    const [clusterer, setClusterer] = useState<Clusterer>();

    useEffect(() => {
      if (!isNil(areaId)) {
        const areaName = areaList.find((area) => areaId === area.id)?.name;
        dispatch(setComponentName(`Job Map - ${areaName ? areaName : 'All Areas'}`));
      }
    },[areaId, areaList]);

    useEffect(() => {
      !areaList?.length && dispatch(callGetAreaList());
      dispatch(callGetMapData(areaId));
      return () => {
        dispatch(setMapData([]));
      }
    }, []);

    useEffect(() => {
      if (inputRef.current) {
          setAutoComplete(new google.maps.places.Autocomplete(inputRef.current))  
      }
    }, [inputRef.current]);

    useEffect(() => {
      let listener: google.maps.MapsEventListener | undefined;
      if (autoComplete){
        autoComplete.setFields(['formatted_address', 'geometry']);
        listener = autoComplete.addListener('place_changed', (e: any) => {
          const addressObj = autoComplete.getPlace();
          if (addressObj?.formatted_address) {
              setReferenceAddress(addressObj?.formatted_address);
              setReferenceAddressLocation(addressObj?.geometry?.location?.toJSON() || null)
              debouncedLocationService(addressObj?.formatted_address);
          }
        });
      }
      return () => {
        listener?.remove();
      }
    }, [autoComplete])

    const markerData: IJobTrackerDashboardSample[] = useMemo(() => {
      return mapData.filter((pin) => !!pin.coordinates);
    }, [mapData]);

    const handleBack = () => {
      navigate(ROUTES.TRACKER);
    }

    const handleLocationChange = (value: any) => {
      setReferenceAddress(value);
    }

    const debouncedLocationService = useCallback(debounce(async (value: string) => {
      if (value) {
        dispatch(addFetch());
        geocoder.geocode({address: value}).then((response) => {
          if (response.results?.[0]?.geometry.location.toJSON()) {
            setCenter(response.results?.[0]?.geometry.location.toJSON());
            setReferenceAddressLocation(response.results?.[0]?.geometry.location.toJSON());
            setAddressId(0);
          }
        }).finally(() => {
          dispatch(removeFetch());
        });
      }
    }, 100), []);
    
    const handleSetCurrentLocation = () => {
      if (window.navigator.geolocation && !currentLocation) {
        dispatch(addFetch());
        window.navigator.geolocation.getCurrentPosition((success: GeolocationPosition) => {
          const position = {
            lat: success.coords.latitude,
            lng: success.coords.longitude
          };
          setCurrentLocation(position);
          setCenter(position);
          dispatch(removeFetch())
          setReferenceAddressLocation(position);
          getStreetAddress(position);
        });
      } else if (currentLocation) {
        setCenter(currentLocation);
        getStreetAddress(currentLocation);
      }
    }

    const getStreetAddress = (position: google.maps.LatLngLiteral) => {
      dispatch(addFetch());
      geocoder.geocode({location: position}).then((response: google.maps.GeocoderResponse) => {
        setReferenceAddress(response.results?.[0]?.formatted_address);
        dispatch(removeFetch())
      });
    }

    const handleCardClick = (jobDetail: IJobTrackerDashboardSample) => () => {
      if (jobDetail.coordinates) {
        setCenter(jobDetail.coordinates);
        setAddressId(jobDetail.id)
        setReferenceAddress('');
        map?.setStreetView(null);
      }
    }

    const handleNavigateToJob = useCallback((jobId: number) => () => {
        navigate(`${ROUTES.JOB_DETAILS}/${jobId}`, { state: { location } });
    }, []);

    const handleGetDirections = useCallback(() => {
      clearDirections(true);
      if (referenceAddressLocation && destinationLocation) {
        directionsService.route({
          origin: referenceAddressLocation,
          destination: destinationLocation,
          travelMode: google.maps.TravelMode.DRIVING
        }).then((response: google.maps.DirectionsResult) => {
          directionRenderer?.setMap(null);
          directionRenderer?.setPanel(null);
          if (map){
            setDirectionsResponse(response);
          }
        })
      }
    }, [referenceAddressLocation, destinationLocation, directionResponse, directionsService]);

    const clearDirections = (keepAddress?: boolean) => () => {
      directionRenderer?.setMap(null);
      directionRenderer?.setPanel(null);
      setDirectionsResponse(undefined);
      setDestinationLocation(null);
      if (keepAddress) {
        setReferenceAddress('');
        setReferenceAddressLocation(null);
      }
    }

    const handleDragEnd = () => {
      setCenter(map?.getCenter()?.toJSON());
    }

    const handleZoomChanged = () => {
      if (map) {
        const zoomLevel = map.getZoom()?.valueOf();
        if (zoomLevel) {
          setZoom(zoomLevel)
        }
        const tempCenter = map.getCenter()?.toJSON();
        if (tempCenter) {
          setCenter(tempCenter);
        }
        if (clusterer) {
          const markers = clusterer.markers;
          if (markers) {
            const bounds = map?.getBounds();
            markers.forEach((marker) => {
              marker.setVisible(!!bounds?.contains(marker.getPosition() as google.maps.LatLng));
            })
          }
        }
      }
    }

    return <div className="map-view-wrapper">
      <div className="left-list-wrapper">
        <div className="flex-row margin-bottom">
          <div className="button-wrapper">
              <BighamButton
                  label="Back"
                  type="default"
                  variant="contained"
                  onClick={handleBack}
              />
          </div>
        </div>
        <div className="flex-row no-wrap margin-bottom">
          <BighamTextInput
            ref={inputRef}
            label={destinationLocation ? 'Origin Address' : 'Enter a Location'}
            placeholder={''}
            value={referenceAddress}
            fieldName="referenceAddress"
            onChange={handleLocationChange}
          />
          <span className={`enable-current-location-wrapper ${center && isEqual(center, currentLocation) && 'active'}`} title={"Use Current Location"}>
            <LocationOnOutlined onClick={handleSetCurrentLocation} />
          </span>
        </div>
        {!destinationLocation && !!markerData?.length && markerData.map((jobDetail) => {
          return <JobMapInfoCard
            key={jobDetail.id}
            handleNavigateToJob={handleNavigateToJob(jobDetail.id)}
            jobDetail={jobDetail}
            isListView={true}
            handleClick={handleCardClick(jobDetail)}
            active={jobDetail.id === addressId} 
          />
        })}
        {destinationLocation && <>
          <div className="flex-row space-between margin-bottom">
            <BighamButton
              label="Get Directions"
              variant="contained"
              type="primary"
              onClick={handleGetDirections}  
            />
            <span className="clickable-link" onClick={clearDirections(false)}>Close</span>
          </div>
          <div className="google-directions-wrapper" ref={panelRef}></div>
        </>}
      </div>
      <GoogleMap
        mapContainerClassName={'map-wrapper'}
        center={center || mapData[0]?.coordinates || {lat: 34.191897, lng: -82.668387}}
        zoom={zoom}
        onDragEnd={handleDragEnd}
        onZoomChanged={handleZoomChanged}
        onLoad={(map) => setMap(map)}
        onUnmount={() => setMap(undefined)}
      >
          {!!map && <>
            <StreetViewPanorama onCloseclick={() => setCenter(center)} options={{ enableCloseButton: true, zoomControl: true }} onLoad={(panorama) => setPanorama(panorama)} />
            {directionResponse && <DirectionsRenderer 
              options={{
                directions: directionResponse,
                panel: panelRef.current
              }}
            onLoad={(directionResponse) => setDirectionRenderer(directionResponse)} />}
            <MarkerClusterer averageCenter={true} gridSize={80} minimumClusterSize={6} onLoad={(clusterer) => setClusterer(clusterer)}>
              {clusterer => {
                return markerData.map((jobDetail) => {
                    return <LocationMarker
                      key={jobDetail.id}
                      map={map}
                      setZoom={setZoom}
                      setDestinationLocation={setDestinationLocation}
                      handleNavigateToJob={handleNavigateToJob}
                      clearDirections={clearDirections}
                      infoWindowOpen={addressId === jobDetail.id}
                      jobDetail={jobDetail}
                      setCenter={setCenter}
                      addressId={addressId}
                      panorama={panorama}
                      setAddressId={setAddressId}
                      position={jobDetail.coordinates as google.maps.LatLngLiteral}
                      clusterer={clusterer} />
                  }) as any
                }
              }
            </MarkerClusterer>
          </>}
      </GoogleMap>
    </div>;
}

export default JobMap;