import React, { FC, ChangeEvent } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

interface CheckboxProps {
    label?: string,
    fieldName: string,
    checked: boolean,
    value: string | number,
    className?: string,
    onChange: (e: ChangeEvent<{ value: string, checked: boolean }>, fieldName: string) => void,
    disabled?: boolean
}

const BighamCheckbox: FC<CheckboxProps> = ({label, value, checked, fieldName, onChange, className = "", disabled = false}) => {
    if (!label) {
        return <Checkbox
            size="small"
            onChange={(e: ChangeEvent<{ value: string, checked: boolean }>) => onChange(e, fieldName)}
            checked={checked}
            color={'primary'}
            value={value}
            className={className}
            disabled={disabled}
        />
    } else {
        return <FormControlLabel
            label={label}
            className={className}
            control={<Checkbox
                size="small"
                onChange={(e: ChangeEvent<{ value: string, checked: boolean }>) => onChange(e, fieldName)}
                checked={checked}
                color={'primary'}
                value={value}
                disabled={disabled}
            />}
        />;
    }
}

export default BighamCheckbox;