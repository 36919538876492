import React, { FC, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BighamButton from '../../library/components/FormControls/BighamButton/BighamButton';
import ROUTES from '../../library/constants/routeConstants';
import { useAppDispatch, useAppSelector } from '../../main/hooks/hooks';
import { selectSelectedArea, setComponentName, setSelectedArea } from '../../main/slices/utilSlice';
import BighamSelect from '../../library/components/FormControls/BighamSelect/BighamSelect';
import { callGetAreaList, selectAreaList } from '../../main/slices/lookupSlice';
import InvoiceReportsGrid from './InvoiceReportsGrid';
import { callGetInvoiceReports, selectInvoiceReports, setInvoiceReports } from '../../main/slices/reportsSlice';
import { RowDoubleClickedEvent } from 'ag-grid-community';

const InvoiceReports: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const gridRef = useRef<AgGridReact>(null);
    const {
        selectedArea,
        areaList,
        invoiceReports
    } = useAppSelector((state) => ({
        selectedArea: selectSelectedArea(state),
        areaList: selectAreaList(state),
        invoiceReports: selectInvoiceReports(state)
    }));

    useEffect(() => {
        dispatch(setComponentName('Requested Invoices'));
        !areaList?.length && dispatch(callGetAreaList());
        return () => {
            dispatch(setInvoiceReports([]));
        }
    },[]);


    useEffect(() => {
        if (areaList?.length) {
            // TODO: set the current qc list to empty array.
            if (selectedArea) {
                handleAreaChange(selectedArea);
            } else {
                handleAreaChange(areaList[0]?.id);
            }
        }
    }, [areaList])

    const handleAreaChange = (value: any): void => {
        dispatch(setSelectedArea(value));
        dispatch(setInvoiceReports([]));
        dispatch(callGetInvoiceReports(value));
    }

    const handleDoubleClick = (params: RowDoubleClickedEvent) => {
        navigate(`${ROUTES.JOB_DETAILS}/${params.data.jobId}?openSection=financials`, { state: { location }})
    }

    return <>
        <div className="production-report-wrapper">
            <div className="flex-row margin-bottom">
                <div className="select-wrapper">
                    <BighamSelect
                        value={selectedArea}
                        onChange={handleAreaChange}
                        fieldName={'area'}
                        label="Area"
                        listItems={areaList}
                    />
                </div>
            </div>
            <InvoiceReportsGrid ref={gridRef} listItems={invoiceReports} handleDoubleClick={handleDoubleClick} />
        </div>
    </>
}

export default InvoiceReports;