import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logo, onCallCalendarImage, splicingCalendarImage } from '../../../library/constants/imageConstants';
import ROUTES from '../../../library/constants/routeConstants';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import './Header.scss';
import { useAppSelector } from '../../hooks/hooks';
import { selectActiveUserName, selectComponentName } from '../../slices/utilSlice';
import NavMenu from '../NavMenu/NavMenu';

const Header: FC = () => {
    const navigate = useNavigate();
    const componentName = useAppSelector(selectComponentName);
    const activeUserName = useAppSelector(selectActiveUserName);
    const [menuOpen, setMenuOpen] = useState(false)

    
    const handleShowMenu = () => {
        setMenuOpen(true);
    }

    return <div className="header-wrapper">
        <div className="header-section left">
            <div className="logo-wrapper" onClick={() => navigate(ROUTES.TRACKER)}>
                <img className="header-logo" src={logo} alt="Logo" />
            </div>
            <div className="header-icons">
                <a href="https://www.bighamcable.net/calendar/" title="On Call Calendar" target="_blank"><img src={onCallCalendarImage} width="24" height="24" alt="On Call Calendar"/></a>
            </div>
            <div className="header-icons">
                <a href="https://www.bighamcable.net/calendar.php" title="Fiber Splicing" target="_blank"><img src={splicingCalendarImage} width="26" height="26" alt="Fiber Splicing"/></a>
            </div>
        </div>
        <div className="header-section middle">
        <span>{componentName}</span>    
        </div>
        <div className="header-section right">
            <span className="username-container">{activeUserName}</span>
            <AccountCircleOutlinedIcon className="user-icon" fontSize="inherit" onClick={handleShowMenu} />
        </div>
        <NavMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        
    </div>;
}

export default Header;