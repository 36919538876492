import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  transformUserDetailsForUpdate,
  transformUserDetailsResponse,
  transformUserDetailsForCreate
} from '../../library/transforms/userDetailsTransforms';
import { transformUserListResponse } from '../../library/transforms/userListTransform';
import { getUserAccessLevels } from '../api/lookupApi';
import {
  createNewUser,
  getUserDetails,
  getUserList,
  updateUserDetails,
  validateUser
} from '../api/userApi';
import type { AppDispatch, RootState } from '../store/store';
import { ILookupItem } from '../types/genericTypes';
import { IUserInterface, IUserListInterface, IUserServiceGet } from '../types/userTypes';
import { callGetUserAccessLevels, callGetUserLookupsByArea, selectUserAccessLevels } from './lookupSlice';
import { selectSelectedArea } from './utilSlice';

interface userState {
  userList: IUserListInterface[];
  userDetails: IUserInterface;
  newUserId: number;
}

export const initialUserState: IUserInterface = {
  id: 0,
  address: '',
  city: '',
  state: '',
  zip: '',
  areaIds: [],
  company: '',
  email: '',
  emergencyContact: '',
  emergencyContactPhoneNumber: '',
  firstName: '',
  interestedArea: '',
  lastName: '',
  mobileNumber: '',
  pay: 0,
  phoneNumber: '',
  statusId: 0,
  username: '',
  userTypeId: 0,
  officeId: 0,
  employeeTitleIds: [],
  accessLevelId: '',
  password: '',
  confirmPassword: ''
};

// Define the initial state using that type
const initialState: userState = {
  userList: [],
  userDetails: { ...initialUserState },
  newUserId: 0
};

export const callGetUserList = createAsyncThunk<any, undefined, { state: RootState, dispatch: AppDispatch }>(
  'user/getUserList',
  async (args, { dispatch, getState }) => {
    const state = getState();
    let accessLevels: ILookupItem[] = [];
    if (!selectUserAccessLevels(state)?.length) {
      const response = await dispatch(callGetUserAccessLevels())
      accessLevels = response.payload as ILookupItem[];
    } else {
      accessLevels = selectUserAccessLevels(state);
    }
    const response = await getUserList();
    dispatch(setUserList(transformUserListResponse(response.data, accessLevels)));
  }
);

export const callGetUserDetails = createAsyncThunk(
  'user/getUserDetails',
  async (userId: number, { dispatch }) => {
    const response = await getUserDetails(userId);
    const transform = transformUserDetailsResponse(response.data);
    dispatch(setUserDetails(transform));
  }
);

export const callValidateUser = createAsyncThunk(
  'user/validateUser',
  async (payload: { [key: string]: string }) => {
    const response = await validateUser(payload);
    return response.data;
  }
);

export const callCreateUser = createAsyncThunk<
  any,
  IUserInterface,
  { dispatch: AppDispatch; state: RootState }
>('user/createUser', async (user: IUserInterface, { dispatch, getState }) => {
  const response = await createNewUser(transformUserDetailsForCreate(user));
  dispatch(setNewUserId(response.data));
  handleUpdateUserListCache(dispatch);
});

export const callUpdateUser = createAsyncThunk<
  any,
  IUserInterface,
  { dispatch: AppDispatch; state: RootState }
>('user/updateUser', async (user: IUserInterface, { dispatch, getState }) => {
  await updateUserDetails(transformUserDetailsForUpdate(user));
  handleUpdateUserListCache(dispatch);
});

const handleUpdateUserListCache = (dispatch: AppDispatch) => {
  dispatch(callGetUserLookupsByArea(0));
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserList: (state, action: PayloadAction<IUserListInterface[]>) => {
      state.userList = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<IUserInterface>) => {
      state.userDetails = action.payload;
    },
    setNewUserId: (state, action: PayloadAction<number>) => {
      state.newUserId = action.payload;
    }
  }
});

export const { setUserList, setUserDetails, setNewUserId } = userSlice.actions;

export const selectUserList = (state: RootState): IUserListInterface[] =>
  state.user.userList;
export const selectUserDetails = (state: RootState): IUserInterface =>
  state.user.userDetails;
export const selectNewUserId = (state: RootState): number =>
  state.user.newUserId;
export default userSlice.reducer;
