const generateQueryString = (params: { [key: string]: string }): string => {
  let string = '?';
  const paramKeys = Object.keys(params);
  if (paramKeys.length) {
    paramKeys.forEach((key, index) => {
      string += `${encodeURIComponent(key)}=${encodeURIComponent(
        '' + params[key].toString()
      )}`;
      if (index < paramKeys.length - 1) {
        string += '&';
      }
    });
  }
  return string;
};

export default generateQueryString;
