import { NumberSequence } from 'ag-grid-community';
import bighamApi from '../axios/bighamApi';
import { IJobCode } from '../types/codeTypes';

export const getCodeList = async (
  areaId: number,
  includePrimaryCustomerCodes = false,
  includeCrewSubCodes = false
): Promise<{
  data: IJobCode[];
}> => {
  const response = await bighamApi({
    method: 'GET',
    url: `JobCodes?areaId=${areaId}&includePrimaryCustomerCodes=${includePrimaryCustomerCodes}&includeCrewSubCodes=${includeCrewSubCodes}`
  });
  return response;
};

export const getCodeDetails = async (
  codeId: number
): Promise<{ data: IJobCode }> => {
  const response = await bighamApi({
    method: 'GET',
    url: `JobCodes/${codeId}`
  });
  return response;
};

export const updateCodeDetails = async (
  codeDetails: IJobCode
): Promise<{ data: number }> => {
  const response = await bighamApi({
    method: 'PUT',
    url: `JobCodes/${codeDetails.id}`,
    data: codeDetails
  });
  return response;
};

export const createCodeDetails = async (
  codeDetails: IJobCode
): Promise<{ data: number }> => {
  const response = await bighamApi({
    method: 'POST',
    url: `JobCodes`,
    data: codeDetails
  });
  return response;
};
