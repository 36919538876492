import { find } from 'lodash';
import { ILookupItem } from '../../main/types/genericTypes';
import { IDailyProductionReport, IQualityControlReport } from '../../main/types/reportTypes';

const crewTypeMap: { [key: string]: number } = {
  'aerial': 1,
  'underground': 2,
  'splicing': 3
}

export const transformQualityControlReports = (
  reports: IQualityControlReport[]
): IQualityControlReport[] => {
  const returnValue: IQualityControlReport[] = reports.map(
    (report): IQualityControlReport => {
      const temp: IQualityControlReport = {
        ...report,
        crewTypeLookup: { id: crewTypeMap[report.crewType.toLowerCase()], name: report.crewType },
        bighamCoordinatorLookup: { id: report.bighamCoordinatorId, name: report.bighamCoordinator },
        foremanLookup: { id: report.foremanId, name: report.foreman },
        dailyDate: report.dailyDate || '',
        qualityControlDate: report.qcDate || '',
        gigRepairDate: report.gigRepairDate || '',
        qualityControlComments: report.comments || ''
      };
      return temp;
    }
  );
  return returnValue;
};

const mapStatusLookup = (statusList: ILookupItem[]): { [id: number]: ILookupItem } => {
  const returnValue: { [id: number]: ILookupItem } = {};
  (statusList || []).forEach((status) => {
    returnValue[status.id] = status;
  })
  return returnValue;
}

export const transformDailyProductionReports = (reports: IDailyProductionReport[], bighamCoords: ILookupItem[], statusList: ILookupItem[]) => {
  const statusLookup: { [id: number]: ILookupItem } = mapStatusLookup(statusList);;

  const returnValue: IDailyProductionReport[] = reports.map(
    (report): IDailyProductionReport => {
      const temp: IDailyProductionReport = {
        ...report,
        dailyStatusLookup: statusLookup[report.dailyStatus],
        crewTypeLookup: { id: crewTypeMap[report.crewType.toLowerCase()], name: report.crewType },
        bighamCoordinatorLookup: {
          id: report.bighamCoordinatorId,
          name: find(bighamCoords, (coord: ILookupItem) => coord.id === report.bighamCoordinatorId)?.name || ''
        },
        foremanLookup: {
          id: report.foremanId,
          name: report.foreman
        },
        reviewerLookup: {
          id: report.reviewerId,
          name: report.reviewer
        }
      }
      return temp;
    }
  )
  return returnValue;
}
