import bighamApi from '../axios/bighamApi';

export const getUserPreferences = async (): Promise<{
  data: { preferenceData: string };
}> => {
  const response = bighamApi({
    url: `preferences`,
    method: 'GET'
  });
  return response;
};

export const updateUserPreferences = async (
  payload: string
): Promise<{ data: boolean }> => {
  const response = bighamApi({
    url: `preferences`,
    method: 'POST',
    data: { preferenceData: payload }
  });
  return response;
};
