import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent, GridSizeChangedEvent, RowDoubleClickedEvent} from 'ag-grid-community';
import { FC, ForwardedRef } from 'react';
import MultiSelectFilter from '../../library/components/GridComponents/GridMultiSelectFilter';
import { forwardRef, useRef, useState, useEffect } from 'react';
import ValueRenderer from '../../library/components/GridComponents/ValueRenderer';
import { DateColumnDef, MoneyColumnDef, ObjectColumnDef, StringColumnDef } from '../JobTracker/trackerColumnFactory';
import { IRequestedInvoiceReport } from '../../main/types/reportTypes';
import { dateFormat } from '../../library/constants/dateFormat';
import variables from '../../assets/styles/variables.module.scss';


interface InvoiceReportsGridProps {
    listItems: IRequestedInvoiceReport[],
    ref: ForwardedRef<AgGridReact>,
    handleDoubleClick(e: RowDoubleClickedEvent): void
}

const InvoiceReportsGrid: FC<InvoiceReportsGridProps> = forwardRef<AgGridReact, InvoiceReportsGridProps>(({listItems, handleDoubleClick}, ref) => {
    const gridWrapperRef = useRef<HTMLDivElement | null>(null);
    const [gridHeight, setGridHeight] = useState('auto');
    const columnDefs: ColDef[] = [
        DateColumnDef('requestDate', 'Request Date', dateFormat, true),
        StringColumnDef('jobNumber', 'Job #', true),
        MoneyColumnDef('invoiceAmount', 'Request Amount', true),
        StringColumnDef('invoiceRequestComments', 'Request Comments', true),
        StringColumnDef('businessName', 'Business', true),
        StringColumnDef('address', 'Address', true),
        ObjectColumnDef('bighamCoordinator', 'Bigham Coordinator', true)
    ];


    const handleSetGridHeight = () => {
        if (gridWrapperRef?.current) {
            const offset = gridWrapperRef.current.getBoundingClientRect().top;
            setGridHeight(`calc(100vh - (${offset}px + ${variables.spacing_standard}))`);
        } else {
            setGridHeight('auto');
        }
    }

    useEffect(() => {
        if (gridWrapperRef.current) {
            handleSetGridHeight();
            window.addEventListener('resize', handleSetGridHeight);
        }
        return () => {
            window.removeEventListener('resize', handleSetGridHeight);
        }
    }, [gridWrapperRef.current])

    return <div ref={gridWrapperRef} className="grid-wrapper ag-theme-material" style={{ height: gridHeight }}>
       {!!listItems?.length && <AgGridReact
            ref={ref}
            components={{
                multiSelect: MultiSelectFilter
            }}
            onRowDoubleClicked={handleDoubleClick}
            defaultColDef={
                {
                    resizable: true,
                    cellRenderer: ValueRenderer,
                    sortable: true,
                    menuTabs: ['filterMenuTab'],
                }
            }
            columnDefs={columnDefs}
            rowData={listItems}
            onGridReady={(event: GridReadyEvent) => {
                event.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(event: GridSizeChangedEvent) => {
                event.api.sizeColumnsToFit();
            }}
        />}
    </div>
})

export default InvoiceReportsGrid;