import { StyleSheet, Font } from '@react-pdf/renderer';
import variables from '../../../assets/styles/variables.module.scss';

export default StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap'
    },
    header: {
        marginLeft: variables.spacing_standard,
        marginRight: variables.spacing_standard,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'flex-end'
    },
    headerContent: {
        width: '50%',
        paddingTop: variables.spacing_xl,
        paddingLeft: variables.spacing_xxl,
        paddingRight: variables.spacing_xxl,
        fontSize: variables.font_size_small
    },
    headerText: {
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        fontSize: variables.font_size_standard,
        color: variables.color_black,
        paddingLeft: '50px'
    },
    content: {
        marginLeft: variables.spacing_standard,
        marginRight: variables.spacing_standard,
    },
    contentRow: {
        marginTop: variables.spacing_xs,
        marginLeft: variables.spacing_xs,
        marginRight: variables.spacing_xs,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
        paddingLeft: '43px'
        // justifyContent: 'space-between'
    },
    contentColumn: {
        marginTop: variables.spacing_xs,
        marginLeft: variables.spacing_xs,
        marginRight: variables.spacing_xs,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
        paddingLeft: '43px'
    },
    contentLabel: {
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        fontSize: variables.font_size_xs
    },
    contentText: {
        fontFamily: 'Helvetica',
        fontWeight: 'normal',
        fontSize: variables.font_size_xs,
        marginLeft: variables.spacing_xs
    },
    blueText: {
        fontFamily: 'Helvetica',
        fontWeight: 'normal',
        fontSize: variables.font_size_xs,
        color: variables.color_blue_accent
    }
})