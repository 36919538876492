import { FC, useState } from "react"
import ModalBase from "../ModalBase/ModalBase"
import BighamButton from "../FormControls/BighamButton/BighamButton";
import BighamTextInput from "../FormControls/BighamTextInput/BighamTextInput";


interface CommentsModalProps {
    onCancel(): void,
    actionText: string
    handleSubmit(comments: string): () => void
}

const CommentsModal: FC<CommentsModalProps> = ({actionText, onCancel, handleSubmit}) => {
    const [comments, setComments] = useState('');
    const handleCommentChange = (value: any, fieldName: string) => {
        setComments(value);
    }

    return <ModalBase onCancel={onCancel} size="medium">
        <h2 className="modal-title">{actionText}</h2>
        <div className="form">
            <div className="form-row">
                <BighamTextInput
                    fieldName="comments"
                    label="Comments"
                    value={comments}
                    onChange={handleCommentChange}
                />
            </div>
        </div>
        <div className="modal-button-container">
            <BighamButton
                type="primary" 
                variant="contained"
                label={actionText}
                onClick={handleSubmit(comments)}
            />
        </div>
    </ModalBase>
}

export default CommentsModal;