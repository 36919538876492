import { LogoutOutlined } from "@mui/icons-material";
import { Drawer, Box, List, ListItem, ListItemText, Divider, ListItemIcon } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../library/constants/routeConstants";
import { useAppSelector } from "../../hooks/hooks";
import { canAccessDailyProductionReports, canAccessDailyQualityControlReports, canAccessRequestedInvoicesReport, canAccessWeeklyProductionReports, canAccessMyProductionReports  } from "../../routes/accessRules";
import { selectIsUserRegistered, selectUserPermissions } from "../../slices/utilSlice";
import './NavMenu.scss';

interface NavMenuProps {
    menuOpen: boolean,
    setMenuOpen(value: boolean): void
}

const NavMenu: FC<NavMenuProps> = ({
    menuOpen,
    setMenuOpen
}) => {
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();
    const permissions = useAppSelector(selectUserPermissions);
    const isUserRegistered = useAppSelector(selectIsUserRegistered);
 
    const handleLogout = () => {
        keycloak.logout();
    }

    const canViewAdminMenu = ():boolean => {
        return !!(permissions.managementPermissions?.canManageJobCodes
        || permissions.managementPermissions?.canManageUsers)
    }

    const canViewAnyReports = (): boolean => {
        return canAccessDailyProductionReports(permissions)() ||
        canAccessDailyQualityControlReports(permissions)() ||
        canAccessWeeklyProductionReports(permissions)() ||
        canAccessMyProductionReports(permissions)() ||
        canAccessRequestedInvoicesReport(permissions)();
    }

    return <Drawer
        anchor="right"
        open={menuOpen}
        variant="temporary"
        onClose={() => setMenuOpen(false)}
        className="wrapper-drawer"
        >
            <Box
                sx={{ width: 270, paddingTop: '16px' }}
                role="presentation"
                onClick={() => setMenuOpen(false)}
                onKeyDown={() => setMenuOpen(false)}
                className="menu-wrapper"
            >
                {isUserRegistered && <>
                    <Divider textAlign="left">Jobs</Divider>
                    <List>
                        <ListItem button key="Dashboard" onClick={() => navigate(ROUTES.TRACKER)}>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                    </List>
                </>}
                {canViewAnyReports() && <>
                    <Divider textAlign="left">Reports</Divider>
                    <List>
                        {canAccessDailyProductionReports(permissions)() && <ListItem button key="Daily Production Reports">
                            <ListItemText primary="Daily Production Reports" onClick={() => navigate(ROUTES.DAILY_PROD_REPORTS)}/>
                        </ListItem>}
                        {canAccessWeeklyProductionReports(permissions)() && <ListItem button key="Weekly Production Reports">
                            <ListItemText primary="Weekly Production Reports" onClick={() => navigate(ROUTES.WEEKLY_PROD_REPORTS)}/>
                        </ListItem>}
                        {canAccessMyProductionReports(permissions)() && <ListItem button key="My Production Reports">
                            <ListItemText primary="My Production Reports" onClick={() => navigate(ROUTES.MY_PROD_REPORTS)}/>
                        </ListItem>}
                        {canAccessDailyQualityControlReports(permissions)() && <ListItem button key="Quality Control Reports">
                            <ListItemText primary="Quality Control Reports" onClick={() => navigate(ROUTES.QC_REPORTS)}/>
                        </ListItem>}
                        {canAccessRequestedInvoicesReport(permissions)() && <ListItem button key="Requested Invoices">
                            <ListItemText primary="Requested Invoices" onClick={() => navigate(ROUTES.REQUESTED_INVOICES)}/>
                        </ListItem>}
                    </List>
                </>}
                {canViewAdminMenu() && <>
                    <Divider textAlign="left">Admin</Divider>
                    <List>
                        {!!permissions.managementPermissions?.canManageUsers && <ListItem button key="User List" onClick={() => navigate(ROUTES.USERS)}>
                            <ListItemText primary="User List" />
                        </ListItem>}
                        {!!permissions.managementPermissions?.canManageJobCodes && <ListItem button key="Job Codes" onClick={() => navigate(ROUTES.CODES)}>
                            <ListItemText primary="Job Codes" />
                        </ListItem>}
                    </List>
                </>}
                {!(!isUserRegistered && !canViewAnyReports() && !canViewAdminMenu()) && <Divider />}
                <List>
                    <ListItem button key="Logout" onClick={handleLogout}>
                        <ListItemText primary="Logout" />
                        <ListItemIcon>
                            <LogoutOutlined />
                        </ListItemIcon>
                    </ListItem>
                </List>
                <div className="footer-wrapper">
                    v{window._env_.JOB_TRACKER_VERSION}
                </div>
            </Box>
        
        
    </Drawer>
}

export default NavMenu;