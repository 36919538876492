import React, { FC } from 'react';
import BighamSelect from '../../../../../library/components/FormControls/BighamSelect/BighamSelect';
import BighamMultiSelect from '../../../../../library/components/FormControls/BighamMultiSelect/BighamMultiSelect';
import BighamDatePicker from '../../../../../library/components/FormControls/BighamDatePicker/BighamDatePicker';
import { ILookupItem } from '../../../../../main/types/genericTypes';
import BighamTextInput from '../../../../../library/components/FormControls/BighamTextInput/BighamTextInput';
import { CloseOutlined } from '@mui/icons-material';
import { IJobPermitsPermissions } from '../../../../../main/types/permissionTypes';
import BighamButton from '../../../../../library/components/FormControls/BighamButton/BighamButton';
import { MenuItem } from '@material-ui/core';
import { IJobDetailsErrors, IJobPermitErrors } from '../../../JobDetails';

interface PermitCardProps {
    data: any,
    list: any[],
    index: number,
    handleLocalChange(value: any, fieldName?: string): void;
    handleRemoveTeam(): void;
    handleEntityChange(value: any): (value: any, fieldName: string) => void;
    lookups: { [key: string]: ILookupItem[] }
    permissions: IJobPermitsPermissions;
    errors?: { [key: string]: string};
    setErrors(errors: IJobPermitErrors): void
}


const PermitCard: FC<PermitCardProps> = ({data, handleLocalChange, handleRemoveTeam, handleEntityChange, lookups, permissions, errors, setErrors}) => {
    const addNewKey = 'add-new';

    return <div className={`footage-card-wrapper form`}>
        {!data.id && <div className="delete-team-wrapper">
            <CloseOutlined onClick={handleRemoveTeam} />
        </div>}
        <div className="form-row">
            <BighamSelect
                fieldName={`permitTypeId`}
                listItems={lookups.permitTypeList}
                value={data[`permitTypeId`]}
                label={`Permit Type* (required)`}
                onChange={handleLocalChange}
                readOnly={!permissions.canEditPermitType}
                error={errors?.permitTypeId}
            />
            <BighamSelect
                fieldName={`permitEntityId`}
                listItems={lookups.permitEntityList}
                value={data[`permitEntityId`]}
                label={`Permit Entity* (required)`}
                onChange={handleEntityChange(addNewKey)}
                readOnly={!permissions.canEditPermitEntity}
                additionalOption={<MenuItem value={addNewKey}>Add New +</MenuItem>}
                error={errors?.permitEntityId}
            />            
        </div>
        <div className="form-row">
            <BighamMultiSelect
                fieldName={`permittingAgentIds`}
                listItems={lookups.permittingAgentList}
                selectedList={data[`permittingAgentIds`]}
                label={`Permitting Agents* (required)`}
                onChange={handleLocalChange}
                readOnly={!permissions.canEditPermittingAgents}
                error={errors?.permittingAgentIds}
            />
            <BighamTextInput
                fieldName="engineeringCompany"
                value={data['engineeringCompany']}
                label="Engineering Company"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditEngineeringCompany}
            />
        </div>
        <div className="form-row">
            <BighamDatePicker 
                fieldName={`dateSentToEngineer`}
                value={data[`dateSentToEngineer`]}
                label="Sent to Engineer"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditDateSentToEngineer}
                error={errors?.dateSentToEngineer}
            />
            <BighamDatePicker 
                fieldName={`dateSentToUtilityOrDot`}
                value={data[`dateSentToUtilityOrDot`]}
                label="Sent to Utitlity or DOT"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditDateSentToUtilityOrDot}
                error={errors?.dateSentToUtilityOrDot}
            />
            <BighamDatePicker 
                fieldName={`readyForConstructionDate`}
                value={data[`readyForConstructionDate`]}
                label="Ready For Construction"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditReadyForConstructionDate}
                error={errors?.readyForConstructionDate}
            />
        </div>
        <div className="form-row">
            <BighamTextInput
                fieldName={'comments'}
                value={data['comments']}
                label="Comments"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditPermitComments}
            />
        </div>
    </div>
}
export default PermitCard;
