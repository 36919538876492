import {
  IJobTrackerDashboardSample
} from '../../main/types/trackerTypes';

export const transformJobTrackerResponsePayload = (
  inputData: any[]
): { trackerData: IJobTrackerDashboardSample[] } => {
  return {
    trackerData: inputData.map((record, index) => {
      const returnData: IJobTrackerDashboardSample = {
        id: record.id,
        category: record.category,
        address: record.address,
        comments: record.comments,
        documents: record.documentCount,
        job: record.job,
        locateDigDates: record.locateDigDates,
        locateUpdateDates: record.locateUpdateDates,
        locateNumbers: record.locateNumbers,
        status: record.status,
        node: record.node,
        receivedDate: record.receivedDate,
        completeDate: record.completeDate,
        bighamCoordinator: record.bighamCoordinator,
        techOpsCoordinator: record.techOpsCoordinator,
        customerCoordinators: record.customerCoordinators,
        aerial: record.aerial,
        underground: record.underground,
        aerialCompletedDate: record.aerialCompletedDate,
        undergroundCompletedDate: record.undergroundCompletedDate,
        aerialTeams: record.aerialTeams,
        undergroundTeams: record.undergroundTeams,
        splicingTeams: record.splicingTeams,
        splicingCompletedDate: record.splicingCompletedDate,
        dueDate: record.dueDate,
          remainingOnPurchaseOrder: record.remainingOnPurchaseOrder,
        business: record.business,
        tsdDate: record.tsdDate
      };

      if (record.latitude && record.longitude) {
        returnData.coordinates = {
          lat: parseFloat(record.latitude),
          lng: parseFloat(record.longitude)
        };
      }

      return returnData;
    })
  };
};
