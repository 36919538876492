import React, { ChangeEvent, ChangeEventHandler, FC } from 'react';
import { TextField } from '@material-ui/core';
import { useEffect } from 'react';
import { InputAdornment } from '@mui/material';
import { formatNumberWithCommas } from '../../../util/numberFormatter';

interface TextProps {
    label?: string,
    fieldName: string,
    placeholder?: string,
    value: string | number | undefined,
    onChange: (value: any, fieldName: string) => void,
    className?: string,
    error?: string,
    readOnly?: boolean,
    step?: string,
    decimalPlaces?: number,
    symbol?: string,
    max?: number
}

const BighamNumberInput: FC<TextProps> = ({label, placeholder, value = '', fieldName, onChange, className = '', error = "", readOnly = false, step = "1", decimalPlaces = 0, symbol = "", max}) => {

    useEffect(() => {
        if (readOnly) {
            onChange(value, fieldName);
        }
    }, [value])

    const InputProps: any = {
      inputProps: {
        readOnly,
        step,
        min: 0
      }
    }

    if (max) {
      InputProps.inputProps.max = max;
    }

    if (symbol) {
      InputProps.startAdornment = <InputAdornment position="start">{symbol}</InputAdornment>;
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (readOnly) {
        onChange(parseFloat(e.target.value).toFixed(decimalPlaces), fieldName)
        return;
      }
      onChange(e.target.value, fieldName)
    }

    return <TextField
        className={`form-control ${className}`}
        label={label}
        variant={'outlined'}
        placeholder={placeholder}
        onBlur={(e) => onChange(parseFloat(e.target.value).toFixed(decimalPlaces), fieldName)}
        onChange={handleChange}
        value={value}
        size="small"
        error={!!error.length}
        helperText={error}
        type='number'
        InputProps={InputProps}
    >
    </TextField>;
}

export default BighamNumberInput;