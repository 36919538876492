import React, { FC, ChangeEvent } from 'react';
import './FootageCard.scss';
import BighamTextInput from '../FormControls/BighamTextInput/BighamTextInput';
import BighamSelect from '../FormControls/BighamSelect/BighamSelect';
import BighamDatePicker from '../FormControls/BighamDatePicker/BighamDatePicker';
import { ILookupItem } from '../../../main/types/genericTypes';
import BighamCheckbox from '../FormControls/BighamCheckbox/BighamCheckbox';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import BighamNumberInput from '../FormControls/BighamNumberInput/BighamNumberInput';
import { IAerialPermissions, IUndergroundPermissions } from '../../../main/types/permissionTypes';
import { IJobTeamErrors } from '../../../pages/JobDetails/JobDetails';

interface FootageCardProps {
    data: any,
    list: any[],
    index: number,
    foremanList: ILookupItem[],
    handleFormChange(value: any, fieldName?: string): void;
    group: 'aerial' | 'underground',
    permissions: IAerialPermissions | IUndergroundPermissions,
    setErrors(errors: IJobTeamErrors): void,
    errors: IJobTeamErrors;
}


const FootageCard: FC<FootageCardProps> = ({data, list, index, foremanList, handleFormChange, group, permissions, errors, setErrors}) => {

    const handleLocalChange = (value: any, fieldName: string) => {
        let updatedValue = {...data, [fieldName]: value};
        const updatedList = [...list];
        const temp = {...errors};
        delete temp?.[group]?.[index][fieldName];
        setErrors(temp);
        if (fieldName == 'foremanId' && !data.issueDate) {
            updatedValue = { ...updatedValue, issueDate: new Date().toISOString() }
        }
        updatedList.splice(index, 1, updatedValue);
        handleFormChange(updatedList, `${group}.teams`);
    }

    const handleCheckboxChange = (e: ChangeEvent<{ value: any, checked: boolean}>, fieldName: string) => {
        const updatedValue = {...data, [fieldName]: e.target.checked};
        const updatedList = [...list];
        updatedList.splice(index, 1, updatedValue);
        handleFormChange(updatedList, `${group}.teams`);
    }

    const handleRemoveTeam = () => {
        const updatedList = [...list];
        updatedList.splice(index, 1);
        handleFormChange(updatedList, `${group}.teams`);
        const temp = {...errors};
        delete temp?.[group]?.[index];
        setErrors(temp);
    }
    
    return <div className={`footage-card-wrapper form`}>
        {!data.id && <div className="delete-team-wrapper">
            <CloseOutlined onClick={handleRemoveTeam} />
        </div>}
        <div className="form-row">
            <BighamNumberInput
                fieldName={`footageCompleted`}
                value={data[`footageCompleted`]}
                label={`Crew Footage Completed`}
                readOnly={true}
                onChange={handleLocalChange}
            />
        </div>
        <div className="form-row">
            <BighamSelect
                fieldName={`foremanId`}
                listItems={foremanList}
                value={data[`foremanId`]}
                label={`Foreman`}
                onChange={handleLocalChange}
                readOnly={!permissions.canEditForeman}
                error={errors?.[group]?.[index]?.foremanId}
            />
        </div>
        <div className="form-row">
            <BighamDatePicker 
                fieldName={`issueDate`}
                value={data[`issueDate`]}
                label="Issue Date"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditIssueDate}
                error={errors?.[group]?.[index]?.issueDate}
            />
            <BighamDatePicker 
                fieldName={`completeDate`}
                value={data[`completeDate`]}
                label="Completed Date"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditCrewCompleteDate}
                error={errors?.[group]?.[index]?.completeDate}
            />
        </div>
        <div className="form-row">
            <BighamTextInput
                fieldName="comments"
                value={data['comments']}
                label="Comments"
                onChange={handleLocalChange}
                readOnly={!permissions.canEditComments}
            />
        </div>
        <div className="form-row">
            <div className="form-control">
                <BighamCheckbox
                    fieldName="isCritical"
                    checked={data['isCritical']}
                    value={data['isCritical']}
                    onChange={handleCheckboxChange}
                    label="Critical"
                    disabled={!permissions.canEditIsCritical}
                />
            </div>
        </div>
    </div>
}
export default FootageCard;
