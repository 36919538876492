import { FC } from "react";

const constructionFiberStampNames: string[] = [
  'gig.png',
  'pole.png',
  'downguy.png',
  'ped.png',
  'vault.png',
  'fiber-splice-point.png',
  'fiber-storage.png',
  'mst-taps-2.png',
  'mst-taps-4.png',
  'mst-taps-8.png',
  'mst-taps-12.png',
  'ote-taps-2.png',
  'ote-taps-4.png',
  'ote-taps-8.png',
  'olt.png',
];

const splicingStampNames: string[] = [
  '2port-23.png',
  '2port-20.png',
  '2port-17.png',
  '2port-14.png',
  '2port-11.png',
  '2port-8.png',
  '2port-4.png',
  '4port-23.png',
  '4port-20.png',
  '4port-17.png',
  '4port-14.png',
  '4port-11.png',
  '4port-8.png',
  '8port-23.png',
  '8port-20.png',
  '8port-17.png',
  '8port-14.png',
  '8port-11.png',
  '2way-3way.png',
  '3way-unbal.png',
  'dc-8.png',
  'dc-12.png',
  'le-agc.png',
  'le-thermal.png',
  'le-manual.png',
  'amp.png',
  'amp-b.png',
  'node.png',
  'leq.png',
  'power-insert.png',
  'power-supply.png',
  'straight-splice.png',
  'housing-terminator.png',
];

interface IStampsCollectionProps {
    handleStampClick(stamp: string, sameStamp?: boolean): void;
    selectedStamp?: string;
}

const StampsCollection: FC<IStampsCollectionProps> = ({handleStampClick, selectedStamp}) => {

    const getStamps = () => {
        return constructionFiberStampNames.concat(splicingStampNames);
    }

    return <div className="flex-row stamp-row">
        {getStamps().map((stamp, index) => <img key={index} onClick={() => handleStampClick(stamp)} className={`stamp-icon ${selectedStamp === stamp && 'active'}`} src={require(`/src/assets/images/stamps/${stamp}`)} />)}
    </div>
}

export default StampsCollection;
