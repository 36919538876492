import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import React, { FC, useRef, useState } from 'react';
import { useEffect } from 'react';
import BighamSelect from '../../library/components/FormControls/BighamSelect/BighamSelect';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import FilterListOffOutlinedIcon from '@mui/icons-material/FilterListOffOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { useAppDispatch, useAppSelector, useReferredState } from '../../main/hooks/hooks';
import './JobTracker.scss';
import JobTrackerGrid from './JobTrackerGrid';
import { callGetAreaList, selectAreaList } from '../../main/slices/lookupSlice';
import { selectSelectedArea, selectUserPermissions, setComponentName, setDownloadRowData, setSelectedArea } from '../../main/slices/utilSlice';
import BighamButton from '../../library/components/FormControls/BighamButton/BighamButton';
import ROUTES from '../../library/constants/routeConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatNumberWithCommas } from '../../library/util/numberFormatter';
import { callGetUserPreferences, callUpdateUserPreferences, selectColumnOrder, selectFilterModel, selectPreferencesResolved, setFilterModel } from '../../main/slices/preferencesSlice';
import { IJobTrackerSummary } from '../../main/types/trackerTypes';
import { ColDef, ColumnState, IServerSideDatasource } from 'ag-grid-community';
import { getTrackerData, getTrackerDataPaged } from '../../main/api/trackerApi';
import { transformJobTrackerResponsePayload } from '../../library/transforms/jobTrackerTransform';
import { generateGridConfig } from './trackerColumnFactory';
import { debounce, isEqual } from 'lodash';
import { gridDataSample } from '../../library/constants/gridDataSample';
import { SESSION_KEYS } from '../../library/constants/sessionStorageKeys';
import GridCsvDownload from '../../library/components/GridCsvDownload/GridCsvDownload';

const JobTracker: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { jobId = 0 } = (location?.state || {}) as { jobId?: number };
    const gridRef = useRef<AgGridReact>(null);
    const [avoidServerCall, avoidServerCallRef, setAvoidServerCall] = useReferredState(true);
    const [colDef, setColDef] = useState<ColDef[]>([]);

    const [trackerSummary, setTrackerSummary] = useState<IJobTrackerSummary>({
        aerialFootageComplete: 0,
        aerialFootage: 0,
        undergroundFootageComplete: 0,
        undergroundFootage: 0,
        rowCount: 0
    });

    const {
        areaList,
        permissions,
        selectedArea,
        filterModel,
        columnOrder,
        prefResolved
    } = useAppSelector((state) => ({
        areaList: selectAreaList(state),
        permissions: selectUserPermissions(state),
        selectedArea: selectSelectedArea(state),
        filterModel: selectFilterModel(state),
        columnOrder: selectColumnOrder(state),
        prefResolved: selectPreferencesResolved(state)
    }));

    useEffect(() => {
        !areaList?.length && dispatch(callGetAreaList());
        dispatch(callGetUserPreferences());
        dispatch(setComponentName('Job Tracker'))
    }, []);

    useEffect(() => {
        if (areaList?.length) {
            if (selectedArea) {
                handleAreaChange(selectedArea);
            } else {
                handleAreaChange(areaList[0]?.id);
            }
        }
    }, [areaList])

    const handleAreaChange = (value: any): void => {
        dispatch(setSelectedArea(value));
        if (selectedArea !== value) {
            handleClearFilters();
        }
        if (gridRef.current?.api) {
            gridRef.current?.api?.setServerSideDatasource(dataSource(value))
        }
    }

    const handleViewOnMap = () => {
        navigate(ROUTES.MAP);
    }

    const handleClearFilters = () => {
        if (gridRef.current) {
            gridRef.current.api.setFilterModel({});
            const columns = gridRef.current.columnApi.getColumnState();
            const savePayload = {
                columnOrder: columns.map((columnValue: ColumnState) => {
                    return { name: columnValue.colId, width: columnValue.width, sort: columnValue.sort, sortIndex: columnValue.sortIndex, pinned: columnValue.pinned };
                }),
                filterModel: {}
            };
            if (!isEqual(savePayload, { columnOrder, filterModel })) {
                dispatch(callUpdateUserPreferences(JSON.stringify(savePayload)));
            }
            gridRef.current.api.setServerSideDatasource(dataSource(selectedArea))
        }
    }

    const handleExportCsv = async () => {
        const reqParams = {
            startRow: 0,
            endRow: 0,
            filterModel,
            sortModel: [],
        }
        const { rowCount, rowData, summary } = await getTrackerDataPaged(selectedArea, reqParams);
        const transformedResponse = transformJobTrackerResponsePayload(rowData);
        dispatch(setDownloadRowData(transformedResponse.trackerData));
    }

    const dataSource = (areaId: number): IServerSideDatasource => ({
        getRows: async (params) => {
            if (avoidServerCallRef.current) {
                const colDef = generateGridConfig(gridDataSample.trackerData, areaId, columnOrder);
                params.columnApi.setPivotResultColumns([...colDef]);
                setColDef([...colDef]);
                if (filterModel && Object.keys(filterModel)?.length) {
                    params.api.setFilterModel(filterModel);
                }
                params.success({
                    rowData: [{}],
                    rowCount: 100000
                });
                return;
            }

            const { rowCount, rowData, summary } = await getTrackerDataPaged(areaId, params.request);
            const { trackerData } = transformJobTrackerResponsePayload(rowData);
            setTrackerSummary({ ...summary, rowCount })
            params.success({
                rowData: trackerData,
                rowCount
            });
            const defaultPage = parseInt(sessionStorage.getItem(SESSION_KEYS.DASHBOARD_PAGE) || '0');
            if (defaultPage !== params.api.paginationGetCurrentPage()) {
                params.api.paginationGoToPage(defaultPage);
            }
            // setTimeout(() => {
            //     if (jobId) {
            //         params.api.ensureNodeVisible(({ data }: { data: any }) => {
            //             if (data == null || !jobId) return false;
            //             if (data.id === jobId) console.log(data);
            //             return data.id == jobId;
            //         }, 'middle');
            //         if (location.state?.jobId) {
            //             location.state.jobId = 0;
            //         }
            //     }
            // });
        }
    });

    return <>
        <GridCsvDownload colDef={colDef} fileName={`Tracker_Summary_Area_${selectedArea}.csv`} />
        <div className="job-tracker-wrapper">
            <div className="flex-row space-between">
                <div className="select-wrapper">
                    <BighamSelect
                        value={selectedArea}
                        onChange={handleAreaChange}
                        fieldName={'area'}
                        label="Area"
                        listItems={areaList}
                    />
                </div>
                {permissions.jobPermissions?.canAddJobs && <div className="button-wrapper">
                    <BighamButton
                        label="Add Job"
                        type="primary"
                        variant="contained"
                        onClick={() => navigate(`${ROUTES.JOB_DETAILS}/new`, { state: { location } })}
                    />
                </div>}
            </div>
            <div className="grid-header">
                <div className="header-left">
                    {!!Object.keys(filterModel)?.length && <div className="clickable-link" onClick={handleClearFilters}>
                        <span>
                            Clear Filters
                        </span>
                        <FilterListOffOutlinedIcon />
                    </div>}
                    <div className="clickable-link" onClick={handleViewOnMap}>
                        <span>
                            Show on Map
                        </span>
                        <PinDropOutlinedIcon />
                    </div>
                    <div className="clickable-link" onClick={handleExportCsv}>
                        <span>
                            Export to .csv
                        </span>
                        <InputOutlinedIcon />
                    </div>
                </div>
                <div className="header-middle">
                    <div className="legend-wrapper">
                        <div className="rec-date-wrapper">
                            Rec. Date: 
                        </div>
                        <div className="rec-date-wrapper yellow">
                            6-8 Days
                        </div>
                        <div className="rec-date-wrapper orange">
                            8-10 Days
                        </div>
                        <div className="rec-date-wrapper red">
                            10+ Days
                        </div>
                    </div>
                </div>
                <div className="header-right">
                    <div className="summary-wrapper">
                        <span>
                            Totals
                        </span>
                        <span>
                            <i>Aerial:</i>
                            <span><span className="red">{formatNumberWithCommas(trackerSummary.aerialFootage)}</span> | {formatNumberWithCommas(trackerSummary.aerialFootageComplete)}</span>
                        </span>
                        <span>-</span>
                        <span>
                            <i>UG:</i>
                            <span><span className="red">{formatNumberWithCommas(trackerSummary.undergroundFootage)}</span> | {formatNumberWithCommas(trackerSummary.undergroundFootageComplete)}</span>
                        </span>
                    </div>
                    <span className="summary-total">
                        ({trackerSummary.rowCount} entries found)
                    </span>
                </div>
            </div>
            {prefResolved && <JobTrackerGrid
                ref={gridRef}
                avoidServerCall={avoidServerCallRef.current}
                setAvoidServerCall={setAvoidServerCall}
                dataSource={dataSource}
                areaId={selectedArea}
            />}
        </div>
    </>;
}

export default JobTracker;