import {
  CellClassParams,
  ColDef,
  ColumnPinnedType,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
  IDateFilterParams,
  KeyCreatorParams
} from 'ag-grid-community';
import { find, isNil, isNull, map, uniq, uniqBy } from 'lodash';
import moment from 'moment';
import FootageDisplayRenderer from '../../library/components/GridComponents/FootageDisplayRenderer';
import { dateFormat } from '../../library/constants/dateFormat';
import { formatDate } from '../../library/transforms/utilTransforms';
import { formatNumberWithCommas } from '../../library/util/numberFormatter';
import { getGridFilterData } from '../../main/api/lookupApi';
import { ILookupItem } from '../../main/types/genericTypes';


const getLabelValue = (key: string): string => {
  const result = key.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

interface DateColumnDef extends ColDef {
  filterParams: IDateFilterParams
}

const generateGridConfig = (rowData: any[], areaId: number, preferences?: any) => {
  const arrayOfKeys = rowData.map((rowItem) => Object.keys(rowItem));
  let providedDataKeysArr: string[] = [];
  arrayOfKeys.forEach((keyCollection) => {
    providedDataKeysArr = providedDataKeysArr.concat(keyCollection);
  });
  const providedDataKeys = uniq(providedDataKeysArr);
  const returnArray: ColDef[] = [];
  if (providedDataKeys?.length) {
    let columnOrder: { name: string }[] = providedDataKeys.map(
      (value: string): { name: string } => ({ name: value })
    );
    // subtract 2 from the default column order for the coordinates, and the id on each record.
    if (preferences?.length && preferences.length >= columnOrder?.length - 2) {
      columnOrder = [...preferences];
    }
    columnOrder.forEach((order: { name: string, width?: number, sort?: 'asc' | 'desc' | null | undefined, sortIndex?: number, pinned?: ColumnPinnedType }) => {
      const foundData = find(rowData, (row) => !isNil(row[order.name]));
      const data = foundData?.[order.name];
      const width = order.width || 200;
      const sort = order.sort || null;
      const sortIndex = order.sortIndex || undefined;
      const pinned = order.pinned || null;
      if (!isNil(data)) {
        if (order.name === 'area') {
          return;
        }
        if (typeof data === 'number' && order.name === 'remainingOnPurchaseOrder'){
          returnArray.push({
            ...DefaultColumnValues(width, sort, sortIndex, pinned),
            ...MoneyColumnDef(order.name, getLabelValue(order.name), true)
          });
        } else if (typeof data === 'number' && order.name == 'documents') {
          returnArray.push({
            ...DefaultColumnValues(width, sort, sortIndex, pinned),
            ...UnsortableNumberColumnDef(order.name, getLabelValue(order.name))
          });
        } else if (typeof data === 'number' && order.name !== 'id') {
          returnArray.push({
            ...DefaultColumnValues(width, sort, sortIndex, pinned),
            ...NumberColumnDef(order.name, getLabelValue(order.name), true)
          });
        } else if (moment(data, moment.ISO_8601, true).isValid()) {
          returnArray.push({
            ...DefaultColumnValues(width, sort, sortIndex, pinned),
            ...DateColumnDef(
              order.name,
              getLabelValue(order.name),
              dateFormat,
              true
            )
          });
        } else if ((order.name == 'aerial' || order.name === 'underground')) {
            returnArray.push({
              ...DefaultColumnValues(width, sort, sortIndex, pinned),
              ...FootageDisplayColumnDef(order.name, getLabelValue(order.name))
              
            })
        } else if (typeof data === 'string' && order.name === 'locateNumbers') {
          returnArray.push({
            ...DefaultColumnValues(width, sort, sortIndex, pinned),
            ...StringColumnDef(order.name, getLabelValue(order.name), true),
            sortable: false
          });
        } else if (typeof data === 'string') {
          returnArray.push({
            ...DefaultColumnValues(width, sort, sortIndex, pinned),
            ...StringColumnDef(order.name, getLabelValue(order.name), true)
          });
        } else if (data instanceof Array && Array.isArray(data) && (order.name === 'status' || order.name === 'customerCoordinators')) {
          returnArray.push({
            ...DefaultColumnValues(width, sort, sortIndex, pinned),
            ...ArrayColumnDef(
              order.name,
              getLabelValue(order.name),
              true,
              areaId
            ),
            sortable: true
          });
        } else if (data instanceof Array && Array.isArray(data)) {
          returnArray.push({
            ...DefaultColumnValues(width, sort, sortIndex, pinned),
            ...ArrayColumnDef(
              order.name,
              getLabelValue(order.name),
              true,
              areaId
            )
          });
        } else if (typeof data === 'object' && order.name !== 'coordinates') {
          returnArray.push({
            ...DefaultColumnValues(width, sort, sortIndex, pinned),
            ...ObjectColumnDef(
              order.name,
              getLabelValue(order.name),
              true,
              areaId
            )
          });
        }
      }
    });
  }
  return returnArray;
};

const FootageDisplayColumnDef = (
  fieldName: string,
  label: string,
): ColDef => ({
  field: fieldName,
  headerName: label,
  sortable: true,
  filter: 'agNumberColumnFilter',
  suppressMenu: false,
  cellRenderer: FootageDisplayRenderer,
})

const DefaultColumnValues = (
  width?: number,
  sort?: 'asc' | 'desc' | null | undefined,
  sortIndex?: number,
  pinned?: ColumnPinnedType
): ColDef => {
  return {
    initialSort: sort,
    initialSortIndex: sortIndex,
    initialWidth: width,
    pinned,
    autoHeight: true,
    wrapText: true
  }
}

const DateColumnDef = (
  fieldName: string,
  label: string,
  format: string,
  enableFilter: boolean,
): DateColumnDef => {
  return {
    field: fieldName,
    headerName: label,
    filter: enableFilter ? 'agDateColumnFilter' : '',
    sortable: enableFilter,
    suppressMenu: !enableFilter,
    comparator: dateSortComparator(format),
    filterParams: {
      comparator: dateFilterComparator(format),
      inRangeFloatingFilterDateFormat: format
    },
    cellClass: (params: CellClassParams) => getDateCellClass(params, fieldName),
    valueGetter: dateFormatter(format, fieldName)
  };
};

const getDateCellClass = (
  params: CellClassParams,
  fieldName: string
): string => {
  const dataTime = moment(params.value);
  const now = moment(new Date());
  const age = now.diff(dataTime, 'days');
  let timePeriod = '';
  if (age > 10) {
    timePeriod = 'error';
  } else if (age <= 10 && age > 8) {
    timePeriod = 'warn';
  } else if (age >= 6) {
    timePeriod = 'caution';
  }
  return `${fieldName} ${timePeriod}`;
};

const StringColumnDef = (
  fieldName: string,
  label: string,
  enableFilter: boolean,
): ColDef => {
  return {
    field: fieldName,
    headerName: label,
    filter: enableFilter ? 'agTextColumnFilter' : '',
    sortable: enableFilter,
    suppressMenu: !enableFilter,
    valueGetter: (params: ValueGetterParams): string => {
      return params.data[fieldName];
    }
  };
};

const UnsortableNumberColumnDef = (
  fieldName: string,
  label: string,
): ColDef => {
  const colDef: ColDef = {
    field: fieldName,
    headerName: label,
    filter: false,
    sortable: false,
    suppressMenu: true,
    cellClass: (): string => {
      return `number-column ${fieldName.replace(/[^a-z0-9]/gi, '-')}`;
    },
    type: 'rightAligned',
    valueFormatter: (params: ValueFormatterParams) => {
      return formatNumberWithCommas(params.data[fieldName]);
    }
  };
  return colDef;
}

const NumberColumnDef = (
  fieldName: string,
  label: string,
  enableFilter: boolean,
): ColDef => {
  const colDef: ColDef = {
    field: fieldName,
    headerName: label,
    filter: enableFilter ? 'agNumberColumnFilter' : '',
    sortable: enableFilter,
    suppressMenu: !enableFilter,
    cellClass: (): string => {
      return `number-column ${fieldName.replace(/[^a-z0-9]/gi, '-')}`;
    },
    type: 'rightAligned',
    valueFormatter: (params: ValueFormatterParams) => {
      return formatNumberWithCommas(params.data[fieldName]);
    }
  };
  return colDef;
};

const ArrayColumnDef = (
  fieldName: string,
  label: string,
  enableFilter: boolean,
  areaId?: any
): ColDef => {
  const colDef: ColDef = {
    field: fieldName,
    headerName: label,
    valueGetter: (params: ValueGetterParams): string => {
      return map(params.data[fieldName], (value: ILookupItem) => {
        return value.name;
      }).join(', ');
    },
    suppressMenu: !enableFilter,
  };

  if (enableFilter) {
    colDef.sortable = false;
    colDef.filter = 'agSetColumnFilter';
    if (areaId) {
      colDef.filterParams = {
        defaultToNothingSelected: true,
        values: async (params: any) => {
            const results = await getGridFilterData(areaId, fieldName)
            params.success(results)
        },
        keyCreator: (params: KeyCreatorParams) => {
          return params.value.id
        },
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value.name;
        },
        comparator: (a: string, b: string) => {
            const valA = parseInt(a);
            const valB = parseInt(b);
            if (valA === valB) return 0;
            return valA > valB ? -1 : 1;
        }
      };
    }
  }
  return colDef;
};

const ObjectColumnDef = (
  fieldName: string,
  label: string,
  enableFilter: boolean,
  areaId?: number
): ColDef => {
  const colDef: ColDef = {
    field: fieldName,
    headerName: label,
    suppressMenu: !enableFilter,
    cellClass: (params: CellClassParams): string => {
      if (params.data[fieldName]?.name) {
        return `${fieldName} ${params.data[fieldName].name
          .toLowerCase()
          .replace(/[^a-z0-9]/gi, '-')}`;
      } else {
        return '';
      }
    },
    valueGetter: (params: ValueGetterParams): string => {
      if (params.data[fieldName]?.name) {
        return params.data[fieldName].name;
      } else {
        return '';
      }
    }
  };
  if (enableFilter) {
    colDef.sortable = true;
    colDef.filter = 'agSetColumnFilter';
    if (areaId) {
      colDef.filterParams = {
        defaultToNothingSelected: true,
        values: async (params: any) => {
            const results = await getGridFilterData(areaId, fieldName)
            params.success(results)
        },
        keyCreator: (params: KeyCreatorParams) => {
          return params.value.id
        },
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value.name;
        },
        comparator: (a: string, b: string) => {
            const valA = parseInt(a);
            const valB = parseInt(b);
            if (valA === valB) return 0;
            return valA > valB ? -1 : 1;
        }
      };
    }
  }
  return colDef;
};

const MoneyColumnDef = (
  fieldName: string,
  label: string,
  enableFilter: boolean
): ColDef => {
  const colDef: ColDef = {
    field: fieldName,
    headerName: label,
    filter: enableFilter ? 'agNumberColumnFilter' : '',
    sortable: enableFilter,
    suppressMenu: !enableFilter,
    type: 'rightAligned',
    valueFormatter: (params: ValueFormatterParams) => {
      return `$${formatNumberWithCommas(parseFloat(params.value).toFixed(2))}`;
    }
  };
  return colDef;
};

const PercentColumnDef = (
  fieldName: string,
  label: string,
  enableFilter: boolean,
  width?: number
): ColDef => {
  const colDef: ColDef = {
    field: fieldName,
    headerName: label,
    filter: enableFilter ? 'agNumberColumnFilter' : '',
    sortable: enableFilter,
    suppressMenu: !enableFilter,
    initialWidth: width,
    type: 'rightAligned',
    valueFormatter: (params: ValueFormatterParams) => {
      return `${params.value}%`;
    }
  };
  return colDef;
};

const generateFilterOptions = (
  rowData: any[],
  fieldName: string,
  isTypeArray: boolean
): ILookupItem[] => {
  if (!rowData?.length) {
    return [];
  }
  if (isTypeArray) {
    let array: ILookupItem[] = [];
    rowData.forEach((row: any) => {
      array = array.concat(row[fieldName]);
    });
    return uniqBy(array, 'id');
  } else {
    const options: ILookupItem[] = [];
    rowData.forEach((row: any) => {
      if (row[fieldName]) {
        options.push({ id: row[fieldName].id, name: row[fieldName].name });
      }
    });
    return uniqBy(options, 'id');
  }
};

export const moneyFormatter =
  (fieldName: string) =>
  (params: ValueGetterParams): string => {
    if (params.data[fieldName]) {
      return `$${formatNumberWithCommas(
        parseFloat(params.data[fieldName]).toFixed(2)
      )}`;
    } else {
      return '';
    }
  };

export const dateFormatter =
  (format: string, fieldName: string) => (params: ValueGetterParams) => {
    if (params.data[fieldName]) {
      return formatDate(params.data[fieldName], format);
    } else {
      return '';
    }
  };

export const dateSortComparator = (format: string) => (arg1: string, arg2: string) => {
  if (!arg1 && !arg2) { 
    return 0;
  }
  if (!arg1) {
    return -1
  }
  if (!arg2) {
    return 1;
  }
  const date1 = moment(arg1, format);
  const date2 = moment(arg2, format);
  const diff = date1.diff(date2, 'days');
  return diff === 0 ? 0 : diff > 0 ? 1 : -1;
}

export const dateFilterComparator = (format: string) => (filterLocalDateAtMidnight: Date, cellValue: string) => {
  const cellDate = moment(cellValue, format).toDate()
  if (cellDate == null) return -1;
  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

export const footageComparator = (arg1: any, arg2: any, nodeA: IRowNode<any>, nodeB: IRowNode<any>) => {
  const val1 = parseInt(arg1.split('/')[0]) || 0;
  const val2 = parseInt(arg2.split('/')[0]) || 0;
  const diff = val1 - val2;
  return diff === 0 ? 0 : diff > 0 ? 1 : -1;
}

export {
  generateGridConfig,
  DefaultColumnValues,
  DateColumnDef,
  NumberColumnDef,
  MoneyColumnDef,
  PercentColumnDef,
  StringColumnDef,
  ObjectColumnDef,
  ArrayColumnDef,
  generateFilterOptions
};