import { AgGridReact } from 'ag-grid-react';
import React, { CellClickedEvent, ColDef, GridReadyEvent, GridSizeChangedEvent, RowDoubleClickedEvent} from 'ag-grid-community';
import { ChangeEvent, FC, ForwardedRef, MouseEvent, useEffect, useRef, useState } from 'react';
import MultiSelectFilter from '../GridComponents/GridMultiSelectFilter';
import { forwardRef } from 'react';
import ValueRenderer from '../GridComponents/ValueRenderer';
import BooleanRenderer from '../GridComponents/BooleanRenderer';
import GigRenderer from '../GridComponents/GigRenderer';
import EditQualityControlRenderer from '../GridComponents/EditQualityControlRenderer';
import { dateFormatter } from '../../../pages/JobTracker/trackerColumnFactory';
import { ITeamJobCode } from '../../../main/types/jobDetailTypes';
import BackChargesRenderer from '../GridComponents/BackChargesRenderer';
import { IProductionReportsPermissions } from '../../../main/types/permissionTypes';
import variables from '../../../assets/styles/variables.module.scss';
import DailyProductionReportModal from './NewFootageModal';



interface DailyProductionReportModalGridProps {
    listItems: ITeamJobCode[];
    ref: ForwardedRef<AgGridReact>;
    isDailyApproved?: boolean;
    launchBackChargesModal?(data: ITeamJobCode): (e: MouseEvent<Element>) => void;
    permissions: IProductionReportsPermissions | undefined
}

const DailyProductionReportModalGrid: FC<DailyProductionReportModalGridProps> = forwardRef<AgGridReact, DailyProductionReportModalGridProps>(({listItems, isDailyApproved = false, launchBackChargesModal, permissions}, ref) => {
    const filteredItems = listItems.filter((jobCode) => jobCode.isCrewSubCode);
    const [gridHeight, setGridHeight] = useState('auto');
    const gridWrapperRef = useRef<HTMLDivElement | null>(null);
    let columnDefs: ColDef[] = [
        {
            field: 'code',
            headerName: 'Job Code'
        },{
            field: 'description',
            headerName: 'Description'
        },{
            field: 'productionQuantity',
            headerName: 'Footage',
            type: 'rightAligned',
            width: 100,
            maxWidth: 100,
            minWidth: 100
        },{
            field: 'productionFootageComments',
            headerName: 'Footage Comments'
        },{
            field: 'productionComments',
            headerName: 'Comments'
        },{
            field: 'unbilledQuantity',
            headerName: 'Unbilled Qty'
        }
    ];

    if (isDailyApproved) {
        columnDefs = columnDefs.concat([
            {
                field: 'backCharge.quantity',
                headerName: "BC Qty"
            },{
                field: 'backCharge.comments',
                headerName: 'BC Comments'
            }
        ]);
        if (permissions?.canSubmitBackCharge) {
            columnDefs.push({
                field: 'backCharge.id',
                headerName: 'Back Charges',
                width: 130,
                minWidth: 130,
                maxWidth: 130,
                cellRenderer: BackChargesRenderer,
                cellRendererParams: {
                    onButtonClick: launchBackChargesModal
                },
                type: 'rightAligned',
                sortable: false,
                suppressFiltersToolPanel: true
            });
        }
    }

    const handleSetGridHeight = () => {
        if (gridWrapperRef?.current) {
            const offset = gridWrapperRef.current.getBoundingClientRect().top;
            setGridHeight(`calc(100vh - (${offset}px + ${variables.spacing_standard}))`);
        }else {
            setGridHeight('auto');
        }
    }

    useEffect(() => {
        if (gridWrapperRef.current) {
            handleSetGridHeight();
            window.addEventListener('resize', handleSetGridHeight);
        }
        return () => {
            window.removeEventListener('resize', handleSetGridHeight);
        }
    }, [gridWrapperRef.current])

    return <div ref={gridWrapperRef} className="grid-wrapper ag-theme-material">
       {!!filteredItems?.length && <AgGridReact
            ref={ref}
            components={{
                multiSelect: MultiSelectFilter
            }}
            domLayout="autoHeight"
            defaultColDef={
                {
                    resizable: true,
                    cellRenderer: ValueRenderer,
                    sortable: true,
                    menuTabs: ['filterMenuTab'],
                }
            }
            columnDefs={columnDefs}
            rowData={filteredItems}
            onGridReady={(event: GridReadyEvent) => {
                event.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(event: GridSizeChangedEvent) => {
                event.api.sizeColumnsToFit();
            }}
        />}
    </div>
})

export default DailyProductionReportModalGrid;