import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ChangeEvent, FC, useRef, useState } from "react";
import { IJobCodeLineItem, IJobLocates } from "../../../../main/types/jobDetailTypes";
import { IJobLocatesPermissions } from "../../../../main/types/permissionTypes";
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import LocatesGrid from "./LocatesGrid";
import LocatesModal from './LocatesModal';
import LocateDatePickerModal from './LocateDatePickerModal';
import { AgGridReact } from "ag-grid-react";
import { RowDoubleClickedEvent, SelectionChangedEvent } from "ag-grid-community";
import { isNil } from "lodash";

interface LocatesSectionProps {
    openSection: string,
    handleAccordionAction(panel: string): (event: ChangeEvent<any>, isExpanded: boolean) => void,
    handleFormChange(value: any, fieldName: string): void,
    setJobDetailModel(model: any): void;
    jobDetailModel: any,
    permissions: IJobLocatesPermissions
}

const LocatesSection: FC<LocatesSectionProps> = ({openSection, handleAccordionAction, setJobDetailModel, handleFormChange, jobDetailModel, permissions}) => {
    const sectionId = 'locates';
    const gridRef = useRef<AgGridReact | null>(null);
    const [detailModalData, setDetailModalData] = useState<IJobLocates | null>(null);
    const [showLocatesModal, setShowLocatesModal] = useState(false);
    const [showDatePickerModal, setShowDatePickerModal] = useState(false);
    const [datePickerField, setDatePickerField] = useState<'locateDigDate' | 'locateUpdate' | ''>('');
    const [hasRowSelections, setHasRowSelections] = useState(false);
    
    const canAddNewRecord = ():boolean => {
        return permissions.canEditLocateDigDate
        || permissions.canEditLocateNumbers
        || permissions.canEditLocateUpdate
    }
    
    const handleHideModal = () => {
        setDetailModalData(null);
        setShowLocatesModal(false);
        setDatePickerField('');
        setShowDatePickerModal(false);
    }

    const handleAddNewLocate = () => {
        setDetailModalData({
            id: 0,
            locateNumber: '',
            locateDigDate: '',
            locateUpdate: ''
        });
        setShowLocatesModal(true);
    }

    const handleDoubleClick = (params: RowDoubleClickedEvent) => {
        if (canAddNewRecord()) {
            const temp: IJobLocates = {...params.data};
            temp.index = jobDetailModel.locates?.findIndex((locate: IJobLocates) => {
                return locate.locateNumber === params.data.locateNumber;
            });
            setDetailModalData(temp);
            setShowLocatesModal(true);
        }
    }

    const handleUpdateLocates = (locate: IJobLocates, isDuplicated = false) => {
        const temp = [...jobDetailModel.locates];
        const { index, ...locateRef } = locate
        if (!isNil(index)) {
            temp.splice(index, 1, locateRef);
        } else {
            temp.push({...locateRef, index: temp.length});
        }
        setJobDetailModel({ ...jobDetailModel, locates: temp });
        if (isDuplicated) {
            setDetailModalData({ ...locateRef, index: undefined, id: 0 });
        }
    }

    const handleSelectionChange = (params: SelectionChangedEvent) => {
        setHasRowSelections(params.api.getSelectedRows()?.length > 0)
    }

    const startBulkChange = (fieldName: 'locateDigDate' | 'locateUpdate') => () => {
        setShowDatePickerModal(true);
        setDatePickerField(fieldName)
    }

    const handleUpdateDate = (value: any, fieldName: string) => () => {
        const selectedRows = gridRef.current?.api.getSelectedRows();
        const tempModel: IJobLocates[] = [...jobDetailModel.locates];
        selectedRows?.forEach((row: IJobLocates) => {
            const index = tempModel.findIndex((value) => value.locateNumber === row.locateNumber);
            const tempValue = { ...tempModel[index], [fieldName]: value };
            tempModel.splice(index, 1, tempValue);
        })
        setJobDetailModel({ ...jobDetailModel, locates: tempModel });
        setHasRowSelections(false);
        handleHideModal();
    }

    const handleDelete = (data: IJobLocates) => {
        const index = jobDetailModel.locates?.findIndex((locate: IJobLocates) => {
            return locate.locateNumber === data.locateNumber;
        });
        const temp = [...jobDetailModel.locates];
        temp.splice(index, 1);
        setJobDetailModel({ ...jobDetailModel, locates: temp });

    }

    return <Accordion className="styled-accordion" expanded={openSection === sectionId} id={sectionId} onChange={handleAccordionAction(sectionId)}>
        <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
        >
            <div className="flex-header">
                <div className="component-title">Locates</div>
            </div>
        </AccordionSummary>
        <AccordionDetails
            style={{flexFlow: 'column'}}
        >
            {showLocatesModal && <LocatesModal 
                onCancel={handleHideModal}
                handleUpdateLocates={handleUpdateLocates}
                data={detailModalData}
                list={jobDetailModel.locates}
                permissions={permissions}
            />}
            {showDatePickerModal && <LocateDatePickerModal
                onCancel={handleHideModal}
                dateType={datePickerField}
                handleUpdateDate={handleUpdateDate}
            />}
            <div className="flex-row reverse-wrap space-between locates-button-wrapper">
                <div className="flex-row button-wrapper">
                    {permissions.canEditLocateDigDate && <BighamButton
                        label={'Change Dig Dates'}
                        type="default"
                        variant="contained"
                        disabled={!hasRowSelections}
                        onClick={startBulkChange('locateDigDate')}
                    />}
                    {permissions.canEditLocateUpdate && <BighamButton
                        label={'Change Update Dates'}
                        type="default"
                        variant="contained"
                        disabled={!hasRowSelections}
                        onClick={startBulkChange('locateUpdate')}
                    />}
                </div>
                {canAddNewRecord() && <BighamButton
                    label={'Add New'}
                    type="primary"
                    variant="contained"
                    onClick={handleAddNewLocate}
                />}
            </div>
            <LocatesGrid
                ref={gridRef}
                listItems={jobDetailModel.locates}
                handleDoubleClick={handleDoubleClick}
                handleSelectionChange={handleSelectionChange}
                handleDelete={handleDelete}
                permissions={permissions}
            />
        </AccordionDetails>
    </Accordion>
}

export default LocatesSection;