import moment from 'moment';
import { FC, useMemo, useState } from 'react';
import { Drawer } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { IAerialTeam, IJobDetailCombined, ISplicingTeam, IUndergroundTeam } from '../../main/types/jobDetailTypes';
import './JobSummaryDrawer.scss';
import { useNavigate } from 'react-router-dom';
import { ILookupItem } from '../../main/types/genericTypes';
import { getNameById, getNamesById } from '../../library/transforms/utilTransforms';
import { find } from 'lodash';
import { dateFormat } from '../../library/constants/dateFormat';

interface JobSummaryDrawerProps {
    jobId: string;
    jobDetails: IJobDetailCombined;
    areaList: ILookupItem[];
    categoryList: ILookupItem[];
    statusList: ILookupItem[];
    bighamCoordinators: ILookupItem[];
    customerCoordinators: ILookupItem[];
    technicalCoordinators: ILookupItem[];
    aerialForeman: ILookupItem[];
    undergroundForeman: ILookupItem[];
    splicers: ILookupItem[]
    setDrawerOpen(arg: boolean): void;
    drawerOpen: boolean;
    drawerWidth: number;
}

const JobSummaryDrawer: FC<JobSummaryDrawerProps> = ({
    jobId,
    jobDetails,
    areaList,
    categoryList,
    statusList,
    bighamCoordinators,
    customerCoordinators,
    technicalCoordinators,
    aerialForeman,
    undergroundForeman,
    splicers,
    setDrawerOpen,
    drawerOpen,
    drawerWidth
}) => {

    const formatDate = (dateString: string): string => {
        return moment(dateString).format(dateFormat);
    }

    const mapForemanName = (teams: any[], foremanLookup: ILookupItem[]):string => {
        if (teams?.length) {
            const temp = teams.map((team: IAerialTeam | IUndergroundTeam | ISplicingTeam) => {
                return find(foremanLookup, (foreman) => foreman.id === team.foremanId)?.name;
            });
            return temp.join(', ');
        } else {
            return '';
        }
    }

    const parsedComments = useMemo(() => {
        return jobDetails.details.comments.split('\n');
    }, [jobDetails.details.comments]);

    return <Drawer
        sx={{
            width: drawerOpen ? drawerWidth : 0,
            maxWidth: '80vw',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: drawerOpen ? drawerWidth : 0,
                maxWidth: '80vw',
                boxSizing: 'border-box',
                marginTop: '80px',
                overflowY: 'visible'
            },
            zIndex: 1,
            marginTop: '80px',
            transition: "all linear .2s"
            
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
    >
        <div className={`expand-collapse-icon`} onClick={() => setDrawerOpen(!drawerOpen)}>
            {drawerOpen ? <ArrowBackIosOutlinedIcon /> : <ArrowForwardIosOutlinedIcon />}
        </div>
        {drawerOpen && <div className="drawer-contents-wrapper">
            <div className="component-title drawer-header">Job Summary - Bigham Internal Job #{jobId}</div>
            <div className="data-row">
                <span className="label">Area:</span>
                <span className="value">{getNameById(jobDetails.details.area, areaList)?.name}</span>
            </div>
            <div className="data-row">
                <span className="label">Category:</span>
                <span className="value">{getNameById(jobDetails.details.category, categoryList)?.name}</span>
            </div>
            <div className="data-row">
                <span className="label">Job Status:</span>
                <span className="value">{getNamesById(jobDetails.details.status, statusList)?.map((status) => status.name)?.join(', ')}</span>
            </div>
            <div className="data-row">
                <span className="label">Job #:</span>
                <span className="value">{jobDetails.details.job}</span>
            </div>
            {jobDetails.details.node && <div className="data-row">
                <span className="label">Node:</span>
                <span className="value">{jobDetails.details.node}</span>
            </div>}
            {jobDetails.details.cpr && <div className="data-row">
                <span className="label">CPR:</span>
                <span className="value">{jobDetails.details.cpr}</span>
            </div>}
            <div className="data-row">
                <span className="label">Bigham Coordinator:</span>
                <span className="value">{getNameById(jobDetails.details.bCoord, bighamCoordinators)?.name}</span>
            </div>
            <div className="data-row">
                <span className="label">Customer Coordinators:</span>
                <span className="value">{getNamesById(jobDetails.details.cCoords, customerCoordinators)?.map((coordinator) => coordinator.name)?.join(', ')}</span>
            </div>
            <div className="data-row">
                <span className="label">Tech Ops Coordinator:</span>
                <span className="value">{getNameById(jobDetails.details.tCoord, technicalCoordinators)?.name}</span>
            </div>
            <div className="data-row">
                <span className="label">Received Date:</span>
                <span className="value">{!!jobDetails.details.recDate && formatDate(jobDetails.details.recDate)}</span>
            </div>
            <div className="data-row">
                <span className="label">Due Date:</span>
                <span className="value">{!!jobDetails.details.dueDate && formatDate(jobDetails.details.dueDate)}</span>
            </div>
            <div className="data-row">
                <span className="label">TSD Date:</span>
                <span className="value">{!!jobDetails.details.tsdDate && formatDate(jobDetails.details.tsdDate)}</span>
            </div>
            <div className="data-row">
                <span className="label">Completion Date:</span>
                <span className="value">{!!jobDetails.details.completeDate && formatDate(jobDetails.details.completeDate)}</span>
            </div>
            <div className="data-row">
                <span className="label">Business Name:</span>
                <span className="value">{jobDetails.details.business}</span>
            </div>
            <div className="data-row">
                <span className="label indent">Address:</span>
                <span className="value">{jobDetails.details.address1}</span>
            </div>
            <div className="data-row">
                <span className="label indent">City:</span>
                <span className="value">{jobDetails.details.city}</span>
            </div>
            <div className="data-row">
                <span className="label indent">State</span>
                <span className="value">{jobDetails.details.state}</span>
            </div>
            <div className="data-row">
                <span className="label indent">Zip:</span>
                <span className="value">{jobDetails.details.zip}</span>
            </div>
            <div className="data-row flex-start">
                <span className="label">Comments:</span>
                <span className="value">{parsedComments.map((comment, index) => {
                    return <span key={index}>{comment}<br/></span>
                })}</span>
            </div>
            <div className="data-row">
                <span className="label">Aerial Teams:</span>
                <span className="value indent">{mapForemanName(jobDetails.aerial.teams, aerialForeman)}</span>
            </div>
            <div className="data-row">
                <span className="label">Aerial Completed Date:</span>
                <span className="value indent">{!!jobDetails.aerial.completeDate && formatDate(jobDetails.aerial.completeDate)}</span>
            </div>
            <div className="data-row">
                <span className="label">Underground Teams:</span>
                <span className="value indent">{mapForemanName(jobDetails.underground.teams, undergroundForeman)}</span>
            </div>
            <div className="data-row">
                <span className="label">Underground Completed Date:</span>
                <span className="value indent">{!!jobDetails.underground.completeDate && formatDate(jobDetails.underground.completeDate)}</span>
            </div>
            <div className="data-row">
                <span className="label">Splicing Teams:</span>
                <span className="value indent">{mapForemanName(jobDetails.splicer.teams, splicers)}</span>
            </div>
            <div className="data-row">
                <span className="label">Splicing Completed Date:</span>
                <span className="value indent">{!!jobDetails.splicer.completeDate && formatDate(jobDetails.splicer.completeDate)}</span>
            </div>
        </div>}
    </Drawer>
}

export default JobSummaryDrawer;