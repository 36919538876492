import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { ILookupItem } from "../../../../main/types/genericTypes";
import { ISplicingTeam } from "../../../../main/types/jobDetailTypes";
import { ISplicerPermissions } from "../../../../main/types/permissionTypes";
import { IJobTeamErrors } from "../../JobDetails";
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import BighamDatePicker from "../../../../library/components/FormControls/BighamDatePicker/BighamDatePicker";
import SplicerCard from "./SplicerCard/SplicerCard";

interface SplicingSectionProps {
    openSection: string,
    handleAccordionAction(panel: string): (event: ChangeEvent<any>, isExpanded: boolean) => void,
    handleAddNewCard(addData: any, fieldName: string): () => void,
    handleFormChange(value: any, fieldName: string): void,
    jobDetailModel: any,
    lookups: { [key: string]: ILookupItem[] },
    permissions: ISplicerPermissions,
    setErrors(errors: IJobTeamErrors): void,
    errors: IJobTeamErrors
}

const SplicingSection: FC<SplicingSectionProps> = ({openSection, handleAccordionAction, handleAddNewCard, handleFormChange, jobDetailModel, lookups, permissions, errors, setErrors}) => {
    const sectionId = 'splicer';
    const canAddNewCard = (): boolean => {
        return permissions.canEditInstaller
        || permissions.canEditIsCritical
        || permissions.canEditSplicer
        || permissions.canEditSplicerOptions
        || permissions.canEditSplicerScheduledDate;
    }

    const handleLocalChange = (value: any, fieldName: string) => {
        const temp = {...errors};
        delete errors?.[sectionId]?.completeDate;
        setErrors(temp);
        handleFormChange(value, fieldName);
    }

    return <Accordion className="styled-accordion" expanded={openSection === sectionId} id={sectionId} onChange={handleAccordionAction(sectionId)}>
        <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
        >
            <div className="flex-header">
                <div className="component-title">Splicing</div>
            </div>
        </AccordionSummary>
        <AccordionDetails
            style={{flexFlow: 'column'}}
        >
            <div className="form">
                <div className="form-row">
                    <BighamDatePicker 
                        label="Complete Date"
                        fieldName="splicer.completeDate"
                        value={jobDetailModel.splicer.completeDate}
                        onChange={handleLocalChange}
                        readOnly={!permissions.canEditCompleteDate}
                        error={errors?.splicer?.completeDate}
                    />
                </div>
            </div>
            <div className="card-container">
                {!!jobDetailModel?.splicer?.teams?.length && jobDetailModel.splicer?.teams?.map((splicerRecord: ISplicingTeam, index: number, collection: ISplicingTeam[]) => 
                    <SplicerCard key={index} index={index} data={splicerRecord} list={collection} installerList={lookups.installerList} foremanList={lookups.foremanList} splicerOptions={lookups.splicerOptions} handleFormChange={handleFormChange} permissions={permissions} errors={errors} setErrors={setErrors} />
                )}
            </div>
            {canAddNewCard() && <div className="flex-row center">
                <BighamButton
                    label={'Add New'}
                    type="primary"
                    variant="contained"
                    onClick={handleAddNewCard({ installDate: '', scheduledSplicerDate: '', completedDate: '', installerId: 0, foremanId: 0, splicerOptionId: 0, isCritical: false }, 'splicer.teams')}
                />
            </div>}
        </AccordionDetails>
    </Accordion>
}

export default SplicingSection;