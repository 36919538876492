import { FC, useState, useRef } from "react"
import ModalBase from "../ModalBase/ModalBase"
import BighamTextInput from "../FormControls/BighamTextInput/BighamTextInput";
import BighamButton from "../FormControls/BighamButton/BighamButton";
import JobCodeGrid from "../JobCodeGrid/JobCodeGrid";
import { AgGridReact } from "ag-grid-react";
import { formatDate } from "../../transforms/utilTransforms";
import BighamCheckbox from "../FormControls/BighamCheckbox/BighamCheckbox";
import { ChangeEvent } from "react";
import BighamDatePicker from "../FormControls/BighamDatePicker/BighamDatePicker";
import { FormControl } from "@material-ui/core";
import { IDailyReport } from "../../../main/types/jobDetailTypes";
import { useAppDispatch } from "../../../main/hooks/hooks";
import { callQualityControlReport } from "../../../main/slices/reportsSlice";
import { dateFormat } from "../../constants/dateFormat";
import { IProductionReportsPermissions } from "../../../main/types/permissionTypes";
import ROUTES from "../../constants/routeConstants";

interface QualityControlModalProps {
    onCancel(): void,
    reportDetails?: IDailyReport,
    permissions: IProductionReportsPermissions | undefined
}


const QualityControlModal: FC<QualityControlModalProps> = ({onCancel, reportDetails, permissions}) => {
    const gridRef = useRef<AgGridReact | null>(null);
    const dispatch = useAppDispatch();
    const [reportDetailsModel, setReportDetailsModel] = useState<any>(reportDetails);

    const handleFormChange = (value: any, fieldName: string) => {
        setReportDetailsModel({ ...reportDetailsModel, [fieldName]: value });
    }

    const handleCheckboxChange = (e: ChangeEvent<{ value: any, checked: boolean }>, fieldName: string) => {
        setReportDetailsModel({ ...reportDetailsModel, [fieldName]: e.target.checked });
    }

    const handleUpdate = () => {
        const payload = {
            id: reportDetailsModel.id,
            qualityControlComments: reportDetailsModel.qualityControlComments,
            gig: reportDetailsModel.gig,
            qualityControlDate: reportDetailsModel.qualityControlDate || null,
            gigRepairDate: reportDetailsModel.gigRepairDate || null,
            gigRepairComments: reportDetailsModel.gigRepairComments
        }
        dispatch(callQualityControlReport(payload));
        onCancel();
    }

    const handleViewMapsClick = () => {
        const url = `${window.location.origin}/${ROUTES.JOB_DETAILS}/${reportDetails?.jobId}?openSection=documents`;
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    return <ModalBase size="large" onCancel={onCancel}>
        <div className="flex-row">
            <span className="modal-title">
                Daily - {formatDate(reportDetails?.dailyDate || '', dateFormat)} - QC
            </span>
            <span className="report-modal-map-link" onClick={handleViewMapsClick}>View Maps</span>
        </div>
        <div className="report-modal-wrapper">
            {!!reportDetails?.jobCodes?.length && <JobCodeGrid 
                listItems={reportDetails?.jobCodes || []}
                ref={gridRef}
                permissions={permissions}
            />}
        </div>
        <div className="form">
            <div className="form-row">
                <BighamDatePicker
                    fieldName="qualityControlDate"
                    value={reportDetailsModel.qualityControlDate}
                    label={'QC Date'}
                    onChange={handleFormChange}
                    readOnly={!permissions?.canEditQualityControlDate}
                />
            </div>
            <div className='form-row'>
                <BighamTextInput
                    fieldName="qualityControlComments"
                    value={reportDetailsModel.qualityControlComments}
                    label="QC Comments"
                    onChange={handleFormChange}
                    readOnly={!permissions?.canEditQualityControlComments}
                />
            </div>
            <div className="form-row">
                <FormControl
                    className="form-control checkbox"
                    component="fieldset"
                    variant="standard"
                >
                    <BighamCheckbox
                        fieldName="gig"
                        value={reportDetailsModel.gig}
                        checked={reportDetailsModel.gig}
                        label={'Gig?'}
                        onChange={handleCheckboxChange}
                        disabled={!permissions?.canEditIsGig}
                    />
                </FormControl>
            </div>
            <div className="form-row">
                <BighamDatePicker
                    fieldName="gigRepairDate"
                    value={reportDetailsModel.gigRepairDate}
                    label={'Gig Repair Date'}
                    onChange={handleFormChange}
                    readOnly={!permissions?.canEditGigRepairDate}
                />
            </div>
            <div className="form-row">
                <BighamTextInput
                    fieldName="gigRepairComments"
                    value={reportDetailsModel.gigRepairComments}
                    label="Gig Repair Comments"
                    onChange={handleFormChange}
                    readOnly={!permissions?.canEditQualityControlComments}
                />
            </div>
        </div>
        <div className="modal-button-container">
            <BighamButton
                type="default"
                variant="contained"
                onClick={onCancel}
                label={'Cancel'}
            />
            {permissions?.canEditDailyQcData && <BighamButton
                type="primary" 
                variant="contained"
                label={`Save`}
                onClick={handleUpdate}
            />}
        </div>
    </ModalBase>
}

export default QualityControlModal;