import moment from 'moment';
import { IJobCode } from '../../main/types/codeTypes';
import { ILookupItem } from '../../main/types/genericTypes';
import { IUserServiceGet } from '../../main/types/userTypes';
import { USER_STATUS } from '../constants/jobCodeStatus';

export const mapUserInfo = (obj: IUserServiceGet): ILookupItem => {
  return {
    id: obj.id,
    name: obj.fullName,
    archived: obj.statusId === USER_STATUS.INACTIVE
  };
};

export const mapArrayUserInfo = (arr: IUserServiceGet[]): ILookupItem[] => {
  return arr.map((item) => {
    return mapUserInfo(item);
  });
};

export const getNameById = (id: number, list: any[]): ILookupItem => {
  const item = list.find((element) => element.id === id);
  return { name: item?.name || '', id: item?.id || 0 };
};

export const getNamesById = (ids: number[], list: any[]): ILookupItem[] => {
  return ids.map((id) => getNameById(id, list));
};

export const mapJobCodes = (jobCodes: IJobCode[]): ILookupItem[] => {
  return jobCodes.map((jobCode: IJobCode) => ({
    id: jobCode.id || 0,
    name: jobCode.code
  }));
};

export const formatDate = (value: string, format: string) => {
  return moment(value).format(format);
};
