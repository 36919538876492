import timer from '../../library/util/timer';
import bighamApi from '../axios/bighamApi';
import {
  IJobDetailAPIUpdatePayload,
  IJobDetailAPIResponse,
  IInvoiceRequestPayload,
  IBackChargeCreatePayload,
  IBackChargeUpdatePayload,
  IIssueInvoicePayload,
  IRejectInvoicePayload
} from '../types/jobDetailTypes';

export const getJobDetailData = async (
  jobId: number
): Promise<{
  data: IJobDetailAPIResponse;
}> => {
  const response = bighamApi({
    method: 'GET',
    url: `jobs/${jobId}`
  });
  return response;
};

export const updateJobDetail = async (
  job: IJobDetailAPIUpdatePayload
): Promise<{
  data: number;
}> => {
  const response = bighamApi({
    method: 'PUT',
    url: `jobs/${job.id}`,
    data: job
  });
  return response;
};

export const createJobDetail = async (
  job: IJobDetailAPIUpdatePayload
): Promise<{
  data: number;
}> => {
  const response = bighamApi({
    method: 'POST',
    url: `jobs`,
    data: job
  });
  return response;
};

export const validateFileNames = async (payload: string[], jobId: number) => {
  const response = bighamApi({
    method: 'POST',
    url: `Jobs/${jobId}/Documents/Validate`,
    data: {
      filenames: payload
    }
  });
  return response;
}

export const uploadFiles = async (
  payload: FormData,
  jobId: string
): Promise<{
  data: number;
}> => {
  const response = bighamApi({
    method: 'POST',
    url: `Jobs/${jobId}/Documents`,
    data: payload,
    headers: {
      'Content-Type': 'form-data'
    }
  });
  return response;
};

export const getInvoiceDailyCSVDownload = async (
  invoiceId: number
): Promise<{ data: Blob }> => {
  const response = bighamApi({
    method: 'GET',
    url: `Jobs/Invoices/${invoiceId}/DailyJobCodes/Csv`,
    responseType: 'blob'
  });
  return response;
};

export const getInvoiceDailyData = async (
  invoiceId: number
) => {
  const response = bighamApi({
    method: 'GET',
    url: `Jobs/Invoices/${invoiceId}/DailyJobCodes`
  });
  return response;
};

export const downloadFile = async (
  fileId: number
): Promise<{
  data: Blob;
}> => {
  const response = bighamApi({
    method: 'GET',
    url: `Documents/${fileId}`,
    responseType: 'blob'
  });
  return response;
};

export const deleteFile = async (
  fileId: number,
): Promise<{
  data: any;
}> => {
  const response = bighamApi({
    method: 'DELETE',
    url: `Documents/${fileId}`,
  });
  return response;
};


export const reviewReport = async (payload: {
  id: number;
  comments: string;
  statusId: number;
}): Promise<{
  data: number;
}> => {
  const { id, ...data } = payload;
  const response = bighamApi({
    method: 'PUT',
    url: `Jobs/Dailies/${id}/Status`,
    data
  });
  return response;
};

export const qualityControlReport = async (payload: {
  id: number;
  qualityControlComments: string;
  gig: boolean;
  gigRepairDate: string;
}): Promise<{ data: number }> => {
  const { id, ...data } = payload;
  const response = bighamApi({
    method: 'PUT',
    url: `Jobs/Dailies/${id}/QualityControl`,
    data
  });
  return response;
};

export const createInvoiceRequest = async (
  payload: IInvoiceRequestPayload
): Promise<{
  data: number;
}> => {
  const { jobId, ...data } = payload;
  const response = bighamApi({
    method: 'POST',
    url: `Jobs/${jobId}/Invoices`,
    data
  });
  return response;
};

export const rejectInvoice = async (
  payload: IRejectInvoicePayload
): Promise<{ data: any}> => {
  const { invoiceId, comments } = payload;
  const response = bighamApi({
    method: 'POST',
    url: `Jobs/Invoices/${invoiceId}/Reject`,
    data: {
      comments
    }
  });
  return response;
}

export const issueInvoiceRequest = async (
  payload: IIssueInvoicePayload
): Promise<{
  data: number;
}> => {
  const { invoiceId, ...data } = payload;
  const response = bighamApi({
    method: 'PUT',
    url: `Jobs/Invoices/${invoiceId}`,
    data
  });
  return response;
};

export const createBackCharge = async (
  payload: IBackChargeCreatePayload
): Promise<{
  data: number;
}> => {
  const { dailyJobCodeId, ...data } = payload;
  const response = bighamApi({
    method: 'POST',
    url: `DailyJobCodes/${dailyJobCodeId}/BackCharges`,
    data
  });
  return response;
};

export const updateBackCharge = async (
  payload: IBackChargeUpdatePayload
): Promise<{
  data: number;
}> => {
  const { backChargeId, ...data } = payload;
  const response = bighamApi({
    method: 'PUT',
    url: `BackCharges/${backChargeId}`,
    data
  });
  return response;
};

export const updateNewDailyFootage = async (
  payload: object,
  dailyId: number | undefined
): Promise<{
  data: number;
}> => {
  const data = payload;
  const response = bighamApi({
    method: 'PUT',
    url: `/Jobs/Dailies/${dailyId}/CompletedFootage`,
    data
  });
  return response;
};

export const createNewPermitEntity = async (payload: {
  name: string;
  dataHandler: (value: any, fieldName: string) => void;
}): Promise<{
  data: number;
}> => {
  const { dataHandler, ...data } = payload;
  const response = bighamApi({
    method: 'POST',
    url: 'PermitEntities',
    data
  });
  return response;
};



