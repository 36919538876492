import { FC } from "react";
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import variables from '../../../../assets/styles/variables.module.scss';

interface PDFTableCellProps {
    label: string,
    flexGrow?: number
}

const styles = StyleSheet.create({
    cell: {
        flexBasis: 0,
        flexGrow: 1,
        flexShrink: 1,
        borderRight: '1px solid black',
        paddingTop: variables.spacing_xxs,
        paddingBottom: variables.spacing_xxs,
        paddingLeft: variables.spacing_xs,
        paddingRight: variables.spacing_xs
    },
    cellValue: {
        fontWeight: 'extrabold',
        fontSize: '10px',
        textAlign: 'center'
    }
})

const PDFTableCell: FC<PDFTableCellProps> = ({ label, flexGrow = 1 }) => {

    return <View style={{...styles.cell, flexGrow }}>
        <Text style={styles.cellValue}>{label}</Text>
    </View>

}

export default PDFTableCell;