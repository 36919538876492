import { ChangeEvent, FC } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ILookupItem } from '../../../../main/types/genericTypes';

interface BighamAutoCompleteProps {
    fieldName: string;
    listItems: ILookupItem[];
    value: ILookupItem;
    label?: string;
    onChange(value: any, fieldName: string): void;
    readOnly?: boolean;
    error?: string;
}

const BighamAutoComplete: FC<BighamAutoCompleteProps> = ({fieldName, listItems, value, label = "", onChange, readOnly = false, error = ""}) => {
  return (
    <Autocomplete
      className="form-control autocomplete"
      disableClearable
      options={listItems}
      onChange={(e, newValue) => onChange(newValue, fieldName)}
      value={value}
      getOptionLabel={(option) => option.name}
      readOnly={readOnly}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          size="small"
          error={!!error.length}
          helperText={error}
        />
      )}
    />
  );
}

export default BighamAutoComplete;