import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { transformJobDetailResponsePayload, transformJobDetailsForDailyModal } from '../../library/transforms/jobDetailsTransform';
import { transformDailyProductionReports, transformQualityControlReports } from '../../library/transforms/reportTransform';
import { reviewReport, qualityControlReport, getJobDetailData } from '../api/jobDetailApi';
import {
  getQualityControlReports,
  getDailyProductionReports,
  getInvoiceReports,
  getWeeklyProductionReports,
  getMyProductionReports
} from '../api/reportsApi';
import type { AppDispatch, RootState } from '../store/store';
import { ILookupItem } from '../types/genericTypes';
import { IDailyReport } from '../types/jobDetailTypes';
import {
  IDailyProductionReport,
  IQualityControlReport,
  IRequestedInvoiceReport,
  IWeeklyProductionReport,
  IMyProductionReport
} from '../types/reportTypes';
import { selectJobDetailData, callGetJobDetailData } from './jobDetailSlice';
import { callGetBighamCoordinatorList, callGetDailyStatusList, selectBighamCoordinatorList, selectDailyStatusList } from './lookupSlice';
import { selectSelectedArea } from './utilSlice';

interface ReportsState {
  dailyProductionReports: IDailyProductionReport[];
  weeklyProductionReports: IWeeklyProductionReport[];
  myProductionReports: IMyProductionReport[];
  qcReports: IQualityControlReport[];
  invoiceReports: IRequestedInvoiceReport[];
}

// Define the initial state using that type
const initialState: ReportsState = {
  dailyProductionReports: [],
  weeklyProductionReports: [],
  myProductionReports: [],
  qcReports: [],
  invoiceReports: []
};

export const callGetDailyProductionReports = createAsyncThunk<any, number, { dispatch: AppDispatch; state: RootState }>(
  'reports/getProdReports',
  async (areaId, { dispatch, getState }) => {
    const response = await getDailyProductionReports(areaId);
    const state = getState();
    let bighamCoords = selectBighamCoordinatorList(state);
    if (!bighamCoords.length) {
      const res = await dispatch(callGetBighamCoordinatorList(areaId));
      bighamCoords = res.payload as ILookupItem[];
    }
    let dailyStatus = selectDailyStatusList(state);
    if (!dailyStatus.length) {
      const res = await dispatch(callGetDailyStatusList());
      dailyStatus = res.payload as ILookupItem[];
    }
    dispatch(setDailyProductionReports(transformDailyProductionReports(response.data, bighamCoords, dailyStatus)));
  }
);

export const callGetQCReports = createAsyncThunk(
  'reports/getQCReports',
  async (args, { dispatch }) => {
    const response = await getQualityControlReports();
    dispatch(setQCReports(transformQualityControlReports(response.data)));
  }
);

export const callReviewDaily = createAsyncThunk<
  any,
  { id: number; comments: string; statusId: number },
  { dispatch: AppDispatch; state: RootState }
>('reports/approveOrRejectReport', async (payload, { dispatch, getState }) => {
  await reviewReport(payload);
  const state = getState();
  const jobDetails = selectJobDetailData(state);
  const selectedArea = selectSelectedArea(state);
  if (jobDetails.details.id) {
    dispatch(callGetJobDetailData(jobDetails.details.id));
  } else {
    dispatch(callGetDailyProductionReports(selectedArea));
  }
});

export const callQualityControlReport = createAsyncThunk<
  any,
  {
    id: number;
    qualityControlComments: string;
    gig: boolean;
    gigRepairDate: string;
  },
  { dispatch: AppDispatch; state: RootState }
>('reports/qualityControlReport', async (payload, { dispatch, getState }) => {
  await qualityControlReport(payload);
  const jobDetails = selectJobDetailData(getState());
  if (jobDetails.details.id) {
    dispatch(callGetJobDetailData(jobDetails.details.id));
  } else {
    dispatch(callGetQCReports());
  }
});

export const callGetInvoiceReports = createAsyncThunk(
  'reports/getInvoiceReports',
  async (areaId: number, { dispatch }) => {
    const response = await getInvoiceReports(areaId);
    dispatch(setInvoiceReports(response.data));
  }
);

export const callGetJobDetailSummary = createAsyncThunk(
  'reports/getJobDetailsSummary',
  async (jobId: number) => {
    const response = await getJobDetailData(jobId);
    const transformedResponse = transformJobDetailResponsePayload(
      response.data,
      []
    );
    return transformJobDetailsForDailyModal(transformedResponse);
  }
)

export const reportsSlice = createSlice({
  name: 'reports',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDailyProductionReports: (
      state,
      action: PayloadAction<IDailyProductionReport[]>
    ) => {
      state.dailyProductionReports = action.payload;
    },
    setWeeklyProductionReports: (
      state,
      action: PayloadAction<IWeeklyProductionReport[]>
    ) => {
      state.weeklyProductionReports = action.payload;
    },
    setMyProductionReports: (
      state,
      action: PayloadAction<IMyProductionReport[]>
    ) => {
      state.myProductionReports = action.payload;
    },
    setQCReports: (state, action: PayloadAction<IQualityControlReport[]>) => {
      state.qcReports = action.payload;
    },
    setInvoiceReports: (state, action: PayloadAction<any[]>) => {
      state.invoiceReports = action.payload;
    }
  }
});

export const {
  setDailyProductionReports,
  setWeeklyProductionReports,
  setMyProductionReports,
  setQCReports,
  setInvoiceReports
} = reportsSlice.actions;

export const selectDailyProductionReports = (state: RootState) =>
  state.reports.dailyProductionReports;
export const selectWeeklyProductionReports = (state: RootState) =>
  state.reports.weeklyProductionReports;
export const selectMyProductionReports = (state: RootState) =>
  state.reports.myProductionReports;
export const selectQCReports = (state: RootState) => state.reports.qcReports;
export const selectInvoiceReports = (state: RootState) =>
  state.reports.invoiceReports;

export default reportsSlice.reducer;
