import React, { FC } from 'react';
import Header from './Header/Header';
import './Layout.scss';


interface LayoutProps {
    children: JSX.Element
}

const Layout: FC<LayoutProps> = ({children}) => {

    return <div className="application-wrapper">
        <Header />
        <div className="component-wrapper">
            {children}
        </div>
    </div>;
}

export default Layout;