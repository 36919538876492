import { transformJobTrackerResponsePayload } from "../transforms/jobTrackerTransform";

export const gridDataSample = transformJobTrackerResponsePayload([
    {
        "id": 0,
        "job": "",
        "category": {
            "id": 0,
            "name": ""
        },
        "address": "",
        "comments": "",
        "documentCount": 0,
        "locateDigDates": [
            {
            "id": 0,
            "name": ""
            }
        ],
        "locateUpdateDates": [
            {
            "id": 0,
            "name": ""
            }
        ],
        "locateNumbers": "",
        "status": [
            {
            "id": 0,
            "name": ""
            }
        ],
        "receivedDate": "2022-10-07T13:37:28Z",
        "completeDate": "2023-01-27T21:55:18Z",
        "bighamCoordinator": {
            "id": 0,
            "name": ""
        },
        "techOpsCoordinator": {
            "id": 0,
            "name": ""
        },
        "customerCoordinators": [
            {
            "id": 0,
            "name": ""
            }
        ],
        "aerial": "0 / 0",
        "underground": "0 / 0",
        "aerialTeams": [
            {
            "id": 0,
            "name": ""
            }
        ],
        "undergroundTeams": [
            {
            "id": 0,
            "name": ""
            }
        ],
        "splicingTeams": [
            {
            "id": 0,
            "name": ""
            }
        ],
        "dueDate": "2022-10-17T13:39:49Z",
        "remainingOnPurchaseOrder": 0,
        "node": "",
        "aerialCompletedDate": "2023-01-23T11:56:29Z",
        "undergroundCompletedDate": "2023-01-23T11:56:29Z",
        "splicingCompletedDate": "2023-01-27T21:55:08Z",
        "tsdDate": "2023-01-23T11:56:29Z",
        "business": "",
        "latitude": "",
        "longitude": ""
    }
]);