import React, { FC, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../main/hooks/hooks';
import './UserDetails.scss';
import { setComponentName, setToastMessage } from '../../main/slices/utilSlice';
import BighamButton from '../../library/components/FormControls/BighamButton/BighamButton';
import { callCreateUser, callGetUserDetails, callUpdateUser, callValidateUser, initialUserState, selectNewUserId, selectUserDetails, setNewUserId, setUserDetails } from '../../main/slices/userSlice';
import { IUserInterface } from '../../main/types/userTypes';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from '../../library/constants/routeConstants';
import BighamTextInput from '../../library/components/FormControls/BighamTextInput/BighamTextInput';
import BighamSelect from '../../library/components/FormControls/BighamSelect/BighamSelect';
import { callGetAccountTypeList, callGetAreaList, callGetOfficeList, callGetStateList, callGetUserAccessLevels, callGetUserEmployeeTitles, callGetUserStatusList, selectAccountTypeList, selectAreaList, selectOfficeList, selectStateList, selectUserAccessLevels, selectUserEmployeeTitles, selectUserStatusList } from '../../main/slices/lookupSlice';
import BighamMultiSelect from '../../library/components/FormControls/BighamMultiSelect/BighamMultiSelect';
import { emailRegex, numberRegex, passwordRegex } from '../../library/constants/regex';
import BighamNumberInput from '../../library/components/FormControls/BighamNumberInput/BighamNumberInput';
import { isEqual } from 'lodash';

const UserDetails: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { userId = '' } = useParams();

    const {
        userDetails,
        stateList,
        userStatusList,
        accountTypeList,
        areaList,
        userEmployeeTitles,
        userAccessLevels,
        newUserId,
        officeList
    } = useAppSelector((state) => ({
        userDetails: selectUserDetails(state),
        stateList: selectStateList(state),
        userStatusList: selectUserStatusList(state),
        accountTypeList: selectAccountTypeList(state),
        areaList: selectAreaList(state),
        userEmployeeTitles: selectUserEmployeeTitles(state),
        userAccessLevels: selectUserAccessLevels(state),
        newUserId: selectNewUserId(state),
        officeList: selectOfficeList(state)
    }));

    useEffect(() => {
        dispatch(setComponentName('User Details'));
        if (!isNewUser()) {
            dispatch(callGetUserDetails(+userId));
        }
        !stateList?.length && dispatch(callGetStateList());
        !accountTypeList?.length && dispatch(callGetAccountTypeList());
        !userStatusList?.length && dispatch(callGetUserStatusList());
        !areaList?.length && dispatch(callGetAreaList());
        !userAccessLevels?.length && dispatch(callGetUserAccessLevels());
        !userEmployeeTitles?.length && dispatch(callGetUserEmployeeTitles());
        !officeList?.length && dispatch(callGetOfficeList());

        return () => {
            dispatch(setUserDetails(initialUserState));
        }
    },[]);

    const [localUserDetails, setLocalUserDetails] = useState<any>(initialUserState);
    const [errors, setErrors] = useState<{ [key: string] : string }>({});

    useEffect(() => {
        if (userDetails) {
            setLocalUserDetails(userDetails);
        }
    }, [userDetails])

    useEffect(() => {
        if (newUserId) {
            navigate(`${ROUTES.USERS}/${newUserId}`)
        }
        return () => {
            dispatch(setNewUserId(0));
        }
    }, [newUserId]);

    const handleSaveClick = () => formValidation(localUserDetails);

    const handleSave = async (values: IUserInterface) => {
        const [emailValid, usernameValid] = await Promise.all([
            dispatch(callValidateUser({ email: values.email })),
            dispatch(callValidateUser({ username: values?.username || '' }))
        ]);
        const isEmailValid = (emailValid.payload || isEqual(values.email, userDetails.email))
        const isUsernameValid = (usernameValid.payload || isEqual(values.username, userDetails.username));
        const invalidItem = !isEmailValid ? 'email address' : !isUsernameValid ? 'username' : '';
        if (isEmailValid && isUsernameValid) {
            if (isNewUser()) {
                dispatch(callCreateUser(values));
            } else {
                dispatch(callUpdateUser(values));
            }
        } else {
            dispatch(setToastMessage({
                type: 'error',
                message: `This ${invalidItem} already exists in our system. Please try again with a different ${invalidItem}.`
            }));
            setErrors({ [!isEmailValid ? 'email' : 'username']: `This ${invalidItem} is already in use in our system` })
        }
    }

    const isNewUser = () => userId === 'new';

    const handleBack = () => {
        navigate(ROUTES.USERS);
    }

    const handlePhoneNumberChange = (value: string, fieldName: string) => {
        const test = value.match(numberRegex)?.join('');
        if ((test && test.length > 0) || value === '') {
            setLocalUserDetails({ ...localUserDetails, [fieldName]: test });
            const temp = {...errors};
            temp[fieldName] && delete temp[fieldName];
            setErrors(temp);
        }
    }

    const formatNumber = (value: any, fieldName: string) => {
        const cleanedString: string = value.match(numberRegex).join('');
        const formattedString = `(${cleanedString.substring(0, 3)}) ${cleanedString.substring(3, 6)}-${cleanedString.substring(6, 10)}`;
        setLocalUserDetails({...localUserDetails, [fieldName]: formattedString});
    }

    const handleFormChange = (value: any, fieldName: string) => {
        setLocalUserDetails({ ...localUserDetails, [fieldName]: value });
        const temp = {...errors};
        temp[fieldName] && delete temp[fieldName];
        setErrors(temp);
    }

    const formValidation = (values: IUserInterface) => {
        const tempErrors: { [key: string] : string } = {...errors};
        
        if (!values.username) {
            tempErrors.username = 'Required';
        }
        if (!values.email) {
            tempErrors.email = 'Required';
        } else if (!emailRegex.test(values.email)) {
            tempErrors.email = 'Please enter a valid email address';
        }

        if (!values.firstName) {
            tempErrors.firstName = 'Required';
        }
        if (!values.lastName) {
            tempErrors.lastName = 'Required';
        }

        if (!values.address) {
            tempErrors.address = 'Required';
        }
        if (!values.city) {
            tempErrors.city = 'Required';
        }
        if (!values.state) {
            tempErrors.state = 'Required';
        }
        if (!values.zip) {
            tempErrors.zip = 'Required';
        }
        if (!values.phoneNumber) {
            tempErrors.phoneNumber = 'Required';
        } else if (values.phoneNumber.length !== 14) {
            tempErrors.phoneNumber = 'Please enter a valid number';
        }
        if (!values.mobileNumber) {
            tempErrors.mobileNumber = 'Required';
        } else if (values.mobileNumber.length !== 14) {
            tempErrors.mobileNumber = 'Please enter a valid number';
        }
        if (!values.statusId) {
            tempErrors.statusId = 'Required';
        }
        if (!values.areaIds?.length) {
            tempErrors.areaIds = 'Required';
        }
        if (!values.officeId) {
            tempErrors.officeId = 'Required';
        }
        if (!values.accessLevelId) {
            tempErrors.accessLevelId = 'Required';
        }
        if (!values.userTypeId) {
            tempErrors.userTypeId = 'Required';
        }
        if (!values.employeeTitleIds?.length) {
            tempErrors.employeeTitleIds = 'Required';
        }

        if (values.emergencyContactPhoneNumber && values.emergencyContactPhoneNumber.length !== 14) {
            tempErrors.emergencyContactPhoneNumber = 'Please enter a valid number';
        }
        
        if (isNewUser()) {
            if (!values.password) {
                tempErrors.password = 'Required';
            } else if (values.password !== values.confirmPassword) {
                tempErrors.password = 'Passwords do not match.'
            } else if (!passwordRegex.test(values.password)) {
                tempErrors.password = 'Must include at least 8 total characters, 1 uppercase and 1 lowercase letter, 1 number, and 1 symbol'
            }
            if (!values.confirmPassword) {
                tempErrors.confirmPassword = 'Required';
            } else if (values.password !== values.confirmPassword) {
                tempErrors.confirmPassword = 'Passwords do not match.'
            }
        }

        if (Object.keys(tempErrors)?.length) {
            setErrors(tempErrors);
        } else {
            handleSave(values);
        }
    }

    const showPayRate = () => {
        return localUserDetails.userTypeId === 1 || localUserDetails.userTypeId === 2;
    }

    return <>
        <div className="user-details-wrapper">
            <div className="flex-row space-between">
                <div className="button-wrapper">
                    <BighamButton
                        label="Back"
                        type="default"
                        variant="contained"
                        onClick={handleBack}
                    />
                </div>
                <div className="button-wrapper">
                    <BighamButton
                        label="Save User"
                        type="primary"
                        variant="contained"
                        onClick={handleSaveClick}
                    />
                </div>
            </div>
            <div className="flex-header">
                <div className="component-title">
                    Details
                </div>
            </div>
            <div className="form margin-bottom">
                <div className="form-row">
                    <BighamTextInput
                        fieldName="firstName"
                        value={localUserDetails.firstName}
                        label="First Name"
                        onChange={handleFormChange}
                        error={errors.firstName}
                    />
                    <BighamTextInput
                        fieldName="lastName"
                        value={localUserDetails.lastName}
                        label="Last Name"
                        onChange={handleFormChange}
                        error={errors.lastName}
                    />
                </div>
                <div className="form-row">
                    <BighamTextInput
                        fieldName="username"
                        value={localUserDetails.username}
                        label="Username"
                        onChange={handleFormChange}
                        error={errors.username}
                        readOnly={!isNewUser()}
                    />
                    <BighamTextInput
                        fieldName="email"
                        value={localUserDetails.email}
                        label="Email Address"
                        onChange={handleFormChange}
                        error={errors.email}
                    />
                </div>
                {isNewUser() && <div className="form-row">
                    <BighamTextInput
                        fieldName="password"
                        value={localUserDetails.password}
                        label="Password"
                        onChange={handleFormChange}
                        error={errors.password}
                        type="password"
                    />
                    <BighamTextInput
                        fieldName="confirmPassword"
                        value={localUserDetails.confirmPassword}
                        label="Confirm Password"
                        onChange={handleFormChange}
                        error={errors.confirmPassword}
                        type="password"
                    />
                </div>}
                <div className="form-row">
                    <BighamTextInput
                        fieldName="company"
                        value={localUserDetails.company}
                        label="Company"
                        onChange={handleFormChange}
                    />
                    <BighamTextInput
                        fieldName="interestedArea"
                        value={localUserDetails.interestedArea}
                        label="Interested Areas"
                        onChange={handleFormChange}
                    />
                </div>
                <div className="form-row">
                    <BighamTextInput
                        fieldName="phoneNumber"
                        value={localUserDetails.phoneNumber}
                        label="Phone Number"
                        onChange={handlePhoneNumberChange}
                        error={errors.phoneNumber}
                        onBlur={formatNumber}
                    />
                    <BighamTextInput
                        fieldName="mobileNumber"
                        value={localUserDetails.mobileNumber}
                        label="Mobile Number"
                        onChange={handlePhoneNumberChange}
                        error={errors.mobileNumber}
                        onBlur={formatNumber}
                    />
                </div>
                <div className="form-row">
                    <BighamTextInput
                        fieldName="address"
                        value={localUserDetails.address}
                        label="Address"
                        onChange={handleFormChange}
                        error={errors.address}
                    />
                    <BighamTextInput
                        fieldName="city"
                        value={localUserDetails.city}
                        label="City"
                        onChange={handleFormChange}
                        error={errors.city}
                    />
                </div>
                <div className="form-row">
                    <BighamSelect
                        label="State"
                        fieldName="state"
                        value={localUserDetails.state}
                        listItems={stateList}
                        useNameAsValue={true}
                        onChange={handleFormChange}
                        error={errors.state}
                    />
                    <BighamTextInput
                        fieldName="zip"
                        value={localUserDetails.zip}
                        label="Zip"
                        onChange={handleFormChange}
                        error={errors.zip}
                    />
                </div>
                <div className="form-row">
                    <BighamTextInput
                        fieldName="emergencyContact"
                        value={localUserDetails.emergencyContact}
                        label="Emergency Contact"
                        onChange={handleFormChange}
                        error={errors.emergencyContact}
                    />
                    <BighamTextInput
                        fieldName="emergencyContactPhoneNumber"
                        value={localUserDetails.emergencyContactPhoneNumber}
                        label="Emergency Phone Number"
                        onChange={handlePhoneNumberChange}
                        error={errors.emergencyContactPhoneNumber}
                        onBlur={formatNumber}
                    />
                </div>
            </div>
            <div className="flex-header">
                <div className="component-title">
                    Settings
                </div>
            </div>
            <div className="form">
                <div className="form-row">
                    <BighamSelect
                        fieldName="statusId"
                        value={localUserDetails.statusId}
                        label="Status* (required)"
                        listItems={userStatusList}
                        onChange={handleFormChange}
                        error={errors.statusId}
                    />
                    <BighamMultiSelect
                        fieldName="areaIds"
                        label="Areas* (required)"
                        selectedList={localUserDetails.areaIds}
                        listItems={areaList}
                        onChange={handleFormChange}
                        error={errors.areaIds}
                    />
                    <BighamSelect
                        fieldName="officeId"
                        value={localUserDetails.officeId}
                        label="Office* (required)"
                        listItems={officeList}
                        onChange={handleFormChange}
                        error={errors.officeId}
                    />
                </div>
                <div className="form-row">
                    <BighamSelect
                        fieldName="accessLevelId"
                        value={localUserDetails.accessLevelId}
                        label="Access Level* (required)"
                        listItems={userAccessLevels}
                        onChange={handleFormChange}
                        error={errors.accessLevelId}
                    />
                    <BighamSelect
                        fieldName="userTypeId"
                        value={localUserDetails.userTypeId}
                        listItems={accountTypeList}
                        label="Account Type* (required)"
                        onChange={handleFormChange}
                        error={errors.userTypeId}
                    />
                    <BighamMultiSelect
                        fieldName="employeeTitleIds"
                        selectedList={localUserDetails.employeeTitleIds}
                        label="Employee Title* (required)"
                        listItems={userEmployeeTitles}
                        onChange={handleFormChange}
                        error={errors.employeeTitleIds}
                    />
                    {showPayRate() && <BighamNumberInput
                        fieldName="pay"
                        value={localUserDetails.pay}
                        label="Pay Rate"
                        step={'0.01'}
                        decimalPlaces={2}
                        symbol={'$'}
                        onChange={handleFormChange}
                    />}
                </div>
            </div>
        </div>
    </>;
}

export default UserDetails;