import React, { FC, MouseEvent } from 'react';
import { Button } from '@material-ui/core';

type Variants = 'text' | 'contained' | 'outlined';
type Types = 'primary' | 'secondary' | 'inherit' | 'default';

interface ButtonProps {
    label: string,
    variant: Variants,
    type: Types,
    startIcon?: JSX.Element,
    endIcon?: JSX.Element,
    onClick: (e: MouseEvent<Element>) => void,
    disabled?: boolean,
    className?: string
}

const BighamButton: FC<ButtonProps> = ({label, variant, type, onClick, ...props}) => {
    return <Button
        size="medium"
        onClick={onClick}
        variant={variant}
        disableElevation
        color={type}
        {...props}
    >
        {label}
    </Button>
}

export default BighamButton;