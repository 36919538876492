import React, { FC, ForwardedRef, forwardRef } from 'react';
import { TextField } from '@material-ui/core';
import { useEffect } from 'react';
import { InputBaseComponentProps } from '@mui/material';

interface TextProps {
    label?: string,
    fieldName: string,
    placeholder?: string,
    value: string | number | undefined,
    onChange: (value: any, fieldName: string) => void,
    className?: string,
    error?: string,
    readOnly?: boolean,
    multiline?: boolean,
    minRows?: number,
    step?: string,
    onBlur?: (value: any, fieldName: string) => void,
    type?: 'text' | 'password',
    maxLength?: number
}

const BighamTextInput = forwardRef<HTMLInputElement | null, TextProps>((
    {
        label,
        placeholder,
        value = '',
        fieldName,
        onChange,
        onBlur,
        className = '',
        error = "",
        readOnly = false,
        multiline = false,
        minRows = 3,
        type = 'text',
        maxLength
    }, 
    ref
) => {

    useEffect(() => {
        if (readOnly) {
            onChange(value, fieldName);
        }
    }, [value])

    const inputProps: InputBaseComponentProps = {
        readOnly
    };
    if (maxLength) {
        inputProps.maxLength = maxLength;
    }


    return <TextField
        className={`form-control ${className}`}
        inputRef={ref}
        label={label}
        variant={'outlined'}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value, fieldName)}
        value={value}
        size="small"
        error={!!error.length}
        helperText={error}
        type={type}
        inputProps={inputProps}
        multiline={multiline}
        minRows={multiline ? minRows : 1}
        onBlur={(e) => onBlur && onBlur(e.target.value, fieldName)}
    >
    </TextField>;
})

export default BighamTextInput;