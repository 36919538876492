import { AgGridReact } from 'ag-grid-react';
import React, { ColDef, GridReadyEvent, GridSizeChangedEvent, IRowNode, SelectionChangedEvent, ValueGetterParams } from 'ag-grid-community';
import { FC, ForwardedRef, useEffect, useRef, useState } from 'react';
import MultiSelectFilter from '../../../../library/components/GridComponents/GridMultiSelectFilter';
import { forwardRef } from 'react';
import ValueRenderer from '../../../../library/components/GridComponents/ValueRenderer';
import { NumberColumnDef, StringColumnDef } from '../../../JobTracker/trackerColumnFactory';
import { useAppSelector } from '../../../../main/hooks/hooks';
import { selectDailyJobCodeStatusList } from '../../../../main/slices/lookupSlice';
import { find } from 'lodash';
import { JOB_CODE_STATUS } from '../../../../library/constants/jobCodeStatus';
import { IProductionReportsForInvoicingPermissions } from '../../../../main/types/permissionTypes';
import variables from '../../../../assets/styles/variables.module.scss';



interface InvoicingJobCodesGridProps {
    listItems: any[];
    ref: ForwardedRef<AgGridReact>;
    permissions: IProductionReportsForInvoicingPermissions | undefined
}

const InvoicingJobCodesGrid: FC<InvoicingJobCodesGridProps> = forwardRef<AgGridReact, InvoicingJobCodesGridProps>(({listItems, permissions}, ref) => {
    const dailyJobCodeStatusList = useAppSelector(selectDailyJobCodeStatusList);
    const [gridHeight, setGridHeight] = useState('auto');
    const gridWrapperRef = useRef<HTMLDivElement | null>(null);
    const columnDefs: ColDef[] = [
        {
            field: 'id',
            headerName: '',
            checkboxSelection: !!permissions?.canSelectDailyJobCodes,
            headerCheckboxSelection: !!permissions?.canSelectDailyJobCodes,
            headerCheckboxSelectionFilteredOnly: !!permissions?.canSelectDailyJobCodes,
            valueGetter: (params: ValueGetterParams) => {
                return '';
            },
            maxWidth: 65,
            width: 65,
            minWidth: 65,
            sortable: false
        },
        StringColumnDef('code', 'Job Code', true),
        StringColumnDef('description', 'Description', false),
        NumberColumnDef('productionQuantity', 'Quantity', false),
        NumberColumnDef('unbilledQuantity', 'Unbilled Quantity', false),
        StringColumnDef('productionComments', 'Comments', false),
        {
            field: 'statusId',
            headerName: 'Status',
            valueGetter: (params: ValueGetterParams): string => {
                const status = find(dailyJobCodeStatusList, (status) => params.data['statusId'] === status.id);
                return status ? status.name : '';
            }
        }
    ];

    const handleSetGridHeight = () => {
        if (gridWrapperRef?.current) {
            const offset = gridWrapperRef.current.getBoundingClientRect().top + 200;
            setGridHeight(`calc(100vh - (${offset}px + ${variables.spacing_standard}))`);
        }else {
            setGridHeight('auto');
        }
    }

    useEffect(() => {
        if (gridWrapperRef.current) {
            handleSetGridHeight();
            window.addEventListener('resize', handleSetGridHeight);
        }
        return () => {
            window.removeEventListener('resize', handleSetGridHeight);
        }
    }, [gridWrapperRef.current])

    return <div ref={gridWrapperRef} className="grid-wrapper ag-theme-material" style={{ height: gridHeight }}>
       <AgGridReact
            ref={ref}
            components={{
                multiSelect: MultiSelectFilter
            }}
            // domLayout="autoHeight"
            defaultColDef={
                {
                    resizable: true,
                    cellRenderer: ValueRenderer,
                    sortable: true,
                    menuTabs: ['filterMenuTab'],
                }
            }
            columnDefs={columnDefs}
            rowData={listItems}
            rowSelection={'multiple'}
            isRowSelectable={(node: IRowNode<any>): boolean => {
                // disable selection for rows that are not approved.
                return !!permissions?.canSelectDailyJobCodes && (node.data.statusId === JOB_CODE_STATUS.APPROVED || (node.data.statusId === JOB_CODE_STATUS.INVOICED_PARTIAL && node.data.unbilledQuantity));
            }}
            suppressRowClickSelection={true}
            onGridReady={(event: GridReadyEvent) => {
                event.api.sizeColumnsToFit();
                event.api.forEachNode((rowNode: IRowNode<any>) => {
                    rowNode.setSelected(rowNode.data.isSelected);
                })
            }}
            onGridSizeChanged={(event: GridSizeChangedEvent) => {
                event.api.sizeColumnsToFit();
            }}
        />
    </div>
})

export default InvoicingJobCodesGrid;