import { AgGridReact } from 'ag-grid-react';
import React, { ColDef, GridReadyEvent, GridSizeChangedEvent, RowNode, SelectionChangedEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { FC, ForwardedRef } from 'react';
import MultiSelectFilter from '../../../../library/components/GridComponents/GridMultiSelectFilter';
import { forwardRef } from 'react';
import ValueRenderer from '../../../../library/components/GridComponents/ValueRenderer';
import { DateColumnDef, DefaultColumnValues, moneyFormatter, NumberColumnDef, StringColumnDef } from '../../../JobTracker/trackerColumnFactory';
import { useAppDispatch, useAppSelector } from '../../../../main/hooks/hooks';
import { IJobInvoice } from '../../../../main/types/jobDetailTypes';
import { dateFormat } from '../../../../library/constants/dateFormat';
import DownloadCellRenderer from '../../../../library/components/GridComponents/DownloadCellRenderer';
import { callGetInvoiceDailyCSVDownload } from '../../../../main/slices/jobDetailSlice';
import moment from 'moment';
import { IJobFinancialsPermissions } from '../../../../main/types/permissionTypes';


interface FinancialsInvoicesGridProps {
    listItems: IJobInvoice[];
    permissions: IJobFinancialsPermissions | undefined;
    ref: ForwardedRef<AgGridReact>;
    handleCreateInvoiceSummary(data: IJobInvoice): void;
}

const FinancialsInvoicesGrid: FC<FinancialsInvoicesGridProps> = forwardRef<AgGridReact, FinancialsInvoicesGridProps>(({listItems, permissions, handleCreateInvoiceSummary}, ref) => {
    const dispatch = useAppDispatch();
    const numberFormatterOverride = (params: ValueFormatterParams) => {
        return params.value;
    }

    const notApplicableOverride = (params: ValueFormatterParams) => {
        if (params.value) {
            return params.value;
        }
        return ''
    }

    const presetColumnWidth = 160;

    const columnDefs: ColDef[] = [
        {
            ...DateColumnDef('invoiceDate', 'Date', dateFormat, true),
            ...DefaultColumnValues(presetColumnWidth),
            valueFormatter: notApplicableOverride
        },
        {
            ...StringColumnDef('invoiceNumber', 'Invoice #', true),
            valueFormatter: notApplicableOverride,
            suppressSizeToFit: false,
            minWidth: 100
        },
        {
            ...NumberColumnDef('amount', 'Amount', true),
            ...DefaultColumnValues(presetColumnWidth),
            valueGetter: (params: ValueGetterParams) => {
                return moneyFormatter('amount')(params);
            },
            valueFormatter: numberFormatterOverride
        },
        {
            ...StringColumnDef('invoiceStatus', 'Status', true),
            ...DefaultColumnValues(presetColumnWidth),
        }
    ];

    if (permissions?.canGenerateInvoiceDocument) {
        columnDefs.push({
            field: 'id',
            headerName: 'Daily CSV',
            sortable: false,
            width: 100,
            cellRenderer: DownloadCellRenderer,
            suppressMenu: true,
            cellRendererParams: {
                handleCellClick: (data: IJobInvoice) => () => {
                    let fileName = `Invoice_Daily_CSV_`;
                    if (data.invoiceNumber && data.invoiceDate) {
                        fileName += `${data.invoiceNumber}_${moment(data.invoiceDate).format(dateFormat)}`
                    } else {
                        fileName += `Requested`;
                    }
                    fileName += '.csv';
                    dispatch(callGetInvoiceDailyCSVDownload({
                        invoiceId: data.id,
                        fileName
                    }));
                },
                hoverTitle: 'Generate Dailies CSV'
            }
        })
        columnDefs.push({
            field: 'id',
            headerName: 'Invoice',
            sortable: false,
            width: 80,
            cellRenderer: DownloadCellRenderer,
            suppressMenu: true,
            cellRendererParams: {
                handleCellClick: (data: IJobInvoice) => () => {
                    handleCreateInvoiceSummary(data);
                },
                hoverTitle: 'Generate Invoice PDF',
                field: 'invoice'
            }
        })
    }

    return <div className="grid-wrapper ag-theme-material">
       {!!listItems?.length && <AgGridReact
            ref={ref}
            components={{
                multiSelect: MultiSelectFilter
            }}
            domLayout="autoHeight"
            defaultColDef={
                {
                    resizable: true,
                    cellRenderer: ValueRenderer,
                    sortable: true,
                    suppressSizeToFit: true,
                    menuTabs: ['filterMenuTab'],
                }
            }
            columnDefs={columnDefs}
            rowData={listItems}
            suppressRowClickSelection={true}
            onGridReady={(event: GridReadyEvent) => {
                event.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(event: GridSizeChangedEvent) => {
                event.api.sizeColumnsToFit();
            }}
        />}
    </div>
})

export default FinancialsInvoicesGrid;