import { FC, useState, useRef } from "react"
import './DailyProductionReportModal.scss';
import ModalBase from "../ModalBase/ModalBase"
import BighamTextInput from "../FormControls/BighamTextInput/BighamTextInput";
import BighamButton from "../FormControls/BighamButton/BighamButton";
import { useAppDispatch, useAppSelector } from "../../../main/hooks/hooks";
import { callReviewDaily } from "../../../main/slices/reportsSlice";
import { updateNewDailyFootage } from "../../../main/api/jobDetailApi";
import { formatDate } from "../../transforms/utilTransforms";
import { AgGridReact } from "ag-grid-react";
import { selectDailyStatusList } from "../../../main/slices/lookupSlice";
import { find } from "lodash";
import { IDailyProductionReport } from "../../../main/types/reportTypes";
import { IDailyReport, ITeamJobCode } from "../../../main/types/jobDetailTypes";
import { dateFormat } from "../../constants/dateFormat";
import { JOB_CODE_STATUS } from "../../constants/jobCodeStatus";
import BackChargesModal from "../BackChargesModal/BackChargesModal";
import NewFootageModal from "./NewFootageModal"
import { IProductionReportsPermissions } from "../../../main/types/permissionTypes";
import ROUTES from "../../constants/routeConstants";
import DailyProductionReportModalGrid from "./DailyProductionReportGrid";
import { IDailyModalJobDetailSummary } from "../../../main/slices/jobDetailSlice";
import { formatNumberWithCommas } from "../../util/numberFormatter";
import ReactPDF from "@react-pdf/renderer";
import DailyGenerator from "../PDFDailyGenerator/PDFDailyGenerator";
import { type } from "os";
import { addFetch, removeFetch } from "../../../main/slices/utilSlice";

interface DailyProductionReportModalProps {
    onCancel(): void,
    reportDetails?: IDailyProductionReport | IDailyReport,
    jobSummary: IDailyModalJobDetailSummary | null,
    permissions: IProductionReportsPermissions | undefined
}

const DailyProductionReportModal: FC<DailyProductionReportModalProps> = ({onCancel, jobSummary, reportDetails, permissions}) => {
    const dispatch = useAppDispatch();
    const gridRef = useRef<AgGridReact | null>(null)
    const dailyId = reportDetails?.id;
    const [newDailyFootage, setNewDailyFootage] = useState(reportDetails?.newDailyFootage);
    const [focusedJobCode, setFocusedJobCode] = useState<ITeamJobCode | null>(null);
    const [showNewDailyFootageModal, setShowNewDailyFootageModal] = useState(false);
    const [reportDetailsModel, setReportDetailsModel] = useState<any>(reportDetails);
    const dailyStatusList = useAppSelector(selectDailyStatusList);
    
    const handleFormChange = (value: any, fieldName: string) => {
        setReportDetailsModel({ ...reportDetailsModel, [fieldName]: value });
    }

    const handleCancelReport = () => {
        dispatch(callReviewDaily({
            id: reportDetailsModel.id,
            comments: reportDetailsModel.approverComments,
            statusId: JOB_CODE_STATUS.CANCELED
        }));
        onCancel();
    }

    const handleApproveReport = () => {
        dispatch(callReviewDaily({
            id: reportDetailsModel.id,
            comments: reportDetailsModel.approverComments,
            statusId: JOB_CODE_STATUS.APPROVED
        }));
        onCancel();
    }

    const handleRejectReport = () => {
        dispatch(callReviewDaily({
            id: reportDetailsModel.id,
            comments: reportDetailsModel.approverComments,
            statusId: JOB_CODE_STATUS.REJECTED
        }))
        onCancel();
    }
    const canReviewReport = (): boolean => {
        // status cannot be approved, canceled, invoiceRequested, or invoiced.
        return (
            (reportDetailsModel.dailyStatus === JOB_CODE_STATUS.SUBMITTED) &&
            !!permissions?.canApproveOrRejectDaily && !!permissions?.canEditDailyApproverComments
        );
    }

    const handleLaunchBackChargesModal = (data: ITeamJobCode) => () => {
        setFocusedJobCode(data);
    }

    const handleCloseBackChargesModal = () => {
        setFocusedJobCode(null);
    }

    const handleShowNewDailyFootageModal = () => {
    setShowNewDailyFootageModal(true);
        
    }

    const handleCloseNewDailyFootageModal = () => {
        setShowNewDailyFootageModal(false);
    }

    const handleViewMapsClick = () => {
        const url = `${window.location.origin}${ROUTES.JOB_DETAILS}/${reportDetails?.jobId}?openSection=documents`;
        window.open(url, '_blank', 'noopener,noreferrer');
    }
    
    const handleSaveNewFootage = () => {
        updateNewDailyFootage({
            "newDailyFootage": parseInt(reportDetailsModel.newDailyFootage)
        }, dailyId)
        setNewDailyFootage(reportDetailsModel.newDailyFootage)
        handleCloseNewDailyFootageModal()
    }

    const handleDownloadReport = () => {
        const type = 'application/pdf;charset=utf-8;'
        const fileName = `${reportDetails?.foreman.replace(' ', '_')}_daily_production_report_${formatDate(reportDetails?.dailyDate || '', dateFormat).replace('/', '_')}`;
        dispatch(addFetch());
        ReactPDF.pdf(<DailyGenerator
            fileName={fileName}
            reportDetails={reportDetails}
        />).toBlob().then((blob) => {
            const svgFile = new Blob([blob], { type: type });
            const svgUrl = URL.createObjectURL(svgFile);
            const tempLink = document.createElement('a');
            tempLink.href = svgUrl;
            tempLink.setAttribute('download', `${fileName}.pdf`);
            tempLink.click();
        }).then(() => {
            dispatch(removeFetch());
        });
    }

    return <ModalBase onCancel={onCancel} size="large">
        <>
            {!!focusedJobCode && <BackChargesModal onCancel={handleCloseBackChargesModal} jobCodeDetails={focusedJobCode} />}
            {showNewDailyFootageModal && <NewFootageModal onCancel={handleCloseNewDailyFootageModal} reportDetails={reportDetails} permissions={permissions} handleSaveNewFootage={handleSaveNewFootage} setReportDetailsModel={setReportDetailsModel} reportDetailsModel={reportDetailsModel} />}
        </>
        <div className="flex-row">
            <span className="modal-title">
                Daily {permissions?.canViewDailyDate && `- ${formatDate(reportDetails?.dailyDate || '', dateFormat)}`} {!!permissions?.canViewDailyStatus && `- ${reportDetails?.dailyStatus && find(dailyStatusList, (status) => +reportDetails.dailyStatus === status.id)?.name || ''}`}
            </span>
            <span className="report-modal-map-link" onClick={handleViewMapsClick}>View Maps</span>
            <span className="report-modal-map-link" onClick={handleDownloadReport}>
                Download Report
            </span>
        </div>
        <div className="report-modal-wrapper">
            <div className="flex-row stretch">
                <div className="label-value-container">
                    <span className="label">Business:</span>
                    <span className="value">{jobSummary?.business}</span>
                </div>
                <div className="summary-wrapper">
                    <span>
                        <i>Aerial:</i>
                        <span><span className="red">{formatNumberWithCommas(jobSummary?.aerialFootage || 0)}</span> | {formatNumberWithCommas(jobSummary?.aerialFootageComplete || 0)}</span>
                    </span>
                    <span>-</span>
                    <span>
                        <i>UG:</i>
                        <span><span className="red">{formatNumberWithCommas(jobSummary?.undergroundFootage || 0)}</span> | {formatNumberWithCommas(jobSummary?.undergroundFootageComplete || 0)}</span>
                    </span>
                </div>
            </div>
            <div className="flex-row stretch">
                <div className="label-value-container">
                    <span className="label">Address:</span>
                    <span className="value">{jobSummary?.address} {jobSummary?.city}, {jobSummary?.state} {jobSummary?.zip}</span>
                </div>
            </div>
            <div className="flex-row stretch">
                {!!permissions?.canViewDailyNewDailyFootage && <div className="label-value-container" onClick={handleShowNewDailyFootageModal}>
                    <span className="label">New Daily Footage:</span>
                    <span className="value blue">{newDailyFootage} ft</span>
                </div>}
                {!!reportDetails?.completeDate && <div className="label-value-container">
                    <span className="label">Complete Date:</span>
                    <span className="value">{formatDate(reportDetails?.completeDate || '', dateFormat)}</span>
                </div>}
            </div>
            <div className="flex-row stretch">
                <div className="label-value-container column">
                    <span className="label">Crew</span>
                    {!!reportDetails?.crew?.length && reportDetails.crew.map((crew: any, index: number) => {
                        return <span key={index} className="value">{permissions?.canViewDailyCrewMemberName && `${crew.firstName} ${crew.lastName}`} {permissions?.canViewDailyCrewMemberAllocation && crew.allocation && `- ${crew.allocation}%`} {permissions?.canViewDailyCrewMemberIsHourly && crew.isHourly && `- Hourly`}</span>
                    })}
                </div>
                {!!permissions?.canViewDailyMaterialsInStock && !!reportDetails?.materials && <div className="label-value-container column">
                    <span className="label">Materials</span>
                    <span className="value">{reportDetails?.materials}</span>
                </div>}
            </div>
            {!!reportDetails?.jobCodes?.length && <DailyProductionReportModalGrid
                listItems={reportDetails?.jobCodes || []}
                ref={gridRef}
                isDailyApproved={reportDetails.dailyStatus === JOB_CODE_STATUS.APPROVED}
                launchBackChargesModal={handleLaunchBackChargesModal}
                permissions={permissions}
            />}
        </div>
        <div className="form">
            <div className="form-row">
                <BighamTextInput
                    fieldName="approverComments"
                    value={reportDetailsModel.approverComments}
                    label="Comments"
                    onChange={handleFormChange}
                    readOnly={!canReviewReport()}
                />
            </div>
        </div>
        <div className="modal-button-container">
            {canReviewReport() && <>
                <BighamButton
                    type="secondary"
                    variant="contained"
                    onClick={handleRejectReport}
                    label={'Reject'}
                />
                <BighamButton
                    type="primary" 
                    variant="contained"
                    label={`Approve`}
                    onClick={handleApproveReport}
                />
                <BighamButton
                    type="secondary"
                    variant="outlined"
                    onClick={handleCancelReport}
                    label={'Cancel Daily'}
                />
            </>}
            {!canReviewReport() && <BighamButton
                type="default" 
                variant="contained"
                label={`Close`}
                onClick={onCancel}
            />}
        </div>
    </ModalBase>
}

export default DailyProductionReportModal;