import { FC, ReactElement } from "react";
import { View, StyleSheet } from '@react-pdf/renderer';

interface PDFTableHeaderProps {
    children: ReactElement[],
}

const styles = StyleSheet.create({
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        borderTop: '1px solid black',
        borderLeft: '1px solid black'
    }
})

const PDFTableHeader: FC<PDFTableHeaderProps> = ({children}) => {

    return <View style={styles.row}>
        {children}
    </View>
}

export default PDFTableHeader;