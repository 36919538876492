import { orderBy, pick, sortBy } from 'lodash';
import { IDailyModalJobDetailSummary } from '../../main/slices/jobDetailSlice';
import { ILookupItem } from '../../main/types/genericTypes';
import {
  IAerialTeam,
  IJobAerial,
  IJobDetailAPIUpdatePayload,
  IJobDetailAPIResponse,
  IJobDetailCombined,
  IJobUnderGround,
  ISplicingTeam,
  IUndergroundTeam,
  IJobAerialTeam,
  IJobUnderGroundTeam,
  IJobSplicing,
  IPermitRecord,
  IJobLocates,
  IJobPurchaseOrder,
  IJobCodeLineItem,
  IDailyReport,
  ITeamDaily,
  ITeamJobCode,
} from '../../main/types/jobDetailTypes';
import { JOB_CODE_STATUS } from '../constants/jobCodeStatus';
import { buildInvoiceSummary, getFinancialSummary } from './invoiceTransform';

export const transformJobDetailResponsePayload = (
  inputData: IJobDetailAPIResponse,
  fileTypes: ILookupItem[]
): IJobDetailCombined => {
  const dailyJobCodes = mapJobCodesForInvoicing(
    inputData.aerialTeams,
    inputData.undergroundTeams,
    inputData.splicingTeams
  );
  const status = mapArrayToIds(inputData.statuses);
  const cCoords = mapArrayToIds(inputData.customerCoordinators);
  const purchaseOrderJobCodes = mapJobCodesForDisplay(inputData.jobCodes) || [];
  const aerialTeams = transformAerialDataForDisplay(inputData.aerialTeams);
  const undergroundTeams = transformUndergroundDataForDisplay(
    inputData.undergroundTeams
  );
  const splicingTeams = transformSplicingDataForDisplay(
    inputData.splicingTeams
  );
  const locates = transformLocatesDataForDisplay(inputData.locates);
  const permits = transformPermitsDataForDisplay(inputData.permits);
  const documents = transformDocumentsForDisplay(
    inputData.documents,
    fileTypes
  );
  const dailies = mapDailiesForDisplay(
    inputData.aerialTeams,
    inputData.undergroundTeams,
    inputData.splicingTeams
  );
  const invoiceSummary = buildInvoiceSummary(inputData.jobCodes, dailyJobCodes);
  const returnValue: IJobDetailCombined = {
    details: {
      id: inputData.id,
      area: inputData.areaId,
      business: inputData.business,
      category: inputData.categoryId,
      status,
      job: inputData.number,
      node: inputData.node,
      cpr: inputData.cpr,
      bCoord: inputData.bighamCoordinatorId,
      cCoords,
      tCoord: inputData.techOpsCoordinatorId,
      recDate: inputData.receivedDate || '',
      dueDate: inputData.dueDate || '',
      tsdDate: inputData.tsdDate || '',
      completeDate: inputData.completeDate || '',
      addressId: inputData.addressId,
      address: inputData.address,
      address1: inputData.address?.addressLine1,
      city: inputData.address?.city,
      state: inputData.address?.state,
      zip: inputData.address?.zipCode,
      lat: inputData.address?.latitude || '',
      lng: inputData.address?.longitude || '',
      comments: inputData.comments,
      sendConfirmationEmail: false,
      lastUpdateDateTime: inputData.lastUpdateDateTime || '',
      lastUpdateUser: inputData.lastUpdateUser || {},
      lastUpdateUserId: inputData.lastUpdateUserId ,
    },
    purchaseOrder: {
      id: inputData?.purchaseOrder?.id || 0,
      purchaseOrderNumber: inputData?.purchaseOrder?.purchaseOrderNumber || '',
      purchaseOrderAmount: inputData?.purchaseOrder?.purchaseOrderAmount || 0,
      ntpJobCodes: inputData?.jobCodes || [],
      jobCodes: purchaseOrderJobCodes
    },
    aerial: {
      totalFootage: inputData.aerialFootage,
      totalFootageCompleted: inputData.aerialFootageComplete,
      completeDate: inputData.aerialCompletedDate || '',
      teams: aerialTeams
    },
    underground: {
      totalFootage: inputData.undergroundFootage || 0,
      totalFootageCompleted: inputData.undergroundFootageComplete,
      completeDate: inputData.undergroundCompletedDate || '',
      teams: undergroundTeams
    },
    splicer: {
      completeDate: inputData.splicingCompletedDate || '',
      teams: splicingTeams
    },
    locates,
    permits,
    documents,
    dailies,
    financials: inputData.financials,
    invoices: {
      invoices: inputData.invoices,
      financialSummary: getFinancialSummary(
        inputData.invoices,
        inputData.purchaseOrder?.purchaseOrderAmount,
        invoiceSummary
      )
    },
    invoicing: {
      jobCodes: dailyJobCodes,
      invoiceSummary
    }
  };

  return returnValue;
};

const mapJobCodesForInvoicing = (
  aerialTeams: IAerialTeam[],
  undergroundTeams: IUndergroundTeam[],
  splicingTeams: ISplicingTeam[]
): ITeamJobCode[] => {
  const aerialJobCodes = getJobCodes(aerialTeams);
  const undergroundJobCodes = getJobCodes(undergroundTeams);
  const splicingJobCodes = getJobCodes(splicingTeams);
  return sortBy(
    [...aerialJobCodes, ...undergroundJobCodes, ...splicingJobCodes],
    ['code']
  );
};

const getJobCodes = (
  teams: IAerialTeam[] | IUndergroundTeam[] | ISplicingTeam[]
): ITeamJobCode[] => {
  let returnValue: ITeamJobCode[] = [];
  if (teams?.length) {
    teams.forEach((team) => {
      team.dailies?.forEach((daily) => {
        // ignore all dailies that are in progress
        if (daily.dailyStatus !== JOB_CODE_STATUS.IN_PROGRESS) {
          returnValue = returnValue.concat(
            daily.jobCodes.map((jobCode) => {
              return {
                ...jobCode,
                productionQuantityAdjusted: jobCode.productionQuantity - (jobCode?.backCharge?.quantity || 0)
              }
            })
          );
        }
      });
    });
  }
  return orderBy(returnValue, ['jobCodeId']);
};

const mapJobCodesForDisplay = (
  jobCodes: IJobCodeLineItem[]
): IJobCodeLineItem[] => {
  if (!jobCodes) {
    return [];
  }
  const returnValue: IJobCodeLineItem[] = [];
  jobCodes.forEach((jobCode) => {
    if (jobCode.isPrimaryCustomerCode) {
      returnValue.push({
        ...jobCode,
        jobCodeObj: { id: jobCode.jobCodeId, name: jobCode.jobCode || '' }
      });
    }
  });
  return returnValue;
};

const mapArrayToIds = (array: any[]): number[] => {
  if (array?.length) {
    return array.map((record: { id: number }) => record.id);
  } else {
    return [];
  }
};

export const transformDocumentsForDisplay = (
  documentArray: any[],
  fileTypes: ILookupItem[]
): any => {
  const returnObj: { [key: string]: any[] } = {};
  const fileTypeRef: { [key: number]: string } = {};
  if (fileTypes?.length && documentArray?.length) {
    fileTypes.forEach((type) => {
      fileTypeRef[type.id] = type.name;
    });
    documentArray.forEach((document) => {
      if (!returnObj[fileTypeRef[document.categoryId]]) {
        returnObj[fileTypeRef[document.categoryId]] = [document];
      } else {
        returnObj[fileTypeRef[document.categoryId]].push(document);
      }
    });
  }
  return returnObj;
};

export const transformJobDetailCreateOrUpdatePayload = (
  inputData: IJobDetailCombined
): IJobDetailAPIUpdatePayload => {
  const purchaseOrder = inputData.purchaseOrder;
  purchaseOrder;
  return {
    id: inputData.details.id,
    areaId: inputData.details.area,
    categoryId: inputData.details.category,
    number: inputData.details.job,
    node: inputData.details.node,
    cpr: inputData.details.cpr,
    receivedDate: inputData.details.recDate,
    tsdDate: inputData.details.tsdDate || null,
    dueDate: inputData.details?.dueDate || null,
    completeDate: inputData.details?.completeDate || null,
    sendConfirmationEmail: inputData.details?.sendConfirmationEmail || false,
    statusIds: inputData.details.status,
    bighamCoordinatorId: inputData.details.bCoord,
    customerCoordinatorIds: inputData.details.cCoords,
    techOpsCoordinatorId: inputData.details.tCoord,
    business: inputData.details.business,
    comments: inputData.details.comments,
    address: {
      addressLine1: inputData.details.address1,
      city: inputData.details.city,
      state: inputData.details.state,
      zipCode: inputData.details.zip,
      latitude: inputData.details.lat,
      longitude: inputData.details.lng
    },
    aerialTeams: transformAerialDataForUpdate(inputData.aerial.teams),
    aerialFootage: +inputData.aerial.totalFootage,
    aerialFootageComplete: +inputData.aerial.totalFootageCompleted,
    aerialCompletedDate: inputData.aerial.completeDate || null,
    locates: transformLocatesDataForUpdate(inputData.locates),
    permits: transformPermitsDataForUpdate(inputData.permits),
    splicingTeams: transformSplicingDataForUpdate(inputData.splicer.teams),
    splicingCompletedDate: inputData.splicer.completeDate || null,
    undergroundTeams: transformUndergroundDataForUpdate(
      inputData.underground.teams
    ),
    undergroundFootage: +inputData.underground.totalFootage,
    undergroundFootageComplete: +inputData.underground.totalFootageCompleted,
    undergroundCompletedDate: inputData.underground.completeDate || null,
    purchaseOrder: mapPurchaseOrderForUpdate(inputData.purchaseOrder),
    invoices: inputData.invoices.invoices,
    jobCodes: mapJobCodesForUpdate(inputData.purchaseOrder.jobCodes || [])
  };
};

const mapPurchaseOrderForUpdate = (
  purchaseOrder: IJobPurchaseOrder
): IJobPurchaseOrder => {
  const temp: IJobPurchaseOrder = {
    purchaseOrderNumber: purchaseOrder.purchaseOrderNumber,
    purchaseOrderAmount: purchaseOrder.purchaseOrderAmount
  };
  if (purchaseOrder.id) temp.id = purchaseOrder.id;
  return temp;
};

const mapJobCodesForUpdate = (jobCodes: IJobCodeLineItem[]) => {
  return jobCodes.map((jobCode: IJobCodeLineItem): IJobCodeLineItem => {
    const returnValue: IJobCodeLineItem = {
      pricePerUnit: +jobCode.pricePerUnit,
      quantity: +jobCode.quantity,
      jobCodeId: jobCode?.jobCodeObj?.id || 0,
      parentJobCodeId: 0
    };
    if (jobCode.id) returnValue.id = jobCode.id;
    return returnValue;
  });
};

const transformAerialDataForDisplay = (
  aerialList: IAerialTeam[]
): IJobAerialTeam[] => {
  if (!aerialList?.length) {
    return [];
  }
  return aerialList.map((aerialTeam) => ({
    id: aerialTeam.id,
    footageCompleted: aerialTeam.footageCompleted,
    issueDate: aerialTeam.issueDate || '',
    completeDate: aerialTeam.completeDate || '',
    foremanId: aerialTeam.foremanId,
    comments: aerialTeam.comments,
    isCritical: aerialTeam.isCritical
  }));
};

const transformAerialDataForUpdate = (
  aerialList: IJobAerialTeam[]
): IAerialTeam[] => {
  return aerialList.map((aerialTeam) => ({
    id: aerialTeam.id,
    footageCompleted: aerialTeam.footageCompleted,
    issueDate: aerialTeam.issueDate || null,
    completeDate: aerialTeam.completeDate || null,
    foremanId: aerialTeam.foremanId,
    comments: aerialTeam.comments,
    isCritical: aerialTeam.isCritical
  }));
};

const transformUndergroundDataForDisplay = (
  undergroundList: IUndergroundTeam[]
): IJobUnderGroundTeam[] => {
  if (!undergroundList?.length) {
    return [];
  }
  return undergroundList.map((undergroundTeam) => ({
    id: undergroundTeam.id,
    footageCompleted: undergroundTeam.footageCompleted,
    issueDate: undergroundTeam.issueDate || '',
    completeDate: undergroundTeam.completeDate || '',
    foremanId: undergroundTeam.foremanId,
    isCritical: undergroundTeam.isCritical,
    comments: undergroundTeam.comments
  }));
};

const transformUndergroundDataForUpdate = (
  undergroundList: IJobUnderGroundTeam[]
): IUndergroundTeam[] => {
  return undergroundList.map((undergroundTeam) => ({
    id: undergroundTeam.id,
    footageCompleted: undergroundTeam.footageCompleted,
    issueDate: undergroundTeam.issueDate || null,
    completeDate: undergroundTeam.completeDate || null,
    foremanId: undergroundTeam.foremanId,
    isCritical: undergroundTeam.isCritical,
    comments: undergroundTeam.comments
  }));
};

const transformSplicingDataForUpdate = (
  splicingList: IJobSplicing[]
): ISplicingTeam[] => {
  return splicingList.map((splicingTeam) => ({
    id: splicingTeam.id,
    splicerOptionId: splicingTeam.splicerOptionId || null,
    installDate: splicingTeam.installDate || null,
    installerIssueDate: splicingTeam.installerIssueDate || null,
    splicerIssueDate: splicingTeam.splicerIssueDate || null,
    scheduledSplicerDate: splicingTeam.scheduledSplicerDate || null,
    completedDate: splicingTeam.completedDate || null,
    foremanId: splicingTeam.foremanId || null,
    splicer2Id: splicingTeam.splicer2Id || null,
    installerId: splicingTeam.installerId || null,
    isCritical: splicingTeam.isCritical,
    comments: splicingTeam.comments
  }));
};

const transformSplicingDataForDisplay = (
  splicingList: ISplicingTeam[]
): IJobSplicing[] => {
  if (!splicingList?.length) {
    return [];
  }
  return splicingList.map((splicingTeam) => ({
    id: splicingTeam.id,
    splicerOptionId: splicingTeam.splicerOptionId || 0,
    installDate: splicingTeam.installDate || '',
    installerIssueDate: splicingTeam.installerIssueDate || '',
    splicerIssueDate: splicingTeam.splicerIssueDate || '',
    scheduledSplicerDate: splicingTeam.scheduledSplicerDate || '',
    completedDate: splicingTeam.completedDate || '',
    foremanId: splicingTeam.foremanId || 0,
    splicer2Id: splicingTeam.splicer2Id || 0,
    installerId: splicingTeam.installerId || 0,
    isCritical: splicingTeam.isCritical,
    comments: splicingTeam.comments
  }));
};

const transformPermitsDataForUpdate = (
  permitList: IPermitRecord[]
): IPermitRecord[] => {
  return permitList.map((permitRecord) => ({
    ...permitRecord,
    permitEntityId: permitRecord.permitEntityId || null,
    permitTypeId: permitRecord.permitTypeId || null,
    dateSentToEngineer: permitRecord.dateSentToEngineer || null,
    dateSentToUtilityOrDot: permitRecord.dateSentToUtilityOrDot || null,
    readyForConstructionDate: permitRecord.readyForConstructionDate || null
  }));
};

const transformPermitsDataForDisplay = (
  permitList: IPermitRecord[]
): IPermitRecord[] => {
  if (!permitList?.length) {
    return [];
  }
  return permitList.map((permitRecord) => ({
    ...permitRecord,
    permitEntityId: permitRecord.permitEntityId || 0,
    permitTypeId: permitRecord.permitTypeId || 0,
    dateSentToEngineer: permitRecord.dateSentToEngineer || '',
    dateSentToUtilityOrDot: permitRecord.dateSentToUtilityOrDot || '',
    readyForConstructionDate: permitRecord.readyForConstructionDate || '',
    permittingAgentIds: permitRecord.permittingAgentIds || []
  }));
};

const transformLocatesDataForDisplay = (
  locateList: IJobLocates[]
): IJobLocates[] => {
  if (!locateList?.length) {
    return [];
  }
  return locateList.map((locateRecord, index) => ({
    ...locateRecord,
    locateDigDate: locateRecord.locateDigDate || '',
    locateUpdate: locateRecord.locateUpdate || '',
    index
  }));
};

const transformLocatesDataForUpdate = (
  locateList: IJobLocates[]
): IJobLocates[] => {
  return locateList.map((locateRecord) => ({
    ...locateRecord,
    locateDigDate: locateRecord.locateDigDate || null,
    locateUpdate: locateRecord.locateUpdate || null
  }));
};

const mapDailiesForDisplay = (
  aerialTeams: IAerialTeam[],
  undergroundTeams: IUndergroundTeam[],
  splicingTeams: ISplicingTeam[]
) => {
  const aerialDailies = mapTheTeams(aerialTeams);
  const undergroundDailies = mapTheTeams(undergroundTeams);
  const splicingDailies = mapTheTeams(splicingTeams);
  return orderBy([...aerialDailies, ...undergroundDailies, ...splicingDailies], ['dailyDate'], ['desc']);
};

const mapTheTeams = (
  teams: IAerialTeam[] | IUndergroundTeam[] | ISplicingTeam[]
): ITeamDaily[] => {
  let reportArray: ITeamDaily[] = [];
  if (teams?.length) {
    teams.forEach((team) => {
      if (team.dailies) {
        reportArray = reportArray.concat(
          transformDailyReportsForDisplay(team.dailies)
        );
      }
    });
  }
  return reportArray;
};

const transformDailyReportsForDisplay = (
  dailyReportList: ITeamDaily[]
): ITeamDaily[] => {
  if (dailyReportList?.length) {
    return dailyReportList.reduce<ITeamDaily[]>((acc, dailyReport, index) => {
      if (dailyReport.dailyStatus !== JOB_CODE_STATUS.IN_PROGRESS) {
        // do not include dailies that are in progress.
        acc.push({
          ...dailyReport,
          dailyDate: dailyReport.dailyDate || '',
          qualityControlDate: dailyReport.qualityControlDate || '',
          gigRepairDate: dailyReport.gigRepairDate || '',
          gigRepairComments: dailyReport.gigRepairComments || '',
          completeDate: dailyReport.completeDate || '',
          reviewDate: dailyReport.reviewDate || '',
          backCharge: dailyReport?.backCharge || {
            id: 0,
            comments: '',
            quantity: 0
          }
        });
        return acc;
      } else {
        return acc;
      }
    }, []);
  } else {
    return [];
  }
};

export const transformJobDetailsForDailyModal = (jobDetails: IJobDetailCombined): IDailyModalJobDetailSummary => {
  const { details, aerial, underground } = jobDetails;
  return {
    business: details.business,
    address: details.address1,
    city: details.city,
    state: details.state,
    zip: details.zip,
    aerialFootage: aerial.totalFootage,
    aerialFootageComplete: aerial.totalFootageCompleted,
    undergroundFootage: underground.totalFootage,
    undergroundFootageComplete: underground.totalFootageCompleted
  }
}