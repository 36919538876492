import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { ILookupItem } from "../../../../main/types/genericTypes";
import { IUndergroundTeam } from "../../../../main/types/jobDetailTypes";
import { IUndergroundPermissions } from "../../../../main/types/permissionTypes";
import { IJobTeamErrors } from "../../JobDetails";
import FootageCard from "../../../../library/components/FootageCard/FootageCard";
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import BighamDatePicker from "../../../../library/components/FormControls/BighamDatePicker/BighamDatePicker";
import BighamNumberInput from "../../../../library/components/FormControls/BighamNumberInput/BighamNumberInput";

interface UndergroundSectionProps {
    openSection: string,
    handleAccordionAction(panel: string): (event: ChangeEvent<any>, isExpanded: boolean) => void,
    handleAddNewCard(addData: any, fieldName: string): () => void,
    handleFormChange(value: any, fieldName: string): void,
    jobDetailModel: any,
    foremanList: ILookupItem[],
    permissions: IUndergroundPermissions,
    setErrors(errors: IJobTeamErrors): void,
    errors: IJobTeamErrors
}

const UndergroundSection: FC<UndergroundSectionProps> = ({openSection, handleAccordionAction, handleAddNewCard, handleFormChange, foremanList, jobDetailModel, permissions, errors, setErrors}) => {
    const sectionId = 'underground'
    const canAddNewCard = ():boolean => {
        return permissions.canEditForeman
            || permissions.canEditIssueDate
            || permissions.canEditCompleteDate 
            || permissions.canEditIsCritical
            || permissions.canEditComments
    }

    const handleLocalChange = (value: any, fieldName: string) => {
        const temp = {...errors};
        delete errors?.[sectionId]?.completeDate;
        setErrors(temp);
        handleFormChange(value, fieldName);
    }

    return <Accordion className="styled-accordion" expanded={openSection === sectionId} id={sectionId} onChange={handleAccordionAction(sectionId)}>
        <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
        >
            <div className="flex-header">
                <div className="component-title">Underground</div>
            </div>
        </AccordionSummary>
        <AccordionDetails
            style={{flexFlow: 'column'}}
        >
            <div className="form">
                <div className="form-row">
                    <BighamNumberInput
                        label="Total Estimated Footage"
                        fieldName="underground.totalFootage"
                        value={jobDetailModel.underground.totalFootage}
                        onChange={handleFormChange}
                        readOnly={!permissions.canEditTotalEstimatedFootage}
                    />
                    <BighamNumberInput
                        label="Total Completed Footage"
                        fieldName="underground.totalFootageCompleted"
                        value={jobDetailModel.underground.totalFootageCompleted}
                        onChange={handleFormChange}
                        readOnly={true}
                    />
                    <BighamDatePicker 
                        label="Complete Date"
                        fieldName="underground.completeDate"
                        value={jobDetailModel.underground.completeDate}
                        onChange={handleLocalChange}
                        readOnly={!permissions.canEditCompleteDate}
                        error={errors?.underground?.completeDate}
                    />
                </div>
            </div>
            <div className="card-container">
                {!!jobDetailModel?.underground?.teams?.length && jobDetailModel.underground?.teams?.map((undergroundRecord: IUndergroundTeam, index: number, collection: IUndergroundTeam[]) => 
                    <FootageCard key={index} index={index} data={undergroundRecord} list={collection} foremanList={foremanList} handleFormChange={handleFormChange} group="underground" permissions={permissions} errors={errors} setErrors={setErrors} />
                )}
            </div>
            {canAddNewCard() && <div className="flex-row center">
                <BighamButton
                    label={'Add New'}
                    type="primary"
                    variant="contained"
                    onClick={handleAddNewCard({ footageCompleted: 0, issueDate: '', completeDate: '', foremanId: 0, isCritical: false }, 'underground.teams')}
                />
            </div>}
        </AccordionDetails>
    </Accordion>
}

export default UndergroundSection;