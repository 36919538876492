import { FC } from "react"
import ModalBase from "../../../../library/components/ModalBase/ModalBase"
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";


interface CompleteDateNotificationModalProps {
    handleSetNotificationFlag(flagValue: boolean): () => void
}

const CompleteDateNotificationModal: FC<CompleteDateNotificationModalProps> = ({handleSetNotificationFlag}) => {
    return <ModalBase onCancel={handleSetNotificationFlag(false)} size="medium">
        <h2 className="modal-title centered">
            Notify Customer of Completion?
        </h2>
        <div className="modal-button-container">
            <BighamButton
                type="default" 
                variant="contained"
                label={`No`}
                onClick={handleSetNotificationFlag(false)}
            />
            <BighamButton
                type="primary" 
                variant="contained"
                label={`Yes`}
                onClick={handleSetNotificationFlag(true)}
            />
        </div>
    </ModalBase>
}

export default CompleteDateNotificationModal;