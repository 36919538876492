import React, { FC, MouseEvent } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import UndoIcon from '@mui/icons-material/Undo';
import BighamButton from '../FormControls/BighamButton/BighamButton';
import { JOB_CODE_STATUS } from '../../constants/jobCodeStatus';

interface BackChargesRendererProps extends ICellRendererParams {
    onButtonClick(data: any): (e: MouseEvent<Element>) => void
}

const BackChargesRenderer: FC<BackChargesRendererProps> = ({onButtonClick, ...params}) => {
    const canShowIcon = JOB_CODE_STATUS.APPROVED === params.data.statusId;
    return <span className="icon-wrapper align-center">
        {canShowIcon && <UndoIcon className="cursor-pointer" onClick={onButtonClick(params.data)} />}
    </span>
};

export default BackChargesRenderer;
