import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generateGridConfig } from '../../pages/JobTracker/trackerColumnFactory';
import {
  getUserPreferences,
  updateUserPreferences
} from '../api/preferencesApi';
import { AppDispatch, RootState } from '../store/store';

interface PreferencesState {
  columnOrder: { name: string }[];
  filterModel: any;
  preferencesResolved: boolean
}

// Define the initial state using that type
const initialState: PreferencesState = {
  columnOrder: [],
  filterModel: {},
  preferencesResolved: false
};

export const callGetUserPreferences = createAsyncThunk(
  'preferences/getColumnOrder',
  async (args, { dispatch }) => {
    const response = await getUserPreferences();
    dispatch(setPreferencesResolved(true))
    if (response.data.preferenceData) {
      const filterModel = JSON.parse(response.data.preferenceData)?.filterModel || {};
      const columnOrder = JSON.parse(response.data.preferenceData)?.columnOrder || [];
      dispatch(setFilterModel(filterModel));
      dispatch(setColumnOrder(columnOrder));
      return {
        columnOrder: columnOrder || [],
        filterModel: filterModel || {}
      }
    }
  }
);

export const callUpdateUserPreferences = createAsyncThunk<any, any, { dispatch: AppDispatch }>(
  'preferences/updateColumnOrder',
  async (payload: string, { dispatch }) => {
    dispatch(setColumnOrder(JSON.parse(payload)?.columnOrder || []));
    dispatch(setFilterModel(JSON.parse(payload)?.filterModel || {}));
    await updateUserPreferences(payload);
  }
);

export const preferencesSlice = createSlice({
  name: 'preferences',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setColumnOrder: (state, action: PayloadAction<{ name: string }[]>) => {
      state.columnOrder = action.payload;
    },
    setFilterModel: (state, action: PayloadAction<any>) => {
      state.filterModel = action.payload;
    },
    setPreferencesResolved: (state, action: PayloadAction<boolean>) => {
      state.preferencesResolved = action.payload;
    }
  }
});

export const { setColumnOrder, setFilterModel, setPreferencesResolved } = preferencesSlice.actions;

export const selectColumnOrder = (state: RootState) =>
  state.preferences.columnOrder;
export const selectFilterModel = (state: RootState) =>
  state.preferences.filterModel;
export const selectPreferencesResolved = (state: RootState) =>
  state.preferences.preferencesResolved;

export default preferencesSlice.reducer;
