import { FC, useState, useRef } from "react"
import ModalBase from "../../../../library/components/ModalBase/ModalBase"
import { ITeamJobCode } from "../../../../main/types/jobDetailTypes";
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import InvoicingJobCodesGrid from "./InvoicingJobCodesGrid";
import { AgGridReact } from "ag-grid-react";
import BighamTextInput from "../../../../library/components/FormControls/BighamTextInput/BighamTextInput";
import { SelectionChangedEvent } from "ag-grid-community";
import { map } from "lodash";
import { JOB_CODE_STATUS } from "../../../../library/constants/jobCodeStatus";
import { IProductionReportsForInvoicingPermissions } from "../../../../main/types/permissionTypes";


interface InvoicingJobCodesModalProps {
    onCancel(): void,
    listItems: ITeamJobCode[],
    handleAddToInvoice(jobCodes: ITeamJobCode[]): void,
    permissions: IProductionReportsForInvoicingPermissions | undefined
}

const InvoicingJobCodesModal: FC<InvoicingJobCodesModalProps> = ({onCancel, listItems, handleAddToInvoice, permissions}) => {
    const filteredItems = listItems.filter((item) => item.isCrewSubCode && item.statusId !=8);
    const ref = useRef<AgGridReact | null>(null);

    const handleUpdateRequestedInvoice = () => {
        const selectedRows: ITeamJobCode[] = ref.current?.api.getSelectedRows() as ITeamJobCode[];
        const rowsWithStatusUpdated = map(selectedRows, (row: ITeamJobCode) => ({
            ...row,
            isSelected: true
        }));
        handleAddToInvoice(rowsWithStatusUpdated);
    }

    return <ModalBase onCancel={onCancel} size="large">
        <h2 className="modal-title">
            Daily Job Codes
        </h2>
        <InvoicingJobCodesGrid ref={ref} listItems={filteredItems} permissions={permissions} />
        <div className="modal-button-container">
            <BighamButton
                type="default"
                variant="contained"
                onClick={onCancel}
                label={'Close'}
            />
            <BighamButton
                type="primary" 
                variant="contained"
                label={`Update Requested Invoice`}
                onClick={handleUpdateRequestedInvoice}
            />
        </div>
    </ModalBase>
}

export default InvoicingJobCodesModal;