import { ChangeEvent, FC } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ILookupItem } from '../../../../main/types/genericTypes';

interface BighamTextAutoCompleteProps {
    fieldName: string;
    listItems: string[];
    value: string;
    label?: string;
    onChange(value: any, fieldName: string): void;
    freeSolo?: boolean;
    readOnly?: boolean;
    error?: string;
}

const BighamTextAutoComplete: FC<BighamTextAutoCompleteProps> = ({fieldName, listItems, value, label = "", onChange, freeSolo = false, readOnly = false, error = ""}) => {
  return (
    <Autocomplete
      className="form-control autocomplete"
      disableClearable
      options={listItems}
      onChange={(e, newValue) => onChange(newValue, fieldName)}
      value={value}
      readOnly={readOnly}
      freeSolo={freeSolo}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          size="small"
          onBlur={(e) => onChange(e.target.value, fieldName)}
          error={!!error.length}
          helperText={error}
        />
      )}
    />
  );
}

export default BighamTextAutoComplete;