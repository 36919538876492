import { AgGridReact } from 'ag-grid-react';
import React, { ColDef, GridReadyEvent, GridSizeChangedEvent, RowDoubleClickedEvent, ValueGetterParams} from 'ag-grid-community';
import { FC, ForwardedRef, useState, useRef, useEffect } from 'react';
import MultiSelectFilter from '../../library/components/GridComponents/GridMultiSelectFilter';
import { forwardRef } from 'react';
import ROUTES from '../../library/constants/routeConstants';
import { useNavigate } from 'react-router-dom';
import ValueRenderer from '../../library/components/GridComponents/ValueRenderer';
import { IUserListInterface } from '../../main/types/userTypes';
import { map } from 'lodash';
import { ILookupItem } from '../../main/types/genericTypes';
import EmailRenderer from '../../library/components/GridComponents/EmailRenderer';
import UserTypeRenderer from '../../library/components/GridComponents/UserTypeRenderer';
import UserStatusRenderer from '../../library/components/GridComponents/UserStatusRenderer';
import { DateColumnDef, generateFilterOptions, ObjectColumnDef, StringColumnDef } from '../JobTracker/trackerColumnFactory';
import { dateFormat, dateTimeFormat } from '../../library/constants/dateFormat';
import variables from '../../assets/styles/variables.module.scss';


interface UserListGridProps {
    userData: IUserListInterface[],
    ref: ForwardedRef<AgGridReact>
}

const UserListGrid: FC<UserListGridProps> = forwardRef<AgGridReact, UserListGridProps>(({userData}, ref) => {
    const navigate = useNavigate();
    const handleDoubleClick = (params: RowDoubleClickedEvent) => {
        navigate(`${ROUTES.USERS}/${params.data.id}`);
    }
    const [gridHeight, setGridHeight] = useState('auto');
    const gridWrapperRef = useRef<HTMLDivElement | null>(null);
    const iconCellWidth = 60;
    const columnDefs: ColDef[] = [
        {
            field: 'userTypeId',
            headerName: ' ',
            width: iconCellWidth,
            maxWidth: iconCellWidth,
            minWidth: iconCellWidth,
            cellRenderer: UserTypeRenderer
        },
        StringColumnDef('fullName', 'Name', true),
        StringColumnDef('username', 'Username', true),
        StringColumnDef('employeeTitles', 'Titles', true),
        DateColumnDef('registrationDate', 'Registered', dateFormat, true),
        DateColumnDef('lastLoginDate', 'Last Login', dateTimeFormat, true),
        {
            ...StringColumnDef('email', 'Email', true),
            cellRenderer: EmailRenderer
        },
        StringColumnDef('phoneNumber', 'Phone #', true),
        StringColumnDef('mobileNumber', 'Mobile #', true),
        {
            field: 'areas',
            headerName: 'Areas',
            valueGetter: (params: ValueGetterParams): string => {
                return map(params.data.areas, (value: ILookupItem) => {
                    return value.name;
                }).join(', ')
            },
            filter: 'multiSelect',
            filterParams: {
                context: {
                    areasFilterOptions: generateFilterOptions(userData, 'areas', true),
                    isArray: true
                }
            },
            sortable: false
        },{
            field: 'statusId',
            headerName: 'Active',
            cellRenderer: UserStatusRenderer
        },
        ObjectColumnDef('accessLevel', 'Access Level', true),
        ObjectColumnDef('userType', 'Account Type', true)
    ];


    const handleSetGridHeight = () => {
        if (gridWrapperRef?.current) {
            const offset = gridWrapperRef.current.getBoundingClientRect().top;
            setGridHeight(`calc(100vh - (${offset}px + ${variables.spacing_standard}))`);
        }else {
            setGridHeight('auto');
        }
    }

    useEffect(() => {
        if (gridWrapperRef.current) {
            handleSetGridHeight();
            window.addEventListener('resize', handleSetGridHeight);
        }
        return () => {
            window.removeEventListener('resize', handleSetGridHeight);
        }
    }, [gridWrapperRef.current])

    return <div ref={gridWrapperRef} className="grid-wrapper ag-theme-material" style={{ height: gridHeight }}>
       {!!userData?.length && <AgGridReact
            ref={ref}
            components={{
                multiSelect: MultiSelectFilter
            }}
            onRowDoubleClicked={handleDoubleClick}
            defaultColDef={
                {
                    resizable: true,
                    cellRenderer: ValueRenderer,
                    sortable: true,
                    menuTabs: ['filterMenuTab'],
                }
            }
            columnDefs={columnDefs}
            rowData={userData}
            onGridReady={(event: GridReadyEvent) => {
                event.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(event: GridSizeChangedEvent) => {
                event.api.sizeColumnsToFit();
            }}
        />}
    </div>
})

export default UserListGrid;