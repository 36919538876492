import { createTheme } from '@material-ui/core/styles';
import variables from '../../assets/styles/variables.module.scss';

export const theme = createTheme({
  palette: {
    primary: {
      main: variables.color_blue_main
    },
    secondary: {
      main: variables.color_red
    }
  }
});
