import { IUserListInterface, IUserServiceGet } from "../../main/types/userTypes";


export const transformUserListResponse = (response: IUserServiceGet[], accessLevels: any[]): IUserListInterface[] => {
    const accessLevelMap = mapAccessLevels(accessLevels);
    return (response || []).map((user): IUserListInterface => {
        const { accessLevelId, accessLevelDisplayName, userTypeId, userTypeName , ...remainingUser } = user;
        const returnValue: IUserListInterface = {
            ...remainingUser,
            accessLevel: { id: accessLevelMap[user.accessLevelId], name: user.accessLevelDisplayName },
            userType: { id: user.userTypeId, name: user.userTypeName },
        }

        return returnValue;
    })
}

const mapAccessLevels = (accessLevels: any[]): { [key: string]: number } => {
    const returnValue: { [key: string]: number } = {};
    (accessLevels || []).map((level, index) => {
        returnValue[level.id] = index + 1;
    });
    return returnValue;

}