import { FC, useMemo } from "react";
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import variables from '../../../../assets/styles/variables.module.scss';
import { formatCurrency, formatNumberWithCommas } from "../../../util/numberFormatter";

interface PDFTableNumberCellProps {
    fieldName: any,
    rowData?: any,
    flexGrow?: number,
    textAlign?: 'left' | 'center' | 'right',
    isMoney?: boolean,
    shaded?: boolean
}

const styles = StyleSheet.create({
    cell: {
        flexBasis: 0,
        flexGrow: 1,
        flexShrink: 1,
        borderRight: '1px solid black',
        paddingTop: variables.spacing_xxs,
        paddingBottom: variables.spacing_xxs,
        paddingLeft: variables.spacing_xs,
        paddingRight: variables.spacing_xs
    },
    cellValue: {
        fontSize: '8px',
        textAlign: 'center',
    }
})

const PDFTableNumberCell: FC<PDFTableNumberCellProps> = ({fieldName, rowData, textAlign = 'left', flexGrow = 1, isMoney = false, shaded = false }) => {
    const value = useMemo(() => {
        if (isMoney) {
            return formatCurrency(rowData[fieldName]);
        }
        return formatNumberWithCommas(rowData[fieldName]);
    }, [rowData, isMoney]);

    const cellStyles = useMemo(() => {
        if (shaded) {
            return { ...styles.cell, flexGrow, backgroundColor: variables.color_silver }
        }
        return { ...styles.cell, flexGrow }
    }, [flexGrow, shaded])

    return <View style={cellStyles}>
        <Text style={{ ...styles.cellValue, textAlign }}>
            {value}
        </Text>
    </View>
}

export default PDFTableNumberCell;