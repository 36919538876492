import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent, GridSizeChangedEvent, IServerSideDatasource, RowDoubleClickedEvent, ValueGetterParams} from 'ag-grid-community';
import { FC, ForwardedRef, useRef, useState } from 'react';
import MultiSelectFilter from '../../library/components/GridComponents/GridMultiSelectFilter';
import { forwardRef } from 'react';
import ValueRenderer from '../../library/components/GridComponents/ValueRenderer';
import { DateColumnDef, dateFormatter, generateFilterOptions, ObjectColumnDef, StringColumnDef } from '../JobTracker/trackerColumnFactory';
import { useAppDispatch, useAppSelector } from '../../main/hooks/hooks';
import { find } from 'lodash';
import { callGetDailyStatusList, selectBighamCoordinatorList, selectDailyStatusList } from '../../main/slices/lookupSlice';
import { useEffect } from 'react';
import { IDailyProductionReport } from '../../main/types/reportTypes';
import { dateFormat } from '../../library/constants/dateFormat';
import variables from '../../assets/styles/variables.module.scss';



interface DailyProductionReportsGridProps {
    listItems: IDailyProductionReport[],
    ref: ForwardedRef<AgGridReact>,
    handleDoubleClick(e: RowDoubleClickedEvent): void;
}

const DailyProductionReportsGrid: FC<DailyProductionReportsGridProps> = forwardRef<AgGridReact, DailyProductionReportsGridProps>(({listItems, handleDoubleClick}, ref) => {
    const dispatch = useAppDispatch();
    const [gridHeight, setGridHeight] = useState('auto');
    const gridWrapperRef = useRef<HTMLDivElement | null>(null);
    const { 
        dailyStatusList,
        bighamCoordinators
     } = useAppSelector((state) => ({
        dailyStatusList: selectDailyStatusList(state),
        bighamCoordinators: selectBighamCoordinatorList(state)
    }));

    useEffect(() => {
        !dailyStatusList?.length && dispatch(callGetDailyStatusList());
    }, []);

    const columnDefs: ColDef[] = [
        DateColumnDef('dailyDate', 'Date', dateFormat, true),
        ObjectColumnDef('dailyStatusLookup', 'Status', true),
        StringColumnDef('jobNumber', 'Job #', true),
        StringColumnDef('businessName', 'Business', true),
        {
            ...StringColumnDef('address', 'Address', true),
            valueGetter: (params: ValueGetterParams): string => {
                const address = params.data.address;
                return `${address.addressLine1}, ${address.city}, ${address.state} ${address.zipCode}`;
            }
        },
        ObjectColumnDef('bighamCoordinatorLookup', 'Bigham Coordinator', true),
        ObjectColumnDef('foremanLookup', 'Foreman', true),
        ObjectColumnDef('crewTypeLookup', 'Crew Type', true),
        ObjectColumnDef('reviewerLookup', 'Reviewer', true),
        DateColumnDef('reviewDate', 'Review Date', dateFormat, true),
        DateColumnDef('crewCompleteDate', 'Crew Complete Date', dateFormat, true)
    ];

    const handleSetGridHeight = () => {
        if (gridWrapperRef?.current) {
            const offset = gridWrapperRef.current.getBoundingClientRect().top;
            setGridHeight(`calc(100vh - (${offset}px + ${variables.spacing_standard}))`);
        }else {
            setGridHeight('auto');
        }
    }

    useEffect(() => {
        if (gridWrapperRef.current) {
            handleSetGridHeight();
            window.addEventListener('resize', handleSetGridHeight);
        }
        return () => {
            window.removeEventListener('resize', handleSetGridHeight);
        }
    }, [gridWrapperRef.current])

    return <div ref={gridWrapperRef} className="grid-wrapper ag-theme-material" style={{ height: gridHeight }}>
       <AgGridReact
            ref={ref}
            components={{
                multiSelect: MultiSelectFilter
            }}
            paginationPageSize={100}
            pagination={true}
            onRowDoubleClicked={handleDoubleClick}
            defaultColDef={
                {
                    resizable: true,
                    cellRenderer: ValueRenderer,
                    sortable: true,
                    menuTabs: ['filterMenuTab'],
                }
            }
            columnDefs={columnDefs}
            rowData={listItems}
        />
    </div>
})

export default DailyProductionReportsGrid;