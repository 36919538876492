import { AlertColor } from '@mui/material';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserPermissions } from '../api/utilApi';
import type { RootState } from '../store/store';
import { IUserPermissions } from '../types/permissionTypes';

// Define types for the slice
interface ToastMessage {
  type?: AlertColor;
  message?: string;
}

interface UtilState {
  toastMessage: ToastMessage;
  activeRequests: number;
  componentName: string;
  activeUser: any;
  permissions: IUserPermissions;
  selectedArea: number;
  downloadRowData: any[]
}

// Define the initial state using that type
const initialState: UtilState = {
  toastMessage: {},
  activeRequests: 0,
  componentName: '',
  activeUser: {},
  permissions: {
    jobPermissions: {},
    managementPermissions: {},
    areaDailyProductionReportPermissions: {},
    areaDailyQualityControlReportPermissions: {},
    areaRequestedInvoicesReportPermissionsDto: {},
    areaWeeklyProductionReportPermissionsDto: {},
    areaMyProductionReportPermissionsDto: {}
  },
  selectedArea: parseInt(window.sessionStorage.getItem('areaId') || '0'),
  downloadRowData: []
};

export const callGetUserPermissions = createAsyncThunk(
  'util/getPermissions',
  async (args, { dispatch }) => {
    const response = await getUserPermissions();
    dispatch(setUserPermissions(response.data));
  }
);

export const utilSlice = createSlice({
  name: 'util',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setToastMessage: (state, action: PayloadAction<ToastMessage>) => {
      state.toastMessage = action.payload;
    },
    addFetch: (state) => {
      state.activeRequests++;
    },
    removeFetch: (state) => {
      state.activeRequests > 0 && state.activeRequests--;
    },
    setComponentName: (state, action: PayloadAction<string>) => {
      state.componentName = action.payload;
    },
    setActiveUser: (state, action: PayloadAction<any>) => {
      state.activeUser = action.payload;
    },
    setUserPermissions: (state, action: PayloadAction<IUserPermissions>) => {
      state.permissions = action.payload;
    },
    setSelectedArea: (state, action: PayloadAction<number>) => {
      window.sessionStorage.setItem('areaId', action.payload.toString());
      state.selectedArea = action.payload;
    },
    setDownloadRowData: (state, action: PayloadAction<any[]>) => {
      state.downloadRowData = action.payload;
    }
  }
});

export const {
  setToastMessage,
  addFetch,
  removeFetch,
  setComponentName,
  setActiveUser,
  setUserPermissions,
  setSelectedArea,
  setDownloadRowData
} = utilSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectToastMessage = (state: RootState): ToastMessage =>
  state.util.toastMessage;
export const selectIsLoading = (state: RootState): boolean =>
  state.util.activeRequests > 0;
export const selectComponentName = (state: RootState): string =>
  state.util.componentName;
export const selectActiveUserName = (state: RootState): string =>
  state.util.activeUser?.preferred_username || '';
export const selectUserPermissions = (state: RootState): IUserPermissions =>
  state.util.permissions;
export const selectSelectedArea = (state: RootState): number =>
  state.util.selectedArea;
export const selectIsUserRegistered = (state: RootState) =>
  !!state.util.activeUser?.roles?.length;
export const selectDownloadRowData = (state: RootState) => 
  state.util.downloadRowData;
export default utilSlice.reducer;
