import React, { FC, FocusEvent, useState } from 'react';
import { FormControl, MenuItem, Select, InputLabel } from '@material-ui/core';
import { ILookupItem } from '../../../../main/types/genericTypes';
import { FormHelperText } from '@mui/material';
import { useMemo } from 'react';
import { sortBy } from 'lodash';

interface SelectProps {
    label?: string,
    fieldName: string,
    useNameAsValue?: boolean,
    value: number | string,
    listItems: Array<ILookupItem>,
    onChange: (value: unknown, fieldName: string) => void,
    initialOption?: JSX.Element,
    error?: string,
    className?: string,
    readOnly?: boolean,
    disabledId?: number
    sortFilterOptions?: boolean,
    additionalOption?: JSX.Element,
}

const defaultItem = <MenuItem value={0} disabled>Select One</MenuItem>

const BighamSelect: FC<SelectProps> = ({label, listItems, value, fieldName, useNameAsValue = false, onChange, className = '', initialOption = defaultItem, additionalOption,error = '', sortFilterOptions = true, readOnly = false, disabledId = 0}) => {

    const sortedListOptions = useMemo(() => {
        if (sortFilterOptions && listItems?.length) {
            return sortBy(listItems, ['name']);
        }
        return listItems;
    }, [listItems])

    const randInt = Math.random();
    return <FormControl
        className={`form-control ${className}`}
        variant="outlined"
        size="small"
        error={!!error.length}
    >
        {label && <InputLabel id={`${randInt}-select-id`}>{label}</InputLabel>}
        <Select
            labelId={`${randInt}-select-id`}
            label={label}
            onChange={(e) => onChange(e.target.value, fieldName)}
            value={value}
            readOnly={readOnly}
        >
            {additionalOption && additionalOption}
            {initialOption}
            {sortedListOptions?.length > 0 && sortedListOptions.map((item: ILookupItem, index: number) => {
                return <MenuItem key={index} value={useNameAsValue ? item.name : item.id} disabled={item.archived || (item.id === disabledId)}>{item.name}</MenuItem>
            })}
        </Select>
        {error?.length > 0 && <FormHelperText error={true}>{error}</FormHelperText>}
    </FormControl>;
}

export default BighamSelect;