import { ChangeEvent, FC, useRef, useState, useMemo } from "react";
import './FinancialsSection.scss'
import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../main/hooks/hooks";
import { formatNumberWithCommas } from "../../../../library/util/numberFormatter";
import FinancialsInvoicesGrid from "./FinancialsInvoicesGrid";
import { AgGridReact } from "ag-grid-react";
import { IInvoiceSummary, IInvoiceSummaryBlock, IJobDetailCombined, IJobInvoice, IRejectInvoicePayload } from "../../../../main/types/jobDetailTypes";
import { INVOICE_STATUS } from "../../../../library/constants/jobCodeStatus";
import { find } from "lodash";
import BighamDatePicker from "../../../../library/components/FormControls/BighamDatePicker/BighamDatePicker";
import BighamTextInput from "../../../../library/components/FormControls/BighamTextInput/BighamTextInput";
import BighamButton from "../../../../library/components/FormControls/BighamButton/BighamButton";
import { callGetInvoiceDailyCSVDownload, callGetInvoiceDailyData, callIssueInvoice, callRejectInvoice } from "../../../../main/slices/jobDetailSlice";
import { IJobFinancialsPermissions } from "../../../../main/types/permissionTypes";
import RequestedInvoiceSummaryGrid from "./RequestedInvoiceSummaryGrid";
import { isDateValid } from "../../../../library/util/validators";
import { buildInvoicedSummary } from "../../../../library/transforms/invoiceTransform";
import ReactPDF from "@react-pdf/renderer";
import InvoiceGenerator from "../../../../library/components/PDFInvoiceGenerator/PDFInvoiceGenerator";
import { type } from "os";
import { addFetch, removeFetch } from "../../../../main/slices/utilSlice";
import { selectBighamCoordinatorList, selectCategoryList, selectCustomerCoordinatorList } from "../../../../main/slices/lookupSlice";
import CommentsModal from "../../../../library/components/CommentsModal/CommentsModal";

interface JobFinancialsSectionProps {
    jobDetailModel: any,
    setJobDetailModel(details: any): void,
    openSection: string,
    handleAccordionAction(panel: string): (event: ChangeEvent<any>, isExpanded: boolean) => void,
    permissions: IJobFinancialsPermissions | undefined
}

const JobFinancialsSection: FC<JobFinancialsSectionProps> = ({openSection, handleAccordionAction, setJobDetailModel, jobDetailModel, permissions}) => {
    const sectionId = 'financials';
    const dispatch = useAppDispatch();
    const gridRef = useRef<AgGridReact | null>(null);

    const {
        customerList,
        bighamList,
        categoryList
    } = useAppSelector((state) => ({
        customerList: selectCustomerCoordinatorList(state),
        bighamList: selectBighamCoordinatorList(state),
        categoryList: selectCategoryList(state)
    }));

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [showRejectModal, setShowRejectModal] = useState(false);

    const [newInvoiceForm, setNewInvoiceForm] = useState({
        invoiceId: 0,
        invoiceDate: new Date().toISOString(),
        invoiceNumber: ''
    });
    
    const requestedInvoice: IJobInvoice = find(jobDetailModel.invoices?.invoices || [], (invoice: IJobInvoice) => invoice.invoiceStatusId === INVOICE_STATUS.REQUESTED);

    const handleFormChange = (value: any, fieldName: string) => {
        const tempErrors = {...errors};
        delete tempErrors[fieldName];
        setErrors(tempErrors);
        setNewInvoiceForm({ ...newInvoiceForm, [fieldName]: value });
    }

    const formatMoneyValues = (value: number): string => {
        return `$${formatNumberWithCommas(parseFloat("" + value).toFixed(2))}`;
    }

    const handleIssueInvoice = async () => {
        const formErrors: { [key: string]: string} = validateForm(newInvoiceForm);
        setErrors(formErrors);
        if (!Object.keys(formErrors)?.length && requestedInvoice?.id > -1) {
            const payload = {
                ...newInvoiceForm,
                invoiceId: requestedInvoice?.id
            }
            dispatch(callIssueInvoice(payload)).then(() => {
                handleFormChange('', 'invoiceNumber');
            });
        }
    }

    const validateForm = (values: any) => {
        const tempErrors: { [key: string]: string } = {};
        if (!values.invoiceNumber) {
            tempErrors.invoiceNumber = 'Required';
        }
        if (!values.invoiceDate) {
            tempErrors.invoiceDate = 'Required'
        } else if (values.invoiceDate && !isDateValid(values.invoiceDate)) {
            tempErrors.invoiceDate = 'Invalid Date (use format MM/DD/YYYY)'
        }
        return tempErrors;
    }

    const handleShowRejectModal = () => {
        setShowRejectModal(true)
    }

    const handleCloseModal = () => {
        setShowRejectModal(false);
    }

    const handleRejectInvoice = (comments: string) => () => {
        const payload: IRejectInvoicePayload = {
            invoiceId: requestedInvoice.id,
            comments,
            jobId: jobDetailModel.details.id
        };
        dispatch(callRejectInvoice(payload));
        setShowRejectModal(false);
    }

    const requestedInvoiceListItems = useMemo(() => {
        return Object.values(jobDetailModel.invoicing.invoiceSummary as IInvoiceSummaryBlock).filter((item: IInvoiceSummary) => item.isPrimaryCustomerCode && item.newInvoiceTotal !== 0);

    }, [jobDetailModel.invoicing.invoiceSummary]);

    const total = useMemo(() => {
        return requestedInvoiceListItems.reduce((acc: number, curr: IInvoiceSummary) => {
            return acc + curr.newInvoiceTotal;
        }, 0);
    }, [requestedInvoiceListItems])

    const handleCreateInvoiceSummary = async (data: IJobInvoice) => {
        const invoiceData = await dispatch(callGetInvoiceDailyData({
            invoiceId: data.id
        }));
        const { dailyJobCodeIds, invoiceNumber, invoiceDate } = data;
        const type = 'application/pdf;charset=utf-8;'
        let fileName = `Invoice_${invoiceNumber}_${invoiceDate}`;
        let rowData: any[] = [];
        if (invoiceNumber && invoiceDate) {
            const invoiceSummary = buildInvoicedSummary((jobDetailModel as IJobDetailCombined).purchaseOrder?.ntpJobCodes || [], (jobDetailModel as IJobDetailCombined).invoicing.jobCodes, dailyJobCodeIds);
            rowData = Object.values(invoiceSummary as IInvoiceSummaryBlock).filter((item: IInvoiceSummary) => item.isPrimaryCustomerCode && item.invoicedTotal !== 0);
        } else {
            rowData = [...requestedInvoiceListItems]
            fileName = `Requested_Invoice_${jobDetailModel.details.job}`;
        }
        dispatch(addFetch());
        ReactPDF.pdf(
            <InvoiceGenerator
                fileName={fileName}
                invoiceDate={invoiceDate}
                invoiceNumber={invoiceNumber}
                data={requestedInvoiceListItems}
                dailyData={invoiceData.payload}
                customerList={customerList}
                bighamList={bighamList}
                categoryList={categoryList}
                jobDetails={jobDetailModel}
            />
        ).toBlob().then((blob) => {
            const svgFile = new Blob([blob], { type: type });
            const svgUrl = URL.createObjectURL(svgFile);
            const tempLink = document.createElement('a');
            tempLink.href = svgUrl;
            tempLink.setAttribute('download', `${fileName}.pdf`);
            tempLink.click();
        }).then(() => {
            dispatch(removeFetch());
        });
    }

    return <Accordion className="styled-accordion" expanded={openSection === sectionId} id={sectionId} onChange={handleAccordionAction(sectionId)}>
        <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
        >
            <div className="flex-header">
                <div className="component-title">Job Financials</div>
            </div>
        </AccordionSummary>
        <AccordionDetails
            style={{flexFlow: 'column'}}
            className="financials-section"
        >
            {showRejectModal && <CommentsModal onCancel={handleCloseModal} actionText={'Reject Invoice'} handleSubmit={handleRejectInvoice} />}
            <div className="sub-header">Summary</div>
            <div className="job-financials-summary-wrapper">
                <div className="summary-column">
                    <div className="summary-row">
                        <div className="summary-label">PO Amount</div>
                        <div className="summary-value">{formatMoneyValues(jobDetailModel?.financials?.purchaseOrderAmount || 0)}</div>
                    </div>
                    <div className="summary-row">
                        <div className="summary-label">Ready to Invoice</div>
                        <div className="summary-value">{formatMoneyValues(jobDetailModel?.financials?.readyToInvoice || 0)}</div>
                    </div>
                    <div className="summary-row">
                        <div className="summary-label">Invoiced to Date</div>
                        <div className="summary-value">{formatMoneyValues(jobDetailModel?.financials?.invoicedToDate || 0)}</div>
                    </div>
                    {permissions?.canViewRemainingOnPurchaseOrder===true &&  <div className="summary-row">
                        <div className="summary-label">Remaining on PO</div>
                        <div className="summary-value">{formatMoneyValues(jobDetailModel?.financials?.remainingOnPurchaseOrder || 0)}</div>
                    </div>}
                    {permissions?.canViewPaidOutData===true && <div className="summary-row">
                        <div className="summary-label">Paid Out</div>
                        <div className="summary-value">{formatMoneyValues(jobDetailModel?.financials?.paidOut || 0)}</div>
                    </div>}
                    {permissions?.canViewProfitLossData && <div className="summary-row">
                        <div className="summary-label">Profit/Loss</div>
                        <div className="summary-value">{formatMoneyValues(jobDetailModel?.financials?.profitLoss || 0)}</div>
                    </div>}
                </div>
            </div>
            {!!jobDetailModel.invoices?.invoices?.length && <>
                <div className="sub-header">Invoice History</div>
                <FinancialsInvoicesGrid ref={gridRef} listItems={jobDetailModel.invoices.invoices} permissions={permissions} handleCreateInvoiceSummary={handleCreateInvoiceSummary} />
            </>}
            {requestedInvoice && <>
                <div className="requested-invoice-wrapper">
                    <div className="issue-invoice-form-wrapper form">
                        <div className="sub-header">Issue Invoice</div>
                        <div className="form-row">
                            <BighamDatePicker
                                fieldName="invoiceDate"
                                label="Invoice Date"
                                value={newInvoiceForm.invoiceDate}
                                onChange={handleFormChange}
                                error={errors.invoiceDate}
                            />
                        </div>
                        <div className="form-row">
                            <BighamTextInput
                                fieldName="invoiceNumber"
                                label="Invoice #"
                                value={newInvoiceForm.invoiceNumber}
                                onChange={handleFormChange}
                                error={errors.invoiceNumber}
                            />
                        </div>
                        {!!permissions?.canIssueInvoice && <div className="form-row">
                            <BighamButton
                                className="form-control"
                                label="Issue Invoice"
                                type="primary"
                                variant="contained"
                                onClick={handleIssueInvoice}
                            />
                        </div>}
                        {!!permissions?.canIssueInvoice && <div className="form-row">
                            <BighamButton
                                className="form-control"
                                label="Reject Invoice"
                                type="secondary"
                                variant="outlined"
                                onClick={handleShowRejectModal}
                            />
                        </div>}
                    </div>
                    <div className="requested-invoice-summary-grid-wrapper">
                        <div className="sub-header">Requested Invoice Summary</div>
                        <RequestedInvoiceSummaryGrid ref={gridRef} listItems={requestedInvoiceListItems} />
                        <div className="flex-row space-between">
                            <span className="comments-wrapper"><b>Comments:</b>{!!requestedInvoice.requestComments?.length && requestedInvoice.requestComments}</span>
                            <span className="total-wrapper">Total: ${formatNumberWithCommas(parseFloat(total).toFixed(2))}</span>
                        </div>
                    </div>
                </div>
            </>}
        </AccordionDetails>
    </Accordion>
}

export default JobFinancialsSection;