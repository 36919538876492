import { NumberSequence } from 'ag-grid-community';
import bighamApi from '../axios/bighamApi';
import {
  IQualityControlReport,
  IDailyProductionReport,
  IWeeklyProductionReport,
  IMyProductionReport
} from '../types/reportTypes';

export const getDailyProductionReports = async (areaId: number): Promise<{
  data: IDailyProductionReport[];
}> => {
  const response = await bighamApi({
    method: 'GET',
    url: `Jobs/Dailies/ProductionReports?areaId=${areaId}`,
  });
  return response;
};
export const getWeeklyProductionReports = async (
  areaId: number,
  requestParams: any
): Promise<{
  data: {
    rowData: IWeeklyProductionReport[];
    rowCount: number;
  }
}> => {
  const response = await bighamApi({
    method: 'PATCH',
    url: `Jobs/Dailies/WeeklyProductionReportPaged?areaId=${areaId}`,
    data: requestParams
  });
  return response;
};

export const getMyProductionReports = async (
  areaId: number,
  requestParams: any
): Promise<{
  data: {
    rowData: IMyProductionReport[];
    rowCount: number;
    summary: {
      totalAmount: number,
      totalQuantity: number
    }
  }
}> => {
  const response = await bighamApi({
    method: 'PATCH',
    url: `Jobs/Dailies/MyProductionReportPaged?areaId=${areaId}`,
    data: requestParams
  });
  return response;
};

export const getQualityControlReports = async (): Promise<{
  data: IQualityControlReport[];
}> => {
  const response = await bighamApi({
    method: 'GET',
    url: `Jobs/Dailies/QualityControl`
  });
  return response;
};

export const getInvoiceReports = async (
  areaId: number
): Promise<{ data: any[] }> => {
  const response = await bighamApi({
    method: 'GET',
    url: `Jobs/Invoices/RequestedReport?areaId=${areaId}`
  });
  return response;
};
