import bighamApi from '../axios/bighamApi';
import { IJobTrackerDashboardSample } from '../types/trackerTypes';

export const getTrackerData = async (
  areaId: number
): Promise<{
    data: Array<IJobTrackerDashboardSample>;
}> => {
  let url = 'jobs/DashboardJobs';
  if (areaId) {
    url += `?areaId=${areaId}`;
  }
  const response = await bighamApi({
    method: 'GET',
    url
  });
  return response;
};

export const getTrackerDataPaged = async (
  areaId: number,
  requestPayload: any
): Promise<{
  rowData: IJobTrackerDashboardSample[],
  rowCount: number,
  summary: any
}> => {
  let url = 'jobs/DashboardJobsPaged';
  if (areaId) {
    url += `?areaId=${areaId}`;
  }
  const response = await bighamApi({
    method: 'PATCH',
    url,
    data: requestPayload
  });
  return response.data;
}
