import { AgGridReact } from 'ag-grid-react';
import { ColDef, FilterChangedEvent, FirstDataRenderedEvent, GridReadyEvent, IServerSideDatasource, PaginationChangedEvent } from 'ag-grid-community';
import { FC, ForwardedRef, useRef, useState } from 'react';
import { forwardRef } from 'react';
import ValueRenderer from '../../library/components/GridComponents/ValueRenderer';
import { DateColumnDef, MoneyColumnDef, NumberColumnDef, ObjectColumnDef, PercentColumnDef, StringColumnDef } from '../JobTracker/trackerColumnFactory';
import { useAppDispatch, useAppSelector } from '../../main/hooks/hooks';
import { callGetDailyStatusList, selectDailyStatusList } from '../../main/slices/lookupSlice';
import { useEffect } from 'react';
import { dateFormat } from '../../library/constants/dateFormat';
import variables from '../../assets/styles/variables.module.scss';
import { SESSION_KEYS } from '../../library/constants/sessionStorageKeys';
import GridCsvDownload from '../../library/components/GridCsvDownload/GridCsvDownload';



interface WeeklyProductionReportsGridProps {
    ref: ForwardedRef<AgGridReact>,
    areaId: number,
    avoidServerCall: boolean;
    setAvoidServerCall(value: boolean): void;
    dataSource(areaId: number): IServerSideDatasource;
}

const WeeklyProductionReportsGrid: FC<WeeklyProductionReportsGridProps> = forwardRef<AgGridReact, WeeklyProductionReportsGridProps>(({areaId, dataSource, avoidServerCall, setAvoidServerCall}, ref) => {
    const dispatch = useAppDispatch();
    const [gridHeight, setGridHeight] = useState('auto');
    const gridWrapperRef = useRef<HTMLDivElement | null>(null);
    const { 
        dailyStatusList,
     } = useAppSelector((state) => ({
        dailyStatusList: selectDailyStatusList(state),
    }));

    useEffect(() => {
        !dailyStatusList?.length && dispatch(callGetDailyStatusList());
    }, []);

    const columnDefs: ColDef[] = [
        DateColumnDef('dailyDate', 'Date', dateFormat, true),
        ObjectColumnDef('dailyStatus', 'Status', true, areaId),
        StringColumnDef('jobNumber', 'Job #', true),
        StringColumnDef('businessName', 'Business', true),
        StringColumnDef('streetCity', 'Street/City', true),
        ObjectColumnDef('bighamCoordinator', 'Bigham Coordinator', true, areaId),
        ObjectColumnDef('dailyCrewMember', 'Crew Member', true, areaId),
        StringColumnDef('accountType', 'Account Type', true),
        StringColumnDef('office', 'Office', true),
        PercentColumnDef('dailyCrewMemberAllocation', 'Crew Allocation', true),
        StringColumnDef('dailyCrewMembersWithAllocations', 'Crew Members', false),
        StringColumnDef('jobCode', 'Job Code', true),
        StringColumnDef('jobCodeDescription', 'Description', true),
        MoneyColumnDef('rate', 'Rate', false),
        NumberColumnDef('productionQuantity', 'Quantity', false),
        MoneyColumnDef('total', 'Total', false),
        MoneyColumnDef('payRate', 'Pay Rate', true)
    ];

    const handleSetGridHeight = () => {
        if (gridWrapperRef?.current) {
            const offset = gridWrapperRef.current.getBoundingClientRect().top;
            setGridHeight(`calc(100vh - (${offset}px + ${variables.spacing_standard}))`);
        }else {
            setGridHeight('auto');
        }
    }

    useEffect(() => {
        if (gridWrapperRef.current) {
            handleSetGridHeight();
            window.addEventListener('resize', handleSetGridHeight);
        }
        return () => {
            window.removeEventListener('resize', handleSetGridHeight);
        }
    }, [gridWrapperRef.current])

    const handleFilterChange = (e: FilterChangedEvent) => {
        sessionStorage.setItem(SESSION_KEYS.WEEKLY_REPORT_FILTERS, JSON.stringify(e.api.getFilterModel()));
    }

    return <>
        <GridCsvDownload colDef={columnDefs} fileName={`Weekly_prodution_reports_area_${areaId}.csv`} />
        <div ref={gridWrapperRef} className="grid-wrapper ag-theme-material" style={{ height: gridHeight }}>
        <AgGridReact
                paginationPageSize={100}
                cacheBlockSize={100}
                blockLoadDebounceMillis={100}
                pagination={true}
                ref={ref}
                rowModelType={'serverSide'}
                serverSideSortOnServer={true}
                serverSideFilterOnServer={true}
                defaultColDef={
                    {
                        resizable: true,
                        cellRenderer: ValueRenderer,
                        sortable: true,
                        menuTabs: ['filterMenuTab'],
                    }
                }
                columnDefs={columnDefs}
                onFilterChanged={handleFilterChange}
                onPaginationChanged={(params: PaginationChangedEvent) => {
                    if (params.newPage && !avoidServerCall) {
                        sessionStorage.setItem(SESSION_KEYS.WEEKLY_REPORT_PAGE, (params.api.paginationGetCurrentPage() || 0).toString());
                    }
                }}
                onGridReady={(params: GridReadyEvent) => {
                    params.api.setServerSideDatasource(dataSource(areaId));
                }}
                onFirstDataRendered={(params: FirstDataRenderedEvent) => {
                    setTimeout(() => {
                        const defaultPage = parseInt(sessionStorage.getItem(SESSION_KEYS.WEEKLY_REPORT_PAGE) || '0');
                        params.api.paginationGoToPage(defaultPage);
                    })
                    setTimeout(() => {
                        setAvoidServerCall(false);
                        params.api.refreshServerSide();
                    }, 50);
                }}
            />
        </div>
    </>
})

export default WeeklyProductionReportsGrid;