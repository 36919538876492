import React, { FC, MouseEvent, useMemo, useEffect } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import DownloadIcon from '@mui/icons-material/Download';
import BighamButton from '../FormControls/BighamButton/BighamButton';

interface DownloadCellRendererProps extends ICellRendererParams {
    handleCellClick(data: any): (e: MouseEvent<Element>) => void,
    hoverTitle: string,
}

const DownloadCellRenderer: FC<DownloadCellRendererProps> = ({handleCellClick, hoverTitle, ...params}) => {


    return <span className="icon-wrapper align-center" title={hoverTitle}>
        <DownloadIcon className="cursor-pointer" onClick={handleCellClick(params.data)} />
    </span>
};

export default DownloadCellRenderer;
