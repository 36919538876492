import { AgGridReact } from 'ag-grid-react';
import React, { CellClickedEvent, ColDef, GridReadyEvent, GridSizeChangedEvent, RowClassParams, RowClickedEvent, RowDoubleClickedEvent, RowNode, ValueFormatterParams, ValueGetterParams} from 'ag-grid-community';
import { FC, ForwardedRef } from 'react';
import MultiSelectFilter from '../../../../library/components/GridComponents/GridMultiSelectFilter';
import { forwardRef } from 'react';
import ValueRenderer from '../../../../library/components/GridComponents/ValueRenderer';
import { formatNumberWithCommas } from '../../../../library/util/numberFormatter';
import { IJobCodeLineItem } from '../../../../main/types/jobDetailTypes';
import DeleteRenderer from '../../../../library/components/GridComponents/DeleteRenderer';
import { moneyFormatter } from '../../../JobTracker/trackerColumnFactory';


interface PurchaseOrderGridProps {
    listItems: IJobCodeLineItem[];
    ref: ForwardedRef<AgGridReact>;
    handleDoubleClick(params: RowDoubleClickedEvent): void;
    handleDelete(data: IJobCodeLineItem, index: number): void;
}

const PurchaseOrderGrid: FC<PurchaseOrderGridProps> = forwardRef<AgGridReact, PurchaseOrderGridProps>(({listItems, handleDoubleClick, handleDelete}, ref) => {

    const columnDefs: ColDef[] = [
        {
            field: 'jobCode',
            headerName: 'Code',
            width: 150,
            maxWidth: 150,
            minWidth: 150,
            filter: 'agTextColumnFilter'
        },{
            field: 'description',
            headerName: 'Description',
            filter: 'agTextColumnFilter'
        },{
            field: 'quantity',
            headerName: 'Quantity',
            filter: 'agNumberColumnFilter',
            valueFormatter: (params: ValueFormatterParams) => {
                return formatNumberWithCommas(params.data['quantity'])
            },
            width: 135,
            maxWidth: 120,
            minWidth: 120,
            type: 'rightAligned'
        },{
            field: 'pricePerUnit',
            headerName: 'Unit Price',
            filter: 'agNumberColumnFilter',
            valueGetter: moneyFormatter('pricePerUnit'),
            width: 135,
            maxWidth: 135,
            minWidth: 135,
            type: 'rightAligned'
        },{
            field: 'total',
            headerName: 'Total',
            filter: 'agNumberColumnFilter',
            valueGetter: moneyFormatter('total'),
            width: 150,
            maxWidth: 150,
            minWidth: 150,
            type: 'rightAligned'
        },{
            field: 'id',
            headerName: '',
            width: 50,
            maxWidth: 50,
            minWidth: 50,
            cellRenderer: DeleteRenderer,
            cellRendererParams: {
                handleDelete
            },
            hide: !listItems.some((item) => !item.id)
        }
    ];

    

    return <div className="grid-wrapper ag-theme-material">
       {!!listItems?.length && <AgGridReact
            ref={ref}
            components={{
                multiSelect: MultiSelectFilter
            }}
            onRowDoubleClicked={handleDoubleClick}
            domLayout="autoHeight"
            defaultColDef={
                {
                    resizable: true,
                    cellRenderer: ValueRenderer,
                    sortable: true,
                    menuTabs: ['filterMenuTab'],
                }
            }
            columnDefs={columnDefs}
            rowData={listItems}
            onGridReady={(event: GridReadyEvent) => {
                event.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(event: GridSizeChangedEvent) => {
                event.api.sizeColumnsToFit();
            }}
        />}
    </div>
})

export default PurchaseOrderGrid;