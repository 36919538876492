import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  transformDocumentsForDisplay,
  transformJobDetailCreateOrUpdatePayload,
  transformJobDetailResponsePayload
} from '../../library/transforms/jobDetailsTransform';
import {
  createBackCharge,
  createInvoiceRequest,
  createJobDetail,
  createNewPermitEntity,
  downloadFile,
  deleteFile,
  getInvoiceDailyCSVDownload,
  getJobDetailData,
  issueInvoiceRequest,
  updateBackCharge,
  updateJobDetail,
  uploadFiles,
  validateFileNames,
  rejectInvoice,
  getInvoiceDailyData
} from '../api/jobDetailApi';
import { getFileTypeList } from '../api/lookupApi';
import type { AppDispatch, RootState } from '../store/store';
import { IAddress } from '../types/genericTypes';
import {
  IBackChargeCreatePayload,
  IBackChargeUpdatePayload,
  IInvoiceRequestPayload,
  IIssueInvoicePayload,
  IRejectInvoicePayload,
  IJobDetail,
  IJobDetailCombined
} from '../types/jobDetailTypes';
import {
  callGetPermitEntityList,
  callGetUserLookupsByArea,
  selectFileTypeList,
  setFileTypeList
} from './lookupSlice';
import { setSelectedArea } from './utilSlice';

interface JobDetailState {
  jobDetailData: IJobDetailCombined;
  newJobId: number;
  downloadPacket: IDownloadPacket;
  annotationData: IAnnotationData;
}
export interface IDailyModalJobDetailSummary {
  business: string,
  address: string,
  city: string,
  state: string,
  zip: string,
  aerialFootage: number,
  aerialFootageComplete: number,
  undergroundFootage: number,
  undergroundFootageComplete: number,
}

interface IDownloadPacket {
  name?: string;
  data?: Blob;
}

export interface IAnnotationData{
  url?: string,
  name?: string
}

// Define the initial state using that type
export const initialJobDetailState: JobDetailState = {
  jobDetailData: {
    details: {
      id: 0,
      area: 0,
      category: 0,
      status: [],
      job: '',
      cpr: '',
      node: '',
      bCoord: 0,
      cCoords: [],
      tCoord: 0,
      recDate: '',
      dueDate: '',
      tsdDate: '',
      completeDate: '',
      business: '',
      addressId: 0,
      address1: '',
      city: '',
      state: '',
      zip: '',
      lat: '',
      lng: '',
      comments: '',
      sendConfirmationEmail: false,
      lastUpdateDateTime: '',
      lastUpdateUser: {},
      lastUpdateUserId: 0,
    },
    purchaseOrder: {
      purchaseOrderNumber: '',
      purchaseOrderAmount: 0,
      jobCodes: []
    },
    aerial: {
      totalFootage: 0,
      totalFootageCompleted: 0,
      completeDate: '',
      teams: []
    },
    underground: {
      totalFootage: 0,
      totalFootageCompleted: 0,
      completeDate: '',
      teams: []
    },
    splicer: {
      completeDate: '',
      teams: []
    },
    locates: [],
    permits: [],
    documents: {},
    dailies: [],
    invoices: {
      invoices: [],
      financialSummary: {
        purchaseOrderAmount: 0,
        paidOut: 0,
        invoicedToDate: 0,
        readyToInvoice: 0,
        remainingOnPurchaseOrder: 0,
        profitLoss: 0
      }
    },
    invoicing: {
      jobCodes: [],
      invoiceSummary: {}
    }
  },
  newJobId: 0,
  downloadPacket: {},
  annotationData: {}
};

export const callGetJobDetailData = createAsyncThunk<
  any,
  number,
  { state: RootState; dispatch: AppDispatch }
>(
  'jobDetail/getJobDetailData',
  async (jobId: number, { dispatch, getState }) => {
    let fileTypesList = selectFileTypeList(getState());
    if (!fileTypesList?.length) {
      const fileTypeResponse = await getFileTypeList();
      dispatch(setFileTypeList(fileTypeResponse.data));
      fileTypesList = fileTypeResponse.data;
    }
    const response = await getJobDetailData(jobId);
    const transformedResponse = transformJobDetailResponsePayload(
      response.data,
      fileTypesList
    );
    dispatch(setJobDetailData(transformedResponse));
    dispatch(callGetUserLookupsByArea(transformedResponse.details.area));
    dispatch(setSelectedArea(transformedResponse.details.area));
  }
);

export const callUpdateJobDetail = createAsyncThunk<
  any,
  IJobDetailCombined,
  { dispatch: AppDispatch }
>('jobDetail/updateJob', async (job, { dispatch }) => {
  const response = await updateJobDetail(
    transformJobDetailCreateOrUpdatePayload(job)
  );
  dispatch(callGetJobDetailData(response.data));
});

export const callCreateJobDetail = createAsyncThunk(
  'jobDetail/createJob',
  async (job: IJobDetailCombined, { dispatch }) => {
    const response = await createJobDetail(
      transformJobDetailCreateOrUpdatePayload(job)
    );
    dispatch(setNewJobId(response.data));
  }
);

export const callValidateFileNames = createAsyncThunk<any, any, { state: RootState }>(
  'jobDetail/validateFileNames',
  async (list: string[], { getState }) => {
    const jobDetails = selectJobDetailData(getState());;
    const response = await validateFileNames(list, jobDetails.details.id);
    return response.data;
  }
)

export const callUploadFiles = createAsyncThunk<
  any,
  { payload: FormData; jobId: string, jobDetailModel: any, setJobDetailModel: any },
  { dispatch: AppDispatch, state: RootState }
>('jobDetail/uploadFiles', async ({ payload, jobId, jobDetailModel, setJobDetailModel }, { dispatch, getState }) => {
  await uploadFiles(payload, jobId);
  const response = await getJobDetailData(+jobId);

  let fileTypesList = selectFileTypeList(getState());
  if (!fileTypesList?.length) {
    const fileTypeResponse = await getFileTypeList();
    dispatch(setFileTypeList(fileTypeResponse.data));
    fileTypesList = fileTypeResponse.data;
  }

  setJobDetailModel({...jobDetailModel, documents: transformDocumentsForDisplay(response.data.documents, fileTypesList)})
});

export const callDownloadFile = createAsyncThunk<
  any,
  { fileId: number; fileName: string }
>('jobDetail/downloadFile', async ({ fileId, fileName }, { dispatch }) => {
  const response = await downloadFile(fileId);
  dispatch(
    setDownloadPacket({
      data: response.data,
      name: fileName
    })
  );
});

export const callDeleteFile = createAsyncThunk<
  any,
  { fileId: number; fileName: string }
>('jobDetail/deleteFile', async ({ fileId }, { dispatch }) => {
  await deleteFile(fileId);
  // dispatch(callGetJobDetailData(jobId))
});

export const callAnnotateFile = createAsyncThunk<
  any,
  { fileId: number; fileName: string }
>('jobDetail/annotateFile', async ({ fileId, fileName }, { dispatch }) => {
  const response = await downloadFile(fileId);
  dispatch(
    setAnnotationData({
      url: window.URL.createObjectURL(response.data),
      name: fileName
    })
  );
});

export const callCreateInvoiceRequest = createAsyncThunk<
  any,
  IInvoiceRequestPayload,
  { dispatch: AppDispatch }
>('jobDetail/createInvoiceRequest', async (payload, { dispatch }) => {
  await createInvoiceRequest(payload);
  dispatch(callGetJobDetailData(payload.jobId));
});

export const callIssueInvoice = createAsyncThunk<
  any,
  IIssueInvoicePayload,
  { dispatch: AppDispatch; state: RootState }
>('jobDetails/issueInvoice', async (payload, { dispatch, getState }) => {
  await issueInvoiceRequest(payload);
  const jobDetails = selectJobDetailData(getState());
  if (jobDetails.details.id) {
    dispatch(callGetJobDetailData(jobDetails.details.id));
  }
  return true;
});

export const callRejectInvoice = createAsyncThunk<
  any,
  IRejectInvoicePayload,
  { dispatch: AppDispatch }
>('jobDetails/rejectInvoice', async (payload, { dispatch }) => {
  await rejectInvoice(payload);
  dispatch(callGetJobDetailData(payload.jobId));
});

export const callCreateBackCharge = createAsyncThunk<
  any,
  IBackChargeCreatePayload,
  { dispatch: AppDispatch; state: RootState }
>('jobDetail/createBackCharge', async (payload, { dispatch, getState }) => {
  await createBackCharge(payload);
  const jobDetails = selectJobDetailData(getState());
  if (jobDetails.details.id) {
    dispatch(callGetJobDetailData(jobDetails.details.id));
  }
});

export const callUpdateBackCharge = createAsyncThunk<
  any,
  IBackChargeUpdatePayload,
  { dispatch: AppDispatch; state: RootState }
>('jobDetail/createBackCharge', async (payload, { dispatch, getState }) => {
  await updateBackCharge(payload);
  const jobDetails = selectJobDetailData(getState());
  if (jobDetails.details.id) {
    dispatch(callGetJobDetailData(jobDetails.details.id));
  }
});

export const callCreateNewPermitEntity = createAsyncThunk(
  'jobDetail/createPermitEntity',
  async (
    payload: {
      name: string;
      dataHandler: (value: any, fieldName: string) => void;
    },
    { dispatch }
  ) => {
    const response = await createNewPermitEntity(payload);
    payload.dataHandler(response.data, 'permitEntityId');
    dispatch(callGetPermitEntityList());
  }
);

export const callGetInvoiceDailyCSVDownload = createAsyncThunk(
  'jobDetail/downloadInvoiceDailyCSV',
  async (
    { invoiceId, fileName }: { invoiceId: number; fileName: string },
    { dispatch }
  ) => {
    const response = await getInvoiceDailyCSVDownload(invoiceId);
    dispatch(
      setDownloadPacket({
        data: response.data,
        name: fileName
      })
    );
    return response.data;
  }
);

export const callGetInvoiceDailyData = createAsyncThunk(
  'jobDetail/downloadInvoiceDailyCSV',
  async (
    { invoiceId }: { invoiceId: number }
  ) => {
    const response = await getInvoiceDailyData(invoiceId);
    return response.data || [];
  }
);

export const jobDetailSlice = createSlice({
  name: 'jobDetail',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: initialJobDetailState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setJobDetailData: (state, action: PayloadAction<IJobDetailCombined>) => {
      state.jobDetailData = action.payload;
    },
    setNewJobId: (state, action: PayloadAction<number>) => {
      state.newJobId = action.payload;
    },
    setDownloadPacket: (
      state,
      action: PayloadAction<{ data: Blob; name: string }>
    ) => {
      state.downloadPacket = action.payload;
    },
    resetDownloadPacket: (state) => {
      state.downloadPacket = {};
    },
    setAnnotationData: (state, action: PayloadAction<IAnnotationData>) => {
      state.annotationData = action.payload;
    }
  }
});

export const {
  setJobDetailData,
  setNewJobId,
  setDownloadPacket,
  resetDownloadPacket,
  setAnnotationData
} = jobDetailSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectJobDetailData = (state: RootState): IJobDetailCombined =>
  state.jobDetail.jobDetailData;
export const selectNewJobId = (state: RootState): number =>
  state.jobDetail.newJobId;
export const selectDownloadPacket = (state: RootState): IDownloadPacket =>
  state.jobDetail.downloadPacket;
export const selectAnnotationData = (state: RootState): IAnnotationData =>
  state.jobDetail.annotationData;
export default jobDetailSlice.reducer;
